import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { DateFormatService } from '../../Services/date-format.service';
var CryptoJS = require("crypto-js");

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.css']
})
export class EditClientComponent implements OnInit {

  client:any;

  typelist = [{
    label:'Kedai Makanan/Restoran Makanan',
    value:'makanan'
  },
  {
    label:'Kedai Runcit/Serbaneka',
    value:'runcit'
  },
  {
    label:'Stesen Minyak',
    value:'minyak'
  },
  {
    label:'Lain-lain',
    value:'lain'
  },]


  constructor(  private dialogRef: MatDialogRef<EditClientComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,
    private tosterservice: ToastrService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private  dialog : MatDialog,
    private angularFireAuth: AngularFireAuth,
  ) {
      this.client = data;

    }

  ngOnInit() {
  }

  private dateFormat = new DateFormatService();

  async addLog(detail,type, message){

    this.angularFireAuth.authState.subscribe(async authState => {
      if(authState){
        const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
        const info = {
          message: message,
          date: new Date(),
          data: detail,
          updatedBy: authState.email,
          dateFormat: dateFormat,
          type:type
        };
        await this.firestore.collection('AdminLog').add(info);
      
      }
     });

   
  }
  cancel(){
    this.dialogRef.close(false);
  }

  confirm(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: 'Adakah anda mengesahkan maklumat ini?'
    };
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result){
        this.spinner.show();
        this.firestore.collection("users").doc(this.client.key).update({
          name:this.client.name,
          shop_name:this.client.shop_name,
          address:this.client.address,
          phone_num:this.client.phone_num,
          business_type:this.client.business_type,
          email:this.client.email,
          poscode: this.client.poscode,
          lot: parseFloat(this.client.lot)
        })
          .then(r=>{
          this.spinner.hide();
          this.toaster.success('Kemaskini berjaya');
        this.addLog(this.client, 'user', `${this.client.name} telah dikemaskini. \nNama Pemilik Premis: ${this.client.name}\n Name Premis: ${this.client.shop_name}\n `)
        this.dialogRef.close(this.client);
        })

      }
    })
  }
  
}
