import { PdfRangePickerComponent } from './pdf-range-picker/pdf-range-picker.component';
import { Component, OnInit, Inject, ViewChild, AfterContentInit, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import { PdfMonthPickerComponent } from './pdf-month-picker/pdf-month-picker.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-pdf-date-picker',
  templateUrl: './pdf-date-picker.component.html',
  styleUrls: ['./pdf-date-picker.component.css']
})
export class PdfDatePickerComponent implements OnInit {

  minDate: Date;
  maxDate: Date;
  dailyDate: Date;
  WeeklyDate: Date;
  MonthDate: Date;
  chooseDate: Date;
  dateFrom: Date;
  dateTo: Date;
  firstDay: any;
  lastDay: any;
  @ViewChild('PdfMonthPickerComponent', { read: PdfMonthPickerComponent, static: true }) month: PdfMonthPickerComponent;

  constructor(
    private dialogRef: MatDialogRef<PdfDatePickerComponent>,
    @Inject(MAT_DIALOG_DATA) data, private dialog: MatDialog) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 20, 0, 1);
    this.maxDate = new Date();

  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  detectChanges(event) {
    this.MonthDate = event;
    this.chooseDate = this.MonthDate;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      message: 'Export the data of this date: ' + (this.chooseDate.getMonth() + 1) + '/' + this.chooseDate.getFullYear(),
    };

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(r => {
      if (r) {
        this.submit('month');
      }
    });
  }

  openDate() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.open(PdfRangePickerComponent, dialogConfig).afterClosed().subscribe(r => {
      if (r) {
        this.submit('week', r);
      }
    });
  }

  datePicked() {
    this.chooseDate = this.dailyDate;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      message: 'Export the data of this date: ' + this.chooseDate.toLocaleDateString(),
    };

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(r => {
      if (r) {
        this.submit('day');
      }
    });

  }

  weekPicked() {
    this.chooseDate = this.WeeklyDate;
    this.firstDay = new Date(this.WeeklyDate.setDate(this.WeeklyDate.getDate() - this.WeeklyDate.getDay()));
    this.lastDay = new Date(this.WeeklyDate.setDate(this.WeeklyDate.getDate() - this.WeeklyDate.getDay() + 6));

    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      message: 'Export the data of this date: ' + this.firstDay.toLocaleDateString() + ' to ' + this.lastDay.toLocaleDateString(),
    };

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(r => {
      if (r) {
        this.submit('week');
      }
    });
  }

  submit(typeofDate, r:any={}) {
    if(r.from){
      const result = {
        dateFrom: r.from,
        dateTo: r.to,
        type: typeofDate
      };
      this.dialogRef.close(result);
    }else{
      const result = {
        date: this.chooseDate,
        type: typeofDate
      };
      this.dialogRef.close(result);
    }

  }

}
