import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ViewChild, AfterViewInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatPaginator, PageEvent, Sort } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Chart } from 'chart.js';
import { ExcelHelperService } from '../Services/excel-helper.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements AfterViewInit {

  depohlist = [];
  constDevicelist = [];
  devicelist = [];
  sorteddevicelist = [];
  length = 0;
  pageSize = 5;
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  searchbox = '';
  searchValue = '';
  sortedu = {
    active: '',
    direction: ''
  };

  lorries = [];
  IDLETIME = 0;
  OVERSPEED = 0;
  pieChartOperasi: Chart;
  pieChartTidakOperasi: Chart;
  pieChartKenderaan: Chart;

  firstDay: Date;
  lastDay: Date;
  selection: string = "Day";

  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  selectedDate: Date;
  maxDate: Date;
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    private excelHelper: ExcelHelperService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private cd: ChangeDetectorRef
  ) {

    this.selectedDate = new Date(new Date().setDate(new Date().getDate() - 1));
    this.firstDay = new Date(this.selectedDate.setDate(this.selectedDate.getDate() - this.selectedDate.getDay()));
    this.lastDay = new Date(this.selectedDate.setDate(this.selectedDate.getDate() - this.selectedDate.getDay() + 6));
    this.maxDate = new Date();
    this.selectedDate = new Date(new Date().setDate(new Date().getDate() - 1));

    this.SetupTable();
    this.firestore.collection('Setting').doc('IDLE').get().forEach(e => {
      this.IDLETIME = parseInt(e.data().time);
      this.OVERSPEED = parseInt(e.data().speed);

    })
  }

  depoh:any = "All";

  changeDepoh(value) {
    this.depoh = value;
    if (this.depoh === 'All') {
      this.sorteddevicelist = this.constDevicelist.slice();
      this.devicelist = this.sorteddevicelist.slice();

      this.limitDevice();
      this.length = this.sorteddevicelist.length;

    }
    else {
      this.sorteddevicelist = this.constDevicelist.filter(d => d.Depoh === this.depoh);
      this.devicelist = this.sorteddevicelist.slice();
      this.limitDevice();
      this.length = this.sorteddevicelist.length;

    }

    this.GetData();
  }

  async SetupTable() {
    this.spinner.show();
    this.constDevicelist = [];
    this.depohlist = [];
    this.resetChart();

    await this.firestore.collection('Devices').get().forEach(devices => {
      devices.forEach(d => {
        const info = {
          ...d.data()
        }
        this.constDevicelist.push(info)
        if (d.data().LorryType === 'Garbage Truck')
          this.pieChartKenderaan.data.datasets[0].data[0]++;
        else if (d.data().LorryType === 'Roro Truck')
          this.pieChartKenderaan.data.datasets[0].data[1]++;
        this.pieChartKenderaan.update();
      })

      if(this.constDevicelist.length > 0){
        this.pieChartKenderaan.data.datasets[0].data[2] = undefined;
        this.pieChartKenderaan.update();

      }

      this.sorteddevicelist = this.constDevicelist.slice(this.offset, (this.offset + this.pageSize));
      this.devicelist = this.constDevicelist.slice();
      this.length = this.constDevicelist.length;
      this.spinner.hide();
    })

    await this.firestore.collection('Depoh').get().forEach(depoh => {
      depoh.forEach(d => {
        const info = {
          key: d.id,
          ...d.data()
        }
        this.depohlist.push(info)

      })


    })
    this.getHistory();

  }

  async GetData(){
    this.resetChart();
    this.getHistory();
  }

  update() {
    this.barChart.update();

  }

  selectionChange(value) {
    this.selection = value;
    this.GetData()
  }
  isFetching = true;

  operasiKenderaan = 0;
  nonoperasiKenderaan = 0;
  dayMultiple = 0;
  getHistory() {
    this.spinner.show();
    var counter = 0;
    this.graphCount = 0;
    this.operasiKenderaan = 0;
    this.nonoperasiKenderaan = 0;
    this.graphLabel = [0, 0];
    this.numberOverSpeed = 0;
    this.numberBrek = 0;
    this.numberStop = 0;
    this.previousOperasiKenderaan = 0;
    this.dayMultiple = 0;
    this.previousNonOperasiKenderaan = 0;

    if(this.devicelist.length === 0){
      this.spinner.hide();
    }

    this.devicelist.forEach((device, ind) => {
      device.info = null
      if (this.selection === 'Day') {
        this.dayMultiple = 1;
        const dateformat = this.selectedDate.getFullYear() + ("0" + (this.selectedDate.getMonth() + 1).toString()).slice(-2) + ("0" + (this.selectedDate.getDate()).toString()).slice(-2)
        var snapshot = this.firestore.collection('Devices').doc(device.ID).collection('History', ref => ref.where('date', '==', dateformat)).get()
        snapshot.forEach(t => {
          if (t.size === 0) {
            if (device.LorryType === 'Garbage Truck')
              this.pieChartTidakOperasi.data.datasets[0].data[0]++;
            else if (device.LorryType === 'Roro Truck')
              this.pieChartTidakOperasi.data.datasets[0].data[1]++;

            this.nonoperasiKenderaan++;
            const info = {
              completion: 0,
              halfCompletion: 0,
              totalStreet: 0,
              numOfIdle: 0,
              numOfOverSpeed: 0,
              speedSettings: 0,
              idleSettings: 0,
              idle: 0,
              overSpeed: 0,
              noData: true,
            }
            device.info = info;
            this.updateGraph(device, null, null, dateformat);
            counter++;
            if (counter === this.devicelist.length)
              this.isFetching = false;
          }
          else {
            if (device.LorryType === 'Garbage Truck')
              this.pieChartOperasi.data.datasets[0].data[0]++;
            else if (device.LorryType === 'Roro Truck')
              this.pieChartOperasi.data.datasets[0].data[1]++;
            this.operasiKenderaan++;
          }

          t.forEach(trip => {
            if (trip.data().status) {
              const info = {
                completion: trip.data().completion,
                halfCompletion: trip.data().halfCompletion,
                totalStreet: trip.data().totalStreet,
                numOfIdle: trip.data().numOfIdle,
                numOfOverSpeed: trip.data().numOfOverSpeed,
                speedSettings: this.OVERSPEED,
                idleSettings: this.IDLETIME,
                idle: trip.data().idle,
                overSpeed: trip.data().overSpeed,
                status: trip.data().status,
                noData: false,
              }
              device.info = info;
              this.updateGraph(device, null, null, dateformat);

              counter++;
              if (counter === this.devicelist.length)
                this.isFetching = false;
            } else {
              if (trip.data().routeID) {
                const dateFrom = new Date(this.selectedDate);
                const startHrs = parseInt(trip.data().startAt.split(':')[0]);
                const startMin = parseInt(trip.data().startAt.split(':')[1]);
                dateFrom.setHours(startHrs);
                dateFrom.setMinutes(startMin);
                dateFrom.setSeconds(0);
                const dateto = new Date(dateFrom);
                const endHrs = parseInt(trip.data().endAt.split(':')[0]);
                const endMin = parseInt(trip.data().endAt.split(':')[1]);
                var h = 0;
                var m = 0;
                if (endHrs - startHrs >= 0) {
                  if (endMin - startMin >= 0) {
                    h = endHrs - startHrs;
                    m = endMin - startMin;
                  } else {
                    h = endHrs - startHrs;
                    if (h > 0)
                      h = h - 1;
                    m = endMin + 60 - startMin;
                  }
                } else {
                  h = endHrs + 24 - startHrs;
                  if (endMin - startMin >= 0) {
                    m = endMin - startMin;
                  } else {
                    m = endMin + 60 - startMin;
                    h = h - 1;
                  }
                }

                dateto.setHours(dateto.getHours() + h)
                dateto.setMinutes(dateFrom.getMinutes() + m)

                this.firestore.collection('Route').doc(trip.data().routeID).get().forEach(plan => {
                  const pathSavedlist = [];
                  const idle = [];
                  const positionlist = [];
                  const timelist = [];
                  const RegionLog = [];
                  const overSpeed = [];

                  plan.data().regions.forEach((e, i) => {
                    let empty = [];
                    RegionLog.push(empty);

                    this.firestore.collection('Region').doc(e).get().forEach(value => {
                      const paths = [];
                      value.data().coordinates.forEach(coor => {
                        paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
                      });
                      const timeSetting = {
                        timeSetting: value.data().timeSetting
                      }
                      const path = new google.maps.Polygon({
                        ...timeSetting,
                        paths: paths,
                        strokeColor: 'black',
                        fillColor: 'red',
                        strokeOpacity: 1.0,
                        strokeWeight: 1,

                      });
                      pathSavedlist.push(path);
                    })
                  });

                  setTimeout(() => {
                    this.firestore.collection('Devices').doc(device.ID).collection('History').doc(trip.id).collection('location', ref => ref.orderBy('Timestamp', 'asc')).get().forEach(l => {
                      var count = 0;
                      var currenttime;
                      var endtime;
                      var flag = true;
                      var nexttime = new Date();

                      var overspeedtime;
                      var nextoverspeedtime;
                      var numberOverSpeed = 0;

                      l.forEach((location) => {
                        if ((location.data().Speed > this.OVERSPEED)) {
                          overspeedtime = new Date(location.data().Timestamp * 1000);
                          if (!nextoverspeedtime) {
                            nextoverspeedtime = new Date(overspeedtime);
                            nextoverspeedtime.setMinutes(overspeedtime.getMinutes() + 1);
                            numberOverSpeed++;
                            const inf = {
                              time: new Date(location.data().Timestamp * 1000),
                            }
                            overSpeed.push(inf)
                          } else {
                            if (overspeedtime.getTime() > nextoverspeedtime.getTime()) {
                              nextoverspeedtime = new Date(overspeedtime);
                              nextoverspeedtime.setMinutes(overspeedtime.getMinutes() + 1);
                              numberOverSpeed++;
                              const inf = {
                                time: new Date(location.data().Timestamp * 1000),
                              }
                              overSpeed.push(inf)
                            }
                          }
                        }

                        if ((!location.data().Speed || location.data().Speed === 0) && flag) {
                          currenttime = new Date(location.data().Timestamp * 1000);
                          nexttime = new Date(currenttime);
                          nexttime.setMinutes(currenttime.getMinutes() + this.IDLETIME);
                          flag = false;
                        } else if (location.data().Speed && !flag) {
                          if (location.data().Speed > 0) {
                            if (l.docs[count].data().Speed > location.data().Speed) {

                            } else {

                              if (nexttime.getTime() < new Date(location.data().Timestamp * 1000).getTime()) {
                                endtime = new Date(location.data().Timestamp * 1000);

                              }
                            }
                          }
                        }
                        if (currenttime && endtime) {
                          flag = true;
                          const idletime = {
                            starttime: currenttime,
                            endtime
                          }
                          nexttime = null;
                          currenttime = null;
                          endtime = null;
                          idle.push(idletime);
                        }
                        count++;
                        timelist.push(new Date(location.data().Timestamp * 1000));
                        positionlist.push(new google.maps.LatLng(location.data().Latitude,
                          location.data().Longitude));
                      });

                      if (positionlist.length > 1) {
                        for (let i = 0; i < positionlist.length; i++) {
                          pathSavedlist.forEach((ab, index) => {
                            const resultPath = google.maps.geometry.poly.containsLocation(
                              positionlist[i],
                              ab
                            )
                            if (resultPath) {
                              const info = {
                                message: "IN",
                                time: timelist[i],
                                position: positionlist[i],
                              }
                              if (RegionLog[index].length === 0) {
                                RegionLog[index].push(info)
                              }
                              else if (RegionLog[index][RegionLog[index].length - 1]) {
                                let m = RegionLog[index][RegionLog[index].length - 1].message;
                                if (m !== 'IN') {
                                  RegionLog[index].push(info)
                                }
                              }


                            } else {
                              const info = {
                                message: "OUT",
                                time: timelist[i],
                                position: positionlist[i],
                              }
                              if (RegionLog[index][RegionLog[index].length - 1]) {
                                let m = RegionLog[index][RegionLog[index].length - 1].message;
                                if (m === 'IN') {
                                  RegionLog[index].push(info)
                                }
                              }
                            }
                          })
                        }
                        let completion = 0;
                        let halfCompletion = 0;
                        RegionLog.forEach((f, ii) => {
                          if (f.length > 1) {
                            let ttime = 0;
                            f.forEach((element, ll) => {
                              if (ll % 2 === 1) {
                                ttime += element.time.getTime() - f[ll - 1].time.getTime()
                              }
                            });
                            var minutes = Math.floor(ttime / 60000);
                            if (minutes >= parseInt(pathSavedlist[ii].timeSetting)) {
                              completion++;
                            } else {
                              halfCompletion++;
                            }
                          }
                        })

                        const d1 = new Date().getFullYear() + ("0" + (new Date().getMonth() + 1).toString()).slice(-2) + ("0" + (new Date().getDate()).toString()).slice(-2)

                        const info = {
                          completion,
                          halfCompletion,
                          totalStreet: pathSavedlist.length,
                          numOfIdle: idle.length,
                          numOfOverSpeed: numberOverSpeed,
                          speedSettings: this.OVERSPEED,
                          idleSettings: this.IDLETIME,
                          idle: idle,
                          overSpeed: overSpeed,
                          status: dateformat === d1 ? false : true,
                          noData: false,
                        }
                        device.info = info;
                        this.updateGraph(device, null, null, dateformat);

                        counter++;
                        if (counter === this.devicelist.length)
                          this.isFetching = false;
                        this.firestore.collection('Devices').doc(device.ID).collection('History').doc(trip.id).update(info);
                      } else {
                        // this.spinner.hide();
                      }
                      if (l.size === 0) {
                        const info = {
                          completion: 0,
                          halfCompletion: 0,
                          totalStreet: 0,
                          numOfIdle: 0,
                          numOfOverSpeed: 0,
                          speedSettings: 0,
                          idleSettings: 0,
                          idle: 0,
                          overSpeed: 0,
                          noData: true,
                        }
                        device.info = info;
                        this.updateGraph(device, null, null, dateformat);

                        counter++;
                        if (counter === this.devicelist.length)
                          this.isFetching = false;
                      }
                    });
                  }, 3000);
                })
              }
            }

          })



          if (this.operasiKenderaan > 0)
            this.pieChartOperasi.data.datasets[0].data[2] = undefined;
          if (this.nonoperasiKenderaan > 0)
            this.pieChartTidakOperasi.data.datasets[0].data[2] = undefined;


          this.pieChartOperasi.update();
          this.pieChartTidakOperasi.update();

        })

        this.getPreviousDataSet(device);
      }

      if (this.selection === 'Week') {
        const firstDay = new Date(this.firstDay);
        const lastDay = new Date(this.lastDay);

        const previousFirstDay = new Date(this.firstDay)
        const previousLastDay = new Date(this.lastDay);
        previousFirstDay.setDate(previousFirstDay.getDate()-7)
        previousLastDay.setDate(previousLastDay.getDate()-7)

        let df2 = previousFirstDay.getFullYear() + ("0" + (previousFirstDay.getMonth() + 1).toString()).slice(-2) + ("0" + (previousFirstDay.getDate()).toString()).slice(-2)
        df2 += '-' + previousLastDay.getFullYear() + ("0" + (previousLastDay.getMonth() + 1).toString()).slice(-2) + ("0" + (previousLastDay.getDate()).toString()).slice(-2)


        let df = firstDay.getFullYear() + ("0" + (firstDay.getMonth() + 1).toString()).slice(-2) + ("0" + (firstDay.getDate()).toString()).slice(-2)
        df += '-' + lastDay.getFullYear() + ("0" + (lastDay.getMonth() + 1).toString()).slice(-2) + ("0" + (lastDay.getDate()).toString()).slice(-2)


        for (let di = 0; di < 7; di++) {
          const year = firstDay.getFullYear();
          const day = ('0' + firstDay.getDate()).slice(-2);
          const month = ('0' + (firstDay.getMonth() + 1)).slice(-2);
          const dateString = year + month + day;

          const year2 = previousFirstDay.getFullYear();
          const day2 = ('0' + previousFirstDay.getDate()).slice(-2);
          const month2 = ('0' + (previousFirstDay.getMonth() + 1)).slice(-2);
          const dateString2 = year2 + month2 + day2;


          if(firstDay.getTime() > new Date().getTime())
              continue;

          this.dayMultiple ++;

          var snapshot = this.firestore.collection('Devices').doc(device.ID).collection('History', ref => ref.where('date', '==', dateString)).get()
          snapshot.forEach(t => {
            if (t.size === 0) {
              if (device.LorryType === 'Garbage Truck')
                this.pieChartTidakOperasi.data.datasets[0].data[0]++;
              else if (device.LorryType === 'Roro Truck')
                this.pieChartTidakOperasi.data.datasets[0].data[1]++;

              this.nonoperasiKenderaan++;
              const info = {
                completion: 0,
                halfCompletion: 0,
                totalStreet: 0,
                numOfIdle: 0,
                numOfOverSpeed: 0,
                speedSettings: 0,
                idleSettings: 0,
                idle: 0,
                overSpeed: 0,
                noData: true,
              }
              if (di === 0)
                device.info = info;
              else {
                device.info.completion += info.completion
                device.info.halfCompletion += info.halfCompletion
                device.info.totalStreet += info.totalStreet
                device.info.numOfIdle += info.numOfIdle
                device.info.numOfOverSpeed += info.numOfOverSpeed
                device.info.speedSettings += info.speedSettings
                device.info.idleSettings += info.idleSettings
                device.info.idle += info.idle
                device.info.overSpeed += info.overSpeed
                if (device.info.noData)
                  device.info.noData = info.noData
              }
              this.updateGraph(device,di,null,null,df);
              counter++;
              if (counter === this.devicelist.length)
                this.isFetching = false;
            }
            else {
              if (device.LorryType === 'Garbage Truck')
                this.pieChartOperasi.data.datasets[0].data[0]++;
              else if (device.LorryType === 'Roro Truck')
                this.pieChartOperasi.data.datasets[0].data[1]++;
              this.operasiKenderaan++;
            }

            t.forEach(trip => {
              if (trip.data().status) {
                const info = {
                  completion: trip.data().completion,
                  halfCompletion: trip.data().halfCompletion,
                  totalStreet: trip.data().totalStreet,
                  numOfIdle: trip.data().numOfIdle,
                  numOfOverSpeed: trip.data().numOfOverSpeed,
                  speedSettings: this.OVERSPEED,
                  idleSettings: this.IDLETIME,
                  idle: trip.data().idle,
                  overSpeed: trip.data().overSpeed,
                  status: trip.data().status,
                  noData: false,
                }
                if (di === 0)
                  device.info = info;
                else {
                  device.info.completion += info.completion
                  device.info.halfCompletion += info.halfCompletion
                  device.info.totalStreet += info.totalStreet
                  device.info.numOfIdle += info.numOfIdle
                  device.info.numOfOverSpeed += info.numOfOverSpeed
                  device.info.speedSettings += info.speedSettings
                  device.info.idleSettings += info.idleSettings
                  device.info.idle += info.idle
                  device.info.overSpeed += info.overSpeed
                  if (device.info.noData)
                    device.info.noData = info.noData
                }
                this.updateGraph(device,di,null,null,df);

                counter++;
                if (counter === this.devicelist.length)
                  this.isFetching = false;
              } else {
                if (trip.data().routeID) {
                  const dateFrom = new Date(this.selectedDate);
                  const startHrs = parseInt(trip.data().startAt.split(':')[0]);
                  const startMin = parseInt(trip.data().startAt.split(':')[1]);
                  dateFrom.setHours(startHrs);
                  dateFrom.setMinutes(startMin);
                  dateFrom.setSeconds(0);
                  const dateto = new Date(dateFrom);
                  const endHrs = parseInt(trip.data().endAt.split(':')[0]);
                  const endMin = parseInt(trip.data().endAt.split(':')[1]);
                  var h = 0;
                  var m = 0;
                  if (endHrs - startHrs >= 0) {
                    if (endMin - startMin >= 0) {
                      h = endHrs - startHrs;
                      m = endMin - startMin;
                    } else {
                      h = endHrs - startHrs;
                      if (h > 0)
                        h = h - 1;
                      m = endMin + 60 - startMin;
                    }
                  } else {
                    h = endHrs + 24 - startHrs;
                    if (endMin - startMin >= 0) {
                      m = endMin - startMin;
                    } else {
                      m = endMin + 60 - startMin;
                      h = h - 1;
                    }
                  }

                  dateto.setHours(dateto.getHours() + h)
                  dateto.setMinutes(dateFrom.getMinutes() + m)

                  this.firestore.collection('Route').doc(trip.data().routeID).get().forEach(plan => {
                    const pathSavedlist = [];
                    const idle = [];
                    const positionlist = [];
                    const timelist = [];
                    const RegionLog = [];
                    const overSpeed = [];

                    plan.data().regions.forEach((e, i) => {
                      let empty = [];
                      RegionLog.push(empty);

                      this.firestore.collection('Region').doc(e).get().forEach(value => {
                        const paths = [];
                        value.data().coordinates.forEach(coor => {
                          paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
                        });
                        const timeSetting = {
                          timeSetting: value.data().timeSetting
                        }
                        const path = new google.maps.Polygon({
                          ...timeSetting,
                          paths: paths,
                          strokeColor: 'black',
                          fillColor: 'red',
                          strokeOpacity: 1.0,
                          strokeWeight: 1,

                        });
                        pathSavedlist.push(path);
                      })
                    });

                    setTimeout(() => {
                      this.firestore.collection('Devices').doc(device.ID).collection('History').doc(trip.id).collection('location', ref => ref.orderBy('Timestamp', 'asc')).get().forEach(l => {
                        var count = 0;
                        var currenttime;
                        var endtime;
                        var flag = true;
                        var nexttime = new Date();

                        var overspeedtime;
                        var nextoverspeedtime;
                        var numberOverSpeed = 0;

                        l.forEach((location) => {
                          if ((location.data().Speed > this.OVERSPEED)) {
                            overspeedtime = new Date(location.data().Timestamp * 1000);
                            if (!nextoverspeedtime) {
                              nextoverspeedtime = new Date(overspeedtime);
                              nextoverspeedtime.setMinutes(overspeedtime.getMinutes() + 1);
                              numberOverSpeed++;
                              const inf = {
                                time: new Date(location.data().Timestamp * 1000),
                              }
                              overSpeed.push(inf)
                            } else {
                              if (overspeedtime.getTime() > nextoverspeedtime.getTime()) {
                                nextoverspeedtime = new Date(overspeedtime);
                                nextoverspeedtime.setMinutes(overspeedtime.getMinutes() + 1);
                                numberOverSpeed++;
                                const inf = {
                                  time: new Date(location.data().Timestamp * 1000),
                                }
                                overSpeed.push(inf)
                              }
                            }
                          }

                          if ((!location.data().Speed || location.data().Speed === 0) && flag) {
                            currenttime = new Date(location.data().Timestamp * 1000);
                            nexttime = new Date(currenttime);
                            nexttime.setMinutes(currenttime.getMinutes() + this.IDLETIME);
                            flag = false;
                          } else if (location.data().Speed && !flag) {
                            if (location.data().Speed > 0) {
                              if (l.docs[count].data().Speed > location.data().Speed) {

                              } else {

                                if (nexttime.getTime() < new Date(location.data().Timestamp * 1000).getTime()) {
                                  endtime = new Date(location.data().Timestamp * 1000);

                                }
                              }
                            }
                          }
                          if (currenttime && endtime) {
                            flag = true;
                            const idletime = {
                              starttime: currenttime,
                              endtime
                            }
                            nexttime = null;
                            currenttime = null;
                            endtime = null;
                            idle.push(idletime);
                          }
                          count++;
                          timelist.push(new Date(location.data().Timestamp * 1000));
                          positionlist.push(new google.maps.LatLng(location.data().Latitude,
                            location.data().Longitude));
                        });

                        if (positionlist.length > 1) {
                          for (let i = 0; i < positionlist.length; i++) {
                            pathSavedlist.forEach((ab, index) => {
                              const resultPath = google.maps.geometry.poly.containsLocation(
                                positionlist[i],
                                ab
                              )
                              if (resultPath) {
                                const info = {
                                  message: "IN",
                                  time: timelist[i],
                                  position: positionlist[i],
                                }
                                if (RegionLog[index].length === 0) {
                                  RegionLog[index].push(info)
                                }
                                else if (RegionLog[index][RegionLog[index].length - 1]) {
                                  let m = RegionLog[index][RegionLog[index].length - 1].message;
                                  if (m !== 'IN') {
                                    RegionLog[index].push(info)
                                  }
                                }


                              } else {
                                const info = {
                                  message: "OUT",
                                  time: timelist[i],
                                  position: positionlist[i],
                                }
                                if (RegionLog[index][RegionLog[index].length - 1]) {
                                  let m = RegionLog[index][RegionLog[index].length - 1].message;
                                  if (m === 'IN') {
                                    RegionLog[index].push(info)
                                  }
                                }
                              }
                            })
                          }
                          let completion = 0;
                          let halfCompletion = 0;
                          RegionLog.forEach((f, ii) => {
                            if (f.length > 1) {
                              let ttime = 0;
                              f.forEach((element, ll) => {
                                if (ll % 2 === 1) {
                                  ttime += element.time.getTime() - f[ll - 1].time.getTime()
                                }
                              });
                              var minutes = Math.floor(ttime / 60000);
                              if (minutes >= parseInt(pathSavedlist[ii].timeSetting)) {
                                completion++;
                              } else {
                                halfCompletion++;
                              }
                            }
                          })

                          const d1 = new Date().getFullYear() + ("0" + (new Date().getMonth() + 1).toString()).slice(-2) + ("0" + (new Date().getDate()).toString()).slice(-2)

                          const info = {
                            completion,
                            halfCompletion,
                            totalStreet: pathSavedlist.length,
                            numOfIdle: idle.length,
                            numOfOverSpeed: numberOverSpeed,
                            speedSettings: this.OVERSPEED,
                            idleSettings: this.IDLETIME,
                            idle: idle,
                            overSpeed: overSpeed,
                            status: dateString === d1 ? false : true,
                            noData: false,
                          }
                          if (di === 0)
                            device.info = info;
                          else {
                            device.info.completion += info.completion
                            device.info.halfCompletion += info.halfCompletion
                            device.info.totalStreet += info.totalStreet
                            device.info.numOfIdle += info.numOfIdle
                            device.info.numOfOverSpeed += info.numOfOverSpeed
                            device.info.speedSettings += info.speedSettings
                            device.info.idleSettings += info.idleSettings
                            device.info.idle += info.idle
                            device.info.overSpeed += info.overSpeed
                            if (device.info.noData)
                              device.info.noData = info.noData
                          }
                          this.updateGraph(device,di,null,null,df);

                          counter++;
                          if (counter === this.devicelist.length)
                            this.isFetching = false;
                          this.firestore.collection('Devices').doc(device.ID).collection('History').doc(trip.id).update(info);
                        } else {
                          // this.spinner.hide();
                        }
                        if (l.size === 0) {
                          const info = {
                            completion: 0,
                            halfCompletion: 0,
                            totalStreet: 0,
                            numOfIdle: 0,
                            numOfOverSpeed: 0,
                            speedSettings: 0,
                            idleSettings: 0,
                            idle: 0,
                            overSpeed: 0,
                            noData: true,
                          }
                          if (di === 0)
                            device.info = info;
                          else {
                            device.info.completion += info.completion
                            device.info.halfCompletion += info.halfCompletion
                            device.info.totalStreet += info.totalStreet
                            device.info.numOfIdle += info.numOfIdle
                            device.info.numOfOverSpeed += info.numOfOverSpeed
                            device.info.speedSettings += info.speedSettings
                            device.info.idleSettings += info.idleSettings
                            device.info.idle += info.idle
                            device.info.overSpeed += info.overSpeed
                            if (device.info.noData)
                              device.info.noData = info.noData
                          }
                          this.updateGraph(device,di,null,null,df);

                          counter++;
                          if (counter === this.devicelist.length)
                            this.isFetching = false;
                        }
                      });
                    }, 3000);
                  })
                }
              }

            })

            if (this.operasiKenderaan > 0)
              this.pieChartOperasi.data.datasets[0].data[2] = undefined;
            if (this.nonoperasiKenderaan > 0)
              this.pieChartTidakOperasi.data.datasets[0].data[2] = undefined;

            this.pieChartOperasi.update();
            this.pieChartTidakOperasi.update();

          })
          this.getPreviousDataSet(device,df2, dateString2);

          firstDay.setDate(firstDay.getDate() + 1);
          previousFirstDay.setDate(previousFirstDay.getDate() + 1);

        }
      }

      if(this.selection === 'Month'){

        var totalDay = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 0).getDate();
        const firstDay = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1);

        const previousFirstDay = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth()-1, 1)
        const previousTotalDay = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 0).getDate();

        for (let di = 0; di < totalDay; di++) {
          const year = firstDay.getFullYear();
          const day = ('0' + firstDay.getDate()).slice(-2);
          const month = ('0' + (firstDay.getMonth() + 1)).slice(-2);
          const dateString = year + month + day;
          const secondDate = new Date(firstDay)
          secondDate.setDate(secondDate.getDate() + 1);

          if(firstDay.getTime() > new Date().getTime()){
            continue;
          }
          if(secondDate.getTime() > new Date().getTime()){
            totalDay = di+1
          }
          this.dayMultiple++;
          var snapshot = this.firestore.collection('Devices').doc(device.ID).collection('History', ref => ref.where('date', '==', dateString)).get()
          snapshot.forEach(t => {
            if (t.size === 0) {
              if (device.LorryType === 'Garbage Truck')
                this.pieChartTidakOperasi.data.datasets[0].data[0]++;
              else if (device.LorryType === 'Roro Truck')
                this.pieChartTidakOperasi.data.datasets[0].data[1]++;

              this.nonoperasiKenderaan++;
              const info = {
                completion: 0,
                halfCompletion: 0,
                totalStreet: 0,
                numOfIdle: 0,
                numOfOverSpeed: 0,
                speedSettings: 0,
                idleSettings: 0,
                idle: 0,
                overSpeed: 0,
                noData: true,
              }
              if (di === 0)
                device.info = info;
              else {
                device.info.completion += info.completion
                device.info.halfCompletion += info.halfCompletion
                device.info.totalStreet += info.totalStreet
                device.info.numOfIdle += info.numOfIdle
                device.info.numOfOverSpeed += info.numOfOverSpeed
                device.info.speedSettings += info.speedSettings
                device.info.idleSettings += info.idleSettings
                device.info.idle += info.idle
                device.info.overSpeed += info.overSpeed
                if (device.info.noData)
                  device.info.noData = info.noData
              }
              this.updateGraph(device,di,totalDay,null,null,month);
              counter++;
              if (counter === this.devicelist.length)
                this.isFetching = false;
            }
            else {
              if (device.LorryType === 'Garbage Truck')
                this.pieChartOperasi.data.datasets[0].data[0]++;
              else if (device.LorryType === 'Roro Truck')
                this.pieChartOperasi.data.datasets[0].data[1]++;
              this.operasiKenderaan++;
            }

            t.forEach(trip => {
              if (trip.data().status) {
                const info = {
                  completion: trip.data().completion,
                  halfCompletion: trip.data().halfCompletion,
                  totalStreet: trip.data().totalStreet,
                  numOfIdle: trip.data().numOfIdle,
                  numOfOverSpeed: trip.data().numOfOverSpeed,
                  speedSettings: this.OVERSPEED,
                  idleSettings: this.IDLETIME,
                  idle: trip.data().idle,
                  overSpeed: trip.data().overSpeed,
                  status: trip.data().status,
                  noData: false,
                }
                if (di === 0)
                  device.info = info;
                else {
                  device.info.completion += info.completion
                  device.info.halfCompletion += info.halfCompletion
                  device.info.totalStreet += info.totalStreet
                  device.info.numOfIdle += info.numOfIdle
                  device.info.numOfOverSpeed += info.numOfOverSpeed
                  device.info.speedSettings += info.speedSettings
                  device.info.idleSettings += info.idleSettings
                  device.info.idle += info.idle
                  device.info.overSpeed += info.overSpeed
                  if (device.info.noData)
                    device.info.noData = info.noData
                }
                this.updateGraph(device,di,totalDay,null,null,month);

                counter++;
                if (counter === this.devicelist.length)
                  this.isFetching = false;
              } else {
                if (trip.data().routeID) {
                  const dateFrom = new Date(this.selectedDate);
                  const startHrs = parseInt(trip.data().startAt.split(':')[0]);
                  const startMin = parseInt(trip.data().startAt.split(':')[1]);
                  dateFrom.setHours(startHrs);
                  dateFrom.setMinutes(startMin);
                  dateFrom.setSeconds(0);
                  const dateto = new Date(dateFrom);
                  const endHrs = parseInt(trip.data().endAt.split(':')[0]);
                  const endMin = parseInt(trip.data().endAt.split(':')[1]);
                  var h = 0;
                  var m = 0;
                  if (endHrs - startHrs >= 0) {
                    if (endMin - startMin >= 0) {
                      h = endHrs - startHrs;
                      m = endMin - startMin;
                    } else {
                      h = endHrs - startHrs;
                      if (h > 0)
                        h = h - 1;
                      m = endMin + 60 - startMin;
                    }
                  } else {
                    h = endHrs + 24 - startHrs;
                    if (endMin - startMin >= 0) {
                      m = endMin - startMin;
                    } else {
                      m = endMin + 60 - startMin;
                      h = h - 1;
                    }
                  }

                  dateto.setHours(dateto.getHours() + h)
                  dateto.setMinutes(dateFrom.getMinutes() + m)

                  this.firestore.collection('Route').doc(trip.data().routeID).get().forEach(plan => {
                    const pathSavedlist = [];
                    const idle = [];
                    const positionlist = [];
                    const timelist = [];
                    const RegionLog = [];
                    const overSpeed = [];

                    plan.data().regions.forEach((e, i) => {
                      let empty = [];
                      RegionLog.push(empty);

                      this.firestore.collection('Region').doc(e).get().forEach(value => {
                        const paths = [];
                        value.data().coordinates.forEach(coor => {
                          paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
                        });
                        const timeSetting = {
                          timeSetting: value.data().timeSetting
                        }
                        const path = new google.maps.Polygon({
                          ...timeSetting,
                          paths: paths,
                          strokeColor: 'black',
                          fillColor: 'red',
                          strokeOpacity: 1.0,
                          strokeWeight: 1,

                        });
                        pathSavedlist.push(path);
                      })
                    });

                    setTimeout(() => {
                      this.firestore.collection('Devices').doc(device.ID).collection('History').doc(trip.id).collection('location', ref => ref.orderBy('Timestamp', 'asc')).get().forEach(l => {
                        var count = 0;
                        var currenttime;
                        var endtime;
                        var flag = true;
                        var nexttime = new Date();

                        var overspeedtime;
                        var nextoverspeedtime;
                        var numberOverSpeed = 0;

                        l.forEach((location) => {
                          if ((location.data().Speed > this.OVERSPEED)) {
                            overspeedtime = new Date(location.data().Timestamp * 1000);
                            if (!nextoverspeedtime) {
                              nextoverspeedtime = new Date(overspeedtime);
                              nextoverspeedtime.setMinutes(overspeedtime.getMinutes() + 1);
                              numberOverSpeed++;
                              const inf = {
                                time: new Date(location.data().Timestamp * 1000),
                              }
                              overSpeed.push(inf)
                            } else {
                              if (overspeedtime.getTime() > nextoverspeedtime.getTime()) {
                                nextoverspeedtime = new Date(overspeedtime);
                                nextoverspeedtime.setMinutes(overspeedtime.getMinutes() + 1);
                                numberOverSpeed++;
                                const inf = {
                                  time: new Date(location.data().Timestamp * 1000),
                                }
                                overSpeed.push(inf)
                              }
                            }
                          }

                          if ((!location.data().Speed || location.data().Speed === 0) && flag) {
                            currenttime = new Date(location.data().Timestamp * 1000);
                            nexttime = new Date(currenttime);
                            nexttime.setMinutes(currenttime.getMinutes() + this.IDLETIME);
                            flag = false;
                          } else if (location.data().Speed && !flag) {
                            if (location.data().Speed > 0) {
                              if (l.docs[count].data().Speed > location.data().Speed) {

                              } else {

                                if (nexttime.getTime() < new Date(location.data().Timestamp * 1000).getTime()) {
                                  endtime = new Date(location.data().Timestamp * 1000);

                                }
                              }
                            }
                          }
                          if (currenttime && endtime) {
                            flag = true;
                            const idletime = {
                              starttime: currenttime,
                              endtime
                            }
                            nexttime = null;
                            currenttime = null;
                            endtime = null;
                            idle.push(idletime);
                          }
                          count++;
                          timelist.push(new Date(location.data().Timestamp * 1000));
                          positionlist.push(new google.maps.LatLng(location.data().Latitude,
                            location.data().Longitude));
                        });

                        if (positionlist.length > 1) {
                          for (let i = 0; i < positionlist.length; i++) {
                            pathSavedlist.forEach((ab, index) => {
                              const resultPath = google.maps.geometry.poly.containsLocation(
                                positionlist[i],
                                ab
                              )
                              if (resultPath) {
                                const info = {
                                  message: "IN",
                                  time: timelist[i],
                                  position: positionlist[i],
                                }
                                if (RegionLog[index].length === 0) {
                                  RegionLog[index].push(info)
                                }
                                else if (RegionLog[index][RegionLog[index].length - 1]) {
                                  let m = RegionLog[index][RegionLog[index].length - 1].message;
                                  if (m !== 'IN') {
                                    RegionLog[index].push(info)
                                  }
                                }


                              } else {
                                const info = {
                                  message: "OUT",
                                  time: timelist[i],
                                  position: positionlist[i],
                                }
                                if (RegionLog[index][RegionLog[index].length - 1]) {
                                  let m = RegionLog[index][RegionLog[index].length - 1].message;
                                  if (m === 'IN') {
                                    RegionLog[index].push(info)
                                  }
                                }
                              }
                            })
                          }
                          let completion = 0;
                          let halfCompletion = 0;
                          RegionLog.forEach((f, ii) => {
                            if (f.length > 1) {
                              let ttime = 0;
                              f.forEach((element, ll) => {
                                if (ll % 2 === 1) {
                                  ttime += element.time.getTime() - f[ll - 1].time.getTime()
                                }
                              });
                              var minutes = Math.floor(ttime / 60000);
                              if (minutes >= parseInt(pathSavedlist[ii].timeSetting)) {
                                completion++;
                              } else {
                                halfCompletion++;
                              }
                            }
                          })

                          const d1 = new Date().getFullYear() + ("0" + (new Date().getMonth() + 1).toString()).slice(-2) + ("0" + (new Date().getDate()).toString()).slice(-2)

                          const info = {
                            completion,
                            halfCompletion,
                            totalStreet: pathSavedlist.length,
                            numOfIdle: idle.length,
                            numOfOverSpeed: numberOverSpeed,
                            speedSettings: this.OVERSPEED,
                            idleSettings: this.IDLETIME,
                            idle: idle,
                            overSpeed: overSpeed,
                            status: dateString === d1 ? false : true,
                            noData: false,
                          }
                          if (di === 0)
                            device.info = info;
                          else {
                            device.info.completion += info.completion
                            device.info.halfCompletion += info.halfCompletion
                            device.info.totalStreet += info.totalStreet
                            device.info.numOfIdle += info.numOfIdle
                            device.info.numOfOverSpeed += info.numOfOverSpeed
                            device.info.speedSettings += info.speedSettings
                            device.info.idleSettings += info.idleSettings
                            device.info.idle += info.idle
                            device.info.overSpeed += info.overSpeed
                            if (device.info.noData)
                              device.info.noData = info.noData
                          }
                          this.updateGraph(device,di,totalDay,null,null,month);

                          counter++;
                          if (counter === this.devicelist.length)
                            this.isFetching = false;
                          this.firestore.collection('Devices').doc(device.ID).collection('History').doc(trip.id).update(info);
                        } else {
                          // this.spinner.hide();
                        }
                        if (l.size === 0) {
                          const info = {
                            completion: 0,
                            halfCompletion: 0,
                            totalStreet: 0,
                            numOfIdle: 0,
                            numOfOverSpeed: 0,
                            speedSettings: 0,
                            idleSettings: 0,
                            idle: 0,
                            overSpeed: 0,
                            noData: true,
                          }
                          if (di === 0)
                            device.info = info;
                          else {
                            device.info.completion += info.completion
                            device.info.halfCompletion += info.halfCompletion
                            device.info.totalStreet += info.totalStreet
                            device.info.numOfIdle += info.numOfIdle
                            device.info.numOfOverSpeed += info.numOfOverSpeed
                            device.info.speedSettings += info.speedSettings
                            device.info.idleSettings += info.idleSettings
                            device.info.idle += info.idle
                            device.info.overSpeed += info.overSpeed
                            if (device.info.noData)
                              device.info.noData = info.noData
                          }
                          this.updateGraph(device,di,totalDay,null,null,month);

                          counter++;
                          if (counter === this.devicelist.length)
                            this.isFetching = false;
                        }
                      });
                    }, 3000);
                  })
                }
              }

            })

            if (this.operasiKenderaan > 0)
              this.pieChartOperasi.data.datasets[0].data[2] = undefined;
            if (this.nonoperasiKenderaan > 0)
              this.pieChartTidakOperasi.data.datasets[0].data[2] = undefined;


            this.pieChartOperasi.update();
            this.pieChartTidakOperasi.update();

          })
          firstDay.setDate(firstDay.getDate() + 1);


        }

        for(let di =0; di< previousTotalDay; di++){
          const year = previousFirstDay.getFullYear();
          const day = ('0' + previousFirstDay.getDate()).slice(-2);
          const month = ('0' + (previousFirstDay.getMonth() + 1)).slice(-2);
          const dateString = year + month + day;

          this.getPreviousDataSet(device,null,dateString,month);
          previousFirstDay.setDate(previousFirstDay.getDate() + 1);


        }
      }

    })
  }

  graphLabel = [0, 0];
  graphCount = 0;
  numberOverSpeed = 0;
  numberBrek = 0;
  numberStop = 0;

  updateGraph(device, di = undefined, totalDay = undefined, datestring= undefined, dateweek= undefined, datemonth= undefined) {

    if(this.selection === 'Week'){
      if(di === 6){
        this.graphCount++;
      }
    }

    if(this.selection === 'Month'){
      if(di === totalDay-1){
        this.graphCount++;
      }
    }

    if(this.selection === 'Day'){
      this.graphCount++;

    }

    if (device.info.noData) {
      if (device.LorryType === 'Garbage Truck') {
        this.graphLabel[0]++;
      }
      else if (device.LorryType === 'Roro Truck') {
        this.graphLabel[1]++;
      }
    } else {

      if (device.info.numOfOverSpeed > 0)
        this.numberOverSpeed++;
      if (device.info.numOfIdle > 0)
        this.numberStop++;
      if (device.info.brek > 0)
        this.numberBrek++;
      let value = ((device.info.completion + device.info.halfCompletion) / device.info.totalStreet * 100)
      if (isNaN(value))
        value = 0;
      if (device.LorryType === 'Garbage Truck') {
        this.barChart.data.datasets[0].data[0] += value
        this.graphLabel[0]++;
      }
      else if (device.LorryType === 'Roro Truck') {
        this.barChart.data.datasets[0].data[1] += value
        this.graphLabel[1]++;
      }
    }

    if(this.selection === 'Week'){
      if (this.graphCount === this.devicelist.length && di === 6) {

        this.barChart.data.datasets[0].data[0] /= this.graphLabel[0];
        const color = this.getBarColor(this.barChart.data.datasets[0].data[0]);
        this.barChart.data.datasets[0].backgroundColor[0] = color;
        this.barChart.data.datasets[0].data[0] = (this.barChart.data.datasets[0].data[0]).toFixed(2);

        this.barChart.data.datasets[0].data[1] /= this.graphLabel[1];
        const color1 = this.getBarColor(this.barChart.data.datasets[0].data[1]);
        this.barChart.data.datasets[0].backgroundColor[1] = color1;
        this.barChart.data.datasets[0].data[1] = (this.barChart.data.datasets[0].data[1]).toFixed(2);
      }
      this.spinner.hide();
      const updateDashboardInfo = {
        Running: this.operasiKenderaan,
        NonRunning: this.nonoperasiKenderaan
      }

      this.firestore.collection('Dashboard').doc(dateweek + '-' + this.depoh.replaceAll(' ', '')).set(updateDashboardInfo)
    }
    if(this.selection === 'Month'){

      if (this.graphCount === this.devicelist.length && di === (totalDay-1) ) {

        this.barChart.data.datasets[0].data[0] /= this.graphLabel[0];
        const color = this.getBarColor(this.barChart.data.datasets[0].data[0]);
        this.barChart.data.datasets[0].backgroundColor[0] = color;
        this.barChart.data.datasets[0].data[0] = (this.barChart.data.datasets[0].data[0]).toFixed(2);

        this.barChart.data.datasets[0].data[1] /= this.graphLabel[1];
        const color1 = this.getBarColor(this.barChart.data.datasets[0].data[1]);
        this.barChart.data.datasets[0].backgroundColor[1] = color1;
        this.barChart.data.datasets[0].data[1] = (this.barChart.data.datasets[0].data[1]).toFixed(2);
      }
      this.spinner.hide();

      const updateDashboardInfo = {
        Running: this.operasiKenderaan,
        NonRunning: this.nonoperasiKenderaan
      }
     this.firestore.collection('Dashboard').doc(datemonth+'-'+this.depoh.replaceAll(' ','')).set(updateDashboardInfo)

    }
    if(this.selection === 'Day'){

      if (this.graphCount === this.devicelist.length) {
        this.barChart.data.datasets[0].data[0] /= this.graphLabel[0];

        const color = this.getBarColor(this.barChart.data.datasets[0].data[0]);
        this.barChart.data.datasets[0].backgroundColor[0] = color;
        this.barChart.data.datasets[0].data[0] = (this.barChart.data.datasets[0].data[0]).toFixed(2);

        this.barChart.data.datasets[0].data[1] /= this.graphLabel[1];
        const color1 = this.getBarColor(this.barChart.data.datasets[0].data[1]);
        this.barChart.data.datasets[0].backgroundColor[1] = color1;
        this.barChart.data.datasets[0].data[1] = (this.barChart.data.datasets[0].data[1]).toFixed(2);
      }
      this.spinner.hide();

      const updateDashboardInfo = {
        Running: this.operasiKenderaan,
        NonRunning: this.nonoperasiKenderaan
      }
     this.firestore.collection('Dashboard').doc(datestring+'-'+this.depoh.replaceAll(' ','')).set(updateDashboardInfo)


    }


    this.update();

  }

  barChart: Chart;

  ngAfterViewInit(): void {

    this.pieChartOperasi = new Chart('pieChartOperasi', {
      plugins: [ChartDataLabels],
      type: 'doughnut',
      data: {
        labels: ["Kompaktor", "Roro", "Tiada Kenderaan"],
        datasets: [{
          label: "Kenderaan sedang Operasi",
          backgroundColor: ['#0058ff', '#21d59b', '#8d8d8d', '#f99600', '#00ffc4', '#ff2300', '#e600ff', '#d3ff00'],
          data: [0, 0, 1],
        }]
      },
      options: {
        legend: {
          position: 'right'
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              if(ctx.chart.data.labels[ctx.dataIndex] === 'Tiada Kenderaan')
                return "";
              if(value === 0)
                return "";
              return value;
            },
            color: '#fff',
          }
        }
      }
    })

    this.pieChartTidakOperasi = new Chart('pieChartTidakOperasi', {
      plugins: [ChartDataLabels],
      type: 'doughnut',
      data: {
        labels: ["Kompaktor", "Roro", "Tiada Kenderaan"],
        datasets: [{
          label: "Kenderaan tidak Operasi",
          backgroundColor: ['#0058ff', '#21d59b', '#8d8d8d', '#f99600', '#00ffc4', '#ff2300', '#e600ff', '#d3ff00'],
          data: [0, 0, 1],
        }]
      },
      options: {
        legend: {
          position: 'right'
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              if(ctx.chart.data.labels[ctx.dataIndex] === 'Tiada Kenderaan')
              return "";
            if(value === 0)
              return "";
            return value;
            },
            color: '#fff',
          }
        }
      }
    })

    this.pieChartKenderaan = new Chart('pieChartKenderaan', {
      plugins: [ChartDataLabels],
      type: 'doughnut',
      data: {
        labels: ["Kompaktor", "Roro", "Tiada Kenderaan"],

        datasets: [{
          label: "Jumlah Kenderaan",
          backgroundColor: ['#0058ff', '#21d59b', '#8d8d8d', '#f99600', '#00ffc4', '#ff2300', '#e600ff', '#d3ff00'],
          data: [0, 0, 1],
        }]
      },
      options: {
        legend: {
          position: 'right'
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              if(ctx.chart.data.labels[ctx.dataIndex] === 'Tiada Kenderaan')
              return "";
            if(value === 0)
              return "";
            return value;
            },
            color: '#fff',
          }
        }
      }
    })
    this.barChart = new Chart('barChart', {
      type: 'bar',
      data: {
        labels: ["Kompaktor", "Roro"],
        datasets: [{
          label: [],
          data: [0, 0],
          backgroundColor: [
            '#5fe2a0',
            '#ffed06',
            '#ff1414'
          ],
        }],
      },
      options: {
        tooltips: {
          callbacks: {
              label: function(tooltipItem, data) {
                  var label = data.datasets[tooltipItem.datasetIndex].label || '';

                  if (label) {
                      label += ': ';
                  }
                  label += Math.round(tooltipItem.yLabel * 100) / 100 + "% ";
                  return label;
              }
          }
      },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              return "";
            },
            color: '#fff',
          }
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              max: 100
            }
          }]
        },
      }

    });


  }

  change() {
    this.GetData()
    const temp = new Date(this.selectedDate);
    this.firstDay = new Date(temp.setDate(temp.getDate() - temp.getDay()));
    this.lastDay = new Date(temp.setDate(temp.getDate() - temp.getDay() + 6));
  }

  detectChanges(event) {
    this.selectedDate = event;
    this.GetData();
    this.firstDay = new Date(this.selectedDate.setDate(this.selectedDate.getDate() - this.selectedDate.getDay()));
    this.lastDay = new Date(this.selectedDate.setDate(this.selectedDate.getDate() - this.selectedDate.getDay() + 6));
  }

  changeWeek() {
    this.firstDay = new Date(this.selectedDate.setDate(this.selectedDate.getDate() - this.selectedDate.getDay()));
    this.lastDay = new Date(this.selectedDate.setDate(this.selectedDate.getDate() - this.selectedDate.getDay() + 6));
    this.GetData()

  }


  sortData(sort: Sort) {
    this.sortedu = sort;
    this.sorteddevicelist = this.devicelist.slice();

    if (!sort.active || sort.direction === '' && !this.searchValue) {
      this.sorteddevicelist = this.devicelist.slice();
      this.limitDevice();
      return;
    }
    this.sorteddevicelist = this.sorteddevicelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'plate': return this.compare(a.PlateNumber, b.PlateNumber, isAsc);
        case 'completion': return this.compare(((a.info.completion + a.info.halfCompletion) / a.info.totalStreet),
          ((b.info.completion + b.info.halfCompletion) / b.info.totalStreet), isAsc);
        case 'notCompletion': return this.compare(((a.info.totalStreet - a.info.completion - a.info.halfCompletion)),
          ((b.info.totalStreet - b.info.completion - b.info.halfCompletion)), isAsc);
        case 'halfway': return this.compare(a.info.halfCompletion, b.info.halfCompletion, isAsc);
        case 'exceed': return this.compare(a.info.numOfOverSpeed, b.info.numOfOverSpeed, isAsc);
        // case 'brek': return this.compare(a.q4, b.q4, isAsc);
        case 'idle': return this.compare(a.info.numOfIdle, b.info.numOfIdle, isAsc);

        default: return 0;
      }
    });
    this.limitDevice();
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  sortDevice() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.sorteddevicelist = this.sorteddevicelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'plate': return this.compare(a.PlateNumber, b.PlateNumber, isAsc);
        case 'completion': return this.compare(((a.info.completion + a.info.halfCompletion) / a.info.totalStreet),
          ((b.info.completion + b.info.halfCompletion) / b.info.totalStreet), isAsc);
        case 'notCompletion': return this.compare(((a.info.totalStreet - a.info.completion - a.info.halfCompletion)),
          ((b.info.totalStreet - b.info.completion - b.info.halfCompletion)), isAsc);
        case 'halfway': return this.compare(a.info.halfCompletion, b.info.halfCompletion, isAsc);
        case 'exceed': return this.compare(a.info.numOfOverSpeed, b.info.numOfOverSpeed, isAsc);
        // case 'brek': return this.compare(a.q4, b.q4, isAsc);
        case 'idle': return this.compare(a.info.numOfIdle, b.info.numOfIdle, isAsc);

        default: return 0;
      }
    });
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    this.sorteddevicelist = this.devicelist.slice();
    this.sortDevice();
    this.limitDevice();
  }
  limitDevice() {
    this.sorteddevicelist = this.sorteddevicelist.slice(this.offset, (this.offset + this.pageSize));
  }

  getColor(value) {
    if (value < 33) {
      return "#f53c56";
    } else if (value < 66) {
      return "#bfc21c"
    } else {
      return "rgb(73 176 125)"
    }
  }
  getBarColor(value) {
    if (value < 33) {
      return "#f53c56";
    } else if (value < 66) {
      return "#bfc21c"
    } else {
      return "#5fe2a0"
    }
  }

  getWidth(value) {
    return value.toFixed(2) + '%';
  }

  resetChart() {
    if (this.pieChartOperasi) {
      this.pieChartKenderaan.data.datasets[0].data[0] = 0;
      this.pieChartKenderaan.data.datasets[0].data[1] = 0;
      this.pieChartKenderaan.data.datasets[0].data[2] = 1;

      for (const device of this.devicelist) {
        if (device.LorryType === 'Garbage Truck')
          this.pieChartKenderaan.data.datasets[0].data[0]++;
        else if (device.LorryType === 'Roro Truck')
          this.pieChartKenderaan.data.datasets[0].data[1]++;
        this.pieChartKenderaan.update();
      }

      if (this.devicelist.length > 0) {
        this.pieChartKenderaan.data.datasets[0].data[2] = undefined;
        this.pieChartKenderaan.update();

      }


      this.pieChartOperasi.data.datasets[0].data[0] = 0;
      this.pieChartOperasi.data.datasets[0].data[1] = 0;
      this.pieChartOperasi.data.datasets[0].data[2] = 1;

      this.pieChartTidakOperasi.data.datasets[0].data[0] = 0;
      this.pieChartTidakOperasi.data.datasets[0].data[1] = 0;
      this.pieChartTidakOperasi.data.datasets[0].data[2] = 1;

      this.barChart.data.datasets[0].data[0] = 0;
      this.barChart.data.datasets[0].data[1] = 0;

      this.barChart.update();
      this.pieChartTidakOperasi.update();
      this.pieChartOperasi.update();
      this.pieChartKenderaan.update();

    }


  }

  previousOperasiKenderaan = 0;
  previousNonOperasiKenderaan = 0;

  getPreviousDataSet(device,df=undefined,datestring=undefined, month=undefined){

    if(this.selection === 'Day'){

      var previousDate = new Date(this.selectedDate);
      previousDate.setDate(previousDate.getDate()-1);
      const docFormat = previousDate.getFullYear() +
       ("0" + (previousDate.getMonth() + 1).toString()).slice(-2) +
       ("0" + (previousDate.getDate()).toString()).slice(-2) + '-' + this.depoh.replaceAll(' ','');
      this.firestore.collection('dashboard').doc(docFormat).get().forEach(doc=>{
        if(doc.exists){
          this.previousNonOperasiKenderaan = doc.data().NonRunning;
          this.previousOperasiKenderaan = doc.data().Running;
        }else{
          const dateFormat = previousDate.getFullYear() +
          ("0" + (previousDate.getMonth() + 1).toString()).slice(-2) +
          ("0" + (previousDate.getDate()).toString()).slice(-2);

          var snapshot = this.firestore.collection('Devices').doc(device.ID).collection('History', ref => ref.where('date', '==', dateFormat)).get()
          snapshot.forEach(t => {
            if (t.size === 0) {
              this.previousNonOperasiKenderaan++;
            }
            else {
              this.previousOperasiKenderaan++;
            }
          })
        }
      })




    }
    else if(this.selection === 'Week'){
      const docFormat = df + '-' + this.depoh.replaceAll(' ','');
      this.firestore.collection('dashboard').doc(docFormat).get().forEach(doc=>{
        if(doc.exists){
          this.previousNonOperasiKenderaan = doc.data().NonRunning;
          this.previousOperasiKenderaan = doc.data().Running;
        }else{
          var snapshot = this.firestore.collection('Devices').doc(device.ID).collection('History', ref => ref.where('date', '==', datestring)).get()
          snapshot.forEach(t => {
            if (t.size === 0) {
              this.previousNonOperasiKenderaan++;
            }
            else {
              this.previousOperasiKenderaan++;
            }
          })
        }
      })
    }else if (this.selection === 'Month'){
      const docFormat = month + '-' + this.depoh.replaceAll(' ','');
      this.firestore.collection('dashboard').doc(docFormat).get().forEach(doc=>{
        if(doc.exists){
          this.previousNonOperasiKenderaan = doc.data().NonRunning;
          this.previousOperasiKenderaan = doc.data().Running;
        }else{

          var snapshot = this.firestore.collection('Devices').doc(device.ID).collection('History', ref => ref.where('date', '==', datestring)).get()
          snapshot.forEach(t => {
            if (t.size === 0) {
              this.previousNonOperasiKenderaan++;
            }
            else {
              this.previousOperasiKenderaan++;
            }
          })
        }
      })
    }
  }

  exportToExcel() {
    const exportInformation = [];
    this.spinner.show();
    for (const device of this.constDevicelist) {
      const info ={
        "Plate Number" :device.PlateNumber.split('(')[0],
        "Peratusan Siap Kerja" : device.info.noData? '-':
        ((device.info.completion + device.info.halfCompletion)/device.info.totalStreet *
                      100).toFixed(2) + "%",
        "Bil. Kerja tidak lengkap": device.info.noData? '-':
        (device.info.totalStreet - device.info.completion - device.info.halfCompletion)
        ,
        "Bil. Kerja Separa lengkap": device.info.noData? '-':
        device.info.halfCompletion,
        "Bil. Melebihi had laju": device.info.noData? '-':
        device.info.numOfOverSpeed,
        "Bil. Brek mengejut": device.info.noData? '-': 0,
        "Bil. berhenti lama":  device.info.noData? '-':
        device.info.numOfIdle
      }
      exportInformation.push(info)
    }

    this.excelHelper.exportAsExcelFile(exportInformation, 'data');
    this.spinner.hide();

  }

}
