import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-create-vehicle',
  templateUrl: './create-vehicle.component.html',
  styleUrls: ['./create-vehicle.component.css']
})
export class CreateVehicleComponent implements OnInit {
    device = {
      Depoh:"",
      ID: "",
      LorryType: "",
      PlateNumber:"",
      RouteCovered:"",
      Scheduling:[],
      UpdatedBy:"",
      UpdatedAt:new Date(),
    };
    routeList = [];
  depoh = [];

    email:string;
    constructor(
      private dialogRef: MatDialogRef<CreateVehicleComponent>,
      @Inject(MAT_DIALOG_DATA) data,
      private firestore: AngularFirestore,
      private spinner: NgxSpinnerService,
      private dialog: MatDialog,
      private cd: ChangeDetectorRef,
      private toast: ToastrService,
    private angularFireAuth: AngularFireAuth,

    ) {
    this.depoh = data.depoh;
    console.log(this.depoh);

      this.angularFireAuth.authState.subscribe(auth=>{
        this.email = auth.email;
      })
    }

    ngOnInit(): void {

    }

    cancel() {
      this.dialogRef.close(false);
    }

    confirm() {

      if (!this.device.ID || !this.device.PlateNumber || !this.device.LorryType ) {
        this.toast.warning('Information not completed', 'Please check')
        return;
      }


      this.firestore.collection('Devices').doc(this.device.ID).get().forEach(r => {
        if (r.exists) {
          this.toast.warning('Same device ID detected', 'Please check')
          return;
        } else {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.height = 'auto';
          dialogConfig.width = '400px';
          const position = {
            top: '5%',
          };
          dialogConfig.position = position;
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data = {
            message: 'Do you want to create this vehicle ID? ' + this.device.ID,
          }

          this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
            if (result) {
              this.spinner.show();
              this.device.UpdatedAt = new Date();
              this.device.UpdatedBy = this.email;
              this.firestore.collection('Devices').doc(this.device.ID).set(this.device).then(r => {
                this.spinner.hide();
                this.toast.success('Added successful');

                this.dialogRef.close(true);
              });
            }
          });
        }
      })




    }


  }
