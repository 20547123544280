import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MatPaginator, MAT_DIALOG_DATA, PageEvent, Sort, MatDialogConfig, MatDialog } from '@angular/material';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { ExcelHelperService } from '../../Services/excel-helper.service';
const cloneDeep = require('lodash.clonedeep')
import * as jsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';
import moment from 'moment';

@Component({
  selector: 'app-history-premis',
  templateUrl: './history-premis.component.html',
  styleUrls: ['./history-premis.component.css']
})
export class HistoryPremisComponent implements OnInit {
  qr: string = "";
  qr660: string = "";
  sortedbinlist: any = []
  data: any;
  url = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  title: string;  fromDateRekod: Date;
  toDateRekod: Date;
  type;
  defaultIndex = 0;
  ic: string;
  name: string;
  shop_name: string;



  constructor(private dialogRef: MatDialogRef<HistoryPremisComponent>,
    private  dialog : MatDialog,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private firestore: AngularFirestore,
    private excelHelper: ExcelHelperService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.type = data.type;
    this.title = data.name
    this.data = data;
    this.name = data.name;
    this.shop_name = data.shop_name;
    this.ic = data.ic_num;

    this.splitByPremis(data.eventDtls.filter(e=>e.name !== 'Top Up'));
    this.sortedbinlist =  cloneDeep(this.splitBinList);

  }

  splitBinList = [];

  splitByPremis(events){
    this.splitBinList = [];
    for (const event of events) {
      let r = this.splitBinList.find(e=>e.premis === event.premis)
      if(event.premis){
        if(r){
          r.events.push(event);
        }else{
          const r = {
            premis: event.premis,
            bin: event.type,
            events: [event]
          }
          this.splitBinList.push(r)
        }
      }
    }
  }

  onTabChanged(event){
    this.defaultIndex = event.index;
    this.fromDateRekod = new Date(new Date().setMonth(new Date().getMonth() - 3));
    this.toDateRekod = new Date();
    this.pageSize = 5;
    this.pageIndex = 0;
    this.offset = this.pageSize * this.pageIndex;
      this.sortedu = {
        active: '',
        direction: ''
      };
    this.regrab();
  }

  detectChanges3(event) {
    this.fromDateRekod = event;
  }

  detectChanges4(event) {
    this.toDateRekod = event;
  }


  length = 0;
  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 15];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  sortedu = {
    active: '',
    direction: ''
  };

  bin = {
    address: '',
    bin_size: '',
    id: '',
    last_scan: '',
    license_num: '',
    location: '',
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    this.regrab();
  }

  limitbin() {
    this.sortedbinlist[this.defaultIndex].events = this.sortedbinlist[this.defaultIndex].events.slice(this.offset, (this.offset + this.pageSize));
  }

  
  regrab() {
    const from = new Date(this.fromDateRekod.setHours(0, 0, 0, 0))
    const to = new Date(this.toDateRekod.setHours(23, 59, 59, 999))
    this.sortedbinlist[this.defaultIndex].events = this.splitBinList[this.defaultIndex].events.filter(u =>
      u.timestamp.getTime() >= from.getTime() && u.timestamp.getTime() <= to.getTime()
    );

    this.length = this.splitBinList[this.defaultIndex].events.filter(u =>
      u.timestamp.getTime() >= from.getTime() && u.timestamp.getTime() <= to.getTime()
    ).length;
    this.sortbin();
    this.limitbin();
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.sortedbinlist =  cloneDeep(this.splitBinList);

    if (!sort.active || sort.direction === '') {
      this.sortedbinlist[this.defaultIndex].events = this.splitBinList[this.defaultIndex].events.slice();
      this.limitbin();
      return;
    }

    this.sortedbinlist[this.defaultIndex].events = this.sortedbinlist[this.defaultIndex].events.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'name': return this.compare(a.name, b.name, isAsc);
        case 'type': return this.compare(a.type, b.type, isAsc);
        case 'transaction_amount': return this.compare(a.transaction_amount, b.transaction_amount, isAsc);
        case 'address': return this.compare(a.address, b.address, isAsc);
        case 'anggaran': return this.compare(a.anggaran, b.anggaran, isAsc);
        case 'timestamp': return this.compareDate(a.timestamp, b.timestamp, isAsc);
        case 'collector_name': return this.compare(a.collector_name, b.collector_name, isAsc);
        default: return 0;
      }
    });
    this.limitbin();
  }


  sortbin() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.sortedbinlist[this.defaultIndex].events = this.sortedbinlist[this.defaultIndex].events.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'name': return this.compare(a.name, b.name, isAsc);
        case 'type': return this.compare(a.type, b.type, isAsc);
        case 'transaction_amount': return this.compare(a.transaction_amount, b.transaction_amount, isAsc);
        case 'address': return this.compare(a.address, b.address, isAsc);
        case 'anggaran': return this.compare(a.anggaran, b.anggaran, isAsc);
        case 'timestamp': return this.compareDate(a.timestamp, b.timestamp, isAsc);
        case 'collector_name': return this.compare(a.collector_name, b.collector_name, isAsc);
        default: return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compareDate(a, b, isAsc: boolean) {

    a = new Date(a).getTime();
    b = new Date(b).getTime();
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }



  ngOnInit() {
    this.fromDateRekod = new Date(new Date().setMonth(new Date().getMonth() - 3));
    this.toDateRekod = new Date();
    this.regrab();
    
  }

  cancel() {
    this.dialogRef.close();
  }

  exportToExcel() {
    const exportInformation = [];
    this.spinner.show();
    const from = new Date(this.fromDateRekod.setHours(0, 0, 0, 0))
    const to = new Date(this.toDateRekod.setHours(23, 59, 59, 999))
    const binlist = this.splitBinList[this.defaultIndex].events.filter(u =>
      u.timestamp.getTime() >= from.getTime() && u.timestamp.getTime() <= to.getTime()
    ).slice().sort((a, b) => {
      return (a.name < b.name ? -1 : 1) * (true ? 1 : -1);
    }
    );
    for (const raw of binlist) {
      const info = {
        "Jenis": raw.name === 'Top Up'? 'Tambah Nilai': (raw.name || "-"),
        "Saiz Tong": raw.type == 'null'? '-': (raw.type || "-"),
        "Lokasi": raw.address || "-",
        "Kadar Bayaran(RM)": raw.name !== 'Top Up'?(raw.transaction_amount*-1):(raw.transaction_amount  || 0),
        "Berat Kutipan(kg)": raw.anggaran || '-',
        "Staf Kutipan": raw.collector_name || '-',
        "Tarikh": raw.timestamp || '-',

      }
      exportInformation.push(info)
    }

    this.excelHelper.exportAsExcelFile(exportInformation, 'Rekod_' + binlist[0].premis + new Date().getTime());
    this.spinner.hide();
  }


  exportToPDF() {
    this.spinner.show();
    const doc = new jsPdf();
    doc.setFontSize(12);
    const col = ['Tarikh', 'Nama Staff', 'Bin', 'Berat Kutipan (KG)', 'Kadar Bayaran (RM)'];
    const rowdata = [];
    
    const from = new Date(this.fromDateRekod.setHours(0, 0, 0, 0))
    const to = new Date(this.toDateRekod.setHours(23, 59, 59, 999))

    const dateFrom = moment(from).format('DD-MMM-YYYY')
    const dateto = moment(to).format('DD-MMM-YYYY')

    const logo1 = document.createElement('img');
    const logo2 = document.createElement('img');

    logo1.src = '../../assets/logo1.jpg';
    logo2.src = '../../assets/logo2.jpg';
    doc.addImage(logo1, 'JPG', 20, 20, 20, 20);
    doc.addImage(logo2, 'JPG', 50, 20, 60, 20);
    doc.setFontType('bold');
    doc.setFontSize(18);

    doc.text('I-Collect MBSP Sistem', 120, 35);
    doc.setFontSize(10);
    doc.setFontType('normal');
    doc.text('Nama Pemilik', 15, 50);
    doc.text(': ' + this.name, 45, 50);
    doc.text('Nama Perniagaan', 15, 55);
    doc.text(': ' + this.shop_name, 45, 55);
    doc.text('Kad Pengenalan', 15, 60);
    doc.text(': ' + this.ic, 45, 60);
    doc.text('Nama Premis', 15, 65);
    doc.text(': ' + this.splitBinList[this.defaultIndex].premis, 45, 65);
    doc.text('Alamat Premis', 15, 70);
    doc.text(': ', 45, 70);
    doc.text(47, 70, this.splitBinList[this.defaultIndex].events[0].address, { maxWidth: 150 });
    doc.setFontType('bold');
    doc.text(`Rekod Dari ${dateFrom} hingga ${dateto}`, 15, 85);
    doc.setFontType('normal');

    const binlist = this.splitBinList[this.defaultIndex].events.filter(u =>
      u.timestamp.getTime() >= from.getTime() && u.timestamp.getTime() <= to.getTime()
    ).slice().sort((a, b) => {
      return (a.timestamp.getTime() < b.timestamp.getTime() ? -1 : 1) * (true ? 1 : -1);
      }
    );

    for (const element of binlist) {
      let datestr = moment(element.timestamp).format('DD-MMM-YYYY hh:mm a')
      const row = [datestr, element.collector_name, element.type, parseFloat(element.anggaran).toFixed(2), (parseFloat(element.transaction_amount)*-1).toFixed(2)];
      rowdata.push(row);
    }

    const drawCell = function (data) {
      const doc = data.doc;
      const rows = data.table.body;
      if (data.row.index === 0 && data.section == 'head') {
        doc.setFillColor(33, 150, 243);
      } 
    };

    doc.autoTable(col, rowdata, {
      startY: 90, theme: 'grid', margin: 0, styles: {
        padding: 0,
        fontSize: 8,
      }, willDrawCell: drawCell
    },
    );

    doc.save(this.splitBinList[this.defaultIndex].premis +'.pdf');
    this.spinner.hide();

  }

  
}
