import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { DateFormatService } from '../../Services/date-format.service';
var CryptoJS = require("crypto-js");

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  password: string;
  collector:any;
  constructor(  private dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private  dialog : MatDialog,
    private angularFireAuth: AngularFireAuth,
  ) {
      this.collector = data;

    }

  ngOnInit() {
  }


  cancel(){
    this.dialogRef.close();
  }

  resetPassword(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: 'Adakah anda mengesahkan menetapkan semula kata laluan ini?'
    };
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result){
        this.spinner.show();

        this.firestore.collection("collectors").doc(this.collector.key).update({password1:this.password}).then(r=>{
          this.spinner.hide();
          this.toaster.success('Kemaskini berjaya');
          this.addLog(this.collector, 'collector', `${this.collector.name} kata laluan telah ditetapkan semula. \nNama Kakitangan: ${this.collector.name}\n Kad Pengenalan: ${this.collector.ic_num}\n `)
          this.dialogRef.close();

        })

      }
    })
  }

  private dateFormat = new DateFormatService();

  async addLog(detail,type, message){

    this.angularFireAuth.authState.subscribe(async authState => {
      if(authState){
        const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
        const info = {
          message: message,
          date: new Date(),
          data: detail,
          updatedBy: authState.email,
          dateFormat: dateFormat,
          type:type
        };
        await this.firestore.collection('AdminLog').add(info);
      
      }
     });

   
  }
}
