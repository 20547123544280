import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatPaginator, MatDialogConfig } from '@angular/material';
import firebase from 'firebase';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DeviceInfoModalComponent } from 'src/app/shared/device-info-modal/device-info-modal.component';
import { MonitorDatePickerComponent } from 'src/app/shared/monitor-date-picker/monitor-date-picker.component';
import { RouteInfoComponent } from '../route-info/route-info.component';
import Chart from 'chart.js';

@Component({
  selector: 'app-multiple',
  templateUrl: './multiple.component.html',
  styleUrls: ['./multiple.component.css']
})
export class MultipleComponent implements OnInit {

  dateFrom: Date;
  maxDate: Date;
  dateTo: Date;
  scheduler: Date;
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private cd: ChangeDetectorRef
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 800px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this.mobileQueryListener);
    this.role = localStorage.getItem('Role');

    this.dateFrom = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    this.maxDate = new Date();
    this.dateTo = new Date();
    this.scheduler = new Date();
    this.firestore.collection('Depoh').get().forEach(depoh => {
      depoh.forEach(d => {
        const info = {
          key: d.id,
          ...d.data()
        }
        this.depohlist.push(info)
      })
    })
  }
  changeDepoh() {
    if (this.depoh === 'All')
      this.sorteddevicelist = this.devicelist.slice();
    else {
      this.sorteddevicelist = this.devicelist.filter(d => d.Depoh === this.depoh);
    }
  }

  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  selectedIndex = 1;
  speedGraph: any;
  map: google.maps.Map;
  marker: google.maps.Marker;
  mapOptions: google.maps.MapOptions;
  coordinates: any;
  lat = 4.2105;
  lng = 101.9758;
  isTracking = false;
  mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;
  devicelist = [];
  sorteddevicelist: any;
  sortedzonelist: any;
  zonelist = []
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  searchbox = '';
  searchValue = '';
  sortedu = {
    active: '',
    direction: ''
  };
  markerlist = [];
  linelist = [];
  positionlist = [];
  lorrylist = [];
  imagelist = [];
  counter = 0;
  speed: any;
  role: any;
  devices: any;
  trips = [];
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;
  identifyCounterTrip = 1;
  minimizeFlag = false;
  minimizeGraphFlag = false;
  maplist = [];
  deletecounter = 0;
  receiptlist = [];
  locatelist = [];
  savinglist = [];
  trackingPath: any;
  mode = 'multiple';
  pathSavedlist = [];

  ngOnInit(): void {
    const geocoder = new google.maps.Geocoder();
    this.SetupTable(geocoder);
    this.spinner.show();
  }

  removePath(path) {
    path.forEach(element => {
      element.setMap(null);
    });
  }

  removeMarkers(marker) {
    marker.forEach(element => {
      element.setMap(null);
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }

  ngAfterViewInit(): void {

  }

  markerLorryList = [];

  async SetupTable(geocoder: google.maps.Geocoder) {
    if (this.role === 'PPS') {
      await this.firestore.collection('Devices', ref => ref.orderBy('ID', 'asc')).get().forEach(async (e) => {
        await e.forEach(async (table) => {
          const r = this.devicelist.find(d => d.ID === table.id);
          if (!r) {
            this.devicelist.push(table.data());
          }

        });
        this.sorteddevicelist = this.devicelist.slice();
        this.getRouteInfo();

      });
    }
  }

  getRouteInfo() {
    this.devicelist.forEach(device => {
      if (device.Day) {
        device.Day.Monday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Monday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Monday[index].data.regions[i] = value.data();
              })
            });
          })
        });
        device.Day.Tuesday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Tuesday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Tuesday[index].data.regions[i] = value.data();
              })
            });
          })
        });
        device.Day.Wednesday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Wednesday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Wednesday[index].data.regions[i] = value.data();
              })
            });
          })
        });
        device.Day.Thursday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Thursday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Thursday[index].data.regions[i] = value.data();
              })
            });
          })
        });
        device.Day.Friday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Friday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Friday[index].data.regions[i] = value.data();
              })
            });
          })
        });
        device.Day.Saturday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Saturday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Saturday[index].data.regions[i] = value.data();
              })
            });
          })
        });
        device.Day.Sunday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Sunday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Sunday[index].data.regions[i] = value.data();
              })
            });
          })
        });
      }
    })
    setTimeout(() => {
      this.spinner.hide();
    }, 3000);
  }

  subsribeService;


  removeLorryMarker() {
    this.markerLorryList.forEach(e => {
      e.setMap(null);
    })
  }



  routeMarker = [];
  route = [];
  roroUrl = "https://img.icons8.com/dusk/50/000000/truck.png";
  garbageUrl = "https://img.icons8.com/dusk/64/000000/garbage-truck.png";


  job = "";
  streets = [];
  savedMarkerlist = [];

  removeSavedPath() {
    this.pathSavedlist.forEach(e => {
      e.setMap(null);
    })
  }

  removeMarkerlist() {
    this.savedMarkerlist.forEach(e => {
      e.setMap(null);
    })
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  minimize() {
    this.minimizeFlag = true;
  }
  minimizeGraph() {
    this.minimizeGraphFlag = true;
  }

  maximize() {
    this.minimizeFlag = false;
  }

  async detectChanges(event) {
    this.maplist = this.fitlerDevice(this.maplist, event);
  }

  fitlerDevice = (arr, query) => {
    return arr.filter(el => (el.id !== query.id || el.deleteID !== query.deleteID));

  }

  stopMonitor() {
    this.removeMarkers(this.markerlist);
    this.removePath(this.linelist);
    this.removeSavedPath();
    this.removeMarkers(this.routeMarker);

    this.devices = '';
    this.speed = '';
    this.markerlist = [];
    this.linelist = [];
    this.lorrylist = [];
    this.positionlist = [];
    this.savinglist = [];
    this.imagelist = [];
    this.trips = [];

    this.maplist = [];
  }
  single() {
    this.mode = 'single';
  }

  multiple() {
    this.mode = 'multiple';
  }
  applyFilter(filterValue: string) {
    this.sorteddevicelist = this.devicelist.slice(0);
    this.sorteddevicelist = this.filterItems(this.sorteddevicelist, filterValue);
  }

  filterItems = (arr, query) => {
    return arr.filter(el => el.id.toLowerCase().indexOf(query.toLowerCase()) > -1);
  }

  applyFilter2(filterValue: string) {
    this.sortedzonelist = this.zonelist.slice(0);
    this.sortedzonelist = this.filterItems2(this.sortedzonelist, filterValue);
  }

  filterItems2 = (arr, query) => {
    return arr.filter(el => el.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
  }
  getCheck(street, day) {
    if (day === 0) {
      day = 7;
    }
    if (street.dayAvailables.find(i => i === day)) {
      return true;
    } else {
      return false;
    }
  }
  depoh = "All";
  depohlist = [];
  onehrs = false;
  twohrs = false;
  fourhrs = false;
  eighthrs = false;
  twehrs = false;
  twofourhrs = false;

  jobTime: any;
  schedulerTime: Date;
  monitoring(hrs, device) {
    this.spinner.show();
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.subsribeService.unsubscribe();
    this.removeLorryMarker();
    const currenttime = new Date();
    this.activebutton(hrs);

    currenttime.setHours(currenttime.getHours() - hrs);

    this.job = "Monitoring from - ";
    this.jobTime = currenttime;
    this.historyFrom = null;
    this.historyTo = null;
    const today = currenttime.getDay();
    this.schedulerTime = currenttime;

    var routeSchedule = [];

    if (!device)
      device = this.deviceInfo;
    if (device.Day) {
      switch (today) {
        case 0: routeSchedule = device.Day.Sunday; break;
        case 1: routeSchedule = device.Day.Monday; break;
        case 2: routeSchedule = device.Day.Tuesday; break;
        case 3: routeSchedule = device.Day.Wednesday; break;
        case 4: routeSchedule = device.Day.Thursday; break;
        case 5: routeSchedule = device.Day.Friday; break;
        case 6: routeSchedule = device.Day.Saturday; break;
      }
    }
    if (routeSchedule.length === 0) {
      this.toaster.info('No schedule of the selected date');
    }
    this.route = routeSchedule;
    this.spinner.hide();
    this.tracking(device, currenttime, null)
  }

  historyFrom: any;
  historyTo: any;

  timeSearch() {
    this.spinner.show();
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.subsribeService.unsubscribe();
    this.removeLorryMarker();
    this.activebutton(false);

    this.job = "History - ";
    this.jobTime = null;

    this.schedulerTime = this.dateFrom;
    this.historyFrom = this.dateFrom;
    this.historyTo = this.dateTo;



    var routeSchedule = [];
    const today = this.dateFrom.getDay();

    var device = this.deviceInfo;
    if (device.Day) {
      switch (today) {
        case 0: routeSchedule = device.Day.Sunday; break;
        case 1: routeSchedule = device.Day.Monday; break;
        case 2: routeSchedule = device.Day.Tuesday; break;
        case 3: routeSchedule = device.Day.Wednesday; break;
        case 4: routeSchedule = device.Day.Thursday; break;
        case 5: routeSchedule = device.Day.Friday; break;
        case 6: routeSchedule = device.Day.Saturday; break;
      }
    }
    if (routeSchedule.length === 0) {
      this.toaster.info('No schedule of the selected date');
    }
    this.route = routeSchedule;
    this.spinner.hide();
    this.tracking(device, this.dateFrom, this.dateTo)
  }

  trackingService: any;
  tracking(device, datefrom: Date, dateto) {
    var timestampFrom = datefrom.getTime() / 1000;
    this.locatelist = [];
    this.removeMarkers(this.markerlist);
    this.removePath(this.linelist);
    this.markerlist = [];
    this.linelist = [];
    this.lorrylist = [];
    this.positionlist = [];
    this.savinglist = [];
    this.imagelist = [];
    this.routeMarker = [];
    this.speedlist = [];
    this.spinner.show();

    const hr = datefrom.getHours();
    const min = datefrom.getMinutes();

    var hr1; var min1;
    if (dateto)
      hr1 = datefrom.getHours();
    min1 = datefrom.getMinutes();


    if (this.route.length > 0) {
      this.removeSavedPath();
      this.pathSavedlist = [];
      this.route.forEach(e => {
        if (!dateto) {
          if (!(hr >= parseInt(e.startAt.split(':')[0]) && min >= parseInt(e.startAt.split(':')[1]))) {
            return;
          }
        } else {
          if (!(hr >= parseInt(e.startAt.split(':')[0]) &&
            min >= parseInt(e.startAt.split(':')[1]) &&
            hr1 <= parseInt(e.endAt.split(':')[0]) &&
            min1 <= parseInt(e.endAt.split(':')[1])
          )) {
            return;
          }
        }

        e.data.regions.forEach(element => {
          const paths = [];
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(element.centerCoordinate.latitude, element.centerCoordinate.longitude),
            icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
          })
          const infoWindow = new google.maps.InfoWindow({
            content: '<span>Region : ' + element.RouteName + '</span><br>'
          });
          marker.addListener('click', () => {
            this.map.setZoom(18);
            this.map.setCenter(marker.getPosition());
            infoWindow.open(marker.getMap(), marker);
          });
          marker.setMap(this.map);
          this.routeMarker.push(marker);
          element.coordinates.forEach(coor => {
            paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
          });
          const path = new google.maps.Polygon({
            paths: paths,
            strokeColor: 'black',
            fillColor: 'red',
            strokeOpacity: 1.0,
            strokeWeight: 1,
          });

          path.setMap(this.map);
          this.pathSavedlist.push(path);

        });
      });
    }
    else {
      this.spinner.hide();
    }
    if (this.trackingService)
      this.trackingService.unsubscribe();
    if (dateto === null) {
      this.trackingService = this.firestore.collection('Devices').doc(device.ID).collection('Monitor', ref => ref.where('Timestamp', '>=', timestampFrom).orderBy('Timestamp', 'asc')).snapshotChanges().subscribe(l => {
        l.forEach((location, index) => {
          const same = this.locatelist.find(l => l.payload.doc.id === location.payload.doc.id);
          if (!same) {
            if (location.payload.doc.data().Speed) {
              this.speed = (location.payload.doc.data().Speed).toFixed(2) + 'km/h';
            } else {
              this.speed = '0 km/h';
            }
            this.speedlist.push(this.speed);

            this.locatelist.push(location);
            if (index === 0) {
              const mark = {
                position: new google.maps.LatLng(location.payload.doc.data().Latitude,
                  location.payload.doc.data().Longitude),
                map: this.map,
                icon: device.LorryType === 'Garbage Truck' ? this.garbageUrl : this.roroUrl,
                trip: device.trip,
                date: new Date(location.payload.doc.data().Timestamp),
                id: device.PlateNumber
              };
              const marker = new google.maps.Marker({
                ...mark
              });
              this.lorrylist.push(marker);
              marker.setMap(this.map);
              this.markerlist.push(marker);
              this.map.setCenter(new google.maps.LatLng(location.payload.doc.data().Latitude,
                location.payload.doc.data().Longitude))
              const infoWindow = new google.maps.InfoWindow({
                content: '<span>Vehicle : ' + mark.id + '</span><br>'
              });
              marker.addListener('click', () => {
                this.map.setZoom(18);
                this.map.setCenter(marker.getPosition());
                infoWindow.open(marker.getMap(), marker);
              });
              this.positionlist.push(new google.maps.LatLng(location.payload.doc.data().Latitude,
                location.payload.doc.data().Longitude));
              this.savinglist.push(new firebase.firestore.GeoPoint(location.payload.doc.data().Latitude,
                location.payload.doc.data().Longitude));
            } else {
              const r = this.lorrylist.find(m => m.id === device.PlateNumber);
              if (r) {
                r.date = new Date(location.payload.doc.data().Timestamp);
                r.setPosition(
                  new google.maps.LatLng(location.payload.doc.data().Latitude,
                    location.payload.doc.data().Longitude)
                );
                const SameThreshold = 0.1;
                this.speedlist.push(this.speed);

                this.map.setCenter(new google.maps.LatLng(location.payload.doc.data().Latitude,
                  location.payload.doc.data().Longitude));
                this.positionlist.push(new google.maps.LatLng(location.payload.doc.data().Latitude,
                  location.payload.doc.data().Longitude));
                this.savinglist.push(new firebase.firestore.GeoPoint(location.payload.doc.data().Latitude,
                  location.payload.doc.data().Longitude));
                // if (google.maps.geometry.spherical.computeDistanceBetween(this.positionlist[this.positionlist.length - 1],
                //   new google.maps.LatLng(location.payload.doc.data().Latitude,
                //     location.payload.doc.data().Longitude)) > SameThreshold) {
                //       this.map.panTo(new google.maps.LatLng(location.payload.doc.data().Latitude,
                //       location.payload.doc.data().Longitude));
                //       this.positionlist.push(new google.maps.LatLng(location.payload.doc.data().Latitude,
                //       location.payload.doc.data().Longitude));
                //     this.savinglist.push(new firebase.firestore.GeoPoint(location.payload.doc.data().Latitude,
                //       location.payload.doc.data().Longitude));
                // }

                if (index === l.length - 1) {
                  if (this.positionlist.length > 1) {
                    const list = [];
                    if (this.trackingPath) {
                      this.trackingPath.setMap(null);
                    }
                    for (let i = 0; i < this.positionlist.length; i++) {
                      list.push(this.positionlist[i]);
                      // const abc = new google.maps.Marker({
                      //   position: this.positionlist[i]
                      // })
                      // abc.setMap(this.map);
                      this.pathSavedlist.forEach((ab, index) => {
                        const resultPath = google.maps.geometry.poly.containsLocation(
                          this.positionlist[i],
                          ab
                        )
                        if (resultPath) {
                          this.pathSavedlist[index].setMap(null);
                          this.pathSavedlist[index] = new google.maps.Polygon({
                            paths: this.pathSavedlist[index].getPaths(),
                            strokeColor: 'black',
                            fillColor: 'green',
                            strokeOpacity: 1.0,
                            strokeWeight: 2,
                          })
                          this.pathSavedlist[index].setMap(this.map);
                        }
                      })

                    }
                    this.trackingPath = new google.maps.Polyline({
                      path: list,
                      strokeColor: 'blue',
                      strokeOpacity: 1.0,
                      strokeWeight: 2,
                    });
                    if (this.trackingLineMode)
                      this.trackingPath.setMap(this.map);
                    this.linelist.push(this.trackingPath);
                    this.spinner.hide();
                  } else {
                    this.spinner.hide();
                  }
                }
              }
            }
            index++;
          }
        });
        if (l.length === 0) {
          this.trackingService.unsubscribe();
          this.trackingService = this.firestore.collection('Devices').doc(device.ID).snapshotChanges().subscribe((location: any) => {

            const r = this.lorrylist.find(m => m.id === device.PlateNumber);
            if (r) {
              r.setPosition(
                new google.maps.LatLng(location.payload.data().Latitude,
                  location.payload.data().Longitude)
              )
              this.map.setCenter(new google.maps.LatLng(location.payload.data().Latitude,
                location.payload.data().Longitude))

            } else {
              const mark = {
                position: new google.maps.LatLng(location.payload.data().Latitude,
                  location.payload.data().Longitude),
                map: this.map,
                icon: device.LorryType === 'Garbage Truck' ? this.garbageUrl : this.roroUrl,
                trip: device.trip,
                id: device.PlateNumber
              };
              const marker = new google.maps.Marker({
                ...mark
              });
              this.lorrylist.push(marker);
              marker.setMap(this.map);
              this.markerlist.push(marker);
              this.map.setCenter(new google.maps.LatLng(location.payload.data().Latitude,
                location.payload.data().Longitude))
              const infoWindow = new google.maps.InfoWindow({
                content: '<span>Vehicle : ' + mark.id + '</span><br>'
              });
              marker.addListener('click', () => {
                this.map.setZoom(18);
                this.map.setCenter(marker.getPosition());
                infoWindow.open(marker.getMap(), marker);
              });
            }

            this.positionlist.push(new google.maps.LatLng(location.payload.data().Latitude,
              location.payload.data().Longitude));
            this.savinglist.push(new firebase.firestore.GeoPoint(location.payload.data().Latitude,
              location.payload.data().Longitude));

            const list = [];
            if (this.trackingPath) {
              this.trackingPath.setMap(null);
            }
            for (let i = 0; i < this.positionlist.length; i++) {
              list.push(this.positionlist[i]);
              this.pathSavedlist.forEach((ab, index) => {
                const resultPath = google.maps.geometry.poly.containsLocation(
                  this.positionlist[i],
                  ab
                )
                if (resultPath) {
                  this.pathSavedlist[index].setMap(null);
                  this.pathSavedlist[index] = new google.maps.Polygon({
                    paths: this.pathSavedlist[index].getPaths(),
                    strokeColor: 'black',
                    fillColor: 'green',
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                  })
                  this.pathSavedlist[index].setMap(this.map);
                }
              })

            }
            this.trackingPath = new google.maps.Polyline({
              path: list,
              strokeColor: 'blue',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            });
            this.trackingPath.setMap(this.map);
            this.linelist.push(this.trackingPath);
            this.spinner.hide();
          })
        }

      });
    } else {
      var timestampTo = dateto.getTime() / 1000;

      this.firestore.collection('Devices').doc(device.ID).collection('Monitor', ref => ref.where('Timestamp', '>=', timestampFrom).where('Timestamp', '<=', timestampTo).orderBy('Timestamp', 'asc')).get().forEach(l => {
        console.log(l.size)
        l.forEach((location) => {
          const same = this.locatelist.find(l => l === location.id);
          if (!same) {
            if (location.data().Speed) {
              this.speed = (location.data().Speed).toFixed(2) + 'km/h';
            } else {
              this.speed = '0 km/h';
            }
            this.speedlist.push(this.speed);

            const r = this.lorrylist.find(m => m.id === device.PlateNumber);
            if (r) {
              r.setPosition(
                new google.maps.LatLng(location.data().Latitude,
                  location.data().Longitude)
              )
              this.map.setCenter(new google.maps.LatLng(location.data().Latitude,
                location.data().Longitude))
            } else {
              const mark = {
                position: new google.maps.LatLng(location.data().Latitude,
                  location.data().Longitude),
                map: this.map,
                icon: device.LorryType === 'Garbage Truck' ? this.garbageUrl : this.roroUrl,
                trip: device.trip,
                date: new Date(location.data().Timestamp),
                id: device.PlateNumber
              };
              const marker = new google.maps.Marker({
                ...mark
              });
              this.lorrylist.push(marker);
              marker.setMap(this.map);
              this.markerlist.push(marker);
              this.map.setCenter(new google.maps.LatLng(location.data().Latitude,
                location.data().Longitude))
              const infoWindow = new google.maps.InfoWindow({
                content: '<span>Vehicle : ' + mark.id + '</span><br>'
              });
              marker.addListener('click', () => {
                this.map.setZoom(18);
                this.map.setCenter(marker.getPosition());
                infoWindow.open(marker.getMap(), marker);
              });
            }

            this.locatelist.push(location.id);
            this.positionlist.push(new google.maps.LatLng(location.data().Latitude,
              location.data().Longitude));
            this.savinglist.push(new firebase.firestore.GeoPoint(location.data().Latitude,
              location.data().Longitude));

            this.map.setCenter(new google.maps.LatLng(location.data().Latitude,
              location.data().Longitude));


          }

        });

        if (this.positionlist.length > 1) {
          const list = [];
          if (this.trackingPath) {
            this.trackingPath.setMap(null);
          }
          for (let i = 0; i < this.positionlist.length; i++) {
            list.push(this.positionlist[i]);

            this.pathSavedlist.forEach((ab, index) => {
              const resultPath = google.maps.geometry.poly.containsLocation(
                this.positionlist[i],
                ab
              )
              if (resultPath) {
                this.pathSavedlist[index].setMap(null);
                this.pathSavedlist[index] = new google.maps.Polygon({
                  paths: this.pathSavedlist[index].getPaths(),
                  strokeColor: 'black',
                  fillColor: 'green',
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                })
                this.pathSavedlist[index].setMap(this.map);
              }
            })

          }
          this.trackingPath = new google.maps.Polyline({
            path: list,
            strokeColor: 'blue',
            strokeOpacity: 1.0,
            strokeWeight: 2,
          });
          this.trackingPath.setMap(this.map);
          this.linelist.push(this.trackingPath);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        if (l.size === 0) {
          this.toaster.info('No records')
        }
      });
    }
  }


  deviceInfo: any;
  addMonitor(deviceId, device) {
    this.deletecounter++;
    const trackingdevice = {
      id: deviceId,
      deleteID: deviceId + this.deletecounter,
      device: device,
    };

    const result = this.maplist.find(map => map.id === trackingdevice.id);
    if (!result) {
    this.maplist.push(trackingdevice);
    }
    else {
      this.toaster.warning('This device and the chosen date already been tracking');
    }
  }

  activebutton(hrs) {
    if (!hrs) {
      this.onehrs = false;
      this.twohrs = false;
      this.fourhrs = false;
      this.eighthrs = false;
      this.twehrs = false;
      this.twofourhrs = false;
    }

    switch (hrs) {
      case 1:
        this.onehrs = true;
        this.twohrs = false;
        this.fourhrs = false;
        this.eighthrs = false;
        this.twehrs = false;
        this.twofourhrs = false;
        break;
      case 2:
        this.onehrs = false;
        this.twohrs = true;
        this.fourhrs = false;
        this.eighthrs = false;
        this.twehrs = false;
        this.twofourhrs = false;
        break;
      case 4:
        this.onehrs = false;
        this.twohrs = false;
        this.fourhrs = true;
        this.eighthrs = false;
        this.twehrs = false;
        this.twofourhrs = false;
        break;
      case 8:
        this.onehrs = false;
        this.twohrs = false;
        this.fourhrs = false;
        this.eighthrs = true;
        this.twehrs = false;
        this.twofourhrs = false;
        break; case 12:
        this.onehrs = false;
        this.twohrs = false;
        this.fourhrs = false;
        this.eighthrs = false;
        this.twehrs = true;
        this.twofourhrs = false;
        break;
      case 24:
        this.onehrs = false;
        this.twohrs = false;
        this.fourhrs = false;
        this.eighthrs = false;
        this.twehrs = false;
        this.twofourhrs = true;
        break;

    }

  }

  updateSchedule() {
    const today = this.scheduler.getDay();
    var routeSchedule = [];
    this.schedulerTime = this.scheduler;

    var device = this.deviceInfo;
    if (device.Day) {
      switch (today) {
        case 0: routeSchedule = device.Day.Sunday; break;
        case 1: routeSchedule = device.Day.Monday; break;
        case 2: routeSchedule = device.Day.Tuesday; break;
        case 3: routeSchedule = device.Day.Wednesday; break;
        case 4: routeSchedule = device.Day.Thursday; break;
        case 5: routeSchedule = device.Day.Friday; break;
        case 6: routeSchedule = device.Day.Saturday; break;
      }
    }
    this.route = routeSchedule;

  }

  speedlist = [];
  trackingLineMode = true;

  enable() {
    this.trackingLineMode = true;
    this.trackingPath.setMap(this.map);
  }

  disable() {
    this.trackingLineMode = false;
    this.trackingPath.setMap(null);
  }

  viewInfo(route) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = '90vh';
    dialogConfig.maxHeight = '90vh'
    dialogConfig.width = '80%';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const data = {
      route: route,
      device: this.deviceInfo,
      date: this.schedulerTime,
    }
    dialogConfig.data = data;
    this.dialog.open(RouteInfoComponent, dialogConfig);

  }
  formatLabel(value: number) {
    return value;
  }
  onInputChange(event) {
    const position = this.positionlist[event.value]
    this.map.setCenter(position);
    this.lorrylist[0].setPosition(position);
    this.speed = this.speedlist[event.value];
  }

}
