import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
var CryptoJS = require("crypto-js");
import * as XLSX from 'xlsx';
import { ExcelHelperService } from '../../Services/excel-helper.service';
import * as firebase from 'firebase';


@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class ImportComponent implements OnInit {

  password: string;
  collector: any;

  importData = [];
  constructor(private dialogRef: MatDialogRef<ImportComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private excelHelper: ExcelHelperService,
  ) {
    this.collector = data;

  }

  ngOnInit() {
  }


  cancel() {
    this.dialogRef.close();
  }

  submit() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: 'Adakah anda menambahkan staf dari excel?'
    };
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show();
        for (const d of this.importData) {
          try {
            await this.firestore.collection('collectors').doc(d.ic_num).update(d);
          } catch (error) {
            d.password1 = '123456'
            await this.firestore.collection('collectors').doc(d.ic_num).set(d)
          }
        }

        this.spinner.hide();
        this.toaster.success('Tambah berjaya');
        this.dialogRef.close(true);

      }
    })
  }

  file;
  valid = false;
  onFileChange(event: any) {
    /* wire up file reader */
    try {
      const target: DataTransfer = <DataTransfer>(event.target);
      if (target.files.length !== 1) {
        throw new Error('Cannot use multiple files');
      }
      const reader: FileReader = new FileReader();
      reader.readAsBinaryString(target.files[0]);
      this.file = target.files[0];
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const data: any = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}


        if (!data[0].hasOwnProperty("Nama Kakitangan")) {
          this.valid = false;
          this.toaster.error("Excel tidak sah")
          return;
        }
        if (!data[0].hasOwnProperty("No Kad Pengenalan")) {
          this.valid = false;
          this.toaster.error("Excel tidak sah")
          return;
        }
        if (!data[0].hasOwnProperty("No Kakitangan")) {
          this.valid = false;
          this.toaster.error("Excel tidak sah")
          return;
        }
        if (!data[0].hasOwnProperty("Jawatan")) {
          this.valid = false;
          this.toaster.error("Excel tidak sah")
          return;
        }
        if (!data[0].hasOwnProperty("Depoh Pembersihan")) {
          this.valid = false;
          this.toaster.error("Excel tidak sah")
          return;
        }
        if (!data[0].hasOwnProperty("Zon")) {
          this.valid = false;
          this.toaster.error("Excel tidak sah")
          return;
        }
        if (!data[0].hasOwnProperty("No Pendaftaran Kenderaan")) {
          this.valid = false;
          this.toaster.error("Excel tidak sah")
          return;
        }
        if (!data[0].hasOwnProperty("Nombor Telefon")) {
          this.valid = false;
          this.toaster.error("Excel tidak sah")
          return;
        }
        if (!data[0].hasOwnProperty("Emel")) {
          this.valid = false;
          this.toaster.error("Excel tidak sah")
          return;
        }
        if (!data[0].hasOwnProperty("Alamat")) {
          this.valid = false;
          this.toaster.error("Excel tidak sah")
          return;
        }
        if (!data[0].hasOwnProperty("Poskod")) {
          this.valid = false;
          this.toaster.error("Excel tidak sah")
          return;
        }

        for (const d of data) {
          if (d['No Kad Pengenalan']) {
            const info = {
              ic_num: String(d['No Kad Pengenalan']).replace(/\-/g, ""),
              phone_num: String(d['Nombor Telefon']).replace(/\-/g, ""),
              email: d['Emel'],
              address: d['Alamat'],
              depo: d['Depoh Pembersihan'],
              name: d['Nama Kakitangan'],
              // password1: '123456',
              position: d['Jawatan'],
              worker_id: d['No Kakitangan'],
              zone: d['Zon'],
              plate_num: d['No Pendaftaran Kenderaan'],
              poscode: d['Poskod'],

            }
            this.importData.push(info)
          }
        }
        if (this.importData.length <= 0) {
          this.valid = false;
          this.toaster.error("Tiada data")
          return;
        }


        this.valid = true;
      };
    } catch (error) {
      this.toaster.error('Sila hubungi admin')
    }

  }

  download() {
    const exportInformation = [];
    this.spinner.show();

    const info = {
      "Nama Kakitangan": "",
      "No Kad Pengenalan": "",
      "No Kakitangan": "",
      "Jawatan": "",
      "Depoh Pembersihan": "",
      "Zon": "",
      "No Pendaftaran Kenderaan": '',
      "Nombor Telefon": '',
      "Emel": '',
      "Alamat": '',
      "Poskod": '',
    }
    exportInformation.push(info)

    this.excelHelper.exportAsExcelFileWithoutTime(exportInformation, 'templat.xlsx');
    this.spinner.hide();
  }
}
