import { ConfirmationDialogComponent } from './../../../shared/confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FirestoreServiceService } from '../../Services/firestore-service.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { MediaMatcher } from '@angular/cdk/layout';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DateService } from '../../Services/date.service';
import { TripSelectComponent } from 'src/app/shared/trip-select/trip-select.component';
import * as firebase from 'firebase';
import { NgxSpinnerService } from 'ngx-spinner';
import { RouteSelectComponent } from 'src/app/shared/route-select/route-select.component';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private fireSrv: FirestoreServiceService,
    changeDetectorRef: ChangeDetectorRef,
    private storageRef: AngularFireStorage,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    router: Router,
    private dialog: MatDialog,
    private dateSrv: DateService,
    private toaster: ToastrService,
    private host: ElementRef<HTMLElement>,
    private toster: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    // tslint:disable-next-line: deprecation
    this.localstorageDate = new Date(localStorage.getItem('Time'));
    this.dateSrv.currentDate.subscribe(date => this.selectedDate = date);
    this.role = localStorage.getItem('Role');

  }
  map: google.maps.Map;
  marker: google.maps.Marker;
  mapOptions: google.maps.MapOptions;
  coordinates: any;
  lat = 4.2105;
  lng = 101.9758;
  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  localstorageDate: Date;
  @Input() deviceId: string;
  @Input() selectedDate: Date;
  @Input() DeleteID: string;
  @Input() device: any;


  SelectedDate: Date;
  speed: any;
  imageServices: any;
  trackingServices: any;
  role: any;
  trips = [];
  trackingLineMode = true;


  @Output() someEvent = new EventEmitter<any>();

  lorrylist = [];
  markerlist = [];
  positionlist = [];
  linelist = [];
  locatelist = [];
  savinglist = [];

  trackingPath: any;

  imagelist = [];
  speedlist = [];
  ngOnInit(): void {
    // this.SelectedDate = new Date(this.selectedDate);
    this.spinner.show();
  }

  ngAfterViewInit(): void {
    this.coordinates = new google.maps.LatLng(this.lat, this.lng);
    this.mapOptions = {
      center: this.coordinates,
      zoom: 15
    };
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.MonitorInMap(0, this.device);

  }

  ngOnDestroy(): void {
    if (this.imageServices) {
      this.imageServices.unsubscribe();
    }
    if (this.trackingServices) {
      this.trackingServices.unsubscribe();
    }
  }

  destroy() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: 'Berhenti memantau kenderaan ' + this.device.PlateNumber + '?'
    };

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.ngOnDestroy();
        this.callParent();
        this.host.nativeElement.remove();
      }
    })

  }
  onInputChange(event){
    const position = this.positionlist[event.value]
    this.map.setCenter(position);
    this.lorrylist[0].setPosition(position);
    this.speed = this.speedlist[event.value];
  }
  callParent() {
    const StopTracking = {
      id: this.deviceId,
      date: this.SelectedDate,
      deleteID: this.DeleteID
    };
    this.someEvent.next(StopTracking);
  }

  markerLorryList=[];
  removeLorryMarker() {
    this.markerLorryList.forEach(e => {
      e.setMap(null);
    })
  }
  subsribeService : any;
  job: any;
  jobTime: any;
  route:any;

  MonitorInMap(hrs, device) {
    this.spinner.show();
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);

    this.removeLorryMarker();
    const currenttime = new Date();

    currenttime.setHours(currenttime.getHours() - hrs);

    this.job = "Monitoring from - ";
    this.jobTime = currenttime;
    const today = currenttime.getDay();
    var routeSchedule = [];

    if (!device)
      device = this.device;
    if (device.Day) {
      switch (today) {
        case 0: routeSchedule = device.Day.Sunday; break;
        case 1: routeSchedule = device.Day.Monday; break;
        case 2: routeSchedule = device.Day.Tuesday; break;
        case 3: routeSchedule = device.Day.Wednesday; break;
        case 4: routeSchedule = device.Day.Thursday; break;
        case 5: routeSchedule = device.Day.Friday; break;
        case 6: routeSchedule = device.Day.Saturday; break;
      }
    }
    if (routeSchedule.length === 0) {
      this.toaster.info('No schedule of the selected date');
    }
    this.route = routeSchedule;
    this.spinner.hide();
    this.tracking(device, currenttime, null)
  }

  removePath(path) {
    path.forEach(element => {
      element.setMap(null);
    });
  }

  removeMarkers(marker) {
    marker.forEach(element => {
      element.setMap(null);
    });
  }

  removeSavedPath() {
    this.pathSavedlist.forEach(e => {
      e.setMap(null);
    })
  }
  routeMarker = [];
  pathSavedlist = [];
  trackingService:any;
  roroUrl = "https://img.icons8.com/dusk/50/000000/truck.png";
  garbageUrl = "https://img.icons8.com/dusk/64/000000/garbage-truck.png";

  monitorHrs: any;
  changeMonitor(){
    this.spinner.show();
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);

    this.removeLorryMarker();
    const currenttime = new Date();

    currenttime.setHours(currenttime.getHours() - parseInt(this.monitorHrs));

    this.job = "Monitoring from - ";
    this.jobTime = currenttime;
    const today = currenttime.getDay();
    var routeSchedule = [];

    if (this.device.Day) {
      switch (today) {
        case 0: routeSchedule = this.device.Day.Sunday; break;
        case 1: routeSchedule = this.device.Day.Monday; break;
        case 2: routeSchedule = this.device.Day.Tuesday; break;
        case 3: routeSchedule = this.device.Day.Wednesday; break;
        case 4: routeSchedule = this.device.Day.Thursday; break;
        case 5: routeSchedule = this.device.Day.Friday; break;
        case 6: routeSchedule = this.device.Day.Saturday; break;
      }
    }
    if (routeSchedule.length === 0) {
      this.toaster.info('No schedule of the selected date');
    }
    this.route = routeSchedule;
    this.spinner.hide();
    this.tracking(this.device, currenttime, null)
  }
  tracking(device, datefrom: Date, dateto) {
    var timestampFrom = datefrom.getTime() / 1000;
    this.locatelist = [];
    this.removeMarkers(this.markerlist);
    this.removePath(this.linelist);
    this.markerlist = [];
    this.linelist = [];
    this.lorrylist = [];
    this.positionlist = [];
    this.savinglist = [];
    this.imagelist = [];
    this.routeMarker = [];
    this.spinner.show();
    this.speedlist = [];
    const hr = datefrom.getHours();
    const min = datefrom.getMinutes();

    var hr1; var min1;
    if(dateto)
       hr1 = datefrom.getHours();
       min1 = datefrom.getMinutes();


    if (this.route.length > 0) {
      this.removeSavedPath();
      this.pathSavedlist = [];
      this.route.forEach(e => {
        if(!dateto){
          if (!(hr >= parseInt(e.startAt.split(':')[0]) && min >= parseInt(e.startAt.split(':')[1]))){
            return;
          }
        }else{
          if(!(hr >= parseInt(e.startAt.split(':')[0]) &&
              min >= parseInt(e.startAt.split(':')[1]) &&
              hr1 <= parseInt(e.endAt.split(':')[0]) &&
              min1 <= parseInt(e.endAt.split(':')[1])
              )){
                return;
              }
        }

        e.data.regions.forEach(element => {
          const paths = [];
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(element.centerCoordinate.latitude, element.centerCoordinate.longitude),
            icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
          })
          const infoWindow = new google.maps.InfoWindow({
            content: '<span>Region : ' + element.RouteName + '</span><br>'
          });
          marker.addListener('click', () => {
            this.map.setZoom(18);
            this.map.setCenter(marker.getPosition());
            infoWindow.open(marker.getMap(), marker);
          });
          marker.setMap(this.map);
          this.routeMarker.push(marker);
          element.coordinates.forEach(coor => {
            paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
          });
          const path = new google.maps.Polygon({
            paths: paths,
            strokeColor: 'black',
            fillColor: 'red',
            strokeOpacity: 1.0,
            strokeWeight: 1,
          });

          path.setMap(this.map);
          this.pathSavedlist.push(path);

        });
      });
    }
    else {
      this.spinner.hide();
    }
    if (this.trackingService)
      this.trackingService.unsubscribe();
    if (dateto === null) {
      this.trackingService = this.firestore.collection('Devices').doc(device.ID).collection('Monitor', ref => ref.where('Timestamp', '>=', timestampFrom).orderBy('Timestamp', 'asc')).snapshotChanges().subscribe(l => {
        l.forEach((location, index) => {
          const same = this.locatelist.find(l => l.payload.doc.id === location.payload.doc.id);
          if (!same) {
            if (location.payload.doc.data().Speed) {
              this.speed = (location.payload.doc.data().Speed).toFixed(2) + 'km/h';
            } else {
              this.speed = '0 km/h';
            }
            this.speedlist.push(this.speed);
            this.locatelist.push(location);

            if (index === 0) {
              const mark = {
                position: new google.maps.LatLng(location.payload.doc.data().Latitude,
                  location.payload.doc.data().Longitude),
                map: this.map,
                icon: device.LorryType === 'Garbage Truck' ? this.garbageUrl : this.roroUrl,
                trip: device.trip,
                date: new Date(location.payload.doc.data().Timestamp),
                id: device.PlateNumber
              };
              const marker = new google.maps.Marker({
                ...mark
              });
              this.lorrylist.push(marker);
              marker.setMap(this.map);
              this.markerlist.push(marker);
              this.map.setCenter(new google.maps.LatLng(location.payload.doc.data().Latitude,
                location.payload.doc.data().Longitude))
              const infoWindow = new google.maps.InfoWindow({
                content: '<span>Vehicle : ' + mark.id + '</span><br>'
              });
              marker.addListener('click', () => {
                this.map.setZoom(18);
                this.map.setCenter(marker.getPosition());
                infoWindow.open(marker.getMap(), marker);
              });
              this.positionlist.push(new google.maps.LatLng(location.payload.doc.data().Latitude,
                location.payload.doc.data().Longitude));
              this.savinglist.push(new firebase.firestore.GeoPoint(location.payload.doc.data().Latitude,
                location.payload.doc.data().Longitude));
            } else {
              const r = this.lorrylist.find(m => m.id === device.PlateNumber);
              if (r) {
                r.date = new Date(location.payload.doc.data().Timestamp);
                r.setPosition(
                  new google.maps.LatLng(location.payload.doc.data().Latitude,
                    location.payload.doc.data().Longitude)
                );
                const SameThreshold = 0.1;

                this.map.setCenter(new google.maps.LatLng(location.payload.doc.data().Latitude,
                  location.payload.doc.data().Longitude));
                this.positionlist.push(new google.maps.LatLng(location.payload.doc.data().Latitude,
                  location.payload.doc.data().Longitude));
                this.savinglist.push(new firebase.firestore.GeoPoint(location.payload.doc.data().Latitude,
                  location.payload.doc.data().Longitude));
                // if (google.maps.geometry.spherical.computeDistanceBetween(this.positionlist[this.positionlist.length - 1],
                //   new google.maps.LatLng(location.payload.doc.data().Latitude,
                //     location.payload.doc.data().Longitude)) > SameThreshold) {
                //       this.map.panTo(new google.maps.LatLng(location.payload.doc.data().Latitude,
                //       location.payload.doc.data().Longitude));
                //       this.positionlist.push(new google.maps.LatLng(location.payload.doc.data().Latitude,
                //       location.payload.doc.data().Longitude));
                //     this.savinglist.push(new firebase.firestore.GeoPoint(location.payload.doc.data().Latitude,
                //       location.payload.doc.data().Longitude));
                // }

                if (index === l.length - 1) {
                  if (this.positionlist.length > 1) {
                    const list = [];
                    if (this.trackingPath) {
                      this.trackingPath.setMap(null);
                    }
                    for (let i = 0; i < this.positionlist.length; i++) {
                      list.push(this.positionlist[i]);
                      // const abc = new google.maps.Marker({
                      //   position: this.positionlist[i]
                      // })
                      // abc.setMap(this.map);
                      this.pathSavedlist.forEach((ab, index) => {
                        const resultPath = google.maps.geometry.poly.containsLocation(
                          this.positionlist[i],
                          ab
                        )
                        if (resultPath) {
                          this.pathSavedlist[index].setMap(null);
                          this.pathSavedlist[index] = new google.maps.Polygon({
                            paths: this.pathSavedlist[index].getPaths(),
                            strokeColor: 'black',
                            fillColor: 'green',
                            strokeOpacity: 1.0,
                            strokeWeight: 2,
                          })
                          this.pathSavedlist[index].setMap(this.map);
                        }
                      })

                    }
                    this.trackingPath = new google.maps.Polyline({
                      path: list,
                      strokeColor: 'blue',
                      strokeOpacity: 1.0,
                      strokeWeight: 2,
                    });
                    if(this.trackingLineMode)
                      this.trackingPath.setMap(this.map);
                    this.linelist.push(this.trackingPath);
                    this.spinner.hide();
                  } else {
                    this.spinner.hide();
                  }
                }
              }
            }
            index++;
          }
        });
        if (l.length === 0) {
          this.trackingService.unsubscribe();
          this.trackingService = this.firestore.collection('Devices').doc(device.ID).snapshotChanges().subscribe((location: any) => {

            if (location.payload.data().Speed) {
              this.speed = (location.payload.data().Speed).toFixed(2) + 'km/h';
            } else {
              this.speed = '0 km/h';
            }
            this.speedlist.push(this.speed);

            const r = this.lorrylist.find(m => m.id === device.PlateNumber);
            if (r) {
              r.setPosition(
                new google.maps.LatLng(location.payload.data().Latitude,
                  location.payload.data().Longitude)
              )
              this.map.setCenter(new google.maps.LatLng(location.payload.data().Latitude,
                location.payload.data().Longitude))

            } else {
              const mark = {
                position: new google.maps.LatLng(location.payload.data().Latitude,
                  location.payload.data().Longitude),
                map: this.map,
                icon: device.LorryType === 'Garbage Truck' ? this.garbageUrl : this.roroUrl,
                trip: device.trip,
                id: device.PlateNumber
              };
              const marker = new google.maps.Marker({
                ...mark
              });
              this.lorrylist.push(marker);
              marker.setMap(this.map);
              this.markerlist.push(marker);
              this.map.setCenter(new google.maps.LatLng(location.payload.data().Latitude,
                location.payload.data().Longitude))
              const infoWindow = new google.maps.InfoWindow({
                content: '<span>Vehicle : ' + mark.id + '</span><br>'
              });
              marker.addListener('click', () => {
                this.map.setZoom(18);
                this.map.setCenter(marker.getPosition());
                infoWindow.open(marker.getMap(), marker);
              });
            }

            this.positionlist.push(new google.maps.LatLng(location.payload.data().Latitude,
              location.payload.data().Longitude));
            this.savinglist.push(new firebase.firestore.GeoPoint(location.payload.data().Latitude,
              location.payload.data().Longitude));

            const list = [];
            if (this.trackingPath) {
              this.trackingPath.setMap(null);
            }
            for (let i = 0; i < this.positionlist.length; i++) {
              list.push(this.positionlist[i]);
              this.pathSavedlist.forEach((ab, index) => {
                const resultPath = google.maps.geometry.poly.containsLocation(
                  this.positionlist[i],
                  ab
                )
                if (resultPath) {
                  this.pathSavedlist[index].setMap(null);
                  this.pathSavedlist[index] = new google.maps.Polygon({
                    paths: this.pathSavedlist[index].getPaths(),
                    strokeColor: 'black',
                    fillColor: 'green',
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                  })
                  this.pathSavedlist[index].setMap(this.map);
                }
              })

            }
            this.trackingPath = new google.maps.Polyline({
              path: list,
              strokeColor: 'blue',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            });
            this.trackingPath.setMap(this.map);
            this.linelist.push(this.trackingPath);
            this.spinner.hide();
          })
        }

      });
    }
    // } else {
    //   var timestampTo = dateto.getTime() / 1000;

    //   this.firestore.collection('Devices').doc(device.ID).collection('Monitor', ref => ref.where('Timestamp', '>=', timestampFrom).where('Timestamp', '<=', timestampTo).orderBy('Timestamp', 'asc')).get().forEach(l => {
    //     console.log(l.size)
    //     l.forEach((location) => {
    //       const same = this.locatelist.find(l => l === location.id);
    //       if (!same) {
    //         if (location.data().Speed) {
    //           this.speed = (location.data().Speed).toFixed(2) + 'km/h';
    //         } else {
    //           this.speed = '0 km/h';
    //         }

    //         const r = this.lorrylist.find(m => m.id === device.PlateNumber);
    //         if (r) {
    //           r.setPosition(
    //             new google.maps.LatLng(location.data().Latitude,
    //               location.data().Longitude)
    //           )
    //           this.map.setCenter(new google.maps.LatLng(location.data().Latitude,
    //             location.data().Longitude))
    //         } else {
    //           const mark = {
    //             position: new google.maps.LatLng(location.data().Latitude,
    //               location.data().Longitude),
    //             map: this.map,
    //             icon: device.LorryType === 'Garbage Truck' ? this.garbageUrl : this.roroUrl,
    //             trip: device.trip,
    //             date: new Date(location.data().Timestamp),
    //             id: device.PlateNumber
    //           };
    //           const marker = new google.maps.Marker({
    //             ...mark
    //           });
    //           this.lorrylist.push(marker);
    //           marker.setMap(this.map);
    //           this.markerlist.push(marker);
    //           this.map.setCenter(new google.maps.LatLng(location.data().Latitude,
    //             location.data().Longitude))
    //           const infoWindow = new google.maps.InfoWindow({
    //             content: '<span>Vehicle : ' + mark.id + '</span><br>'
    //           });
    //           marker.addListener('click', () => {
    //             this.map.setZoom(18);
    //             this.map.setCenter(marker.getPosition());
    //             infoWindow.open(marker.getMap(), marker);
    //           });
    //         }

    //         this.locatelist.push(location.id);
    //         this.positionlist.push(new google.maps.LatLng(location.data().Latitude,
    //           location.data().Longitude));
    //         this.savinglist.push(new firebase.firestore.GeoPoint(location.data().Latitude,
    //           location.data().Longitude));

    //         this.map.setCenter(new google.maps.LatLng(location.data().Latitude,
    //           location.data().Longitude));


    //       }

    //     });

    //     if (this.positionlist.length > 1) {
    //       const list = [];
    //       if (this.trackingPath) {
    //         this.trackingPath.setMap(null);
    //       }
    //       for (let i = 0; i < this.positionlist.length; i++) {
    //         list.push(this.positionlist[i]);

    //         this.pathSavedlist.forEach((ab, index) => {
    //           const resultPath = google.maps.geometry.poly.containsLocation(
    //             this.positionlist[i],
    //             ab
    //           )
    //           if (resultPath) {
    //             this.pathSavedlist[index].setMap(null);
    //             this.pathSavedlist[index] = new google.maps.Polygon({
    //               paths: this.pathSavedlist[index].getPaths(),
    //               strokeColor: 'black',
    //               fillColor: 'green',
    //               strokeOpacity: 1.0,
    //               strokeWeight: 2,
    //             })
    //             this.pathSavedlist[index].setMap(this.map);
    //           }
    //         })

    //       }
    //       this.trackingPath = new google.maps.Polyline({
    //         path: list,
    //         strokeColor: 'blue',
    //         strokeOpacity: 1.0,
    //         strokeWeight: 2,
    //       });
    //       if(this.trackingLineMode)
    //         this.trackingPath.setMap(this.map);
    //       this.linelist.push(this.trackingPath);
    //       this.spinner.hide();
    //     } else {
    //       this.spinner.hide();
    //     }
    //     if (l.size === 0) {
    //       this.toaster.info('No records')
    //     }
    //   });
    // }
  }

  enable(){
    this.trackingLineMode = true;
    this.trackingPath.setMap(this.map);
  }

  disable(){
    this.trackingLineMode = false;
    this.trackingPath.setMap(null);
  }




}
