import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import * as jsPdf from 'jspdf';
import { MatDialogConfig, MatDialog, MatCalendarBody } from '@angular/material';
import { PdfDatePickerComponent } from 'src/app/shared/pdf-date-picker/pdf-date-picker.component';
import { AngularFireStorage } from '@angular/fire/storage';
import * as Chart from 'chart.js';
import html2canvas from 'html2canvas';
import { AngularFirestore } from '@angular/fire/firestore';
import { title } from 'process';
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'October'];
  role: any;
  zonelist = [];
  selectedZone: any;
  WeightGraph: any;
  Title: any;
  selectedDate: Date;
  maxDate: Date;
  constructor(private dialog: MatDialog,
    private storageRef: AngularFireStorage,
    private spinner: NgxSpinnerService,
    private firestore: AngularFirestore,
  ) {
    this.role = localStorage.getItem('Role');
    if (this.role === 'KPA') {
      this.Title = 'All';
    }
    this.selectedDate=new Date();
    this.maxDate=new Date();

  }

  change(){

  }
  ngOnInit() {
    this.spinner.show()
    this.firestore.collection('zones').get().forEach(e => {
      this.spinner.hide()
      e.forEach(doc => {
        this.zonelist.push(doc.data());
      })
    })
  }

  showZone(zone) {
    this.selectedZone = zone.name;
    this.Title = this.selectedZone;
  }


  ngAfterViewInit(): void {


      this.WeightGraph = new Chart('graph', {
        type: 'horizontalBar',
        data: {
          labels: ['PLA3321', 'PLM4123', 'PLA2131', 'PLM3333', 'PPA1112', 'PPE2212',
            'PLA8642', 'PMM23123'],
          datasets: [{
            label: 'Berat Dalam Tan',
            data: [12, 19, 3, 5, 2, 3, 8,7,5],
            backgroundColor:'#e46479',
            borderWidth: 1
          }]
        },
        options: {
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {

                  return "";

              },
              color: '#fff',
            }
          },
          legend: {
            display: true
          },
          scales: {
            xAxes: [{
              display: true,
              ticks: {
                beginAtZero: true   // minimum value will be 0.
              }
            }],
            yAxes: [{
              display: true,
            }],
          },
          responsive: true,
          maintainAspectRatio: true
        }
      });
      this.WeightGraph = new Chart('graph2', {
        type: 'horizontalBar',
        data: {
          labels: ['PLA3321', 'PLM4123', 'PLA2131', 'PLM3333', 'PPA1112', 'PPE2212',
            'PLA8642', 'PMM23123'],
            datasets: [{
              label: 'Bilangan Perjalanan',
              data: [12, 19, 3, 5, 2, 3, 8,7,5],
              backgroundColor:'#7764e4',
              borderWidth: 1
            }]
          },
          options: {
            plugins: {
              datalabels: {
                formatter: (value, ctx) => {

                    return "";

                },
                color: '#fff',
              }
            },
            legend: {
              display: true
            },
            scales: {
              xAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true   // minimum value will be 0.
                }
              }],
              yAxes: [{
                display: true,
              }],
            },
            responsive: true,
            maintainAspectRatio: true
          }
        });
      this.WeightGraph = new Chart('graph3', {
        type: 'horizontalBar',
        data: {
          labels: ['Domestik', 'Industri', 'Lain-lain'],
          datasets: [{
            label: 'Berat Dalam Tan',
            data: [22, 16, 26 ],
            backgroundColor: '#e4d764'
          }]
        },
        options: {
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {

                  return "";

              },
              color: '#fff',
            }
          },
          legend: {
            display: true
          },
          scales: {
            xAxes: [{
              display: true,
              ticks: {
                beginAtZero: true   // minimum value will be 0.
              }
            }],
            yAxes: [{
              display: true,
            }],
          },
          responsive: true,
          maintainAspectRatio: true
        }
      });

      new Chart('graph7', {
        type: 'horizontalBar',
        data: {
          labels: ['PLA3321', 'PLM4123', 'PLA2131', 'PLM3333', 'PPA1112', 'PPE2212',
            'PLA8642', 'PMM23123'],
          datasets: [{
            label: 'Berat Dalam Tan',
            data: [3, 3, 5, 2, 1, 1, 7,1,2],
            backgroundColor:'#e46479',
            borderWidth: 1
          }]
        },
        options: {
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {

                  return "";

              },
              color: '#fff',
            }
          },
          legend: {
            display: true
          },
          scales: {
            xAxes: [{
              display: true,
              ticks: {
                beginAtZero: true   // minimum value will be 0.
              }
            }],
            yAxes: [{
              display: true,
            }],
          },
          responsive: true,
          maintainAspectRatio: true
        }
      });

      new Chart('graph8', {
        type: 'horizontalBar',
        data: {
          labels: ['PLA3321', 'PLM4123', 'PLA2131', 'PLM3333', 'PPA1112', 'PPE2212',
            'PLA8642', 'PMM23123'],
            datasets: [{
              label: 'Bilangan Perjalanan',
              data: [5, 2, 8, 1, 1, 1, 1,2,3],
              backgroundColor:'#7764e4',
              borderWidth: 1
            }]
          },
          options: {
            plugins: {
              datalabels: {
                formatter: (value, ctx) => {

                    return "";

                },
                color: '#fff',
              }
            },
            legend: {
              display: true
            },
            scales: {
              xAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true   // minimum value will be 0.
                }
              }],
              yAxes: [{
                display: true,
              }],
            },
            responsive: true,
            maintainAspectRatio: true
          }
        });
      new Chart('graph9', {
        type: 'horizontalBar',
        data: {
          labels: ['Domestik', 'Industri', 'Lain-lain'],
          datasets: [{
            label: 'Berat Dalam Tan',
            data: [10, 2, 20 ],
            backgroundColor: '#e4d764'
          }]
        },
        options: {
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {

                  return "";

              },
              color: '#fff',
            }
          },
          legend: {
            display: true
          },
          scales: {
            xAxes: [{
              display: true,
              ticks: {
                beginAtZero: true   // minimum value will be 0.
              }
            }],
            yAxes: [{
              display: true,
            }],
          },
          responsive: true,
          maintainAspectRatio: true
        }
      });


  }

  compare(a: number | string, b: number | string, isAsc: boolean) {

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  async getZone(street: string): Promise<any> {
    var returnedData = this.firestore.collection('streets',
      ref => ref.where('streetName', '==', street)).get().toPromise();
    return returnedData;
  }

  async getImage(dateString): Promise<any> {
    var returnedData = await this.firestore.collection('reports').doc(dateString).
      collection('reports', ref => ref.where('mode', '==', 'kpa').orderBy('deviceId', 'asc'))
      .get().toPromise();
    for (const report of returnedData.docs) {
      const street = await this.getZone(report.data().choosedStreet);
      let info = {};
      const r = this.imagelist.find(i => i.choosedWork === report.data().choosedWork &&
        i.choosedStreet === report.data().choosedStreet);
      if (r) {
        if (report.data().type === 'sebelum') {
          r.imagesebelum = report.data().image;
          if (report.data().deviceId === 'Gurun') {
            r.datesebelum = new Date(report.data().date);
          } else {
            r.datesebelum = new Date(report.data().date.toDate());
          }

        } else if (report.data().type === 'semasa') {
          r.imagesemasa = report.data().image;
          if (report.data().deviceId === 'Gurun') {
            r.datesemasa = new Date(report.data().date);
          } else {
            r.datesemasa = new Date(report.data().date.toDate());
          }
        } else if (report.data().type === 'selepas') {
          r.imageselepas = report.data().image;
          if (report.data().deviceId === 'Gurun') {
            r.dateselepas = new Date(report.data().date);
          } else {
            r.dateselepas = new Date(report.data().date.toDate());
          }

        }
      } else {
        await street.forEach(async s => {

          if (report.data().type === 'sebelum') {

            if (report.data().deviceId === 'Gurun') {
              info = {
                position: new google.maps.LatLng(report.data().location.latitude,
                  report.data().location.longitude),
                latitude: report.data().location.latitude,
                longitude: report.data().location.longitude,
                id: report.data().deviceId,
                datesebelum: new Date(report.data().date),
                datesemasa: '',
                dateselepas: '',
                imagesebelum: report.data().image,
                imageselepas: '../../../assets/notyet.png',
                imagesemasa: '../../../assets/notyet.png',
                choosedStreet: report.data().choosedStreet,
                choosedWork: report.data().choosedWork,
                belongZone: s.data().belongZone
              };
            } else {
              info = {
                position: new google.maps.LatLng(report.data().location.latitude,
                  report.data().location.longitude),
                latitude: report.data().location.latitude,
                longitude: report.data().location.longitude,
                id: report.data().deviceId,
                datesebelum: new Date(report.data().date.toDate()),
                datesemasa: '',
                dateselepas: '',
                imagesebelum: report.data().image,
                imageselepas: '../../../assets/notyet.png',
                imagesemasa: '../../../assets/notyet.png',
                choosedStreet: report.data().choosedStreet,
                choosedWork: report.data().choosedWork,
                belongZone: s.data().belongZone

              };
            }
            this.imagelist.push(info);

          } else if (report.data().type === 'semasa') {
            if (report.data().deviceId === 'Gurun') {
              info = {
                position: new google.maps.LatLng(report.data().location.latitude,
                  report.data().location.longitude),
                latitude: report.data().location.latitude,
                longitude: report.data().location.longitude,
                id: report.data().deviceId,
                datesebelum: '',
                datesemasa: new Date(report.data().date),
                dateselepas: '',
                imagesebelum: '../../../assets/notyet.png',
                imagesemasa: report.data().image,
                imageselepas: '../../../assets/notyet.png',
                choosedStreet: report.data().choosedStreet,
                choosedWork: report.data().choosedWork,
                belongZone: s.data().belongZone

              };
            } else {
              info = {
                position: new google.maps.LatLng(report.data().location.latitude,
                  report.data().location.longitude),
                latitude: report.data().location.latitude,
                longitude: report.data().location.longitude,
                id: report.data().deviceId,
                datesebelum: '',
                datesemasa: new Date(report.data().date.toDate()),
                dateselepas: '',
                imagesebelum: '../../../assets/notyet.png',
                imagesemasa: report.data().image,
                imageselepas: '../../../assets/notyet.png',
                choosedStreet: report.data().choosedStreet,
                choosedWork: report.data().choosedWork,
                belongZone: s.data().belongZone

              };
            }

            this.imagelist.push(info);

          } else if (report.data().type === 'selepas') {
            if (report.data().deviceId === 'Gurun') {
              info = {
                position: new google.maps.LatLng(report.data().location.latitude,
                  report.data().location.longitude),
                latitude: report.data().location.latitude,
                longitude: report.data().location.longitude,
                id: report.data().deviceId,
                datesebelum: '',
                datesemasa: '',
                dateselepas: new Date(report.data().date),
                imagesebelum: '../../../assets/notyet.png',
                imagesemasa: '../../../assets/notyet.png',
                imageselepas: report.data().image,
                choosedStreet: report.data().choosedStreet,
                choosedWork: report.data().choosedWork,
                belongZone: s.data().belongZone

              };
            } else {
              info = {
                position: new google.maps.LatLng(report.data().location.latitude,
                  report.data().location.longitude),
                latitude: report.data().location.latitude,
                longitude: report.data().location.longitude,
                id: report.data().deviceId,
                datesebelum: '',
                datesemasa: '',
                dateselepas: new Date(report.data().date.toDate()),
                imagesebelum: '../../../assets/notyet.png',
                imagesemasa: '../../../assets/notyet.png',
                imageselepas: report.data().image,
                choosedStreet: report.data().choosedStreet,
                choosedWork: report.data().choosedWork,
                belongZone: s.data().belongZone

              };
            }

            this.imagelist.push(info);

          }
        })
      }
    }


    return this.imagelist;
  }
  imagelist = [];
  async savePdfDay(date) {
    this.imagelist = [];
    this.spinner.show();
    const year = date.getFullYear();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const dateString = year + month + day;
    this.imagelist = await this.getImage(dateString);
    this.imagelist = this.imagelist.sort((a, b) => {
      return this.compare(a.choosedStreet, b.choosedStreet, true);
    })
    const doc = new jsPdf();
    doc.setFontSize(32);
    doc.setFontType('bold');
    doc.text('LAPORAN BERGAMBAR', 35, 80);
    doc.text('(' + this.Title + ')', 35, 100);
    doc.setFontSize(28);
    doc.setFontType('bold');
    doc.text('Date: ' + date.toDateString(), 35, 120);
    doc.setFontType('normal');
    this.spinner.hide();
    let counter = 0;

    if (this.Title !== 'All') {
      this.imagelist = this.imagelist.filter(i => i.belongZone === this.selectedZone);
    }

    this.imagelist.forEach(device => {
      if (device.datesebelum === '' || device.datesemasa === '' || device.dateselepas === '') {

      } else {
        if (counter % 2 === 0) {
          doc.addPage();
          doc.setFontSize(12);
          const splitTitle = doc.splitTextToSize('Lokasi :' + device.choosedStreet, 70);

          doc.text(splitTitle, 30, 20);
          doc.text('Activiti :' + device.choosedWork, 30, 30);
          doc.text('Sebelum', 30, 35);
          doc.text('Semasa', 30, 120);
          doc.text('Selepas', 30, 205);
          const imagesebelum = document.createElement('img');
          const imageselepas = document.createElement('img');
          const imagesemasa = document.createElement('img');

          imagesebelum.src = device.imagesebelum;
          imageselepas.src = device.imageselepas;
          imagesemasa.src = device.imagesemasa;
          doc.addImage(imagesebelum, 'PNG', 30, 38, 70, 70);
          doc.setTextColor(255, 255, 255);
          doc.addImage(imagesemasa, 'PNG', 30, 123, 70, 70);
          doc.addImage(imageselepas, 'PNG', 30, 208, 70, 70);

          doc.setTextColor(0, 0, 0);

        } else {
          const splitTitle = doc.splitTextToSize('Lokasi:' + device.choosedStreet, 70);

          doc.text(splitTitle, 110, 20);
          doc.text('Activity :' + device.choosedWork, 110, 30);

          doc.text('Sebelum', 110, 35);
          doc.text('Semasa', 110, 120);
          doc.text('Selepas', 110, 205);
          const imagesebelum = document.createElement('img');
          const imageselepas = document.createElement('img');
          const imagesemasa = document.createElement('img');

          imagesebelum.src = device.imagesebelum;
          imageselepas.src = device.imageselepas;
          imagesemasa.src = device.imagesemasa;
          doc.addImage(imagesebelum, 'PNG', 110, 38, 70, 70);
          doc.setTextColor(255, 255, 255);
          doc.addImage(imagesemasa, 'PNG', 110, 123, 70, 70);
          doc.addImage(imageselepas, 'PNG', 110, 208, 70, 70);
          doc.setTextColor(0, 0, 0);

        }
        counter++;
      }
    })
    this.spinner.hide();

    doc.save(date.toDateString() + '_KPA_Report.pdf');
  }

  async savePdfWeek(dateFrom, dateTo) {
    const lastDay = new Date(dateTo);
    const firstDay = new Date(dateFrom);
    var Difference_In_Time = lastDay.getTime() - firstDay.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    console.log(Difference_In_Days)
    this.imagelist = [];
    this.spinner.show();
    const doc = new jsPdf();
    for (let i = 0; i <= Difference_In_Days; i++) {
      const year = firstDay.getFullYear();
      const day = ('0' + firstDay.getDate()).slice(-2);
      const month = ('0' + (firstDay.getMonth() + 1)).slice(-2);
      const dateString = year + month + day;
      this.imagelist = await this.getImage(dateString);
      this.imagelist = this.imagelist.sort((a, b) => {
        return this.compare(a.choosedStreet, b.choosedStreet, true);
      })
      console.log(this.imagelist);
      doc.setFontSize(32);
      doc.setFontType('bold');
      doc.text('LAPORAN BERGAMBAR', 35, 80);
      doc.text('(' + this.Title + ')', 35, 100);
      doc.setFontSize(28);
      doc.setFontType('bold');
      doc.text('Date: ' + firstDay.toDateString(), 35, 120);
      doc.setFontType('normal');
      let counter = 0;
      this.imagelist.forEach(device => {
        if (device.datesebelum === '' || device.datesemasa === '' || device.dateselepas === '') {
        } else {
          if (counter % 2 === 0) {
            doc.addPage();
            doc.setFontSize(12);
            const splitTitle = doc.splitTextToSize('Lokasi :' + device.choosedStreet, 70);
            doc.text(splitTitle, 30, 20);
            doc.text('Activiti :' + device.choosedWork, 30, 30);
            doc.text('Sebelum', 30, 35);
            doc.text('Semasa', 30, 120);
            doc.text('Selepas', 30, 205);
            const imagesebelum = document.createElement('img');
            const imageselepas = document.createElement('img');
            const imagesemasa = document.createElement('img');

            imagesebelum.src = device.imagesebelum;
            imageselepas.src = device.imageselepas;
            imagesemasa.src = device.imagesemasa;
            doc.addImage(imagesebelum, 'PNG', 30, 38, 70, 70);
            doc.setTextColor(255, 255, 255);
            doc.addImage(imagesemasa, 'PNG', 30, 123, 70, 70);
            doc.addImage(imageselepas, 'PNG', 30, 208, 70, 70);

            doc.setTextColor(0, 0, 0);

          } else {
            const splitTitle = doc.splitTextToSize('Lokasi:' + device.choosedStreet, 70);
            const splitTitleimg = doc.splitTextToSize(device.choosedStreet, 40);

            doc.text(splitTitle, 110, 20);
            doc.text('Activity :' + device.choosedWork, 110, 30);
            doc.text('Sebelum', 110, 35);
            doc.text('Semasa', 110, 120);
            doc.text('Selepas', 110, 205);
            const imagesebelum = document.createElement('img');
            const imageselepas = document.createElement('img');
            const imagesemasa = document.createElement('img');

            imagesebelum.src = device.imagesebelum;
            imageselepas.src = device.imageselepas;
            imagesemasa.src = device.imagesemasa;
            doc.addImage(imagesebelum, 'PNG', 110, 38, 70, 70);
            doc.setTextColor(255, 255, 255);
            doc.addImage(imagesemasa, 'PNG', 110, 123, 70, 70);
            doc.addImage(imageselepas, 'PNG', 110, 208, 70, 70);
            doc.setTextColor(0, 0, 0);

          }
          counter++;
        }
      })
      doc.addPage();

      console.log(firstDay);
      firstDay.setDate(firstDay.getDate() + 1);
      if (i === (Difference_In_Days)) {
        this.spinner.hide();
      }

    }
    doc.save(dateFrom.toDateString() + '_KPA_Report_Range.pdf');

  }

  async savePdfMonth(date) {
    const totalDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    this.imagelist = [];
    this.spinner.show();
    const doc = new jsPdf();
    for (let i = 0; i < totalDay; i++) {
      const year = firstDay.getFullYear();
      const day = ('0' + firstDay.getDate()).slice(-2);
      const month = ('0' + (firstDay.getMonth() + 1)).slice(-2);
      const dateString = year + month + day;
      this.imagelist = await this.getImage(dateString);
      this.imagelist = this.imagelist.sort((a, b) => {
        return this.compare(a.choosedStreet, b.choosedStreet, true);
      })
      doc.setFontSize(32);
      doc.setFontType('bold');
      doc.text('LAPORAN BERGAMBAR', 35, 80);
      doc.text('(' + this.Title + ')', 35, 100);
      doc.setFontSize(28);
      doc.setFontType('bold');
      doc.text('Date: ' + firstDay.toDateString(), 35, 120);
      doc.setFontType('normal');
      let counter = 0;
      this.imagelist.forEach(device => {
        if (device.datesebelum === '' || device.datesemasa === '' || device.dateselepas === '') {
        } else {
          if (counter % 2 === 0) {
            doc.addPage();
            doc.setFontSize(12);
            const splitTitle = doc.splitTextToSize('Lokasi :' + device.choosedStreet, 70);
            doc.text(splitTitle, 30, 20);
            doc.text('Activiti :' + device.choosedWork, 30, 30);
            doc.text('Sebelum', 30, 35);
            doc.text('Semasa', 30, 120);
            doc.text('Selepas', 30, 205);
            const imagesebelum = document.createElement('img');
            const imageselepas = document.createElement('img');
            const imagesemasa = document.createElement('img');

            imagesebelum.src = device.imagesebelum;
            imageselepas.src = device.imageselepas;
            imagesemasa.src = device.imagesemasa;
            doc.addImage(imagesebelum, 'PNG', 30, 38, 70, 70);
            doc.setTextColor(255, 255, 255);
            doc.addImage(imagesemasa, 'PNG', 30, 123, 70, 70);
            doc.addImage(imageselepas, 'PNG', 30, 208, 70, 70);

            doc.setTextColor(0, 0, 0);

          } else {
            const splitTitle = doc.splitTextToSize('Lokasi:' + device.choosedStreet, 70);
            const splitTitleimg = doc.splitTextToSize(device.choosedStreet, 40);

            doc.text(splitTitle, 110, 20);
            doc.text('Activity :' + device.choosedWork, 110, 30);
            doc.text('Sebelum', 110, 35);
            doc.text('Semasa', 110, 120);
            doc.text('Selepas', 110, 205);
            const imagesebelum = document.createElement('img');
            const imageselepas = document.createElement('img');
            const imagesemasa = document.createElement('img');

            imagesebelum.src = device.imagesebelum;
            imageselepas.src = device.imageselepas;
            imagesemasa.src = device.imagesemasa;
            doc.addImage(imagesebelum, 'PNG', 110, 38, 70, 70);
            doc.setTextColor(255, 255, 255);
            doc.addImage(imagesemasa, 'PNG', 110, 123, 70, 70);
            doc.addImage(imageselepas, 'PNG', 110, 208, 70, 70);
            doc.setTextColor(0, 0, 0);

          }
          counter++;
        }
      })
      doc.addPage();
      console.log(firstDay);
      firstDay.setDate(firstDay.getDate() + 1);
      if (i === totalDay - 1) {
        this.spinner.hide();
      }

    }
    doc.save(date.toDateString() + '_KPA_Report_Week.pdf');
  }

  saveMonthlyPPSReport() {
    const cDate = new Date();
    const totalDay = new Date(cDate.getFullYear(), cDate.getMonth() + 1, 0).getDate();
    const doc = new jsPdf();
    doc.setFontSize(12);
    const col = ['Tarikh', 'Berat (KG)', 'TRIP', 'Berat (TAN)'];
    const rowdata = [];
    const LorryType = ['Type1', 'Type2', 'Type3', 'Type4'];
    const LorryTypeTotal = LorryType.length;
    const LorryTypeNumber = ['6', '3', '4', '5'];
    const TotalLorry = 6 + 3 + 4 + 5;

    let total = 0;
    let totalTrip = 0;
    for (let i = 1; i <= totalDay; i++) {
      const randomNumber = Math.floor(Math.random() * Math.floor(10000));
      const tripNumber = Math.floor(Math.random() * Math.floor(42));
      total += randomNumber;
      totalTrip += tripNumber;
      const row = [i, randomNumber, tripNumber, randomNumber / 1000];
      rowdata.push(row);

      if (i === totalDay) {
        const row = ['Total ', total, totalTrip, total / 1000];
        rowdata.push(row);

      }
    }

    doc.setFontType('bold');

    doc.text('PPS Monthly Report ' + this.months[cDate.getMonth()] + ' ' + cDate.getFullYear(), 70, 10);
    doc.setFontSize(10);
    doc.setFontType('normal');

    doc.text('Bulan', 20, 15);
    doc.text(':', 55, 15);
    doc.text(this.months[cDate.getMonth()], 58, 15);


    doc.text('Jumlah Lori', 20, 20);
    doc.text(':', 55, 20);
    doc.text(TotalLorry.toString(), 58, 20);
    doc.text('Jenis Lori', 20, 25);
    doc.text(':', 55, 25);
    let textLorryJenis = '';
    LorryType.forEach(element => {
      textLorryJenis += element + ' | ';
    });
    doc.text(textLorryJenis, 58, 25);
    doc.text('Nama Kontraktor', 20, 30);
    doc.text(':', 55, 30);
    doc.text('xxx sdn. bhd', 58, 30);

    doc.text('Zon', 20, 35);
    doc.text(':', 55, 35);
    doc.text('USM', 58, 35);


    const drawCell = function (data) {
      const doc = data.doc;
      const rows = data.table.body;
      if (rows.length === 1) {
      } else if (data.row.index === rows.length - 1) {
        doc.setFontStyle('bold');
        doc.setFontSize('9');
        doc.setFillColor(255, 255, 255);
      }
    };

    doc.autoTable(col, rowdata, {
      startY: 40, theme: 'grid', margin: 0, styles: {
        padding: 0,
        fontSize: 8,
      }, willDrawCell: drawCell
    },
    );

    doc.addPage('a4', 'landscape');
    doc.setFontSize(12);
    doc.setFontType('bold');
    doc.text('PPS Monthly Report ' + this.months[cDate.getMonth()] + ' ' + cDate.getFullYear(), 110, 10);
    doc.setFontSize(10);
    doc.setFontType('normal');
    doc.text('Nama Kontraktor', 20, 20);
    doc.text(':', 55, 20);
    doc.text('xxx sdn. bhd', 58, 20);
    doc.text('Zon', 20, 25);
    doc.text(':', 55, 25);
    doc.text('USM', 58, 25);


    const col2 = ['Lori', 'Berat (KG)', 'Berat (TAN)'];
    const rowdata2 = [];

    for (let i = 1; i <= TotalLorry; i++) {
      const randomNumber = Math.floor(Math.random() * Math.floor(10000));
      total += randomNumber;
      const row = [i, randomNumber, randomNumber / 1000];
      rowdata2.push(row);
    }

    const options = {
      background: 'white',
    };

    const canvas = document.getElementById('graph');
    html2canvas(canvas, options).then((canvas) => {
      const img = canvas.toDataURL('image/PNG');
      doc.addImage(img, 'PNG', 130, 50, 150, 120);
      doc.autoTable(col2, rowdata2, {
        startX: 20, startY: 30, theme: 'grid', margin: 0, tableWidth: 100, styles: {
          padding: 0,
          fontSize: 8,
        }
      },
      );
      LorryType.forEach((type, index) => {
        doc.addPage('a4', 'landscape');
        doc.setFontSize(12);
        doc.text('PPS Monthly Report ' + this.months[cDate.getMonth()] + ' ' + cDate.getFullYear(), 110, 10);
        doc.setFontSize(10);
        doc.setFontType('normal');
        doc.text('Nama Kontraktor', 20, 20);
        doc.text(':', 55, 20);
        doc.text('xxx sdn. bhd', 58, 20);
        doc.text('Zon', 20, 25);
        doc.text(':', 55, 25);
        doc.text('USM', 58, 25);
        doc.text('Jenis Lori', 20, 30);
        doc.text(':', 55, 30);
        doc.text(type, 58, 30);
        const head = [];
        const headContent = [];
        const headContent2 = [];
        headContent.push({ content: 'Tarikh', colSpan: 1, rowSpan: 2 });
        const length = parseInt(LorryTypeNumber[index]);
        for (let i = 1; i <= length; i++) {
          const item = { content: type + '-' + i, colSpan: 1 };
          headContent.push(item);
          headContent2.push('KG');
          if (i === length) {
            const t = { content: 'Jumlah', colSpan: 1 };
            headContent.push(t);
            headContent2.push('KG');
          }
        }

        for (let i = 1; i <= length; i++) {
          const item = { content: type + '-' + i, colSpan: 1 };
          headContent.push(item);
          headContent2.push('Trip');
          if (i === length) {
            const t = { content: 'Jumlah', colSpan: 1 };
            headContent.push(t);
            headContent2.push('Trip');
          }
        }
        head.push(headContent);
        head.push(headContent2);
        const rowdata1 = [];
        let jumlahPerLorry = [];

        for (let i = 1; i <= totalDay; i++) {
          const row1 = [];
          row1.push(i);
          let jumlah = 0;
          for (let i = 1; i <= length; i++) {
            const randomNumber1 = Math.floor(Math.random() * Math.floor(10000));
            jumlah += randomNumber1;
            row1.push(randomNumber1.toString());
          }
          row1.push(jumlah);
          let jumlahtrip = 0;
          for (let i = 1; i <= length; i++) {
            const tripNumber1 = Math.floor(Math.random() * Math.floor(5)) + 1;
            jumlahtrip += tripNumber1;
            row1.push(tripNumber1.toString());
          }

          row1.push(jumlahtrip);
          if (i === 1) {
            jumlahPerLorry = row1.slice();
          } else {
            row1.forEach((e, count) => {
              jumlahPerLorry[count] = parseInt(jumlahPerLorry[count]) + parseInt(e);
            });
          }
          rowdata1.push(row1);
          if (i === totalDay) {
            jumlahPerLorry[0] = 'Total';
            rowdata1.push(jumlahPerLorry);
          }
        }

        const drawJumlahCell = function (data) {
          const doc = data.doc;
          const rows = data.table.body;
          if (data.column.index === length + 1 || (data.column.index) === (length * 2 + 2)) {
            doc.setFontStyle('bold');
            doc.setFontSize('9');
          }
          if (data.row.index === rows.length - 1) {
            doc.setFontStyle('bold');
            doc.setFontSize('9');
            doc.setFillColor(255, 255, 255);
          }
        };
        doc.autoTable({
          head, body: rowdata1, theme: 'grid', startY: 35,
          headStyles: {
            lineWidth: 0.1
          },
          willDrawCell: drawJumlahCell
        });
      });

      for (let i = 1; i <= totalDay; i++) {
        doc.addPage('a4');
        doc.setFontSize(12);
        doc.text('PPS Monthly Report ' + this.months[cDate.getMonth()] + ' ' + cDate.getFullYear(), 70, 10);

        doc.setFontSize(10);
        doc.setFontType('normal');
        doc.text('Nama Kontraktor', 20, 20);
        doc.text(':', 55, 20);
        doc.text('xxx sdn. bhd', 58, 20);
        doc.text('Zon', 20, 25);
        doc.text(':', 55, 25);
        doc.text('USM', 58, 25);
        doc.text('Date', 20, 30);
        doc.text(':', 55, 30);
        doc.text(i + ' ' + this.months[cDate.getMonth()] + ' ' + cDate.getFullYear(), 58, 30);


        const headDay = [[
          { content: 'BIL', colSpan: 1, rowSpan: 2, styles: { halign: 'center' } },
          { content: 'LORI', colSpan: 1, rowSpan: 2, styles: { halign: 'center' } },
          { content: 'TIKET TIMBANG', colSpan: 4, styles: { halign: 'center' } },
        ],
        [{ content: 'TRIP (KG)', styles: { halign: 'center' } },
        { content: 'MASA', styles: { halign: 'center' } },
        { content: 'JUMLAH (KG)', styles: { halign: 'center' } },
        { content: 'JUMLAH (KG)', styles: { halign: 'center' } }],];
        const bodyDay = [];

        let jumlahWeight = 0;
        let jumlahTrip = 0;
        LorryType.forEach((type, index) => {
          console.log(type);
          const l = parseInt(LorryTypeNumber[index]);
          for (let j = 1; j <= l; j++) {
            const tripno = Math.floor(Math.random() * Math.floor(4)) + 1;
            console.log(tripno);
            let t = 0;
            for (let k = 1; k <= tripno; k++) {
              const body = [];
              if (k === 1) {
                const randomNo = 1000;
                jumlahWeight += 1000 * tripno;
                jumlahTrip += tripno;
                t += randomNo;
                const bil = { content: i, colSpan: 1, rowSpan: tripno, styles: { halign: 'center' } };
                const lorrytype = { content: type + '-' + j, colSpan: 1, rowSpan: tripno, styles: { halign: 'center' } };
                const r1 = { content: randomNo, colSpan: 1, rowSpan: 1, styles: { halign: 'center', } };
                const masa = { content: '7:30', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } };
                body.push(bil);
                body.push(lorrytype);
                body.push(r1);
                body.push(masa);
                const j1 = { content: 1000 * tripno, colSpan: 1, rowSpan: tripno, styles: { halign: 'center' } };
                const tr1 = { content: tripno, colSpan: 1, rowSpan: tripno, styles: { halign: 'center' } };
                body.push(j1);
                body.push(tr1);

                bodyDay.push(body);
              } else {
                const randomNo = 1000;
                t += randomNo;
                const r1 = { content: randomNo, colSpan: 1, rowSpan: 1, styles: { halign: 'center' } };
                const masa = { content: '7:30', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } };
                body.push(r1);
                body.push(masa);
                bodyDay.push(body);
              }
            }
          }
        });

        const lastrow = [];
        const lastrowdata = { content: 'JUMLAH (KG|TRIP)', colSpan: 2, styles: { halign: 'center' } };
        const lastrowdata1 = { content: jumlahWeight, colSpan: 1, styles: { halign: 'center' } };
        const lastrowdata2 = { content: '', colSpan: 1, styles: { halign: 'center' } };
        const lastrowdata3 = { content: jumlahWeight, colSpan: 1, styles: { halign: 'center' } };
        const lastrowdata4 = { content: jumlahTrip, colSpan: 1, styles: { halign: 'center' } };
        lastrow.push(lastrowdata);
        lastrow.push(lastrowdata1);
        lastrow.push(lastrowdata2);
        lastrow.push(lastrowdata3);
        lastrow.push(lastrowdata4);

        bodyDay.push(lastrow);


        doc.autoTable({
          head: headDay, body: bodyDay, theme: 'grid', startY: 35, headStyles: {
            lineWidth: 0.1
          },
          willDrawCell: drawCell
        });


      }


      doc.save('_PPS_Report.pdf');

    });



  }

  saveMonthlyPPSReportOldStyle() {
    const cDate = new Date();
    const totalDay = new Date(cDate.getFullYear(), cDate.getMonth() + 1, 0).getDate();
    const doc = new jsPdf();
    doc.setFontSize(12);
    const col = ['Tarikh', 'Berat (KG)', 'TRIP', 'Berat (TAN)'];
    const rowdata = [];
    const LorryType = ['Type1', 'Type2', 'Type3', 'Type4'];
    const LorryTypeTotal = LorryType.length;
    const LorryTypeNumber = ['6', '3', '4', '5'];
    const TotalLorry = 6 + 3 + 4 + 5;

    let total = 0;
    let totalTrip = 0;
    for (let i = 1; i <= totalDay; i++) {
      const randomNumber = Math.floor(Math.random() * Math.floor(10000));
      const tripNumber = Math.floor(Math.random() * Math.floor(42));
      total += randomNumber;
      totalTrip += tripNumber;
      const row = [i, randomNumber, tripNumber, randomNumber / 1000];
      rowdata.push(row);

      if (i === totalDay) {
        const row = ['Total ', total, totalTrip, total / 1000];
        rowdata.push(row);

      }
    }

    doc.setFontType('bold');

    doc.text('PPS Monthly Report ' + this.months[cDate.getMonth()] + ' ' + cDate.getFullYear(), 70, 10);
    doc.setFontSize(10);
    doc.setFontType('normal');

    doc.text('Bulan', 20, 15);
    doc.text(':', 55, 15);
    doc.text(this.months[cDate.getMonth()], 58, 15);


    doc.text('Jumlah Lori', 20, 20);
    doc.text(':', 55, 20);
    doc.text(TotalLorry.toString(), 58, 20);
    doc.text('Jenis Lori', 20, 25);
    doc.text(':', 55, 25);
    let textLorryJenis = '';
    LorryType.forEach(element => {
      textLorryJenis += element + ' | ';
    });
    doc.text(textLorryJenis, 58, 25);
    doc.text('Nama Kontraktor', 20, 30);
    doc.text(':', 55, 30);
    doc.text('xxx sdn. bhd', 58, 30);

    doc.text('Zon', 20, 35);
    doc.text(':', 55, 35);
    doc.text('USM', 58, 35);


    const drawCell = function (data) {
      const doc = data.doc;
      const rows = data.table.body;
      if (rows.length === 1) {
      } else if (data.row.index === rows.length - 1) {
        doc.setFontStyle('bold');
        doc.setFontSize('9');
        doc.setFillColor(255, 255, 255);
      }
    };

    doc.autoTable(col, rowdata, {
      startY: 40, theme: 'grid', margin: 0, styles: {
        padding: 0,
        fontSize: 8,
      }, willDrawCell: drawCell
    },
    );

    doc.addPage('a4', 'landscape');
    doc.setFontSize(12);
    doc.setFontType('bold');
    doc.text('PPS Monthly Report ' + this.months[cDate.getMonth()] + ' ' + cDate.getFullYear(), 110, 10);
    doc.setFontSize(10);
    doc.setFontType('normal');
    doc.text('Nama Kontraktor', 20, 20);
    doc.text(':', 55, 20);
    doc.text('xxx sdn. bhd', 58, 20);
    doc.text('Zon', 20, 25);
    doc.text(':', 55, 25);
    doc.text('USM', 58, 25);


    const col2 = ['Lori', 'Berat (KG)', 'Berat (TAN)'];
    const rowdata2 = [];

    for (let i = 1; i <= TotalLorry; i++) {
      const randomNumber = Math.floor(Math.random() * Math.floor(10000));
      total += randomNumber;
      const row = [i, randomNumber, randomNumber / 1000];
      rowdata2.push(row);
    }

    const options = {
      background: 'white',
    };

    const canvas = document.getElementById('graph');
    html2canvas(canvas, options).then((canvas) => {
      const img = canvas.toDataURL('image/PNG');
      doc.addImage(img, 'PNG', 130, 50, 150, 120);
      doc.autoTable(col2, rowdata2, {
        startX: 20, startY: 30, theme: 'grid', margin: 0, tableWidth: 100, styles: {
          padding: 0,
          fontSize: 8,
        }
      },
      );
      doc.addPage('a4', 'landscape');
      doc.setFontSize(12);
      doc.text('PPS Monthly Report ' + this.months[cDate.getMonth()] + ' ' + cDate.getFullYear(), 110, 10);
      doc.setFontSize(10);
      doc.setFontType('normal');
      doc.text('Nama Kontraktor', 20, 15);
      doc.text(':', 55, 15);
      doc.text('xxx sdn. bhd', 58, 15);
      doc.text('Zon', 20, 20);
      doc.text(':', 55, 20);
      doc.text('USM', 58, 20);

      let head = [];
      let tarikhHeader = [{ content: 'Tarikh', colSpan: 17, rowSpan: 1 }];
      let loriHeader = [];
      let kgTripHeader = [];
      const counterDate = 1;
      const separateTableNumber = Math.ceil(totalDay / 8);
      console.log(separateTableNumber);
      let index = 1;
      let flag = false;
      for (let table = 1; table <= separateTableNumber; table++) {
        loriHeader = [];
        kgTripHeader = [];
        loriHeader.push({ content: 'Lori', colSpan: 1, rowSpan: 2 });
        for (let d = 1; d <= 8; d++) {
          if (index - 1 === totalDay) {
            if (totalDay === 31) {
              tarikhHeader = [{ content: 'Tarikh', colSpan: 15, rowSpan: 1 },
              { content: 'Jumlah', colSpan: 2, rowSpan: 2 }, { content: 'Jumlah', colSpan: 1, rowSpan: 3 }];
              kgTripHeader.push({ content: 'KG', colSpan: 1 });
              kgTripHeader.push({ content: 'TRIP', colSpan: 1 });
              flag = true;
            } else if (totalDay === 30) {
              tarikhHeader = [{ content: 'Tarikh', colSpan: 13, rowSpan: 1 },
              { content: 'Jumlah', colSpan: 2, rowSpan: 2 }, { content: 'Jumlah', colSpan: 1, rowSpan: 3 }];
              kgTripHeader.push({ content: 'KG', colSpan: 1 });
              kgTripHeader.push({ content: 'TRIP', colSpan: 1 });
              flag = true;

            } else if (totalDay === 29) {
              tarikhHeader = [{ content: 'Tarikh', colSpan: 11, rowSpan: 1 },
              { content: 'Jumlah', colSpan: 2, rowSpan: 2 }, { content: 'Jumlah', colSpan: 1, rowSpan: 3 }];
              kgTripHeader.push({ content: 'KG', colSpan: 1 });
              kgTripHeader.push({ content: 'TRIP', colSpan: 1 });
              flag = true;

            } else if (totalDay === 28) {
              tarikhHeader = [{ content: 'Tarikh', colSpan: 9, rowSpan: 1 },
              { content: 'Jumlah', colSpan: 2, rowSpan: 2 }, { content: 'Jumlah', colSpan: 1, rowSpan: 3 }];
              kgTripHeader.push({ content: 'KG', colSpan: 1 });
              kgTripHeader.push({ content: 'TRIP', colSpan: 1 });
              flag = true;

            }
            break;
          }
          loriHeader.push({ content: index, colSpan: 2 });
          kgTripHeader.push({ content: 'KG', colSpan: 1 });
          kgTripHeader.push({ content: 'TRIP', colSpan: 1 });
          index++;
        }
        head.push(tarikhHeader);
        head.push(loriHeader);
        head.push(kgTripHeader);
        let row = [];
        const body = [];

        LorryType.forEach((type, ini) => {
          const length = parseInt(LorryTypeNumber[ini]);
          const jumlahWeight = [0, 0, 0, 0, 0, 0, 0, 0];
          const jumlahTrip = [0, 0, 0, 0, 0, 0, 0, 0];


          for (let i = 1; i <= length; i++) {
            row = [];
            row.push({ content: type + '-' + i, colSpan: 1 });

            for (let d = 1; d <= 8; d++) {
              if (table === 4 && d === 8) {
                break;
              }
              const randomNumber1 = Math.floor(Math.random() * Math.floor(10000));
              const tripNumber1 = Math.floor(Math.random() * Math.floor(5)) + 1;
              row.push({ content: randomNumber1, colSpan: 1 });
              row.push({ content: tripNumber1, colSpan: 1 });
              jumlahWeight[d - 1] += randomNumber1;
              jumlahTrip[d - 1] += tripNumber1;

            }

            body.push(row);


            if (i === length) {
              row = [];

              row.push({ content: 'Jumlah', colSpan: 1, styles: { fontStyle: 'bold', fillColor: 'rgb(248,248,248)' } });
              for (let d = 1; d <= 8; d++) {
                if (table === 4 && d === 8) {
                  break;
                }
                row.push({ content: jumlahWeight[d - 1], colSpan: 1, styles: { fontStyle: 'bold', fillColor: 'rgb(248,248,248)' } });
                row.push({ content: jumlahTrip[d - 1], colSpan: 1, styles: { fontStyle: 'bold', fillColor: 'rgb(248,248,248)' } });
              }
              body.push(row);

            }

          }

        });


        doc.autoTable({
          head, body, theme: 'grid', startY: 25,
          headStyles: {
            lineWidth: 0.1

          }, styles: {
            padding: 0,
            fontSize: 7,
            halign: 'center'
          }
        });
        head = [];

        if (table !== separateTableNumber) {
          doc.addPage('a4', 'landscape');

        }

      }


      for (let i = 1; i <= totalDay; i++) {
        doc.addPage('a4');
        doc.setFontSize(12);
        doc.text('PPS Monthly Report ' + this.months[cDate.getMonth()] + ' ' + cDate.getFullYear(), 70, 10);

        doc.setFontSize(10);
        doc.setFontType('normal');
        doc.text('Nama Kontraktor', 20, 20);
        doc.text(':', 55, 20);
        doc.text('xxx sdn. bhd', 58, 20);
        doc.text('Zon', 20, 25);
        doc.text(':', 55, 25);
        doc.text('USM', 58, 25);
        doc.text('Date', 20, 30);
        doc.text(':', 55, 30);
        doc.text(i + ' ' + this.months[cDate.getMonth()] + ' ' + cDate.getFullYear(), 58, 30);


        const headDay = [[
          { content: 'BIL', colSpan: 1, rowSpan: 2, styles: { halign: 'center' } },
          { content: 'LORI', colSpan: 1, rowSpan: 2, styles: { halign: 'center' } },
          { content: 'TIKET TIMBANG', colSpan: 4, styles: { halign: 'center' } },
        ],
        [{ content: 'TRIP (KG)', styles: { halign: 'center' } },
        { content: 'MASA', styles: { halign: 'center' } },
        { content: 'JUMLAH (KG)', styles: { halign: 'center' } },
        { content: 'JUMLAH (KG)', styles: { halign: 'center' } }],];
        const bodyDay = [];

        let jumlahWeight = 0;
        let jumlahTrip = 0;
        let bilno = 0;
        LorryType.forEach((type, index) => {
          const l = parseInt(LorryTypeNumber[index]);
          for (let j = 1; j <= l; j++) {
            const tripno = Math.floor(Math.random() * Math.floor(4)) + 1;
            let t = 0;
            for (let k = 1; k <= tripno; k++) {
              const body = [];
              if (k === 1) {
                const randomNo = 1000;
                jumlahWeight += 1000 * tripno;
                jumlahTrip += tripno;
                t += randomNo;
                const bil = { content: bilno, colSpan: 1, rowSpan: tripno, styles: { halign: 'center' } };
                bilno++;
                const lorrytype = { content: type + '-' + j, colSpan: 1, rowSpan: tripno, styles: { halign: 'center' } };
                const r1 = { content: randomNo, colSpan: 1, rowSpan: 1, styles: { halign: 'center', } };
                const masa = { content: '7:30', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } };
                body.push(bil);
                body.push(lorrytype);
                body.push(r1);
                body.push(masa);
                const j1 = { content: 1000 * tripno, colSpan: 1, rowSpan: tripno, styles: { halign: 'center' } };
                const tr1 = { content: tripno, colSpan: 1, rowSpan: tripno, styles: { halign: 'center' } };
                body.push(j1);
                body.push(tr1);

                bodyDay.push(body);
              } else {
                const randomNo = 1000;
                t += randomNo;
                const r1 = { content: randomNo, colSpan: 1, rowSpan: 1, styles: { halign: 'center' } };
                const masa = { content: '7:30', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } };
                body.push(r1);
                body.push(masa);
                bodyDay.push(body);
              }
            }
          }
        });

        const lastrow = [];
        const lastrowdata = { content: 'JUMLAH (KG|TRIP)', colSpan: 2, styles: { halign: 'center' } };
        const lastrowdata1 = { content: jumlahWeight, colSpan: 1, styles: { halign: 'center' } };
        const lastrowdata2 = { content: '', colSpan: 1, styles: { halign: 'center' } };
        const lastrowdata3 = { content: jumlahWeight, colSpan: 1, styles: { halign: 'center' } };
        const lastrowdata4 = { content: jumlahTrip, colSpan: 1, styles: { halign: 'center' } };
        lastrow.push(lastrowdata);
        lastrow.push(lastrowdata1);
        lastrow.push(lastrowdata2);
        lastrow.push(lastrowdata3);
        lastrow.push(lastrowdata4);

        bodyDay.push(lastrow);


        doc.autoTable({
          head: headDay, body: bodyDay, theme: 'grid', startY: 35, headStyles: {
            lineWidth: 0.1
          },
          willDrawCell: drawCell
        });


      }


      doc.save('_PPS_Report.pdf');

    });



  }

  openPicker() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.open(PdfDatePickerComponent, dialogConfig).afterClosed().subscribe(async (result) => {
      if (result) {
        switch (result.type) {
          case 'day':
            this.savePdfDay(result.date);
            break;
          case 'week':
            this.savePdfWeek(result.dateFrom, result.dateTo);
            break;
          case 'month':
            this.savePdfMonth(result.date);
            break;
        }
      }
    });

  }


}
