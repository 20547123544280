import { NgZone } from '@angular/core';
import { Component, ElementRef, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationlistComponent } from '../create-route/confirmationlist/confirmationlist.component';
const cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'app-route-detail',
  templateUrl: './route-detail.component.html',
  styleUrls: ['./route-detail.component.css']
})
export class RouteDetailComponent implements AfterViewInit {

  @ViewChild('mapContainer1', { static: false }) gmap: ElementRef;
  @ViewChild('search', { static: false }) searchElementRef: ElementRef;
  map: google.maps.Map;

  regionlist = [];
  clone = [];
  form: FormGroup;
  pathSavedlist = [];
  markerlist = [];
  name: string;
  selectionlist = [];
  route: any;
  disposal: any;
  constructor(private dialogRef: MatDialogRef<RouteDetailComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,
    private tosterservice: ToastrService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private ngZone: NgZone
  ) {
    this.regionlist = data.region;
    this.clone = this.regionlist.slice();
    this.name = data.route.Name;


    this.route = cloneDeep(data.route);

    this.selectionlist = this.route.regions;

    this.disposal = this.clone.find(e=>e.id === data.route.mark)?this.clone.find(e=>e.id === data.route.mark).RouteName: '';

    const regionControl = new FormControl();
    const regionfilterControl = new FormControl();
    regionfilterControl.valueChanges.subscribe(() => {
      this.findRegion(regionfilterControl.value);
    });
    regionControl.valueChanges.subscribe(() => {
      if (regionControl.value) {
        const result = this.clone.find(e => e.RouteName === regionControl.value);
        if (result) {
          const r = this.markerlist.find(m => m.id === result.id);
          this.map.panTo(r.position);
          this.map.setZoom(18);
        }

      }
    });

    this.form = this.fb.group({
      regionControl,
      regionfilterControl,
    });
  }

  findRegion(value) {
    this.regionlist = this.clone.filter(p => p.RouteName.toLowerCase().includes(value.toLowerCase()));
  }

  ngAfterViewInit() {
    const coordinates = new google.maps.LatLng(4.2105, 101.9758);
    const mapOptions = {
      center: coordinates,
      zoom: 15
    };
    this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);
    const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        // get the place result
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();

        // verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        this.map.panTo(place.geometry.location);
        this.map.setZoom(18);

      });
    });

    this.clone.forEach(e => {
      const sameID = this.route.regions.find(id=>id === e.id)

      const marker = new google.maps.Marker({
        ...e,
        position: new google.maps.LatLng(e.centerCoordinate.latitude,
          e.centerCoordinate.longitude),
        draggable: true,

      });
      marker.setMap(this.map);
      if(sameID){
        this.map.setZoom(19);
        this.map.setCenter(marker.getPosition());
      }

      var infoWindow;
      if(!sameID){
        infoWindow = new google.maps.InfoWindow({
          content: '<div class="text-center"><span class="mb-1"> ' + e.RouteName + '</span><br>' +
            '<button class="btn btn-primary p-0 w-100" style="font-size:12px" id=window' + e.id + '>Select </button></div>'
        });
      }else{
        infoWindow = new google.maps.InfoWindow({
          content: '<div class="text-center"><span class="mb-1"> ' + e.RouteName + '</span><br>' +
            '<button class="btn btn-danger p-0 w-100" style="font-size:12px" id=window' + e.id + ' >Deselect </button></div>'
        });
      }

      google.maps.event.addListener(infoWindow, 'domready', () => {
        document.getElementById('window' + e.id).addEventListener('click', () => {
          const text = document.getElementById('window' + e.id).innerText;
          if (text === 'Select') {
            this.selectionlist.push(e.id);
            document.getElementById('window' + e.id).innerText = "Deselect"
            document.getElementById('window' + e.id).style.background = "red";
            document.getElementById('window' + e.id).style.borderColor = "red";

            const path = this.pathSavedlist.find(s => s.id === e.id);
            path.setOptions({ strokeColor: 'blue' });
            infoWindow.close();


          } else {
            const i = this.selectionlist.findIndex(s => s === e.id);
            this.selectionlist.splice(i, 1);
            document.getElementById('window' + e.id).innerText = "Select"
            document.getElementById('window' + e.id).style.background = "#007bff";
            document.getElementById('window' + e.id).style.borderColor = "#007bff";

            const path = this.pathSavedlist.find(s => s.id === e.id);
            path.setOptions({ strokeColor: 'gray' });
            infoWindow.close();
          }

        });
      })
      marker.addListener('click', () => {
        this.map.setZoom(19);
        this.map.setCenter(marker.getPosition());
        infoWindow.open(marker.getMap(), marker);
      });
      // if(!sameID)
      //   google.maps.event.trigger(marker, 'click');

      const paths = [];
      e.coordinates.forEach(coor => {
        paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
      });
      if(sameID){
        const path = new google.maps.Polyline({
          ...e,
          path: paths,
          strokeColor: 'blue',
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });
        path.setMap(this.map);
        this.pathSavedlist.push(path);
      }else{
        const path = new google.maps.Polyline({
          ...e,
          path: paths,
          strokeColor: 'gray',
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });
        path.setMap(this.map);
        this.pathSavedlist.push(path);
      }


      this.markerlist.push(marker);
    })
  }

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    const list = []

    this.selectionlist.forEach(e => {
      var data = this.regionlist.find(region => region.id === e);
      list.push(data);
    })

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '70%';

    dialogConfig.disableClose = true;

    dialogConfig.data = {
      data: list,
      route: this.route,
    };
    this.dialog.open(ConfirmationlistComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close(true);
      }
    })
  }

}
