import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatPaginator, PageEvent, Sort } from '@angular/material';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ExcelHelperService } from '../../Services/excel-helper.service';
import { HistoryComponent } from '../history/history.component';

@Component({
  selector: 'app-history-qr',
  templateUrl: './history-qr.component.html',
  styleUrls: ['./history-qr.component.css']
})
export class HistoryQrComponent implements OnInit {
  qr: string = "";
  qr660: string = "";
  binlist: any = [];
  sortedbinlist: any = []
  data: any;
  url = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  title: string;  
  fromDateRekod: Date = new Date();
  toDateRekod: Date = new Date();
  type;
  ic: string;
  name: string;
  shop_name: string;
  last_date: Date;
  depoList: any[] = [];

  constructor(private dialogRef: MatDialogRef<HistoryQrComponent>,
    private  dialog : MatDialog,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private firestore: AngularFirestore,
    private excelHelper: ExcelHelperService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.depoList = data;
  }

  detectChanges3(event) {
    this.fromDateRekod = event;
  }

  detectChanges4(event) {
    this.toDateRekod = event;
  }


  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  sortedu = {
    active: '',
    direction: ''
  };

  bin = {
    address: '',
    bin_size: '',
    id: '',
    last_scan: '',
    license_num: '',
    location: '',
  }
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    this.regrab();
  }

  limitbin() {
    this.sortedbinlist = this.sortedbinlist.slice(this.offset, (this.offset + this.pageSize));
  }

  
  regrab() {
    const from = new Date(this.fromDateRekod.setHours(0, 0, 0, 0))
    const to = new Date(this.toDateRekod.setHours(23, 59, 59, 999))
    this.sortedbinlist = this.binlist.filter(u =>
      u.date.getTime() >= from.getTime() && u.date.getTime() <= to.getTime()
    );

    this.length = this.sortedbinlist.length;
    this.sortbin();
    this.limitbin();
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.sortedbinlist = this.binlist.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedbinlist = this.binlist.slice();
      this.limitbin();
      return;
    }

    this.sortedbinlist = this.sortedbinlist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'depo': return this.compare(a.depo, b.depo, isAsc);
        case 'shop_name': return this.compare(a.shop_name, b.shop_name, isAsc);
        case 'name': return this.compare(a.name, b.name, isAsc);
        case 'license_number': return this.compare(a.license_number, b.license_number, isAsc);
        case 'bin': return this.compare(a.bin, b.bin, isAsc);
        case 'date': return this.compareDate(a.date, b.date, isAsc);
        case 'address': return this.compare(a.address, b.address, isAsc);
        case 'status': return this.compare(a.status, b.status, isAsc);
        default: return 0;
      }
    });
    this.limitbin();
  }


  sortbin() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.sortedbinlist = this.sortedbinlist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'depo': return this.compare(a.depo, b.depo, isAsc);
        case 'shop_name': return this.compare(a.shop_name, b.shop_name, isAsc);
        case 'name': return this.compare(a.name, b.name, isAsc);
        case 'license_number': return this.compare(a.license_number, b.license_number, isAsc);
        case 'bin': return this.compare(a.bin, b.bin, isAsc);
        case 'date': return this.compareDate(a.date, b.date, isAsc);
        case 'address': return this.compare(a.address, b.address, isAsc);
        case 'status': return this.compare(a.status, b.status, isAsc);
        default: return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compareDate(a, b, isAsc: boolean) {

    a = new Date(a).getTime();
    b = new Date(b).getTime();
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }



  ngOnInit() {
    this.spinner.show();
    this.firestore.collection('requestslog', ref=>ref.orderBy('date','desc')).get().forEach(doc=>{
      if(!doc.empty){
        let index = 0;
        doc.forEach(d=>{
          if(this.depoList.find( e=>e.Name === d.data().depo) || d.data().depo === '-'){
            if(index === 0){
              this.last_date =  new Date(d.data().date.toDate());
              const ld = new Date(this.last_date);
              this.fromDateRekod = new Date(ld.setMonth(ld.getMonth() - 3));
              this.toDateRekod = new Date(this.last_date);
            }
            this.binlist.push({...d.data(), date: new Date(d.data().date.toDate())})
            this.sortedbinlist = this.binlist.slice();
            index++;
          }
        })
        this.regrab();
      
     
      }
    }).finally(()=>{
      this.spinner.hide();
    })
   
  }

  cancel() {
    this.dialogRef.close();
  }

}
