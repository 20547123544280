import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-route-select-vehicle',
  templateUrl: './route-select.component.html',
  styleUrls: ['./route-select.component.css']
})
export class VehicleRouteSelectComponent {
  form: FormGroup;
  timestart:any;
  timeend:any;
  constructor(
    private dialogRef: MatDialogRef<VehicleRouteSelectComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private toast: ToastrService,
    private fb: FormBuilder,

  ) {
    this.initRoute();
  }

  routelist = [];
  cloneRoute = [];
  initRoute() {
    this.spinner.show();
    this.routelist = [];
    this.firestore.collection('Route').get().forEach(route => {
      route.forEach(e => {
        this.routelist.push(e.data());
      })
      this.routelist.sort((a, b) => {
        return this.compareDay(new Date(a.UpdatedDate), new Date(b.UpdatedDate), false)
      })
      this.cloneRoute = this.routelist.slice();
      this.spinner.hide();
    })

    const regionControl = new FormControl();
    const regionfilterControl = new FormControl();
    regionfilterControl.valueChanges.subscribe(() => {
      this.findRegion(regionfilterControl.value);
    });
    regionControl.valueChanges.subscribe(() => {
      if(regionControl.value){
        if(this.timestart){
          let hr = parseInt(this.timestart.split(':')[0]);
          let min = parseInt(this.timestart.split(':')[1].split(' ')[0]);
          let offset = this.timestart.split(':')[1].split(' ')[1];
          if (offset === 'PM')
            hr = hr + 12;

          let minend = min + this.form.get('regionControl').value.Duration;
          let hrleft = Math.floor(minend / 60);
          let minuteleft = minend % 60;
          hrleft = hr + hrleft;
          if (hrleft >= 24)
            hrleft = hrleft - 24;
          if (hrleft >= 12) {
            if (hrleft > 12)
              hrleft = hrleft - 12
          }

          this.timeend = ("0" + hrleft.toString()).slice(-2) + ':' + ("0" + minuteleft.toString()).slice(-2) ;
        }
      }
    });

    this.form = this.fb.group({
      regionControl,
      regionfilterControl,
    });


  }
  change(){
    if(this.form.get('regionControl').value){
      let hr = parseInt(this.timestart.split(':')[0]);
      let min = parseInt(this.timestart.split(':')[1].split(' ')[0]);
      let offset = this.timestart.split(':')[1].split(' ')[1];
      if(offset === 'PM')
        hr = hr + 12;
      let minend = min + this.form.get('regionControl').value.Duration;
      let hrleft = Math.floor(minend/60);
      let minuteleft = minend%60;
      hrleft = hr+hrleft;
      if(hrleft >= 24)
      hrleft = hrleft - 24;

      this.timeend = ("0" + hrleft.toString()).slice(-2) + ':' +("0" + minuteleft.toString()).slice(-2);
    }
  }

  findRegion(value) {
    this.routelist = this.cloneRoute.filter(p => p.Name.toLowerCase().includes(value.toLowerCase()));
  }
  compareDay(a, b, isAsc) {
    return ((a === b) ? 0 : a ? -1 : 1) * (isAsc ? 1 : -1);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm(){
    const region = this.form.get('regionControl').value;
    const result = this.cloneRoute.find(e => e.Name === region.Name);
    const info = {
      route: result,
      timestart: this.timestart,
      timeend: this.timeend,
    }
    if(result && this.timeend && this.timestart){
      this.dialogRef.close(info);
    }else{
      this.toast.warning('Incomplete information');
    }

  }

}
