import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MatPaginator, MAT_DIALOG_DATA, PageEvent, Sort, MatDialogConfig, MatDialog } from '@angular/material';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import jsPDF from 'jspdf';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { ExcelHelperService } from '../../Services/excel-helper.service';
const cloneDeep = require('lodash.clonedeep')
import * as jsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';
import moment from 'moment';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  qr: string = "";
  qr660: string = "";
  binlist: any = [];
  sortedbinlist: any = []
  data: any;
  url = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  title: string;  fromDateRekod: Date;
  toDateRekod: Date;
  type;
  ic: string;
  name: string;
  shop_name: string;
  last_date: Date;
  constructor(private dialogRef: MatDialogRef<HistoryComponent>,
    private  dialog : MatDialog,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private firestore: AngularFirestore,
    private excelHelper: ExcelHelperService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {

    this.name = data.name;
    this.shop_name = data.shop_name;
    this.ic = data.ic_num;

    this.type = data.type;
    this.title = data.name
    this.data = data;
    this.binlist = data.eventDtls
    if(this.type)
      this.binlist = this.binlist.filter(e=>e.name === this.type);

    this.binlist = this.binlist.sort((a,b)=> this.compareDate(a.timestamp, b.timestamp, false))
    if(this.binlist.length > 0)
      this.last_date = new Date(this.binlist[0].timestamp);
    this.sortedbinlist = this.binlist.slice();
  }

  detectChanges3(event) {
    this.fromDateRekod = event;
  }

  detectChanges4(event) {
    this.toDateRekod = event;
  }


  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  sortedu = {
    active: '',
    direction: ''
  };

  bin = {
    address: '',
    bin_size: '',
    id: '',
    last_scan: '',
    license_num: '',
    location: '',
  }
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    this.regrab();
  }

  limitbin() {
    this.sortedbinlist = this.sortedbinlist.slice(this.offset, (this.offset + this.pageSize));
  }

  
  regrab() {
    const from = new Date(this.fromDateRekod.setHours(0, 0, 0, 0))
    const to = new Date(this.toDateRekod.setHours(23, 59, 59, 999))
    this.sortedbinlist = this.binlist.filter(u =>
      u.timestamp.getTime() >= from.getTime() && u.timestamp.getTime() <= to.getTime()
    );

    this.length = this.sortedbinlist.length;
    this.sortbin();
    this.limitbin();
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.sortedbinlist = this.binlist.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedbinlist = this.binlist.slice();
      this.limitbin();
      return;
    }

    this.sortedbinlist = this.sortedbinlist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'name': return this.compare(a.name, b.name, isAsc);
        case 'type': return this.compare(a.type, b.type, isAsc);
        case 'transaction_amount': return this.compare(a.transaction_amount, b.transaction_amount, isAsc);
        case 'address': return this.compare(a.address, b.address, isAsc);
        case 'anggaran': return this.compare(a.anggaran, b.anggaran, isAsc);
        case 'timestamp': return this.compareDate(a.timestamp, b.timestamp, isAsc);
        case 'collector_name': return this.compare(a.collector_name, b.collector_name, isAsc);
        case 'bankId': return this.compare(a.bankId, b.bankId, isAsc);
        case 'bankBranch': return this.compare(a.bankBranch, b.bankBranch, isAsc);
        case 'resit': return this.compare(a.receiptNo[0].no_resit, b.receiptNo[0].no_resit, isAsc);
        case 'akuan': return this.compare(a.receiptNo[0].no_akaun, b.receiptNo[0].no_akaun, isAsc);
        default: return 0;
      }
    });
    this.limitbin();
  }


  sortbin() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.sortedbinlist = this.sortedbinlist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'name': return this.compare(a.name, b.name, isAsc);
        case 'type': return this.compare(a.type, b.type, isAsc);
        case 'transaction_amount': return this.compare(a.transaction_amount, b.transaction_amount, isAsc);
        case 'address': return this.compare(a.address, b.address, isAsc);
        case 'anggaran': return this.compare(a.anggaran, b.anggaran, isAsc);
        case 'timestamp': return this.compareDate(a.timestamp, b.timestamp, isAsc);
        case 'collector_name': return this.compare(a.collector_name, b.collector_name, isAsc);
        case 'bankId': return this.compare(a.bankId, b.bankId, isAsc);
        case 'bankBranch': return this.compare(a.bankBranch, b.bankBranch, isAsc);
        case 'resit': return this.compare(a.receiptNo[0].no_resit, b.receiptNo[0].no_resit, isAsc);
        case 'akuan': return this.compare(a.receiptNo[0].no_akaun, b.receiptNo[0].no_akaun, isAsc);
        default: return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compareDate(a, b, isAsc: boolean) {

    a = new Date(a).getTime();
    b = new Date(b).getTime();
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }



  ngOnInit() {
    const ld = new Date(this.last_date);
    this.fromDateRekod = new Date(ld.setMonth(ld.getMonth() - 3));
    this.toDateRekod = new Date(this.last_date);
    this.regrab();
  }

  cancel() {
    this.dialogRef.close();
  }

  exportToExcel() {
    const exportInformation = [];
    this.spinner.show();
    const binlist = this.binlist.slice().sort((a, b) => {
      return (a.name < b.name ? -1 : 1) * (true ? 1 : -1);
    }
    );
    if(this.type === 'Top Up'){
      for (const raw of binlist) {
        const info = {
          "Jenis": 'Tambah Nilai',
          "Jumlah (RM)": raw.transaction_amount,
          "No Akaun": raw.receiptNo[0].no_akaun || '-',
          "No Resit": raw.receiptNo[0].no_resit || '-',
          "Nama Bank": raw.bankBranch || '-',
          "Bank Id": raw.bankId || '-',
          "Tarikh": raw.timestamp || '-',
        }
        exportInformation.push(info)
      }
    }else{
      for (const raw of binlist) {
        const info = {
          "Jenis": raw.name === 'Top Up'? 'Tambah Nilai': (raw.name || "-"),
          "Saiz Tong": raw.type == 'null'? '-': (raw.type || "-"),
          "Lokasi": raw.address || "-",
          "Kadar Bayaran(RM)": raw.name !== 'Top Up'?raw.transaction_amount*-1:raw.transaction_amount  || 0,
          "Berat Kutipan(kg)": raw.anggaran || '-',
          "Staf Kutipan": raw.collector_name || '-',
          "Tarikh": raw.timestamp || '-',
  
        }
        exportInformation.push(info)
      }
    }
    

    this.excelHelper.exportAsExcelFile(exportInformation, 'Rekod' + new Date().getTime());
    this.spinner.hide();
  }

  exportToPDF() {
    this.spinner.show();
    const doc = new jsPdf();
    doc.setFontSize(12);
    const col = ['Tarikh', 'Nama Bank', 'No Akaun', 'No Resit', 'No Reference', 'Jumlah (RM)'];
    const rowdata = [];
    
    const from = new Date(this.fromDateRekod.setHours(0, 0, 0, 0))
    const to = new Date(this.toDateRekod.setHours(23, 59, 59, 999))

    const dateFrom = moment(from).format('DD-MMM-YYYY')
    const dateto = moment(to).format('DD-MMM-YYYY')

    const logo1 = document.createElement('img');
    const logo2 = document.createElement('img');

    logo1.src = '../../assets/logo1.jpg';
    logo2.src = '../../assets/logo2.jpg';
    doc.addImage(logo1, 'JPG', 20, 20, 20, 20);
    doc.addImage(logo2, 'JPG', 50, 20, 60, 20);
    doc.setFontType('bold');
    doc.setFontSize(18);

    doc.text('I-Collect MBSP Sistem', 120, 35);
    doc.setFontSize(10);
    doc.setFontType('normal');
    doc.text('Nama Pemilik', 15, 50);
    doc.text(': ' + this.name, 45, 50);
    doc.text('Nama Perniagaan', 15, 55);
    doc.text(': ' + this.shop_name, 45, 55);
    doc.text('Kad Pengenalan', 15, 60);
    doc.text(': ' + this.ic, 45, 60);
    doc.setFontType('bold');
    doc.text(`Tambah Nilai Rekod Dari ${dateFrom} hingga ${dateto}`, 15, 65);
    doc.setFontType('normal');

    const binlist = this.binlist.filter(u =>
      u.timestamp.getTime() >= from.getTime() && u.timestamp.getTime() <= to.getTime()
    ).slice().sort((a, b) => {
      return (a.timestamp.getTime() < b.timestamp.getTime() ? -1 : 1) * (true ? 1 : -1);
      }
    );

    for (const element of binlist) {
      let datestr = moment(element.timestamp).format('DD-MMM-YYYY hh:mm a')
      const row = [datestr, element.bankBranch, element.receiptNo[0].no_akaun, element.receiptNo[0].no_resit, element.referenceNo, (parseFloat(element.transaction_amount)).toFixed(2)];
      rowdata.push(row);
    }

    const drawCell = function (data) {
      const doc = data.doc;
      const rows = data.table.body;
      if (data.row.index === 0 && data.section == 'head') {
        doc.setFillColor(33, 150, 243);
      } 
    };

    doc.autoTable(col, rowdata, {
      startY: 70, theme: 'grid', margin: 0, styles: {
        padding: 0,
        fontSize: 8,
      }, willDrawCell: drawCell
    },
    );

    doc.save(this.name +'TopUp.pdf');
    this.spinner.hide();

  }

  downloadPdf(bin){
    this.spinner.show();
    const doc = new jsPdf();
    doc.setFontSize(12);
    
    const from = new Date(this.fromDateRekod.setHours(0, 0, 0, 0))
    const to = new Date(this.toDateRekod.setHours(23, 59, 59, 999))

    const dateFrom = moment(from).format('DD-MMM-YYYY')
    const dateto = moment(to).format('DD-MMM-YYYY')

    const logo1 = document.createElement('img');
    const logo2 = document.createElement('img');

    logo1.src = '../../assets/logo1.jpg';
    logo2.src = '../../assets/logo2.jpg';
    doc.addImage(logo1, 'JPG', 20, 20, 20, 20);
    doc.addImage(logo2, 'JPG', 50, 20, 60, 20);
    doc.setFontType('bold');
    doc.setFontSize(18);

    doc.text('I-Collect MBSP Sistem', 120, 35);

    doc.text('No Reference', 80, 50);
    doc.text(bin.referenceNo, 72, 60);


    doc.setFontSize(10);
    doc.setFontType('normal');
    doc.text('Nama Pemilik', 15, 70);
    doc.text(': ' + this.name, 45, 70);
    doc.text('Nama Perniagaan', 15, 75);
    doc.text(': ' + this.shop_name, 45, 75);
    doc.text('Kad Pengenalan', 15, 80);
    doc.text(': ' + this.ic, 45, 80);
    doc.text('Tambah Nilai', 15, 85);
    doc.text(': RM' + bin.transaction_amount, 45, 85);
    doc.text('Bank', 15, 90);
    doc.text(': ' + bin.bankBranch, 45, 90);
    doc.text('Bank ID', 15, 95);
    doc.text(': ' + bin.bankId, 45, 95);
    doc.text('No Akaun', 15, 100);
    doc.text(': ' + bin.receiptNo[0].no_akaun, 45, 100);
    doc.text('No Resit', 15, 105);
    doc.text(': ' + bin.receiptNo[0].no_resit, 45, 105);
    doc.text('Kumpulan ID', 15, 110);
    doc.text(': ' + bin.batchId, 45, 110);
    doc.text('Saluran', 15, 115);
    doc.text(': ' + bin.channel, 45, 115);
    doc.text('Transaksi', 15, 120);
    doc.text(': ' + bin.trxId, 45, 120);
    doc.text('Tarikh', 15, 125);
    let datestr = moment(bin.timestamp).format('DD-MMM-YYYY hh:mm a')
    doc.text(': ' + datestr, 45, 125);

    doc.save(bin.referenceNo +'.pdf');
    this.spinner.hide();
  }

  
}
