import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { DateFormatService } from '../../Services/date-format.service';
import { AngularFireAuth } from '@angular/fire/auth';
var CryptoJS = require("crypto-js");

@Component({
  selector: 'app-new-collector',
  templateUrl: './new-collector.component.html',
  styleUrls: ['./new-collector.component.css']
})
export class NewCollectorComponent implements OnInit {

  collector={
    name:'',
    ic_num:'',
    email:'',
    phone_num:'',
    position:'',
    worker_id:'',
    zone:'',
    plate_num:'',
    address:''
  };

  zonelist = [
    'ZON 01 PENAGA',
    'ZON 02 BERTAM',
    'ZON 03 PINANG TUNGGAL',
    'ZON 04 PINANG TUNGGAL',
    'ZON 05 PERMATANG BERANGAN',
    'ZON 06 SUNGAI DUA',
    'ZON 07 TELUK AIR TAWAR',
    'ZON 08 SUNGAI PUYU',
    'ZON 09 BAGAN JERMAL',
    'ZON 10 BAGAN DALAM',
    'ZON 11 SEBERANG JAYA',
    'ZON 12 SEBERANG JAYA',
    'ZON 13 PERMATANG PASIR',
    'ZON 14 PENANTI',
    'ZON 15 BERAPIT',
    'ZON 16 PADANG LALANG A',
    'ZON 17 PADANG LALANG B',
    'ZON 18 MACHANG BUBUK A1',
    'ZON 19 MACHANG BUBUK A2',
    'ZON 20 MACHANG BUBUK B',
    'ZON 21 PERAI',
    'ZON 22 KAWASAN INDUSTRI PERAI',
    'ZON 23 BUKIT TENGAH A',
    'ZON 24 BUKIT TENGAH B',
    'ZON 25 KAWASAN INDUSTRI BUKIT MINYAK',
    'ZON 26 BATU KAWAN',
    'ZON 27 SIMPANG AMPAT',
    'ZON 28 JAWI A',
    'ZON 29 JAWI B',
    'ZON 30 SUNGAI BAKAP A',
    'ZON 31 SUNGAI BAKAP B',
    'ZON 32 SUNGAI ACEH'
  ]
  constructor(  private dialogRef: MatDialogRef<NewCollectorComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,
    private tosterservice: ToastrService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private  dialog : MatDialog,
    private angularFireAuth: AngularFireAuth,
  ) {

    }

  ngOnInit() {
  }


  cancel(){
    this.dialogRef.close();
  }

  private dateFormat = new DateFormatService();

  async addLog(detail,type, message){

    this.angularFireAuth.authState.subscribe(async authState => {
      if(authState){
        const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
        const info = {
          message: message,
          date: new Date(),
          data: detail,
          updatedBy: authState.email,
          dateFormat: dateFormat,
          type:type
        };
        await this.firestore.collection('AdminLog').add(info);
      
      }
     });

   
  }

  confirm(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: 'Adakah anda menambahkan staf ini?'
    };
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result){
        this.spinner.show();
        let cipher = CryptoJS.AES.encrypt('123456', 'GARBAGE');
        let p = cipher.toString() + "/encrypt";

        this.firestore.collection("collectors").doc(this.collector.ic_num).set({...this.collector,password1:p,
          worker_id: parseFloat(this.collector.worker_id),
         })
          .then(r=>{
          this.spinner.hide();
          this.toaster.success('Tambah berjaya');
          this.addLog(this.collector, 'collector', `${this.collector.name} telah ditambah. \nNama Kakitangan: ${this.collector.name}\n Kad Pengenalan: ${this.collector.ic_num}\n `)

          this.dialogRef.close(true);
        })

      }
    })
  }
  
}
