import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

  message = '';
  data = [];
  containListItem: boolean;
  enterName: boolean;
  enterLorry: boolean;
  enterNote: boolean;
  enterRoute: boolean;
  flag = false;
  name: string;
  note: any;
  notedescription: any;
  monday = true;
  tuesday = true;
  wednesday = true;
  thursday = true;
  friday = true;
  saturday = true;
  sunday = true;
  lorry: any;
  route: any;
  zone: any;
  time:string;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
  ) {

    this.message = data.message;
    if (data.data) {
      this.data = data.data;
      this.containListItem = true;
    } else {
      this.containListItem = false;
    }
    if(data.lorry){
      this.enterLorry = true;
      this.flag = true;
    }else{
      this.enterLorry = false;
    }

    if(data.route){
      this.enterRoute = true;
      this.flag = true;
    }else{
      this.enterRoute = false;
    }

    if (data.name) {
      this.enterName = true;
      this.zone = data.zone.slice();
      this.flag = true;
    } else {
      this.enterName = false;
    }
    if (data.note) {
      if (data.data) {
        this.note = data.data;
      }
      this.enterNote = true;
      this.flag = true;

    } else {
      this.enterNote = false;
    }

    console.log(this.note);
  }

  ngOnInit() { }

  cancel() {

    this.dialogRef.close(false);
  }

  confirm() {
    if (this.enterName) {
      if (this.name.length < 1) {
        this.toast.error('Please fill in the name', 'Input error');
        return;
      }
      if (!this.monday && !this.tuesday  && !this.wednesday
        && !this.thursday && !this.friday && !this.saturday && !this.sunday) {
        this.toast.error('Please check one of the available day', 'Input error');
        return;
      }
      const dayavailable = [];
      if (this.monday) {
        dayavailable.push(1);
      }
      if (this.tuesday) {
        dayavailable.push(2);
      }
      if (this.wednesday) {
        dayavailable.push(3);
      }
      if (this.thursday) {
        dayavailable.push(4);
      }
      if (this.friday) {
        dayavailable.push(5);
      }
      if (this.saturday) {
        dayavailable.push(6);
      }
      if (this.sunday) {
        dayavailable.push(7);
      }
      const check = this.zone.find(z=> z.check === true);
      if(check){
        const result = {
          name: this.name,
          dayavailable,
          zone: check.name
        };
       this.dialogRef.close(result);
      }else{
        const result = {
          name: this.name,
          dayavailable,
          zone: ''
        };
        this.dialogRef.close(result);
      }

    } else if (this.note) {
      const result = {
        note: this.note,
      };
      this.dialogRef.close(result);
    } else if (this.lorry) {
      const result = {
        lorry: this.lorry,
      };
      this.dialogRef.close(result);
    } else if(this.route){
      const result = {
        route: this.route,
        time: this.time,
      };
      this.dialogRef.close(result);
    } else {
      this.dialogRef.close(true);

    }

  }

  change(a){
    const result = this.zone.filter(z=>z.name !==a );
    result.forEach(element => {
      element.check =false;
    });
  }

}
