import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateService } from '../Services/date.service';
import { FirestoreServiceService } from '../Services/firestore-service.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  time:any;
  speed:any;
  constructor(
    private fireSrv: FirestoreServiceService,
    changeDetectorRef: ChangeDetectorRef,
    private storageRef: AngularFireStorage,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    router: Router,
    private dialog: MatDialog,
    private dateSrv: DateService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService

  ) {

  }

  ngOnInit(): void {
    this.firestore.collection('Setting').doc('IDLE').get().forEach(e=>{
      this.time = e.data().time;
      this.speed = e.data().speed;
    })
  }

  update(){
    if(!this.time || !this.speed){
      this.toaster.warning("Please fill in");
      return;
    }

    this.spinner.show();
    this.firestore.collection('Setting').doc('IDLE').set({"time":this.time, "speed":this.speed}).then(e=>{
      this.toaster.success('Update Successful');
      this.spinner.hide();
    })
  }

}
