import { DepohdetailComponent } from './Depoh/depohdetail/depohdetail.component';
import { CreateDepohComponent } from './Depoh/create-depoh/create-depoh.component';
import { SetScheduleComponent } from './set-schedule/set-schedule.component';
import { VehicleDetailComponent } from './Vehicle/vehicle-detail/vehicle-detail.component';
import { CreateVehicleComponent } from './Vehicle/create-vehicle/create-vehicle.component';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatPaginator, MatDialog, PageEvent, Sort, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { ManageLorryComponent } from '../device-management/manage-lorry/manage-lorry.component';
import { DateService } from '../Services/date.service';
import { FirestoreServiceService } from '../Services/firestore-service.service';

@Component({
  selector: 'app-pps-setting',
  templateUrl: './pps-setting.component.html',
  styleUrls: ['./pps-setting.component.css']
})
export class PpsSettingComponent implements OnInit {

  devicelist = [];
  sorteddevicelist: any;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  searchbox = '';
  searchValue = '';
  sortedu = {
    active: '',
    direction: ''
  };

  lorries = [];

  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;


  depohlist = [];
  sorteddepohlist: any;

  length1 = 0;
  pageSize1 = 10;
  pageSizeOptions1: number[] = [10, 25, 50];
  pageIndex1 = 0;
  offset1 = this.pageSize1 * this.pageIndex1;
  searchbox1 = '';
  searchValue1 = '';
  sortedu1 = {
    active: '',
    direction: ''
  };


  @ViewChild('topPaginator1', { read: MatPaginator, static: true }) topPaginator1: MatPaginator;
  @ViewChild('bottomPaginator1', { read: MatPaginator, static: true }) bottomPaginator1: MatPaginator;



  constructor(
    private fireSrv: FirestoreServiceService,
    changeDetectorRef: ChangeDetectorRef,
    private storageRef: AngularFireStorage,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    router: Router,
    private dialog: MatDialog,
    private dateSrv: DateService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.SetupTable();
  }

  async SetupTable() {
    this.devicelist = [];
    this.depohlist = [];
    await this.firestore.collection('Devices').get().forEach(devices=>{
      devices.forEach(d=>{
        const info ={
          key: d.id,
          ...d.data()
        }
        this.devicelist.push(info)
      })
      this.sorteddevicelist = this.devicelist.slice(this.offset, (this.offset + this.pageSize));
      this.length = this.devicelist.length;
      this.spinner.hide();
    })

    await this.firestore.collection('Depoh').get().forEach(depoh=>{
      depoh.forEach(d=>{
        const info ={
          key: d.id,
          ...d.data()
        }
        this.depohlist.push(info)
      })
      this.sorteddepohlist = this.depohlist.slice(this.offset1, (this.offset1 + this.pageSize1));
      this.length1 = this.depohlist.length;
      this.spinner.hide();
    })


  }



  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    this.sorteddevicelist = this.devicelist.slice();
    this.sortDevice();
    this.limitDevice();
  }

  limitDevice() {
    this.sorteddevicelist = this.sorteddevicelist.slice(this.offset, (this.offset + this.pageSize));
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.sorteddevicelist = this.devicelist.slice();
    if (this.searchValue) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.searchValue) {
      this.sorteddevicelist = this.devicelist.slice();
      this.limitDevice();
      return;
    }

    this.sorteddevicelist = this.sorteddevicelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'id': return this.compare(a.ID, b.ID, isAsc);
        case 'plate': return this.compare(a.PlateNumber, b.PlateNumber, isAsc);
        case 'lorry': return this.compare(a.LorryType, b.LorryType, isAsc);
        case 'updateBy': return this.compare(a.UpdatedBy, b.UpdatedBy, isAsc);
        case 'date': return this.compareDate(this.getDate(a.UpdatedAt), this.getDate(b.UpdatedAt), isAsc);
        default: return 0;
      }
    });
    this.limitDevice();
  }

  sortDevice() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.sorteddevicelist = this.sorteddevicelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'id': return this.compare(a.ID, b.ID, isAsc);
        case 'plate': return this.compare(a.PlateNumber, b.PlateNumber, isAsc);
        case 'lorry': return this.compare(a.LorryType, b.LorryType, isAsc);
        case 'updateBy': return this.compare(a.UpdatedBy, b.UpdatedBy, isAsc);
        case 'date': return this.compareDate(this.getDate(a.UpdatedAt), this.getDate(b.UpdatedAt), isAsc);
        default: return 0;
      }
    });
  }


  dynamicSearch() {
    this.searchValue = this.searchbox;
    this.sorteddevicelist = this.devicelist.filter(u =>
      String(u.id).toLowerCase().includes(this.searchValue.toLowerCase()) ||
      String(u.LorryType).toLowerCase().includes(this.searchValue.toLowerCase()) ||
      String(u.latitude).toLowerCase().includes(this.searchValue.toLowerCase()) ||
      String(u.longitude).toLowerCase().includes(this.searchValue.toLowerCase()) ||
      String(u.location).toLowerCase().includes(this.searchValue.toLowerCase()));
    this.length = this.sorteddevicelist.length;
    this.sortDevice();
    this.limitDevice();
  }

  DS() {
    this.sorteddevicelist = this.devicelist.filter(u =>
      String(u.id).toLowerCase().includes(this.searchValue.toLowerCase()) ||
      String(u.LorryType).toLowerCase().includes(this.searchValue.toLowerCase()) ||
      String(u.latitude).toLowerCase().includes(this.searchValue.toLowerCase()) ||
      String(u.longitude).toLowerCase().includes(this.searchValue.toLowerCase()) ||
      String(u.location).toLowerCase().includes(this.searchValue.toLowerCase()));
    this.length = this.sorteddevicelist.length;
    this.sortDevice();
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compareDate(a, b, isAsc: boolean) {
    a = new Date(a).getTime();
    b = new Date(b).getTime();
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }




  addVehicle(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      depoh: this.depohlist
    }

    this.dialog.open(CreateVehicleComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result)
        this.SetupTable();
    });
  }

  viewDetail(device){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      ...device,
      depoh: this.depohlist

    }
      ;

    this.dialog.open(VehicleDetailComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result)
        this.SetupTable();
    });
  }

  getDate(timestamp){
    if(typeof(timestamp)=== 'object')
      return new Date(timestamp.toDate());
    else
      return new Date(timestamp);
  }

  schedule(device){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.height = '90%';
      dialogConfig.maxHeight = '80vw';

      dialogConfig.width = '90vw';
      dialogConfig.maxWidth = '90vw';

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = device;

      this.dialog.open(SetScheduleComponent, dialogConfig).afterClosed().subscribe(result => {
        if(result)
          this.SetupTable();
      });
  }

  getRoute(device){
    const day = new Date().getDay();
    if(typeof device.Day !== 'undefined'){
      switch(day){
        case 0: return device.Day.Sunday.sort((a,b)=>{
          return this.compare(a.startAt, b.startAt, true);
        });
        case 1: return device.Day.Monday.sort((a,b)=>{
          return this.compare(a.startAt, b.startAt, true);
        });
        case 2: return device.Day.Tuesday.sort((a,b)=>{
          return this.compare(a.startAt, b.startAt, true);
        });
        case 3: return device.Day.Wednesday.sort((a,b)=>{
          return this.compare(a.startAt, b.startAt, true);
        });
        case 4: return device.Day.Thursday.sort((a,b)=>{
          return this.compare(a.startAt, b.startAt, true);
        });
        case 5: return device.Day.Friday.sort((a,b)=>{
          return this.compare(a.startAt, b.startAt, true);
        });
        case 6: return device.Day.Saturday.sort((a,b)=>{
          return this.compare(a.startAt, b.startAt, true);
        });
        default: return [];

      }
    }else{
      return []
    }
  }

  addDepoh(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.depohlist;

    this.dialog.open(CreateDepohComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result)
        this.SetupTable();
    });
  }


  viewDetail1(depoh){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      depoh: depoh,
      list:this.depohlist
    }

    this.dialog.open(DepohdetailComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result)
        this.SetupTable();
    });
  }


  paginator1(pageEvent: PageEvent) {
    this.pageSize1 = pageEvent.pageSize;
    this.pageIndex1 = pageEvent.pageIndex;
    this.offset1 = this.pageSize1 * this.pageIndex1;
    if (this.topPaginator1.pageIndex < this.pageIndex1) {
      this.topPaginator1.nextPage();
    } else if (this.topPaginator1.pageIndex > this.pageIndex1) {
      this.topPaginator1.previousPage();
    }
    if (this.bottomPaginator1.pageIndex < this.pageIndex1) {
      this.bottomPaginator1.nextPage();
    } else if (this.bottomPaginator1.pageIndex > this.pageIndex1) {
      this.bottomPaginator1.previousPage();
    }
    this.sorteddepohlist = this.depohlist.slice();
    this.sortDepoh();
    this.limitDepoh();
  }

  limitDepoh() {
    this.sorteddepohlist = this.sorteddepohlist.slice(this.offset1, (this.offset1 + this.pageSize1));
  }

  sortData1(sort: Sort) {
    this.sortedu1 = sort;
    this.sorteddepohlist = this.depohlist.slice();
    if (this.searchValue1) {
      this.DS1();
    }
    if (!sort.active || sort.direction === '' && !this.searchValue) {
      this.sorteddepohlist = this.depohlist.slice();
      this.limitDepoh();
      return;
    }

    this.sorteddepohlist = this.sorteddepohlist.sort((a, b) => {
      const isAsc = this.sortedu1.direction === 'asc';
      switch (this.sortedu1.active) {
        case 'name': return this.compare(a.Name, b.Name, isAsc);
        // case 'roro': return this.compare(a.latitude, b.latitude, isAsc);
        // case 'garbage': return this.compare(a.longitude, b.longitude, isAsc);
        case 'updateby': return this.compare(a.UpdatedBy, b.UpdatedBy, isAsc);
        case 'date': return this.compareDate(this.getDate(a.UpdatedAt), this.getDate(b.UpdatedAt), isAsc);
        default: return 0;
      }
    });
    this.limitDepoh();
  }

  sortDepoh() {
    if (!this.sortedu1.active || this.sortedu1.direction === '') {
      return;
    }
    this.sorteddepohlist = this.sorteddepohlist.sort((a, b) => {
      const isAsc = this.sortedu1.direction === 'asc';
      switch (this.sortedu1.active) {
        case 'name': return this.compare(a.Name, b.Name, isAsc);
        // case 'roro': return this.compare(a.latitude, b.latitude, isAsc);
        // case 'garbage': return this.compare(a.longitude, b.longitude, isAsc);
        case 'updateby': return this.compare(a.UpdatedBy, b.UpdatedBy, isAsc);
        case 'date': return this.compareDate(this.getDate(a.UpdatedAt), this.getDate(b.UpdatedAt), isAsc);
        default: return 0;
      }
    });
  }


  dynamicSearch1() {
    this.searchValue1 = this.searchbox1;
    this.sorteddepohlist = this.depohlist.filter(u =>
      String(u.Name).toLowerCase().includes(this.searchValue1.toLowerCase()));
    this.length1 = this.sorteddepohlist.length;
    this.sortDepoh();
    this.limitDepoh();
  }

  DS1() {
    this.sorteddepohlist = this.depohlist.filter(u =>
      String(u.Name).toLowerCase().includes(this.searchValue1.toLowerCase()));
    this.length1 = this.sorteddepohlist.length;
    this.sortDepoh();
  }

  DeleteDepoh(depoh){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: 'Do you want to remove: ' + depoh.Name
    };
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result){
        this.firestore.collection('Depoh').doc(depoh.ID).delete().then(e=>{
          const list = this.devicelist.filter(d=>d.Depoh === depoh.Name);
          list.forEach(e=>{
            this.firestore.collection('Devices').doc(e.ID).update({Depoh:""});
          })
        this.SetupTable();

        })

      }
    });
  }

  getNumberLorry(depoh, lorryType){

    switch(lorryType){
      case "Roro Truck": return this.devicelist.filter(d=>d.Depoh === depoh.Name && d.LorryType === 'Roro Truck').length;
      case "Garbage Truck":return this.devicelist.filter(d=>d.Depoh === depoh.Name && d.LorryType === 'Garbage Truck').length;
    }

  }




}
