import { CompanyComponent } from './Components/company/company.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './Components/login/login.component';
import { LayoutComponent } from './Components/DashboardLayoutBase/layout/layout.component';


const routes: Routes = [{
  path: '',
  redirectTo: '/Login',
  pathMatch: 'full',
},
{ path: 'Login', component: LoginComponent },
{ path: 'Home', component: LayoutComponent,
  children: [
    {
      path:'Client',
      component: CompanyComponent
    },
  ]
},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
