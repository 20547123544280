import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {v4 as uuidv4} from 'uuid'

@Component({
  selector: 'app-confirmationlist',
  templateUrl: './confirmationlist.component.html',
  styleUrls: ['./confirmationlist.component.css']
})
export class ConfirmationlistComponent implements OnInit {

  message = '';
  data = [];
  totalTime:number = 0;
    email:string;
    route: string;
  regionlist =[];
  check = false;
  routeData: any;
  constructor(
    private dialogRef: MatDialogRef<ConfirmationlistComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,
  ) {

    this.data = data.data;
    this.regionlist = [];
    if(data.route){
      this.route = data.route.Name
      this.check = true;
      this.routeData = data.route;
      this.disposalPlace = this.data.find(e=>e.id === this.routeData.mark)?this.data.find(e=>e.id === this.routeData.mark).RouteName: null;

    }


    this.data.forEach(element => {
      this.regionlist.push(element.id);
      if(element.RouteName === this.disposalPlace)
        element.mark = true;
      var time = parseFloat(element.timeSetting.split('m')[0]);

      this.totalTime += time;
    });
    this.angularFireAuth.authState.subscribe(auth=>{
      this.email = auth.email;
    })
  }

  ngOnInit() { }

  cancel() {

    this.dialogRef.close(false);
  }
  async mark(i){
    if(this.data[i].mark){
      this.data[i].mark = !this.data[i].mark;
      this.disposalPlace = null
      return;
    }

    await this.data.forEach(e=>{
      e.mark = false;
    })
    this.data[i].mark = true;
    this.disposalPlace = this.data[i].RouteName

  }

  disposalPlace: string;
  confirm() {

    var id = uuidv4();

    if(this.check)
      id = this.routeData.id;

    const r = this.data.find(e=>e.mark)
    if(!r){
      this.toast.warning('Please choose the disposal place');
      return;
    }

    const info = {
      id: id,
      regions: this.regionlist,
      UpdatedBy:this.email,
      UpdatedAt:new Date(),
      Name:this.route,
      Duration:this.totalTime,
      mark: r.id

    }
    this.spinner.show();

    if(this.check){
      this.firestore.collection('Route').doc(this.routeData.id).update(info).then(q=>{

        // this.firestore.collection('Devices').get().forEach(d=>{
        //   d.forEach(device=>{
        //    const devicesData = device.data();
        //    if(devicesData.Day){
        //     devicesData.Day.Monday.forEach(element => {
        //       if(element.routeID === info.id){
        //        let hr = parseInt(element.startAt.split(':')[0]);
        //        let min = parseInt(element.startAt.split(':')[1]);

        //        let minend = min + info.Duration;
        //        let hrleft = Math.floor(minend / 60);
        //        let minuteleft = minend % 60;
        //        hrleft = hr + hrleft;
        //        if (hrleft >= 24)
        //          hrleft = hrleft - 24;
        //        if (hrleft >= 12) {
        //          if (hrleft > 12)
        //            hrleft = hrleft - 12
        //        }

        //        element.endAt = ("0" + hrleft.toString()).slice(-2) + ':' + ("0" + minuteleft.toString()).slice(-2) ;
        //       }
        //     });

        //     devicesData.Day.Tuesday.forEach(element => {
        //       if(element.routeID === info.id){
        //        let hr = parseInt(element.startAt.split(':')[0]);
        //        let min = parseInt(element.startAt.split(':')[1]);

        //        let minend = min + info.Duration;
        //        let hrleft = Math.floor(minend / 60);
        //        let minuteleft = minend % 60;
        //        hrleft = hr + hrleft;
        //        if (hrleft >= 24)
        //          hrleft = hrleft - 24;
        //        if (hrleft >= 12) {
        //          if (hrleft > 12)
        //            hrleft = hrleft - 12
        //        }

        //        element.endAt = ("0" + hrleft.toString()).slice(-2) + ':' + ("0" + minuteleft.toString()).slice(-2) ;
        //       }
        //     });

        //     devicesData.Day.Wednesday.forEach(element => {
        //       if(element.routeID === info.id){
        //        let hr = parseInt(element.startAt.split(':')[0]);
        //        let min = parseInt(element.startAt.split(':')[1]);

        //        let minend = min + info.Duration;
        //        let hrleft = Math.floor(minend / 60);
        //        let minuteleft = minend % 60;
        //        hrleft = hr + hrleft;
        //        if (hrleft >= 24)
        //          hrleft = hrleft - 24;
        //        if (hrleft >= 12) {
        //          if (hrleft > 12)
        //            hrleft = hrleft - 12
        //        }

        //        element.endAt = ("0" + hrleft.toString()).slice(-2) + ':' + ("0" + minuteleft.toString()).slice(-2) ;
        //       }
        //     });

        //     devicesData.Day.Thursday.forEach(element => {
        //       if(element.routeID === info.id){
        //        let hr = parseInt(element.startAt.split(':')[0]);
        //        let min = parseInt(element.startAt.split(':')[1]);

        //        let minend = min + info.Duration;
        //        let hrleft = Math.floor(minend / 60);
        //        let minuteleft = minend % 60;
        //        hrleft = hr + hrleft;
        //        if (hrleft >= 24)
        //          hrleft = hrleft - 24;
        //        if (hrleft >= 12) {
        //          if (hrleft > 12)
        //            hrleft = hrleft - 12
        //        }

        //        element.endAt = ("0" + hrleft.toString()).slice(-2) + ':' + ("0" + minuteleft.toString()).slice(-2);
        //       }
        //     });

        //     devicesData.Day.Friday.forEach(element => {
        //       if(element.routeID === info.id){
        //        let hr = parseInt(element.startAt.split(':')[0]);
        //        let min = parseInt(element.startAt.split(':')[1]);

        //        let minend = min + info.Duration;
        //        let hrleft = Math.floor(minend / 60);
        //        let minuteleft = minend % 60;
        //        hrleft = hr + hrleft;
        //        if (hrleft >= 24)
        //          hrleft = hrleft - 24;
        //        if (hrleft >= 12) {
        //          if (hrleft > 12)
        //            hrleft = hrleft - 12
        //        }

        //        element.endAt = ("0" + hrleft.toString()).slice(-2) + ':' + ("0" + minuteleft.toString()).slice(-2) ;
        //       }
        //     });

        //     devicesData.Day.Saturday.forEach(element => {
        //       if(element.routeID === info.id){
        //        let hr = parseInt(element.startAt.split(':')[0]);
        //        let min = parseInt(element.startAt.split(':')[1]);

        //        let minend = min + info.Duration;
        //        let hrleft = Math.floor(minend / 60);
        //        let minuteleft = minend % 60;
        //        hrleft = hr + hrleft;
        //        if (hrleft >= 24)
        //          hrleft = hrleft - 24;
        //        if (hrleft >= 12) {
        //          if (hrleft > 12)
        //            hrleft = hrleft - 12
        //        }

        //        element.endAt = ("0" + hrleft.toString()).slice(-2) + ':' + ("0" + minuteleft.toString()).slice(-2);
        //       }
        //     });

        //     devicesData.Day.Sunday.forEach(element => {
        //       if(element.routeID === info.id){
        //        let hr = parseInt(element.startAt.split(':')[0]);
        //        let min = parseInt(element.startAt.split(':')[1]);

        //        let minend = min + info.Duration;
        //        let hrleft = Math.floor(minend / 60);
        //        let minuteleft = minend % 60;
        //        hrleft = hr + hrleft;
        //        if (hrleft >= 24)
        //          hrleft = hrleft - 24;
        //        if (hrleft >= 12) {
        //          if (hrleft > 12)
        //            hrleft = hrleft - 12
        //        }

        //        element.endAt = ("0" + hrleft.toString()).slice(-2) + ':' + ("0" + minuteleft.toString()).slice(-2);
        //       }
        //     });

        //    this.firestore.collection('Devices').doc(device.id).update(devicesData);

        //    }
        //    this.spinner.hide();
        //    this.dialogRef.close(true);
        //   })

        // })
        this.toast.success('Update sucessfully');
        this.spinner.hide();
                this.dialogRef.close(true);
      })
    }else{
      this.firestore.collection('Route').doc(id).set(info).then(q=>{

        this.spinner.hide();
        this.toast.success('Create sucessfully');
        this.dialogRef.close(true);
      })
    }


  }


}
