import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {

  region = {
    id:"",
    RouteName: "",
    timeSetting: "",
    UpdatedAt: new Date(),
    UpdatedBy: "",

  };
  routeList = [];
  email: string;
  constructor(
    private dialogRef: MatDialogRef<DetailComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private toast: ToastrService,
    private angularFireAuth: AngularFireAuth,

  ) {
    this.region.id= data.id;
    this.region.RouteName= data.RouteName;
    this.region.timeSetting= data.timeSetting;

    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    })
  }

  ngOnInit(): void {

  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {

    if (!this.region.RouteName || !this.region.timeSetting ) {
      this.toast.warning('Information not completed', 'Please check')
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '400px';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      message: 'Do you want to update this region? '
    }

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        this.region.UpdatedAt = new Date();
        this.region.UpdatedBy = this.email;
        this.firestore.collection('Region').doc(this.region.id).update(this.region).then(r => {
          this.spinner.hide();
          this.toast.success('Updated successful');
          this.dialogRef.close(this.region);
        });
      }
    });
  }






}
