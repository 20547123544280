import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { DateFormatService } from '../../Services/date-format.service';
var CryptoJS = require("crypto-js");

@Component({
  selector: 'app-new-schedule',
  templateUrl: './new-schedule.component.html',
  styleUrls: ['./new-schedule.component.css']
})
export class NewScheduleComponent implements OnInit {

  schedule={
    name:'',
    mon:false,
    tue:false,
    wed:false,
    thu:false,
    fri:false,
    sat:false,
    sun:false,
  };
  edit = false;
  key: any;
  constructor(  private dialogRef: MatDialogRef<NewScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,
    private tosterservice: ToastrService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private  dialog : MatDialog,
    private angularFireAuth: AngularFireAuth,
  ) {
      if(data){
        this.schedule = {
          name: data.name,
          mon: data.mon,
          tue: data.tue,
          wed: data.wed,
          thu: data.thu,
          fri: data.fri,
          sat: data.sat,
          sun: data.sun,
        };
        this.edit = true;
        this.key = data.key;
      }

    }

  ngOnInit() {
  }


  cancel(){
    this.dialogRef.close();
  }

  confirm(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: this.edit? "Adakah anda mengesahkan maklumat ini?":'Adakah anda menambahkan jadual ini?' 
    };
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result){
        this.spinner.show();
        if(this.edit){
          this.firestore.collection("schedules").doc(this.key).set(this.schedule)
          .then(r=>{
          this.spinner.hide();
          this.toaster.success('Kemaskini berjaya');
          this.addLog(this.schedule, 'jadual', `${this.schedule.name} telah dikemaskini. \nNama Jadual: ${this.schedule.name} `)
          this.dialogRef.close(true);
        })
        }else{
          this.firestore.collection("schedules").add(this.schedule)
          .then(r=>{
          this.spinner.hide();
          this.toaster.success('Tambah berjaya');
          this.addLog(this.schedule, 'jadual', `${this.schedule.name} telah ditambah. \nNama Jadual: ${this.schedule.name} `)

          this.dialogRef.close(true);
        })
        }
       

      }
    })
  }
  private dateFormat = new DateFormatService();

  async addLog(detail,type, message){

    this.angularFireAuth.authState.subscribe(async authState => {
      if(authState){
        const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
        const info = {
          message: message,
          date: new Date(),
          data: detail,
          updatedBy: authState.email,
          dateFormat: dateFormat,
          type:type
        };
        await this.firestore.collection('AdminLog').add(info);
      
      }
     });

   
  }

  
}
