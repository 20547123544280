
import { Component, OnInit, Input, AfterViewInit, SimpleChanges, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css'],

})

export class DatePickerComponent implements OnInit {

  minDate: Date;
  maxDate: Date;
  dateDisplay: Date;
  @Output() someEvent = new EventEmitter<Date>();

  constructor() {
    const currentYear = new Date().getFullYear();
    this.dateDisplay = new Date();
    this.maxDate = new Date();
    this.minDate = new Date(currentYear - 20, 0, 1);
  }

  ngOnInit() {
  }


  callParent() {
    this.someEvent.next(this.dateDisplay);
  }

}


