import { DatePickerComponent } from "./Components/dashboard/date-picker/date-picker.component";
import { CreateDepohComponent } from "./Components/pps-setting/Depoh/create-depoh/create-depoh.component";
import { PpsSettingComponent } from "./Components/pps-setting/pps-setting.component";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./Components/login/login.component";
import { LayoutComponent } from "./Components/DashboardLayoutBase/layout/layout.component";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { NgxSpinnerModule } from "ngx-spinner";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";

import {
  MatButtonModule,
  MatFormFieldModule,
  MatDialogModule,
  MatTableModule,
  MatMenuModule,
  MatSortModule,
  MatToolbarModule,
  MatExpansionModule,
  MatPaginatorModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatChipsModule,
  MatSidenavModule,
  MatCheckboxModule,
  MatCardModule,
  MatListModule,
  MatIconModule,
  MatRadioModule,
  MatTooltipModule,
  MatStepperModule,
  MatSlideToggleModule,
  MatGridListModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatButtonToggleModule,
  MatSliderModule,
} from "@angular/material";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { BackButtonDisableModule } from "angular-disable-browser-back-button";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import {
  AngularFirestoreModule,
  AngularFirestore,
} from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { HttpClientModule } from "@angular/common/http";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { TopnavComponent } from "./Components/DashboardLayoutBase/topnav/topnav.component";
import { DeviceManagementComponent } from "./Components/device-management/device-management.component";
import { MenuChosenPageComponent } from "./Components/DashboardLayoutBase/menu-chosen-page/menu-chosen-page.component";
import { MonitorComponent } from "./Components/monitor/monitor.component";
import { ReportComponent } from "./Components/report/report.component";
import { ConfirmationDialogComponent } from "./shared/confirmation-dialog/confirmation-dialog.component";
import { AddRouteComponent } from "./Components/monitor/add-route/add-route.component";
import { PdfDatePickerComponent } from "./shared/pdf-date-picker/pdf-date-picker.component";
import { PdfMonthPickerComponent } from "./shared/pdf-date-picker/pdf-month-picker/pdf-month-picker.component";
import { DeviceInfoModalComponent } from "./shared/device-info-modal/device-info-modal.component";
import { SpeedGraphComponent } from "./shared/speed-graph/speed-graph.component";
import { MapComponent } from "./Components/monitor/map/map.component";
import { TripSelectComponent } from "./shared/trip-select/trip-select.component";
import { AduanComponent } from "./Components/aduan/aduan.component";
import { MonitorDatePickerComponent } from "./shared/monitor-date-picker/monitor-date-picker.component";
import { TripInfoComponent } from "./shared/device-info-modal/trip-info/trip-info.component";
import { MapRouteComponent } from "./shared/map-route/map-route.component";
import { AreaComponent } from "./Components/area/area.component";
import { ManageLorryComponent } from "./Components/device-management/manage-lorry/manage-lorry.component";
import { PdfRangePickerComponent } from "./shared/pdf-date-picker/pdf-range-picker/pdf-range-picker.component";
import { AssignDeviceComponent } from "./Components/area/assign-device/assign-device.component";
import { RouteSelectComponent } from "./shared/route-select/route-select.component";
import { KpaWebComponent } from "./public/kpa-web/kpa-web.component";
import { PpsWebComponent } from "./public/pps-web/pps-web.component";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { CreateVehicleComponent } from "./Components/pps-setting/Vehicle/create-vehicle/create-vehicle.component";
import { VehicleDetailComponent } from "./Components/pps-setting/Vehicle/vehicle-detail/vehicle-detail.component";
import { CreateRouteComponent } from "./Components/area/create-route/create-route.component";
import { ConfirmationlistComponent } from "./Components/area/create-route/confirmationlist/confirmationlist.component";
import { DetailComponent } from "./Components/area/detail/detail.component";
import { RouteDetailComponent } from "./Components/area/route-detail/route-detail.component";
import { SetScheduleComponent } from "./Components/pps-setting/set-schedule/set-schedule.component";
import { VehicleRouteSelectComponent } from "./Components/pps-setting/set-schedule/route-select/route-select.component";
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxMatNativeDateModule,
} from "@angular-material-components/datetime-picker";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { RouteInfoComponent } from "./Components/monitor/route-info/route-info.component";
import { MultipleComponent } from "./Components/monitor/multiple/multiple.component";
import { DashboardComponent } from "./Components/dashboard/dashboard.component";
import { RunSchedulerComponent } from "./Components/run-scheduler/run-scheduler.component";
import { DepohdetailComponent } from "./Components/pps-setting/Depoh/depohdetail/depohdetail.component";
import { SettingsComponent } from "./Components/settings/settings.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { MonthPickerComponent } from "./Components/dashboard/month-picker/month-picker.component";
import { CompanyComponent } from "./Components/company/company.component";
import { QrCodeComponent } from "./Components/company/qr-code/qr-code.component";
import { ResetPasswordComponent } from "./Components/company/reset-password/reset-password.component";
import { EditCollectorComponent } from "./Components/company/edit-collector/edit-collector.component";
import { NewCollectorComponent } from "./Components/company/new-collector/new-collector.component";
import { OnlyNumber } from "./shared/OnlyNumber";
import { DetailsComponent } from "./Components/company/details/details.component";
import { NewScheduleComponent } from "./Components/company/new-schedule/new-schedule.component";
import { AssignScheduleComponent } from "./Components/company/assign-schedule/assign-schedule.component";
import { HistoryComponent } from "./Components/company/history/history.component";
import { EditClientComponent } from "./Components/company/edit-client/edit-client.component";
import { ImportComponent } from "./Components/company/import/import.component";
import { nl2brPipe } from "./shared/nlpipe";
import { ChartComponent } from "./Components/company/chart/chart.component";
import { HistoryPremisComponent } from "./Components/company/history-premis/history-premis.component";

const config = {
  apiKey: "AIzaSyCbaFxeNpUI-KHN0-6PQyXPQyWaRRlAvmc",
  authDomain: "i-collect-sws.firebaseapp.com",
  projectId: "i-collect-sws",
  storageBucket: "i-collect-sws.appspot.com",
  messagingSenderId: "420679674481",
  appId: "1:420679674481:web:244e3ae907ad84dc2c49d0",
};

// const config = {
//   apiKey: "AIzaSyCvKFUPGqRhsPL31Qd03425VntmkppO6Lg",
//   authDomain: "smart-waste-system-pps.firebaseapp.com",
//   databaseURL: "https://smart-waste-system-pps-default-rtdb.firebaseio.com",
//   projectId: "smart-waste-system-pps",
//   storageBucket: "smart-waste-system-pps.appspot.com",
//   messagingSenderId: "1093278084986",
//   appId: "1:1093278084986:web:c2c66cd134a705f72770c3",
//   measurementId: "G-HSX2BETJS3"
// };

@NgModule({
  declarations: [
    OnlyNumber,
    nl2brPipe,
    AppComponent,
    LoginComponent,
    LayoutComponent,
    TopnavComponent,
    DeviceManagementComponent,
    MenuChosenPageComponent,
    MonitorComponent,
    ReportComponent,
    ConfirmationDialogComponent,
    AddRouteComponent,
    PdfDatePickerComponent,
    PdfMonthPickerComponent,
    DeviceInfoModalComponent,
    SpeedGraphComponent,
    MapComponent,
    TripSelectComponent,
    AduanComponent,
    MonitorDatePickerComponent,
    TripInfoComponent,
    MapRouteComponent,
    AreaComponent,
    ManageLorryComponent,
    PdfRangePickerComponent,
    AssignDeviceComponent,
    RouteSelectComponent,
    KpaWebComponent,
    PpsWebComponent,
    PpsSettingComponent,
    CreateVehicleComponent,
    VehicleDetailComponent,
    CreateRouteComponent,
    ConfirmationlistComponent,
    DetailComponent,
    RouteDetailComponent,
    SetScheduleComponent,
    VehicleRouteSelectComponent,
    RouteInfoComponent,
    MultipleComponent,
    DashboardComponent,
    RunSchedulerComponent,
    CreateDepohComponent,
    DepohdetailComponent,
    SettingsComponent,
    MonthPickerComponent,
    CompanyComponent,
    QrCodeComponent,
    ResetPasswordComponent,
    DatePickerComponent,
    EditCollectorComponent,
    NewCollectorComponent,
    DetailsComponent,
    NewScheduleComponent,
    AssignScheduleComponent,
    HistoryComponent,
    EditClientComponent,
    ImportComponent,
    HistoryPremisComponent,
    ChartComponent,
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    AddRouteComponent,
    PdfDatePickerComponent,
    PdfMonthPickerComponent,
    DeviceInfoModalComponent,
    SpeedGraphComponent,
    TripSelectComponent,
    MonitorDatePickerComponent,
    TripInfoComponent,
    MapRouteComponent,
    ManageLorryComponent,
    PdfRangePickerComponent,
    AssignDeviceComponent,
    RouteSelectComponent,
    CreateVehicleComponent,
    VehicleDetailComponent,
    CreateRouteComponent,
    DetailComponent,
    ConfirmationlistComponent,
    RouteDetailComponent,
    SetScheduleComponent,
    VehicleRouteSelectComponent,
    RouteInfoComponent,
    CreateDepohComponent,
    DepohdetailComponent,
    QrCodeComponent,
    ResetPasswordComponent,
    EditCollectorComponent,
    NewCollectorComponent,
    DetailsComponent,
    NewScheduleComponent,
    AssignScheduleComponent,
    HistoryComponent,
    EditClientComponent,
    ImportComponent,
    ChartComponent,
    HistoryPremisComponent
  ],
  imports: [
    NgxSpinnerModule,
    NgxQRCodeModule,
    ReactiveFormsModule,
    MatSliderModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDialogModule,
    NgxSkeletonLoaderModule,
    MatTableModule,
    MatMenuModule,
    NgxMatSelectSearchModule,
    MatSortModule,
    MatToolbarModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatRadioModule,
    MatTooltipModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    FormsModule,
    ToastrModule.forRoot(),
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true,
    }),
    AngularFireModule.initializeApp(config),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    HttpClientModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMaterialTimepickerModule,
    NgxMatNativeDateModule,
    DragDropModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AngularFirestore,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
