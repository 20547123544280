import { Injectable } from '@angular/core';

import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelHelperService {

  constructor() { }

  static toExportFileName(excelFileName: string): string {

    return `${excelFileName}.xlsx`;
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {Sheets: {data: worksheet}, SheetNames: ['data']};
    XLSX.writeFile(workbook, ExcelHelperService.toExportFileName(excelFileName));
  }

  public exportAsExcelFileWithoutTime(json: any[], excelFileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {Sheets: {data: worksheet}, SheetNames: ['data']};
    XLSX.writeFile(workbook, excelFileName);
  }
}
