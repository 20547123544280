import { ConfirmationDialogComponent } from "./../../shared/confirmation-dialog/confirmation-dialog.component";
import { Chart } from "chart.js";
import { QrCodeComponent } from "./qr-code/qr-code.component";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import {
  MatDialog,
  MatDialogConfig,
  MatPaginator,
  PageEvent,
  Sort,
} from "@angular/material";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DateService } from "../Services/date.service";
import { FirestoreServiceService } from "../Services/firestore-service.service";
import { v4 as uuidv4 } from "uuid";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { AuthService } from "../Services/auth.service";
var CryptoJS = require("crypto-js");
import firebase from "firebase/app";
import { ExcelHelperService } from "../Services/excel-helper.service";
import { EditCollectorComponent } from "./edit-collector/edit-collector.component";
import { NewCollectorComponent } from "./new-collector/new-collector.component";

import * as XLSX from "xlsx";
import { DetailsComponent } from "./details/details.component";
import { NewScheduleComponent } from "./new-schedule/new-schedule.component";
import { AssignScheduleComponent } from "./assign-schedule/assign-schedule.component";
import { HistoryComponent } from "./history/history.component";
import { EditClientComponent } from "./edit-client/edit-client.component";
import { ImportComponent } from "./import/import.component";
import { DateFormatService } from "../Services/date-format.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { firestore } from "firebase";
import { ChartComponent } from "./chart/chart.component";
import { HistoryPremisComponent } from "./history-premis/history-premis.component";

@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.css"],
})
export class CompanyComponent implements OnInit {
  clientlist = [];
  approvalList = [];
  sortedclientlist: any;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  searchbox = "";
  searchValue = "";
  sortedu = {
    active: "name",
    direction: "asc",
  };

  fromDate: Date;
  toDate: Date;

  fromDateRekod: Date;
  toDateRekod: Date;

  datePicker: Date;
  FromPicker: Date;

  @ViewChild("topPaginator", { read: MatPaginator, static: true })
  topPaginator: MatPaginator;
  @ViewChild("bottomPaginator", { read: MatPaginator, static: true })
  bottomPaginator: MatPaginator;

  collectorlist = [];
  sortedcollectorlist: any;
  lengthC = 0;
  pageSizeC = 10;
  pageSizeOptionsC: number[] = [10, 25, 50];
  pageIndexC = 0;
  offsetC = this.pageSizeC * this.pageIndexC;
  searchboxC = "";
  searchValueC = "";
  sorteduC = {
    active: "name",
    direction: "asc",
  };

  @ViewChild("topPaginatorC", { read: MatPaginator, static: true })
  topPaginatorC: MatPaginator;
  @ViewChild("bottomPaginatorC", { read: MatPaginator, static: true })
  bottomPaginatorC: MatPaginator;

  eventlist = [];
  sortedeventlist: any;
  lengthE = 0;
  pageSizeE = 10;
  pageSizeOptionsE: number[] = [10, 25, 50];
  pageIndexE = 0;
  offsetE = this.pageSizeE * this.pageIndexE;
  searchboxE = "";
  searchValueE = "";
  sorteduE = {
    active: "date",
    direction: "desc",
  };

  @ViewChild("topPaginatorE", { read: MatPaginator, static: true })
  topPaginatorE: MatPaginator;
  @ViewChild("bottomPaginatorE", { read: MatPaginator, static: true })
  bottomPaginatorE: MatPaginator;

  jaduallist = [];
  sortedjaduallist: any;
  lengthJ = 0;
  pageSizeJ = 10;
  pageSizeOptionsJ: number[] = [10, 25, 50];
  pageIndexJ = 0;
  offsetJ = this.pageSizeJ * this.pageIndexJ;
  searchboxJ = "";
  searchValueJ = "";
  sorteduJ = {
    active: "",
    direction: "",
  };

  selectedDepohChart: string = "All";

  @ViewChild("topPaginatorJ", { read: MatPaginator, static: true })
  topPaginatorJ: MatPaginator;
  @ViewChild("bottomPaginatorJ", { read: MatPaginator, static: true })
  bottomPaginatorJ: MatPaginator;

  tclientlist = [];
  tsortedclientlist: any;
  tlength = 0;
  tpageSize = 10;
  tpageSizeOptions: number[] = [10, 25, 50];
  tpageIndex = 0;
  toffset = this.tpageSize * this.tpageIndex;
  tsearchbox = "";
  tsearchValue = "";
  tsortedu = {
    active: "last_signedin",
    direction: "desc",
  };

  @ViewChild("ttopPaginator", { read: MatPaginator, static: true })
  ttopPaginator: MatPaginator;
  @ViewChild("tbottomPaginator", { read: MatPaginator, static: true })
  tbottomPaginator: MatPaginator;

  transactionLength = 10;
  penalityLength = 10;
  role;
  depo;

  selectedDepoh = "All";
  selectedDepoh2 = "All";
  selectedDepoh3 = "All";
  tselectedDepoh = "All";

  selectedPerniagaan = "All";
  selectedSkim = "All";

  stringMONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  constructor(
    private fireSrv: FirestoreServiceService,
    changeDetectorRef: ChangeDetectorRef,
    private storageRef: AngularFireStorage,
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    private dateSrv: DateService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private excelHelper: ExcelHelperService,
    private angularFireAuth: AngularFireAuth
  ) {
    this.fromDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
    this.toDate = new Date();

    this.fromDateRekod = new Date(
      new Date().setMonth(new Date().getMonth() - 3)
    );
    this.toDateRekod = new Date();

    this.authService.currentRole.subscribe((role) => (this.role = role));
    this.authService.currentDepo.subscribe((depo) => (this.depo = depo));

    this.datePicker = new Date();
    this.FromPicker = new Date(
      new Date().setMonth(this.datePicker.getMonth() - 1)
    );
  }

  ngOnInit(): void {
    this.spinner.show();
    this.SetupTable();

    setTimeout(() => {
      this.getApprovalList();
      this.oncheck();
      this.pieUpdate();
    }, 500);

    setTimeout(() => {
      this.getULog();
      this.getCLog();
      this.getJLog();
    }, 1000);
  }

  topUpList = [];
  topUpList2 = [];
  totoltopUp = 0;
  weightList = [];
  weightList2 = [];
  payList2 = [];
  totolweight = 0;
  totalPay = 0;
  months = [];

  monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  jumlahClient = 0;

  clickCheck() {
    this.oncheck();
    this.pieUpdate();
  }

  async oncheck() {
    this.topUpList = []
    this.totoltopUp = 0;
    this.weightList = []
    this.totolweight = 0;
    this.totalPay = 0;
    this.spinner.show();
    this.months = []
    this.topUpList2 = []
    this.weightList2 = []
    this.payList2 = []

    const from = new Date(new Date(this.fromDate.setHours(0, 0, 0, 0)).setDate(1))
    const to = new Date(new Date(this.toDate.getFullYear(), this.toDate.getMonth() + 1, 0).setHours(23, 59, 59, 999))

    let m = this.monthDiff(from, to);
    let currentMonth = from.getMonth();
    let currentYear = from.getFullYear();

    this.months.push(this.stringMONTHS[currentMonth] + currentYear);
    this.topUpList2.push(0);
    this.weightList2.push(0);
    this.payList2.push(0);
    for (let index = 0; index < m; index++) {
      currentMonth++;
      if (currentMonth >= 12) {
        currentYear++;
        currentMonth = 0;
      }
      this.months.push(this.stringMONTHS[currentMonth] + currentYear);
      this.topUpList2.push(0);
      this.weightList2.push(0);
      this.payList2.push(0);

    }

    var timestampFrom = firebase.firestore.Timestamp.fromDate(from)
    var timestampTo = firebase.firestore.Timestamp.fromDate(to)

    await this.firestore.collection('event', ref =>
      ref.where('timestamp', '>=', timestampFrom).
        where('timestamp', '<=', timestampTo)
        .orderBy('timestamp', 'asc')).get().forEach(event => {
          event.forEach(async d => {
            const res = this.collectorlist.find(e => e.key === d.data().collector_id);
            const info = {
              key: d.id,
              ...d.data(),
              date: d.data().timestamp.toDate(),
              depo: d.data().depo,
              collector_name: res ? res.name : '',
              name: d.data().name,
              transaction_amount: d.data().transaction_amount,
              anggaran: d.data().anggaran,
            }
            let ind

            if (this.selectedDepohChart === 'All') {
              switch (info.name.toLowerCase()) {
                case 'top up':
                  this.topUpList.push(info);
                  this.totoltopUp += (isNaN(parseFloat(info.transaction_amount)) ? 0 : parseFloat(info.transaction_amount))

                  ind = this.months.findIndex(e => e === this.stringMONTHS[info.date.getMonth()] + info.date.getFullYear())
                  if (ind !== -1) {
                    this.topUpList2[ind] += (isNaN(parseFloat(info.transaction_amount)) ? 0 : parseFloat(info.transaction_amount));
                  }
                  break;
                case 'pengutip sampah':
                  this.weightList.push(info);
                  this.totalPay += (isNaN(parseFloat(info.transaction_amount)) ? 0 : parseFloat(info.transaction_amount) * -1);
                  this.totolweight += (parseFloat(info.anggaran));

                  ind = this.months.findIndex(e => e === this.stringMONTHS[info.date.getMonth()] + info.date.getFullYear())
                  if (ind !== -1) {
                    this.weightList2[ind] += (parseFloat(info.anggaran));
                    this.payList2[ind] += (isNaN(parseFloat(info.transaction_amount)) ? 0 : parseFloat(info.transaction_amount) * -1);

                  }
                  break;
              }
            }
            else if (this.selectedDepohChart == info.depo || info.name.toLowerCase() === 'top up') {

              switch (info.name.toLowerCase()) {
                case 'top up':
                  this.topUpList.push(info);
                  this.totoltopUp += (isNaN(parseFloat(info.transaction_amount)) ? 0 : parseFloat(info.transaction_amount))

                  ind = this.months.findIndex(e => e === this.stringMONTHS[info.date.getMonth()] + info.date.getFullYear())
                  if (ind !== -1) {
                    this.topUpList2[ind] += (isNaN(parseFloat(info.transaction_amount)) ? 0 : parseFloat(info.transaction_amount));
                  }
                  break;
                case 'pengutip sampah':
                  this.weightList.push(info);
                  this.totalPay += (isNaN(parseFloat(info.transaction_amount)) ? 0 : parseFloat(info.transaction_amount) * -1);
                  this.totolweight += (parseFloat(info.anggaran));

                  ind = this.months.findIndex(e => e === this.stringMONTHS[info.date.getMonth()] + info.date.getFullYear())
                  if (ind !== -1) {
                    this.weightList2[ind] += (parseFloat(info.anggaran));
                    this.payList2[ind] += (isNaN(parseFloat(info.transaction_amount)) ? 0 : parseFloat(info.transaction_amount) * -1);

                  }
                  break;
              }
            }

          })

          this.lineChart.data.labels = this.months;
          this.lineChart.data.datasets[0].data = this.payList2;
          this.lineChart.update();

          this.lineChart2.data.labels = this.months;
          this.lineChart2.data.datasets[0].data = this.weightList2;
          this.lineChart2.update();

          this.lineChart3.data.labels = this.months;
          this.lineChart3.data.datasets[0].data = this.topUpList2;
          this.lineChart3.update();

          this.spinner.hide();
        })


  }


  pieUpdate() {
    this.clientTotalTypeList = [];
    this.clientTotalTypeList2 = [];
    this.typeList = [];
    this.typeList2 = [];
    this.jumlahBin = 0;
    this.binTotalTypeList2 = [0, 0];
    this.binTotalTypeList3 = [0, 0];
    this.binTotalTypeList4 = [0, 0];

    this.typeList4 = [];
    this.collectorTotalTypeList2 = [];
    this.jumlahDepo = 0;
    this.jumlahClient = 0;

    for (const c of this.clientlist) {

      if (this.selectedDepohChart === 'All') {
        if (c.business_type) {
          let ind = this.typeList.findIndex(e => e.toLowerCase() === c.business_type.toLowerCase())
          if (ind === -1) {
            this.typeList.push(this.capitalizeFirstLetter(c.business_type));
            this.clientTotalTypeList.push(1);
          } else {
            this.clientTotalTypeList[ind]++;
          }
        }


        if (c.skim) {
          let ind2 = this.typeList2.findIndex(e => e.toLowerCase() === c.skim.toLowerCase())
          if (ind2 === -1) {
            this.typeList2.push(this.capitalizeFirstLetter(c.skim));
            this.clientTotalTypeList2.push(1);
          } else {
            this.clientTotalTypeList2[ind2]++;
          }
          this.jumlahClient++;
        } else {
          let ind2 = this.typeList2.findIndex(e => e.toLowerCase() === 'belum ditugaskan')
          if (ind2 === -1) {
            this.typeList2.push(this.capitalizeFirstLetter('belum ditugaskan'));
            this.clientTotalTypeList2.push(1);
          } else {
            this.clientTotalTypeList2[ind2]++;
          }
          this.jumlahClient++;
        }

        if (c.bin && c.bin.length > 0) {
          for (const bin of c.bin) {
            if (bin.bin_size == 240) {
              this.binTotalTypeList2[0]++;
              if (bin.last_scan) {
                this.binTotalTypeList4[0]++;
              } else {
                this.binTotalTypeList4[1]++;
              }
            }
            else if (bin.bin_size == 660) {
              this.binTotalTypeList2[1]++;
              if (bin.last_scan) {
                this.binTotalTypeList4[0]++;
              } else {
                this.binTotalTypeList4[1]++;
              }
            }
            this.jumlahBin++;
          }
          this.binTotalTypeList3[0]++;
        } else {
          this.binTotalTypeList3[1]++;
        }
      } else if (this.selectedDepohChart == c.depo) {
        if (c.business_type) {
          let ind = this.typeList.findIndex(e => e.toLowerCase() === c.business_type.toLowerCase())
          if (ind === -1) {
            this.typeList.push(this.capitalizeFirstLetter(c.business_type));
            this.clientTotalTypeList.push(1);
          } else {
            this.clientTotalTypeList[ind]++;
          }
        }


        if (c.skim) {
          let ind2 = this.typeList2.findIndex(e => e.toLowerCase() === c.skim.toLowerCase())
          if (ind2 === -1) {
            this.typeList2.push(this.capitalizeFirstLetter(c.skim));
            this.clientTotalTypeList2.push(1);
          } else {
            this.clientTotalTypeList2[ind2]++;
          }
          this.jumlahClient++;
        } else {
          let ind2 = this.typeList2.findIndex(e => e.toLowerCase() === 'belum ditugaskan')
          if (ind2 === -1) {
            this.typeList2.push(this.capitalizeFirstLetter('belum ditugaskan'));
            this.clientTotalTypeList2.push(1);
          } else {
            this.clientTotalTypeList2[ind2]++;
          }
          this.jumlahClient++;

        }
        if (c.bin && c.bin.length > 0) {
          for (const bin of c.bin) {
            if (bin.bin_size == 240) {
              this.binTotalTypeList2[0]++;
              if (bin.last_scan) {
                this.binTotalTypeList4[0]++;
              } else {
                this.binTotalTypeList4[1]++;
              }
            }
            else if (bin.bin_size == 660) {
              this.binTotalTypeList2[1]++;
              if (bin.last_scan) {
                this.binTotalTypeList4[0]++;
              } else {
                this.binTotalTypeList4[1]++;
              }
            }
            this.jumlahBin++;
          }
          this.binTotalTypeList3[0]++;
        } else {
          this.binTotalTypeList3[1]++;
        }
      }


    }

    this.pieChart.data.labels = this.typeList;
    this.pieChart.data.datasets[0].data = this.clientTotalTypeList;
    this.pieChart.update();

    this.pieChart2.data.labels = this.typeList2;
    this.pieChart2.data.datasets[0].data = this.clientTotalTypeList2;
    this.pieChart2.update();

    this.pieChart3.data.labels = this.typeList3;
    this.pieChart3.data.datasets[0].data = this.binTotalTypeList2;
    this.pieChart3.update();

    this.pieChart5.data.labels = this.typeList5;
    this.pieChart5.data.datasets[0].data = this.binTotalTypeList3;
    this.pieChart5.update();

    this.pieChart6.data.labels = this.typeList6;
    this.pieChart6.data.datasets[0].data = this.binTotalTypeList4;
    this.pieChart6.update();

    for (const c of this.collectorlist) {
    if (c.depo) {
        if (this.selectedDepohChart === 'All') {
          let ind2 = this.typeList4.findIndex(e => e.toLowerCase() === c.depo.toLowerCase())
          if (ind2 === -1) {
            this.typeList4.push(this.capitalizeFirstLetter(c.depo));
            this.collectorTotalTypeList2.push(1);
          } else {
            this.collectorTotalTypeList2[ind2]++;
          }
          this.jumlahDepo++;
        } else if (this.selectedDepohChart == c.depo) {
          let ind2 = this.typeList4.findIndex(e => e.toLowerCase() === c.depo.toLowerCase())
          if (ind2 === -1) {
            this.typeList4.push(this.capitalizeFirstLetter(c.depo));
            this.collectorTotalTypeList2.push(1);
          } else {
            this.collectorTotalTypeList2[ind2]++;
          }
          this.jumlahDepo++;
        }

      }
    }

    this.pieChart4.data.labels = this.typeList4;
    this.pieChart4.data.datasets[0].data = this.collectorTotalTypeList2;
    this.pieChart4.update();
  }

  lastYear() {
    this.fromDate = new Date(
      new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setMonth(0)
    );
    this.toDate = new Date(
      new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setMonth(
        11
      )
    );
    this.clickCheck();
  }

  thisyear() {
    this.fromDate = new Date(
      new Date(new Date().setFullYear(new Date().getFullYear())).setMonth(0)
    );
    let lastMonth = new Date().getMonth();
    this.toDate = new Date(
      new Date(new Date().setFullYear(new Date().getFullYear())).setMonth(
        lastMonth
      )
    );
    this.clickCheck();
  }

  pieChart: Chart;
  pieChart2: Chart;
  pieChart3: Chart;
  pieChart4: Chart;
  pieChart5: Chart;
  pieChart6: Chart;
  lineChart: Chart;
  lineChart2: Chart;
  lineChart3: Chart;

  depoList = [];
  typeList = [];
  typeList2 = [];
  typeList3 = ["240 L", "660 L"];
  typeList4 = [];
  typeList5 = ["PREMIS DENGAN QR", "PREMIS TANPA QR"];
  typeList6 = ["AKTIF QR", "TAK AKTIF QR"];

  clientTotalTypeList = [];

  ngAfterViewInit(): void {
    this.pieChart = new Chart('pieChart', {
      type: 'pie',
      data: {
        labels: [],
        datasets: [{
          backgroundColor: ['#FCCF1C', '#2AF560', '#d3ff00', '#34b4eb', '#00ffc4', '#ff2300', '#e600ff', '#8d8d8d'],
          data: [],
        }]
      },
      options: {
        legend: {
          position: 'right'
        },
      }
    })
    this.pieChart2 = new Chart('pieChart2', {
      type: 'pie',
      data: {
        labels: [],
        datasets: [{
          backgroundColor: ['#FCCF1C', '#2AF560', '#d3ff00', '#34b4eb', '#00ffc4', '#ff2300', '#e600ff', '#8d8d8d'],
          data: [],
        }]
      },
      options: {
        legend: {
          position: 'right'
        },
      }
    })
    this.pieChart3 = new Chart('pieChart3', {
      type: 'pie',
      data: {
        labels: [],
        datasets: [{
          backgroundColor: ['#FCCF1C', '#2AF560', '#d3ff00', '#34b4eb', '#00ffc4', '#ff2300', '#e600ff', '#8d8d8d'],
          data: [],
        }]
      },
      options: {
        legend: {
          position: 'right'
        },
      }
    })
    this.pieChart4 = new Chart('pieChart4', {
      type: 'pie',
      data: {
        labels: [],
        datasets: [{
          backgroundColor: ['#FCCF1C', '#2AF560', '#d3ff00', '#34b4eb', '#00ffc4', '#ff2300', '#e600ff', '#8d8d8d', '#B32428', '#A5A5A5', '#6C6960', '##FF7514', '##20603D'],
          data: [],
        }]
      },
      options: {
        legend: {
          position: 'right'
        },
      }
    })

    this.pieChart5 = new Chart('pieChart5', {
      type: 'pie',
      data: {
        labels: [],
        datasets: [{
          backgroundColor: ['#34b4eb', '#d3ff00'],
          data: [],
        }]
      },
      options: {
        legend: {
          position: 'right'
        },
      }
    })

    this.pieChart6 = new Chart('pieChart6', {
      type: 'pie',
      data: {
        labels: [],
        datasets: [{
          backgroundColor: ['#ff2300', '#e600ff'],
          data: [],
        }]
      },
      options: {
        legend: {
          position: 'right'
        },
      }
    })

    this.lineChart = new Chart('lineChart', {
      type: 'bar',
      data: {
        labels: [],
        datasets: [{
          label: 'Jumlah Bayaran Kutipan (RM)'.toUpperCase(),
          data: [],
          fill: false,
          backgroundColor: [
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)',
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)',
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)',
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)'
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)',
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)',
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)',
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)'
          ],
          tension: 0.1
        }]
      },
      options: {
        onClick: (e, activeElements) => {
          if (activeElements.length === 0) {
            return
          }
          const chart = activeElements[0]._chart
          const points = chart.getElementsAtEventForMode(e, 'point', chart.options);
          if (points.length) {
            const firstPoint = points[0];
            var label = this.lineChart.data.labels[firstPoint._index];
            var value = this.lineChart.data.datasets[firstPoint._datasetIndex].data[firstPoint._index];
            const dialogConfig = new MatDialogConfig();
            dialogConfig.autoFocus = false;
            dialogConfig.width = '80%';
            dialogConfig.disableClose = true;
            dialogConfig.data = {
              data: this.weightList,
              month: label,
              type: 'pay',
              charttitle: 'Jumlah Bayaran Kutipan (RM)'.toUpperCase()
            }
            this.dialog.open(ChartComponent, dialogConfig);
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }

    })
    this.lineChart2 = new Chart('lineChart2', {
      type: 'bar',
      data: {
        labels: [],
        datasets: [{
          label: 'Jumlah Berat Kutipan (kg)'.toUpperCase(),
          data: [],
          fill: false,
          backgroundColor: [
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)',
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)',
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)',
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)'
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)',
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)',
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)',
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)'
          ],
          tension: 0.1
        }]
      },
      options: {
        onClick: (e, activeElements) => {
          if (activeElements.length === 0) {
            return
          }
          const chart = activeElements[0]._chart
          const points = chart.getElementsAtEventForMode(e, 'point', chart.options);

          if (points.length) {
            const firstPoint = points[0];
            var label = this.lineChart.data.labels[firstPoint._index];
            var value = this.lineChart.data.datasets[firstPoint._datasetIndex].data[firstPoint._index];
            const dialogConfig = new MatDialogConfig();
            dialogConfig.autoFocus = false;
            dialogConfig.width = '80%';
            dialogConfig.disableClose = true;
            dialogConfig.data = {
              data: this.weightList,
              month: label,
              type: 'anggaran',
              charttitle: 'Jumlah Berat Kutipan (kg)'.toUpperCase()
            }
            this.dialog.open(ChartComponent, dialogConfig);
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }

    })
    this.lineChart3 = new Chart('lineChart3', {
      type: 'bar',
      data: {
        labels: [],
        datasets: [{
          label: 'Jumlah Tambah Nilai (RM)'.toUpperCase(),
          data: [],
          fill: false,
          backgroundColor: [
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)',
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)',
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)',
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)'
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)',
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)',
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)',
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)'
          ],
          tension: 0.1
        }]
      },
      options: {
        onClick: (e, activeElements) => {
          if (activeElements.length === 0) {
            return
          }
          const chart = activeElements[0]._chart
          const points = chart.getElementsAtEventForMode(e, 'point', chart.options);


          if (points.length) {
            const firstPoint = points[0];
            var label = this.lineChart.data.labels[firstPoint._index];
            var value = this.lineChart.data.datasets[firstPoint._datasetIndex].data[firstPoint._index];
            const dialogConfig = new MatDialogConfig();
            dialogConfig.autoFocus = false;
            dialogConfig.width = '80%';
            dialogConfig.disableClose = true;
            dialogConfig.data = {
              data: this.topUpList,
              month: label,
              type: 'top up',
              charttitle: 'Jumlah Tambah Nilai (RM)'.toUpperCase()

            }
            this.dialog.open(ChartComponent, dialogConfig);
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
      }
    })
  }
  capitalizeFirstLetter(string) {
    return string.toUpperCase();
  }

  GetActivity(value) {
    this.transactionLength = value;
  }

  SetupTable() {
    this.initDepo();
    this.initCollector();
    this.initClient();
    this.initJadual();
  }

  async initDepo() {
    this.spinner.show();
    this.depoList = [];
    await this.firestore
      .collection("depohs")
      .get()
      .forEach((de) => {
        de.forEach(async (d) => {
          if (d.data().Show) {
            const info = {
              key: d.id,
              ...d.data(),
            };
            this.depoList.push(info);
          }
        });
        this.depoList = this.depoList.sort((a, b) =>
          this.compare(a.Seq, b.Seq, true)
        );

        if (this.role === "User") {
          this.depoList = this.depoList.filter((item) =>
            this.depo.includes(item.key)
          );
        }
      });
  }

  clientTotalTypeList2 = [];
  binTotalTypeList2 = [0, 0];
  binTotalTypeList3 = [0, 0];
  binTotalTypeList4 = [0, 0];
  jumlahBin = 0;

  async initClient() {
    this.spinner.show();
    this.clientlist = [];
    this.clientTotalTypeList = [];
    this.clientTotalTypeList2 = [];
    this.typeList = [];
    this.typeList2 = [];
    this.jumlahBin = 0;
    this.binTotalTypeList2 = [0, 0];
    this.binTotalTypeList3 = [0, 0];
    this.binTotalTypeList4 = [0, 0];
    this.jumlahClient = 0;

    await this.firestore.collection('users', ref => ref.orderBy('name', 'asc')).get().forEach(clients => {
      clients.forEach(async d => {
        const info = {
          key: d.id,
          ...d.data(),
          uuid: d.data().uuid,
          depo: d.data().depo || '-',
          event: d.data().event,
          last_signedin: d.data().last_signedin ? d.data().last_signedin.toDate() : null,
          eventDtls: [],
          weight: 0,
          pay: 0,
          topup: 0,
        }
        if (!info.uuid) {
          let id = uuidv4();
          await this.firestore.collection('users').doc(info.key).update({ uuid: id });
          info.uuid = id;
        }


        if (this.role === 'Admin') {
          if (d.data().business_type) {
            let ind = this.typeList.findIndex(e => e.toLowerCase() === d.data().business_type.toLowerCase())
            if (ind === -1) {
              this.typeList.push(this.capitalizeFirstLetter(d.data().business_type));
              this.clientTotalTypeList.push(1);
            } else {
              this.clientTotalTypeList[ind]++;
            }
          }


          if (d.data().skim) {
            let ind2 = this.typeList2.findIndex(e => e.toLowerCase() === d.data().skim.toLowerCase())
            if (ind2 === -1) {
              this.typeList2.push(this.capitalizeFirstLetter(d.data().skim));
              this.clientTotalTypeList2.push(1);
            } else {
              this.clientTotalTypeList2[ind2]++;
            }
            this.jumlahClient++;

          } else {
            let ind2 = this.typeList2.findIndex(e => e.toLowerCase() === 'belum ditugaskan')
            if (ind2 === -1) {
              this.typeList2.push(this.capitalizeFirstLetter('belum ditugaskan'));
              this.clientTotalTypeList2.push(1);
            } else {
              this.clientTotalTypeList2[ind2]++;
            }
            this.jumlahClient++;

          }

          if (d.data().bin && d.data().bin.length > 0) {
            for (const bin of d.data().bin) {
              if (bin.bin_size == 240) {
                this.binTotalTypeList2[0]++;
                if (bin.last_scan) {
                  this.binTotalTypeList4[0]++;
                } else {
                  this.binTotalTypeList4[1]++;
                }
              }
              else if (bin.bin_size == 660) {
                this.binTotalTypeList2[1]++;
                if (bin.last_scan) {
                  this.binTotalTypeList4[0]++;
                } else {
                  this.binTotalTypeList4[1]++;
                }
              }
              this.jumlahBin++;
            }
            this.binTotalTypeList3[0]++;
          } else {
            this.binTotalTypeList3[1]++;
          }
          setTimeout(() => {
            for (var e of info.event) {
              this.firestore.collection('event').doc(e).get().forEach(evt => {
                if (evt.exists) {
                  if (evt.data().timestamp) {
                    const res = this.collectorlist.find(e => e.key === evt.data().collector_id);
                    info.eventDtls.push({
                      ...evt.data(),
                      timestamp: evt.data().timestamp ? evt.data().timestamp.toDate() : null,
                      collector_name: res ? res.name : ''
                    });
                    if (evt.data().name === 'Top Up') {
                      info.topup += parseFloat(evt.data().transaction_amount)
                    }
                    else {
                      if (evt.data().anggaran) {
                        info.weight += parseFloat(evt.data().anggaran)
                      }
                      if (evt.data().transaction_amount) {
                        info.pay += (parseFloat(evt.data().transaction_amount) * -1)
                      }
                    }
                  }
  
                }
              })
            }
          }, 1000);
          
          this.clientlist.push(info)
        } else if (this.depo.includes(info.depo)) {
          if (d.data().business_type) {
            let ind = this.typeList.findIndex(e => e.toLowerCase() === d.data().business_type.toLowerCase())
            if (ind === -1) {
              this.typeList.push(this.capitalizeFirstLetter(d.data().business_type));
              this.clientTotalTypeList.push(1);
            } else {
              this.clientTotalTypeList[ind]++;
            }
          }


          if (d.data().skim) {
            let ind2 = this.typeList2.findIndex(e => e.toLowerCase() === d.data().skim.toLowerCase())
            if (ind2 === -1) {
              this.typeList2.push(this.capitalizeFirstLetter(d.data().skim));
              this.clientTotalTypeList2.push(1);
            } else {
              this.clientTotalTypeList2[ind2]++;
            }
          } else {
            let ind2 = this.typeList2.findIndex(e => e.toLowerCase() === 'belum ditugaskan')
            if (ind2 === -1) {
              this.typeList2.push(this.capitalizeFirstLetter('belum ditugaskan'));
              this.clientTotalTypeList2.push(1);
            } else {
              this.clientTotalTypeList2[ind2]++;
            }
          }

          if (d.data().bin && d.data().bin.length > 0) {
            for (const bin of d.data().bin) {
              if (bin.bin_size == 240) {
                this.binTotalTypeList2[0]++;
                if (bin.last_scan) {
                  this.binTotalTypeList4[0]++;
                } else {
                  this.binTotalTypeList4[1]++;
                }
              }
              else if (bin.bin_size == 660) {
                this.binTotalTypeList2[1]++;
                if (bin.last_scan) {
                  this.binTotalTypeList4[0]++;
                } else {
                  this.binTotalTypeList4[1]++;
                }
              }
              this.jumlahBin++;
            }
            this.binTotalTypeList3[0]++;
          } else {
            this.binTotalTypeList3[1]++;
          }


          for (var e of info.event) {
            this.firestore.collection('event').doc(e).get().forEach(evt => {
              if (evt.exists) {
                if (evt.data().timestamp) {
                  const res = this.collectorlist.find(e => e.key === evt.data().collector_id);
                  info.eventDtls.push({
                    ...evt.data(),
                    timestamp: evt.data().timestamp ? evt.data().timestamp.toDate() : null,
                    collector_name: res ? res.name : ''
                  });
                  if (evt.data().name === 'Top Up') {
                    info.topup += parseFloat(evt.data().transaction_amount)
                  }
                  else {
                    if (evt.data().anggaran) {
                      info.weight += parseFloat(evt.data().anggaran)
                    }
                    if (evt.data().transaction_amount) {
                      info.pay += (parseFloat(evt.data().transaction_amount) * -1)
                    }
                  }
                }

              }
            })
          }
          this.clientlist.push(info)
        }
        this.sortedclientlist = this.clientlist.slice(this.offset, (this.offset + this.pageSize));
        this.length = this.clientlist.length;
        this.sortclient();

        this.tclientlist = this.clientlist.slice();
        this.tsortedclientlist = this.tclientlist.slice(this.toffset, (this.toffset + this.tpageSize));
        this.tlength = this.tclientlist.length;
        this.tsortclient();

      })

      this.pieChart.data.labels = this.typeList;
      this.pieChart.data.datasets[0].data = this.clientTotalTypeList;
      this.pieChart.update();

      this.pieChart2.data.labels = this.typeList2;
      this.pieChart2.data.datasets[0].data = this.clientTotalTypeList2;
      this.pieChart2.update();

      this.pieChart3.data.labels = this.typeList3;
      this.pieChart3.data.datasets[0].data = this.binTotalTypeList2;
      this.pieChart3.update();

      this.pieChart5.data.labels = this.typeList5;
      this.pieChart5.data.datasets[0].data = this.binTotalTypeList3;
      this.pieChart5.update();

      this.pieChart6.data.labels = this.typeList6;
      this.pieChart6.data.datasets[0].data = this.binTotalTypeList4;
      this.pieChart6.update();


      this.sortedclientlist = this.clientlist.slice(this.offset, (this.offset + this.pageSize));
      this.length = this.clientlist.length;
      this.sortclient();
      this.spinner.hide();
    })
  }
  collectorTotalTypeList2 = [];
  jumlahDepo = 0;

  async initCollector() {
    this.collectorlist = [];
    this.typeList4 = [];
    this.collectorTotalTypeList2 = [];
    this.jumlahDepo = 0;

    this.spinner.show();
    await this.firestore
      .collection("collectors", (ref) => ref.orderBy("name", "asc"))
      .get()
      .forEach((collector) => {
        collector.forEach(async (d) => {
          const info = {
            key: d.id,
            ...d.data(),
            password: d.data().password1.replace("/encrypt", ""),
            last_signedin: d.data().last_signedin
              ? d.data().last_signedin.toDate()
              : null,
            depo: d.data().depo,
          };

          if (this.role === "Admin") {
            this.collectorlist.push(info);
            if (info.depo) {
              let ind2 = this.typeList4.findIndex(
                (e) => e.toLowerCase() === info.depo.toLowerCase()
              );
              if (ind2 === -1) {
                this.typeList4.push(this.capitalizeFirstLetter(info.depo));
                this.collectorTotalTypeList2.push(1);
              } else {
                this.collectorTotalTypeList2[ind2]++;
              }
              this.jumlahDepo++;
            }
          } else if (this.depo.includes(info.depo)) {
            this.collectorlist.push(info);

            if (info.depo) {
              let ind2 = this.typeList4.findIndex(
                (e) => e.toLowerCase() === info.depo.toLowerCase()
              );
              if (ind2 === -1) {
                this.typeList4.push(this.capitalizeFirstLetter(info.depo));
                this.collectorTotalTypeList2.push(1);
              } else {
                this.collectorTotalTypeList2[ind2]++;
              }
              this.jumlahDepo++;
            }
          }
          this.sortedcollectorlist = this.collectorlist.slice(
            this.offsetC,
            this.offsetC + this.pageSizeC
          );
          this.lengthC = this.collectorlist.length;
          this.sortcollector();
        });

        this.pieChart4.data.labels = this.typeList4;
        this.pieChart4.data.datasets[0].data = this.collectorTotalTypeList2;
        this.pieChart4.update();

        this.initRecord();
        this.sortedcollectorlist = this.collectorlist.slice(
          this.offsetC,
          this.offsetC + this.pageSizeC
        );
        this.lengthC = this.collectorlist.length;
        this.sortcollector();
        this.spinner.hide();
      });
  }
  async initRecord() {
    this.eventlist = [];
    this.spinner.show();

    const from = new Date(this.fromDateRekod.setHours(0, 0, 0, 0))
    const to = new Date(this.toDateRekod.setHours(23, 59, 59, 999))

    var timestampFrom = firebase.firestore.Timestamp.fromDate(from)
    var timestampTo = firebase.firestore.Timestamp.fromDate(to)

    await this.firestore.collection('event', ref =>
      ref.where('timestamp', '>=', timestampFrom).
        where('timestamp', '<=', timestampTo)
        .orderBy('timestamp', 'asc')).get().forEach(event => {
          event.forEach(async d => {
            if (d.data().images) {
              const res = this.collectorlist.find(e => e.key === d.data().collector_id);
              const info = {
                key: d.id,
                ...d.data(),
                date: d.data().timestamp.toDate(),
                depo: d.data().depo,
                collector_name: res ? res.name : ''
              }
              if (this.role === 'Admin') {
                this.eventlist.push(info)
              } else if (this.depo.includes(info.depo)) {
                this.eventlist.push(info)
              }
            }
          })

          this.lengthE = this.eventlist.length;
          this.sortedeventlist = this.eventlist.slice(0);
          this.sortevent();
          this.limitevent();
          this.spinner.hide();
        })
  }

  async initJadual() {
    this.jaduallist = [];
    this.spinner.show();
    await this.firestore
      .collection("schedules", (ref) => ref.orderBy("name", "asc"))
      .get()
      .forEach((schedule) => {
        schedule.forEach(async (d) => {
          const info = {
            key: d.id,
            ...d.data(),
          };
          this.jaduallist.push(info);

          this.sortedjaduallist = this.jaduallist.slice(
            this.offsetC,
            this.offsetC + this.pageSizeC
          );
          this.lengthJ = this.sortedjaduallist.length;
          this.sortJ();
        });

        this.sortedjaduallist = this.jaduallist.slice(
          this.offsetC,
          this.offsetC + this.pageSizeC
        );
        this.lengthJ = this.sortedjaduallist.length;
        this.sortJ();
        this.spinner.hide();
      });
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    this.sortedclientlist = this.clientlist.slice();
    this.DS();
    this.sortclient();
    this.limitclient();
  }

  limitclient() {
    this.sortedclientlist = this.sortedclientlist.slice(
      this.offset,
      this.offset + this.pageSize
    );
  }

  sortData(sort: Sort) {
    this.pageIndex = 0;
    this.topPaginator.firstPage();
    this.bottomPaginator.firstPage();
    this.offset = this.pageSize * this.pageIndex;

    this.sortedu = sort;
    this.sortedclientlist = this.clientlist.slice();

    this.DS();
    if (!sort.active || (sort.direction === "" && !this.searchValue)) {
      this.sortedclientlist = this.clientlist.slice();
      this.limitclient();
      return;
    }

    this.sortedclientlist = this.sortedclientlist.sort((a, b) => {
      const isAsc = this.sortedu.direction === "asc";

      switch (this.sortedu.active) {
        case "name":
          return this.compare(a.name, b.name, isAsc);
        case "ic":
          return this.compare(a.ic_num, b.ic_num, isAsc);
        case "phone":
          return this.compare(a.phone_num, b.phone_num, isAsc);
        case "email":
          return this.compare(a.email, b.email, isAsc);
        case "jenis":
          return this.compare(a.skim || "", b.skim || "", isAsc);
        case "license":
          return this.compare(a.license_num || "", b.license_num || "", isAsc);
        case "address":
          return this.compare(a.address || "", b.address || "", isAsc);
        case "postcode":
          return this.compare(a.postcode, b.postcode, isAsc);
        case "type":
          return this.compare(
            a.business_type || "",
            b.business_type || "",
            isAsc
          );
        case "depoh":
          return this.compare(a.depo || "", b.depo || "", isAsc);
        case "shop_name":
          return this.compare(a.shop_name, b.shop_name, isAsc);
        case "lot":
          return this.compare(a.lot || "", b.lot || "", isAsc);
        case "schedule":
          return this.compare(a.schedule || "", b.schedule || "", isAsc);
        default:
          return 0;
      }
    });
    this.limitclient();
  }

  sortclient() {
    if (!this.sortedu.active || this.sortedu.direction === "") {
      return;
    }
    this.sortedclientlist = this.sortedclientlist.sort((a, b) => {
      const isAsc = this.sortedu.direction === "asc";

      switch (this.sortedu.active) {
        case "name":
          return this.compare(a.name, b.name, isAsc);
        case "ic":
          return this.compare(a.ic_num, b.ic_num, isAsc);
        case "phone":
          return this.compare(a.phone_num, b.phone_num, isAsc);
        case "email":
          return this.compare(a.email, b.email, isAsc);
        case "jenis":
          return this.compare(a.skim || "", b.skim || "", isAsc);
        case "license":
          return this.compare(a.license_num || "", b.license_num || "", isAsc);
        case "address":
          return this.compare(a.address || "", b.address || "", isAsc);
        case "postcode":
          return this.compare(a.postcode, b.postcode, isAsc);
        case "type":
          return this.compare(
            a.business_type || "",
            b.business_type || "",
            isAsc
          );
        case "depoh":
          return this.compare(a.depo || "", b.depo || "", isAsc);
        case "shop_name":
          return this.compare(a.shop_name, b.shop_name, isAsc);
        case "lot":
          return this.compare(a.lot || "", b.lot || "", isAsc);
        case "schedule":
          return this.compare(a.schedule || "", b.schedule || "", isAsc);

        default:
          return 0;
      }
    });
  }

  dynamicSearch() {
    this.pageIndex = 0;
    this.topPaginator.firstPage();
    this.bottomPaginator.firstPage();
    this.offset = this.pageSize * this.pageIndex;
    this.searchValue = this.searchbox;
    this.sortedclientlist = this.clientlist.filter(
      (u) =>
        String(u.name).toLowerCase().includes(this.searchValue.toLowerCase()) ||
        String(u.ic_num)
          .toLowerCase()
          .includes(this.searchValue.toLowerCase()) ||
        String(u.phone_num)
          .toLowerCase()
          .includes(this.searchValue.toLowerCase()) ||
        String(u.email)
          .toLowerCase()
          .includes(this.searchValue.toLowerCase()) ||
        // String(u.license).toLowerCase().includes(this.searchValue.toLowerCase()) ||
        String(u.business_type)
          .toLowerCase()
          .includes(this.searchValue.toLowerCase()) ||
        String(u.address)
          .toLowerCase()
          .includes(this.searchValue.toLowerCase()) ||
        String(u.shop_name)
          .toLowerCase()
          .includes(this.searchValue.toLowerCase()) ||
        String(u.postcode)
          .toLowerCase()
          .includes(this.searchValue.toLowerCase()) ||
        String(u.depo).toLowerCase().includes(this.searchValue.toLowerCase()) ||
        String(u.type).toLowerCase().includes(this.searchValue.toLowerCase())
    );

    if (this.selectedDepoh !== "All") {
      this.sortedclientlist = this.sortedclientlist.filter(
        (u) => String(u.depo) === this.selectedDepoh
      );
    }
    if (this.selectedPerniagaan !== "All") {
      this.sortedclientlist = this.sortedclientlist.filter(
        (u) => String(u.business_type) === this.selectedPerniagaan
      );
    }
    if (this.selectedSkim !== "All") {
      this.sortedclientlist = this.sortedclientlist.filter(
        (u) => String(u.skim) === this.selectedSkim
      );
    }
    this.length = this.sortedclientlist.length;
    this.sortclient();
    this.limitclient();
  }

  DS() {
    this.sortedclientlist = this.clientlist.filter(
      (u) =>
        String(u.name).toLowerCase().includes(this.searchValue.toLowerCase()) ||
        String(u.ic_num)
          .toLowerCase()
          .includes(this.searchValue.toLowerCase()) ||
        String(u.phone_num)
          .toLowerCase()
          .includes(this.searchValue.toLowerCase()) ||
        String(u.email)
          .toLowerCase()
          .includes(this.searchValue.toLowerCase()) ||
        // String(u.license).toLowerCase().includes(this.searchValue.toLowerCase()) ||
        String(u.business_type)
          .toLowerCase()
          .includes(this.searchValue.toLowerCase()) ||
        String(u.address)
          .toLowerCase()
          .includes(this.searchValue.toLowerCase()) ||
        String(u.shop_name)
          .toLowerCase()
          .includes(this.searchValue.toLowerCase()) ||
        String(u.postcode)
          .toLowerCase()
          .includes(this.searchValue.toLowerCase()) ||
        String(u.depo).toLowerCase().includes(this.searchValue.toLowerCase()) ||
        String(u.type).toLowerCase().includes(this.searchValue.toLowerCase())
    );

    if (this.selectedDepoh !== "All") {
      this.sortedclientlist = this.sortedclientlist.filter(
        (u) => String(u.depo) === this.selectedDepoh
      );
    }
    if (this.selectedPerniagaan !== "All") {
      this.sortedclientlist = this.sortedclientlist.filter(
        (u) => String(u.business_type) === this.selectedPerniagaan
      );
    }
    if (this.selectedSkim !== "All") {
      this.sortedclientlist = this.sortedclientlist.filter(
        (u) => String(u.skim) === this.selectedSkim
      );
    }
    this.length = this.sortedclientlist.length;
    this.sortclient();
  }

  paginatorC(pageEvent: PageEvent) {
    this.pageSizeC = pageEvent.pageSize;
    this.pageIndexC = pageEvent.pageIndex;
    this.offsetC = this.pageSizeC * this.pageIndexC;
    if (this.topPaginatorC.pageIndex < this.pageIndexC) {
      this.topPaginatorC.nextPage();
    } else if (this.topPaginatorC.pageIndex > this.pageIndexC) {
      this.topPaginatorC.previousPage();
    }
    if (this.bottomPaginatorC.pageIndex < this.pageIndexC) {
      this.bottomPaginatorC.nextPage();
    } else if (this.bottomPaginatorC.pageIndex > this.pageIndexC) {
      this.bottomPaginatorC.previousPage();
    }
    this.sortedcollectorlist = this.collectorlist.slice();

    if(this.searchValueC)
      this.DSC();

    this.sortcollector();
    this.limitcollector();
  }

  limitcollector() {
    this.sortedcollectorlist = this.sortedcollectorlist.slice(
      this.offsetC,
      this.offsetC + this.pageSizeC
    );
  }

  sortDataC(sort: Sort) {
    this.pageIndexC = 0;
    this.topPaginatorC.firstPage();
    this.bottomPaginatorC.firstPage();
    this.offsetC = this.pageSizeC * this.pageIndexC;

    this.sorteduC = sort;
    this.sortedcollectorlist = this.collectorlist.slice();
    if(this.searchValueC)
      this.DSC();
    if (!sort.active || (sort.direction === "" && !this.searchValueC)) {
      this.sortedcollectorlist = this.collectorlist.slice();
      this.limitcollector();
      return;
    }

    this.sortedcollectorlist = this.sortedcollectorlist.sort((a, b) => {
      const isAsc = this.sorteduC.direction === "asc";
      switch (this.sorteduC.active) {
        case "address":
          return this.compare(a.address, b.address, isAsc);
        case "email":
          return this.compare(a.email, b.email, isAsc);
        case "ic_num":
          return this.compare(a.ic_num, b.ic_num, isAsc);
        case "name":
          return this.compare(a.name, b.name, isAsc);
        case "poscode":
          return this.compare(a.poscode, b.poscode, isAsc);
        case "phone_num":
          return this.compare(a.phone_num, b.phone_num, isAsc);
        case "depoh":
          return this.compare(a.depo || "", b.depo || "", isAsc);
        case "worker_id":
          return this.compare(a.worker_id, b.worker_id, isAsc);
        case "zone":
          return this.compare(a.zone || "", b.zone || "", isAsc);
        case "plate_num":
          return this.compare(a.plate_num || "", b.plate_num || "", isAsc);
        case "position":
          return this.compare(a.position, b.position, isAsc);
        case "last_signedin":
          return this.compareDate(a.last_signedin, b.last_signedin, isAsc);
        default:
          return 0;
      }
    });
    this.limitcollector();
  }

  sortcollector() {
    if (!this.sorteduC.active || this.sorteduC.direction === "") {
      return;
    }
    this.sortedcollectorlist = this.sortedcollectorlist.sort((a, b) => {
      const isAsc = this.sorteduC.direction === "asc";
      switch (this.sorteduC.active) {
        case "address":
          return this.compare(a.address, b.address, isAsc);
        case "email":
          return this.compare(a.email, b.email, isAsc);
        case "ic_num":
          return this.compare(a.ic_num, b.ic_num, isAsc);
        case "name":
          return this.compare(a.name, b.name, isAsc);
        case "poscode":
          return this.compare(a.poscode, b.poscode, isAsc);
        case "phone_num":
          return this.compare(a.phone_num, b.phone_num, isAsc);
        case "depoh":
          return this.compare(a.depo || "", b.depo || "", isAsc);
        case "worker_id":
          return this.compare(a.worker_id, b.worker_id, isAsc);
        case "zone":
          return this.compare(a.zone || "", b.zone || "", isAsc);
        case "plate_num":
          return this.compare(a.plate_num || "", b.plate_num || "", isAsc);
        case "position":
          return this.compare(a.position, b.position, isAsc);
        case "last_signedin":
          return this.compareDate(a.last_signedin, b.last_signedin, isAsc);
        default:
          return 0;
      }
    });
  }

  dynamicSearchC() {
    this.pageIndexC = 0;
    this.topPaginatorC.firstPage();
    this.bottomPaginatorC.firstPage();
    this.offsetC = this.pageSizeC * this.pageIndexC;

    this.searchValueC = this.searchboxC;
    this.sortedcollectorlist = this.collectorlist.filter(
      (u) =>
        // String(u.address).toLowerCase().includes(this.searchValueC.toLowerCase()) ||
        // String(u.email).toLowerCase().includes(this.searchValueC.toLowerCase()) ||
        String(u.ic_num)
          .toLowerCase()
          .includes(this.searchValueC.toLowerCase()) ||
        String(u.name)
          .toLowerCase()
          .includes(this.searchValueC.toLowerCase()) ||
        String(u.phone_num)
          .toLowerCase()
          .includes(this.searchValueC.toLowerCase()) ||
        String(u.depo)
          .toLowerCase()
          .includes(this.searchValueC.toLowerCase()) ||
        String(u.worker_id)
          .toLowerCase()
          .includes(this.searchValueC.toLowerCase()) ||
        String(u.zone)
          .toLowerCase()
          .includes(this.searchValueC.toLowerCase()) ||
        String(u.plate_num)
          .toLowerCase()
          .includes(this.searchValueC.toLowerCase()) ||
        String(u.position)
          .toLowerCase()
          .includes(this.searchValueC.toLowerCase())
      // String(u.poscode).toLowerCase().includes(this.searchValueC.toLowerCase())
    );

    if (this.selectedDepoh2 !== "All") {
      this.sortedcollectorlist = this.sortedcollectorlist.filter(
        (u) => String(u.depo) === this.selectedDepoh2
      );
    }
    this.lengthC = this.sortedcollectorlist.length;
    this.sortcollector();
    this.limitcollector();
  }

  DSC() {
    this.sortedcollectorlist = this.collectorlist.filter(
      (u) =>
        // String(u.address).toLowerCase().includes(this.searchValueC.toLowerCase()) ||
        // String(u.email).toLowerCase().includes(this.searchValueC.toLowerCase()) ||
        String(u.ic_num)
          .toLowerCase()
          .includes(this.searchValueC.toLowerCase()) ||
        String(u.name)
          .toLowerCase()
          .includes(this.searchValueC.toLowerCase()) ||
        String(u.phone_num)
          .toLowerCase()
          .includes(this.searchValueC.toLowerCase()) ||
        String(u.depo)
          .toLowerCase()
          .includes(this.searchValueC.toLowerCase()) ||
        String(u.worker_id)
          .toLowerCase()
          .includes(this.searchValueC.toLowerCase()) ||
        String(u.zone)
          .toLowerCase()
          .includes(this.searchValueC.toLowerCase()) ||
        String(u.plate_num)
          .toLowerCase()
          .includes(this.searchValueC.toLowerCase()) ||
        String(u.position)
          .toLowerCase()
          .includes(this.searchValueC.toLowerCase())
      // String(u.poscode).toLowerCase().includes(this.searchValueC.toLowerCase())
    );

    if (this.selectedDepoh2 !== "All") {
      this.sortedcollectorlist = this.sortedcollectorlist.filter(
        (u) => String(u.depo) === this.selectedDepoh2
      );
    }
    this.lengthC = this.sortedcollectorlist.length;

    this.sortcollector();
  }

  paginatorE(pageEvent: PageEvent) {
    this.pageSizeE = pageEvent.pageSize;
    this.pageIndexE = pageEvent.pageIndex;
    this.offsetE = this.pageSizeE * this.pageIndexE;
    if (this.topPaginatorE.pageIndex < this.pageIndexE) {
      this.topPaginatorE.nextPage();
    } else if (this.topPaginatorE.pageIndex > this.pageIndexE) {
      this.topPaginatorE.previousPage();
    }
    if (this.bottomPaginatorE.pageIndex < this.pageIndexE) {
      this.bottomPaginatorE.nextPage();
    } else if (this.bottomPaginatorE.pageIndex > this.pageIndexE) {
      this.bottomPaginatorE.previousPage();
    }
    this.sortedeventlist = this.eventlist.slice();
    this.DSE();
    this.sortevent();
    this.limitevent();
  }

  limitevent() {
    this.sortedeventlist = this.sortedeventlist.slice(
      this.offsetE,
      this.offsetE + this.pageSizeE
    );
  }

  sortDataE(sort: Sort) {
    this.pageIndexE = 0;
    this.topPaginatorE.firstPage();
    this.bottomPaginatorE.firstPage();
    this.offsetE = this.pageSizeE * this.pageIndexE;

    this.sorteduE = sort;
    this.sortedeventlist = this.eventlist.slice();
    this.DSE();
    if (!sort.active || (sort.direction === "" && !this.searchValueE)) {
      this.sortedeventlist = this.eventlist.slice();
      this.limitevent();
      return;
    }

    this.sortedeventlist = this.sortedeventlist.sort((a, b) => {
      const isAsc = this.sorteduE.direction === "asc";
      switch (this.sorteduE.active) {
        case "name":
          return this.compare(a.premis, b.premis, isAsc);
        case "address":
          return this.compare(a.address, b.address, isAsc);
        case "date":
          return this.compareDate(a.date, b.date, isAsc);
        case "pay":
          return this.compare(
            a.transaction_amount || 0,
            b.transaction_amount || 0,
            isAsc
          );
        case "weight":
          return this.compare(a.anggaran || 0, b.anggaran || 0, isAsc);
        case "staff":
          return this.compare(
            a.collector_name || "",
            b.collector_name || "",
            isAsc
          );
        case "depo":
          return this.compare(a.depo || "", b.depo || "", isAsc);
        default:
          return 0;
      }
    });
    this.limitevent();
  }

  sortevent() {
    if (!this.sorteduE.active || this.sorteduE.direction === "") {
      return;
    }
    this.sortedeventlist = this.sortedeventlist.sort((a, b) => {
      const isAsc = this.sorteduE.direction === "asc";
      switch (this.sorteduE.active) {
        case "name":
          return this.compare(a.premis, b.premis, isAsc);
        case "address":
          return this.compare(a.address, b.address, isAsc);
        case "date":
          return this.compareDate(a.date, b.date, isAsc);
        case "pay":
          return this.compare(
            a.transaction_amount || 0,
            b.transaction_amount || 0,
            isAsc
          );
        case "weight":
          return this.compare(a.anggaran || 0, b.anggaran || 0, isAsc);
        case "staff":
          return this.compare(
            a.collector_name || "",
            b.collector_name || "",
            isAsc
          );
        case "depo":
          return this.compare(a.depo || "", b.depo || "", isAsc);
        default:
          return 0;
      }
    });
  }

  dynamicSearchE() {
    this.pageIndexE = 0;
    this.topPaginatorE.firstPage();
    this.bottomPaginatorE.firstPage();
    this.offsetE = this.pageSizeE * this.pageIndexE;

    this.searchValueE = this.searchboxE;
    this.sortedeventlist = this.eventlist.filter(
      (u) =>
        String(u.premis)
          .toLowerCase()
          .includes(this.searchValueE.toLowerCase()) ||
        String(u.address)
          .toLowerCase()
          .includes(this.searchValueE.toLowerCase()) ||
        String(u.transaction_amount)
          .toLowerCase()
          .includes(this.searchValueE.toLowerCase()) ||
        String(u.anggaran)
          .toLowerCase()
          .includes(this.searchValueE.toLowerCase()) ||
        String(u.collector_name)
          .toLowerCase()
          .includes(this.searchValueE.toLowerCase()) ||
        String(u.depo).toLowerCase().includes(this.searchValueE.toLowerCase())
    );

    if (this.selectedDepoh3 !== "All") {
      this.sortedeventlist = this.sortedeventlist.filter(
        (u) => String(u.depo) === this.selectedDepoh3
      );
    }
    this.lengthE = this.sortedeventlist.length;
    this.sortevent();
    this.limitevent();
  }

  DSE() {
    this.sortedeventlist = this.eventlist.filter(
      (u) =>
        String(u.premis)
          .toLowerCase()
          .includes(this.searchValueE.toLowerCase()) ||
        String(u.address)
          .toLowerCase()
          .includes(this.searchValueE.toLowerCase()) ||
        String(u.transaction_amount)
          .toLowerCase()
          .includes(this.searchValueE.toLowerCase()) ||
        String(u.anggaran)
          .toLowerCase()
          .includes(this.searchValueE.toLowerCase()) ||
        String(u.collector_name)
          .toLowerCase()
          .includes(this.searchValueE.toLowerCase()) ||
        String(u.depo).toLowerCase().includes(this.searchValueE.toLowerCase())
    );

    if (this.selectedDepoh3 !== "All") {
      this.sortedeventlist = this.sortedeventlist.filter(
        (u) => String(u.depo) === this.selectedDepoh3
      );
    }
    this.lengthE = this.sortedeventlist.length;
    this.sortevent();
  }

  paginatorJ(pageEvent: PageEvent) {
    this.pageSizeJ = pageEvent.pageSize;
    this.pageIndexJ = pageEvent.pageIndex;
    this.offsetJ = this.pageSizeJ * this.pageIndexJ;
    if (this.topPaginatorJ.pageIndex < this.pageIndexJ) {
      this.topPaginatorJ.nextPage();
    } else if (this.topPaginatorJ.pageIndex > this.pageIndexJ) {
      this.topPaginatorJ.previousPage();
    }
    if (this.bottomPaginatorJ.pageIndex < this.pageIndexJ) {
      this.bottomPaginatorJ.nextPage();
    } else if (this.bottomPaginatorJ.pageIndex > this.pageIndexJ) {
      this.bottomPaginatorJ.previousPage();
    }
    this.sortedjaduallist = this.jaduallist.slice();
    this.DSJ();
    this.sortJ();
    this.limitJ();
  }

  limitJ() {
    this.sortedjaduallist = this.sortedjaduallist.slice(
      this.offsetJ,
      this.offsetJ + this.pageSizeJ
    );
  }

  sortDataJ(sort: Sort) {
    this.pageIndexJ = 0;
    this.topPaginatorJ.firstPage();
    this.bottomPaginatorJ.firstPage();
    this.offsetJ = this.pageSizeJ * this.pageIndexJ;

    this.sorteduJ = sort;
    this.sortedjaduallist = this.jaduallist.slice();
    this.DSJ();
    if (!sort.active || (sort.direction === "" && !this.searchValueJ)) {
      this.sortedjaduallist = this.jaduallist.slice();
      this.limitJ();
      return;
    }

    this.sortedjaduallist = this.sortedjaduallist.sort((a, b) => {
      const isAsc = this.sorteduJ.direction === "asc";
      switch (this.sorteduJ.active) {
        case "name":
          return this.compare(a.name, b.name, isAsc);
        case "mon":
          return this.compare(a.mon, b.mon, isAsc);
        case "tue":
          return this.compare(a.tue, b.tue, isAsc);
        case "wed":
          return this.compare(a.wed, b.wed, isAsc);
        case "thu":
          return this.compare(a.thu, b.thu, isAsc);
        case "fri":
          return this.compare(a.fri, b.fri, isAsc);
        case "sat":
          return this.compare(a.sat, b.sat, isAsc);
        case "sun":
          return this.compare(a.sun, b.sun, isAsc);
        default:
          return 0;
      }
    });
    this.limitJ();
  }

  sortJ() {
    if (!this.sorteduJ.active || this.sorteduJ.direction === "") {
      return;
    }
    this.sortedjaduallist = this.sortedjaduallist.sort((a, b) => {
      const isAsc = this.sorteduJ.direction === "asc";
      switch (this.sorteduJ.active) {
        case "name":
          return this.compare(a.name, b.name, isAsc);
        case "mon":
          return this.compare(a.mon, b.mon, isAsc);
        case "tue":
          return this.compare(a.tue, b.tue, isAsc);
        case "wed":
          return this.compare(a.wed, b.wed, isAsc);
        case "thu":
          return this.compare(a.thu, b.thu, isAsc);
        case "fri":
          return this.compare(a.fri, b.fri, isAsc);
        case "sat":
          return this.compare(a.sat, b.sat, isAsc);
        case "sun":
          return this.compare(a.sun, b.sun, isAsc);
        default:
          return 0;
      }
    });
  }

  dynamicSearchJ() {
    this.pageIndexJ = 0;
    this.topPaginatorJ.firstPage();
    this.bottomPaginatorJ.firstPage();
    this.offsetJ = this.pageSizeJ * this.pageIndexJ;

    this.searchValueJ = this.searchboxJ;
    this.sortedjaduallist = this.jaduallist.filter((u) =>
      String(u.name).toLowerCase().includes(this.searchValueJ.toLowerCase())
    );

    this.lengthJ = this.sortedjaduallist.length;
    this.sortJ();
    this.limitJ();
  }

  DSJ() {
    this.sortedjaduallist = this.jaduallist.filter((u) =>
      String(u.name).toLowerCase().includes(this.searchValueJ.toLowerCase())
    );

    this.lengthJ = this.sortedjaduallist.length;
    this.sortJ();
    this.limitJ();
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compareDate(a, b, isAsc: boolean) {
    a = new Date(a).getTime();
    b = new Date(b).getTime();
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  viewlesen(client) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "80%";
    dialogConfig.disableClose = true;
    dialogConfig.data = client;
    this.dialog.open(DetailsComponent, dialogConfig);
  }

  async Qr(client) {
    if (!client.uuid) {
      let id = uuidv4();
      await this.firestore
        .collection("users")
        .doc(client.key)
        .update({ uuid: id });
      client.uuid = id;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = "50%";
    dialogConfig.width = "80%";

    dialogConfig.disableClose = true;
    dialogConfig.data = client;
    this.dialog
      .open(QrCodeComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          client.bin = res;
        }
      });
  }

  async Assign(depoh, client) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    const position = {
      top: "5%",
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message:
        "Adakah anda ingin menukar " + depoh + " kepada " + client.name + "?",
    };
    this.dialog
      .open(ConfirmationDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          await this.firestore
            .collection("users")
            .doc(client.key)
            .update({ depo: depoh });
          this.toaster.success("Kemaskini berjaya");
          client.depo = depoh;
          this.addLog(
            depoh,
            "user",
            `${depoh} telah ditugaskan kepadanya. \nNama Pemilik Premis: ${client.name}\n Name Premis: ${client.shop_name}\n `
          );
        }
      });
  }

  async AssignSkim(skim, client) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    const position = {
      top: "5%",
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message:
        "Adakah anda ingin menukar " + skim + " kepada " + client.name + "?",
    };
    this.dialog
      .open(ConfirmationDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          await this.firestore
            .collection("users")
            .doc(client.key)
            .update({ skim: skim });
          this.toaster.success("Kemaskini berjaya");
          client.skim = skim;
          this.addLog(
            skim,
            "user",
            `${skim} telah ditugaskan kepadanya. \nNama Pemilik Premis: ${client.name}\n Name Premis: ${client.shop_name}\n `
          );
        }
      });
  }

  async AssignP(skim, client) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    const position = {
      top: "5%",
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message:
        "Adakah anda ingin menukar " + skim + " kepada " + client.name + "?",
    };
    this.dialog
      .open(ConfirmationDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          await this.firestore
            .collection("users")
            .doc(client.key)
            .update({ business_type: skim });
          this.toaster.success("Kemaskini berjaya");
          client.business_type = skim;
          this.addLog(
            skim,
            "user",
            `${skim} telah ditugaskan kepadanya. \nNama Pemilik Premis: ${client.name}\n Name Premis: ${client.shop_name}\n `
          );
        }
      });
  }

  async AssignC(depoh, collector) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    const position = {
      top: "5%",
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message:
        "Adakah anda ingin menukar " +
        depoh +
        " kepada " +
        collector.name +
        "?",
    };
    this.dialog
      .open(ConfirmationDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          await this.firestore
            .collection("collectors")
            .doc(collector.key)
            .update({ depo: depoh });
          this.toaster.success("Kemaskini berjaya");
          collector.depo = depoh;
          this.addLog(
            depoh,
            "collector",
            `${depoh} telah ditugaskan kepadanya. \nNama Kakitangan: ${collector.name}\n Kad Pengenalan: ${collector.ic_num}\n `
          );
        }
      });
  }

  detectChanges1(event) {
    this.fromDate = event;
  }

  detectChanges2(event) {
    this.toDate = event;
  }

  detectChanges3(event) {
    this.fromDateRekod = event;
  }

  detectChanges4(event) {
    this.toDateRekod = event;
  }

  refresh(value) {
    switch (value) {
      case 1:
        this.initClient();
        this.sortedu = {
          active: "name",
          direction: "asc",
        };
        this.searchValue = "";
        break;
      case 2:
        this.initCollector();
        this.sorteduC = {
          active: "name",
          direction: "asc",
        };
        this.searchValueC = "";
        break;
      case 3:
        this.initRecord();
        break;
      case 4:
        this.sorteduJ = {
          active: "name",
          direction: "asc",
        };
        this.searchValueJ = "";
        this.initJadual();
        break;
      case 6:
        this.tsortedu = {
          active: "name",
          direction: "asc",
        };
        this.tsearchValue = "";
        this.initClient();
        break;
      case 7:
        this.getApprovalList();
        break;
    }
  }

  changePassword(collector) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "80%";

    dialogConfig.disableClose = true;
    dialogConfig.data = collector;
    this.dialog.open(ResetPasswordComponent, dialogConfig);
  }

  editC(client) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "80%";
    dialogConfig.disableClose = true;
    dialogConfig.data = { ...client };
    this.dialog
      .open(EditClientComponent, dialogConfig)
      .afterClosed()
      .subscribe(async (res) => {
        if (res) {
          client.business_type = res.business_type;
          client.name = res.name;
          client.shop_name = res.shop_name;
          client.address = res.address;
          client.phone_num = res.phone_num;
          client.email = res.email;
          client.poscode = res.poscode;
          client.lot = res.lot;
        }
      });
  }

  add() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "80%";

    dialogConfig.disableClose = true;
    this.dialog
      .open(NewCollectorComponent, dialogConfig)
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          this.initCollector();
        }
      });
  }

  edit(collector) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "80%";
    dialogConfig.disableClose = true;
    dialogConfig.data = { ...collector };
    this.dialog
      .open(EditCollectorComponent, dialogConfig)
      .afterClosed()
      .subscribe(async (res) => {
        if (res) {
          collector.name = res.name;
          collector.email = res.email;
          collector.address = res.address;
          collector.phone_num = res.phone_num;
          collector.worker_id = res.worker_id;
          collector.zone = res.zone;
          collector.position = res.position;
          collector.plate_num = res.plate_num;
          collector.poscode = res.poscode;
        }
      });
  }

  open(Image) {
    window.open(Image);
  }

  regrab() {
    this.initRecord();
  }

  exportToExcel() {
    const exportInformation = [];
    this.spinner.show();
    const clientlist = this.clientlist.slice().sort((a, b) => {
      return (a.name < b.name ? -1 : 1) * (true ? 1 : -1);
    });
    for (const raw of clientlist) {
      const info = {
        "Nama Pemilik Premis": raw.name || "-",
        "Nama Premis": raw.shop_name || "-",
        "Kad Pengenalan": raw.ic_num || "-",
        "Jenis Skim": raw.skim || "-",
        "Nombor Telefon": raw.phone_num || "-",
        Emel: raw.email || "-",
        "No Lesen Perniagaan":
          raw.bin.reduce(
            (acc, { license_num }) => acc + license_num + ", ",
            ""
          ) || "-",
        Alamat: raw.address || "-",
        Poskod: raw.poscode || "-",
        "Jenis Perniagaan": raw.business_type || "-",
        Depoh: raw.depo || "-",
      };
      exportInformation.push(info);
    }

    this.excelHelper.exportAsExcelFile(
      exportInformation,
      "PremisPerniagaan" + new Date().getTime()
    );
    this.spinner.hide();
  }

  exportToExcelStaff() {
    const exportInformation = [];
    this.spinner.show();
    const collectorlist = this.collectorlist.slice().sort((a, b) => {
      return (a.name < b.name ? -1 : 1) * (true ? 1 : -1);
    });
    for (const raw of collectorlist) {
      const info = {
        "Nama Kakitangan": raw.name || "-",
        "No Kad Pengenalan": raw.ic_num || "-",
        "No Kakitangan": raw.worker_id || "-",
        Jawatan: raw.position || "-",
        "Depoh Pembersihan": raw.depo || "-",
        Zon: raw.zone || "-",
        "No Pendaftaran Kenderaan": raw.plate_num || "-",
        "Nombor Telefon": raw.zone || "-",
        Emel: raw.emel || "-",
        Alamat: raw.address || "-",
        Poskod: raw.poscode || "-",
        "Log Masuk Terakhir": raw.last_signedin || "-",
      };
      exportInformation.push(info);
    }

    this.excelHelper.exportAsExcelFile(
      exportInformation,
      "StafMBSP" + new Date().getTime()
    );
    this.spinner.hide();
  }

  addJadual() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "80%";

    dialogConfig.disableClose = true;
    this.dialog
      .open(NewScheduleComponent, dialogConfig)
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          this.initJadual();
        }
      });
  }

  editJ(schedule) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "80%";

    dialogConfig.disableClose = true;
    dialogConfig.data = schedule;
    this.dialog
      .open(NewScheduleComponent, dialogConfig)
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          this.initJadual();
        }
      });
  }

  deleteJ(schedule) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    const position = {
      top: "5%",
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: "Adakah anda ingin padamkan " + schedule.name + "?",
    };
    this.dialog
      .open(ConfirmationDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          var list = this.clientlist.filter(
            (e) => e.scheduleid === schedule.key
          );
          for (const client of list) {
            await this.firestore
              .collection("users")
              .doc(client.key)
              .update({ schedule: null, scheduleid: null });
            client.schedule = null;
            client.scheduleid = null;
          }

          await this.firestore
            .collection("schedules")
            .doc(schedule.key)
            .delete();

          this.addLog(
            schedule,
            "jadual",
            `${schedule.name} telah dipadam. \nNama Jadual: ${schedule.name}`
          );

          this.toaster.success("Padam berjaya");
          this.initJadual();
        }
      });
  }

  delete(collector) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    const position = {
      top: "5%",
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: "Adakah anda ingin padamkan " + collector.name + "?",
    };
    this.dialog
      .open(ConfirmationDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          await this.firestore
            .collection("collectors")
            .doc(collector.key)
            .delete();
          this.toaster.success("Padam berjaya");
          this.addLog(
            collector,
            "collector",
            `${collector.name} telah dipadam. \nNama Kakitangan: ${collector.name}\n Kad Pengenalan: ${collector.ic_num}\n `
          );
          this.initCollector();
        }
      });
  }

  deleteC(client) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    const position = {
      top: "5%",
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: "Adakah anda ingin padamkan " + client.name + "?",
    };
    this.dialog
      .open(ConfirmationDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          await this.firestore.collection("users").doc(client.key).delete();
          this.toaster.success("Padam berjaya");
          this.addLog(
            client,
            "user",
            `${client.name} telah dipadam. \nNama Pemilik Premis: ${client.name}\n Name Premis: ${client.shop_name}\n `
          );

          this.initClient();
        }
      });
  }

  AssignJ(client) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "80%";

    dialogConfig.disableClose = true;
    dialogConfig.data = { list: this.jaduallist, key: client.key };

    this.dialog
      .open(AssignScheduleComponent, dialogConfig)
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          client.schedule = result.name;
          client.scheduleid = result.key;
          this.addLog(
            result,
            "user",
            `${result.name} telah ditugaskan kepadanya. \nNama Pemilik Premis: ${client.name}\n Name Premis: ${client.shop_name}\n `
          );
        }
      });
  }

  tpaginator(pageEvent: PageEvent) {
    this.tpageSize = pageEvent.pageSize;
    this.tpageIndex = pageEvent.pageIndex;
    this.toffset = this.tpageSize * this.tpageIndex;
    if (this.ttopPaginator.pageIndex < this.tpageIndex) {
      this.ttopPaginator.nextPage();
    } else if (this.ttopPaginator.pageIndex > this.tpageIndex) {
      this.ttopPaginator.previousPage();
    }
    if (this.tbottomPaginator.pageIndex < this.tpageIndex) {
      this.tbottomPaginator.nextPage();
    } else if (this.tbottomPaginator.pageIndex > this.tpageIndex) {
      this.tbottomPaginator.previousPage();
    }
    this.tsortedclientlist = this.tclientlist.slice();

    this.tsortclient();
    this.tlimitclient();
  }

  tlimitclient() {
    this.tsortedclientlist = this.tsortedclientlist.slice(
      this.toffset,
      this.toffset + this.tpageSize
    );
  }

  tsortData(sort: Sort) {
    this.tsortedu = sort;
    this.tsortedclientlist = this.tclientlist.slice();
    if (this.tsearchValue) {
      this.tDS();
    }
    if (!sort.active || (sort.direction === "" && !this.tsearchValue)) {
      this.tsortedclientlist = this.tclientlist.slice();
      this.tlimitclient();
      return;
    }

    this.tsortedclientlist = this.tsortedclientlist.sort((a, b) => {
      const isAsc = this.tsortedu.direction === "asc";
      switch (this.tsortedu.active) {
        case "name":
          return this.compare(a.name, b.name, isAsc);
        case "shop_name":
          return this.compare(a.shop_name || "", b.shop_name || "", isAsc);
        case "phone":
          return this.compare(a.phone_num, b.phone_num, isAsc);
        case "type":
          return this.compare(
            a.business_type || "",
            b.business_type || "",
            isAsc
          );
        case "jenis":
          return this.compare(a.skim || "", b.skim || "", isAsc);
        case "depoh":
          return this.compare(a.depo || "", b.depo || "", isAsc);
        case "pay":
          return this.compare(a.pay || 0, b.pay || 0, isAsc);
        case "weight":
          return this.compare(a.weight || 0, b.weight || 0, isAsc);
        case "topup":
          return this.compare(a.topup || 0, b.topup || 0, isAsc);
        case "amount":
          return this.compare(a.amount || 0, b.amount || 0, isAsc);
        case "last_signedin":
          return this.compareDate(a.last_signedin, b.last_signedin, isAsc);
        default:
          return 0;
      }
    });
    this.tlimitclient();
  }

  tsortclient() {
    if (!this.tsortedu.active || this.tsortedu.direction === "") {
      return;
    }
    this.tsortedclientlist = this.tsortedclientlist.sort((a, b) => {
      const isAsc = this.tsortedu.direction === "asc";
      switch (this.tsortedu.active) {
        case "name":
          return this.compare(a.name, b.name, isAsc);
        case "shop_name":
          return this.compare(a.shop_name || "", b.shop_name || "", isAsc);
        case "phone":
          return this.compare(a.phone_num, b.phone_num, isAsc);
        case "type":
          return this.compare(
            a.business_type || "",
            b.business_type || "",
            isAsc
          );
        case "jenis":
          return this.compare(a.skim || "", b.skim || "", isAsc);
        case "depoh":
          return this.compare(a.depo || "", b.depo || "", isAsc);
        case "pay":
          return this.compare(a.pay || 0, b.pay || 0, isAsc);
        case "weight":
          return this.compare(a.weight || 0, b.weight || 0, isAsc);
        case "topup":
          return this.compare(a.topup || 0, b.topup || 0, isAsc);
        case "amount":
          return this.compare(a.amount || 0, b.amount || 0, isAsc);
        case "last_signedin":
          return this.compareDate(a.last_signedin, b.last_signedin, isAsc);
        default:
          return 0;
      }
    });
  }

  tdynamicSearch() {
    this.tsearchValue = this.tsearchbox;
    this.tsortedclientlist = this.tclientlist.filter(
      (u) =>
        String(u.name)
          .toLowerCase()
          .includes(this.tsearchValue.toLowerCase()) ||
        String(u.phone_num)
          .toLowerCase()
          .includes(this.tsearchValue.toLowerCase()) ||
        String(u.business_type)
          .toLowerCase()
          .includes(this.tsearchValue.toLowerCase()) ||
        String(u.shop_name)
          .toLowerCase()
          .includes(this.tsearchValue.toLowerCase()) ||
        String(u.weight)
          .toLowerCase()
          .includes(this.tsearchValue.toLowerCase()) ||
        String(u.topup)
          .toLowerCase()
          .includes(this.tsearchValue.toLowerCase()) ||
        String(u.amount)
          .toLowerCase()
          .includes(this.tsearchValue.toLowerCase()) ||
        String(u.depo)
          .toLowerCase()
          .includes(this.tsearchValue.toLowerCase()) ||
        String(u.pay).toLowerCase().includes(this.tsearchValue.toLowerCase())
    );

    if (this.tselectedDepoh !== "All") {
      this.tsortedclientlist = this.tsortedclientlist.filter(
        (u) => String(u.depo) === this.tselectedDepoh
      );
    }
    this.tlength = this.tsortedclientlist.length;
    this.tsortclient();
    this.tlimitclient();
  }

  tDS() {
    this.tsortedclientlist = this.tclientlist.filter(
      (u) =>
        String(u.name)
          .toLowerCase()
          .includes(this.tsearchValue.toLowerCase()) ||
        String(u.phone_num)
          .toLowerCase()
          .includes(this.tsearchValue.toLowerCase()) ||
        String(u.shop_name)
          .toLowerCase()
          .includes(this.tsearchValue.toLowerCase()) ||
        String(u.business_type)
          .toLowerCase()
          .includes(this.tsearchValue.toLowerCase()) ||
        String(u.depo)
          .toLowerCase()
          .includes(this.tsearchValue.toLowerCase()) ||
        String(u.weight)
          .toLowerCase()
          .includes(this.tsearchValue.toLowerCase()) ||
        String(u.topup)
          .toLowerCase()
          .includes(this.tsearchValue.toLowerCase()) ||
        String(u.amount)
          .toLowerCase()
          .includes(this.tsearchValue.toLowerCase()) ||
        String(u.pay).toLowerCase().includes(this.tsearchValue.toLowerCase())
    );

    if (this.tselectedDepoh !== "All") {
      this.tsortedclientlist = this.tsortedclientlist.filter(
        (u) => String(u.depo) === this.tselectedDepoh
      );
    }
    this.tlength = this.tsortedclientlist.length;
    this.tsortclient();
  }

  tView(itm) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = "90%";
    dialogConfig.width = "90%";

    dialogConfig.disableClose = true;
    dialogConfig.data = { ...itm, type: null };
    this.dialog.open(HistoryComponent, dialogConfig);
  }

  tViewPremis(itm) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = "90%";
    dialogConfig.width = "90%";

    dialogConfig.disableClose = true;
    dialogConfig.data = { ...itm, type: null };
    this.dialog.open(HistoryPremisComponent, dialogConfig);
  }

  tViewT(itm) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = "auto";
    dialogConfig.width = "80%";

    dialogConfig.disableClose = true;
    dialogConfig.data = { ...itm, type: "Top Up" };
    this.dialog.open(HistoryComponent, dialogConfig);
  }

  importStaf() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = "auto";
    dialogConfig.width = "80%";

    dialogConfig.disableClose = true;
    this.dialog.open(ImportComponent, dialogConfig);
  }

  typelistss = [
    {
      label: "Kedai Makanan",
      value: "makanan",
    },
    {
      label: "Kedai Runcit",
      value: "runcit",
    },
    {
      label: "Stesen Minyak",
      value: "minyak",
    },
    {
      label: "Lain-lain",
      value: "lain",
    },
  ];

  getLabel(v) {
    let r = this.typelistss.find((e) => e.value == v);
    return r ? r.label : "-";
  }

  onTabChanged(event) {
    if (event.index === 0) {
      this.fromDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
      this.toDate = new Date();
      this.selectedDepohChart = 'All'
      this.clickCheck()
    }

    this.selectedDepoh = "All";
    this.selectedDepoh2 = "All";
    this.selectedDepoh3 = "All";
    this.tselectedDepoh = "All";

    this.selectedPerniagaan = "All";
    this.selectedSkim = "All";

    this.pageIndex = 0;
    this.topPaginator.firstPage();
    this.bottomPaginator.firstPage();
    this.offset = this.pageSize * this.pageIndex;
    this.sortedclientlist = this.clientlist.slice();
    this.searchValue = "";
    this.searchbox = "";
    this.sortedu = {
      active: "name",
      direction: "asc",
    };
    this.sortclient()
    this.limitclient();

    this.pageIndexC = 0;
    this.topPaginatorC.firstPage();
    this.bottomPaginatorC.firstPage();
    this.offsetC = this.pageSizeC * this.pageIndexC;
    this.sortedcollectorlist = this.collectorlist.slice();
    this.searchValueC = "";
    this.searchboxC = "";
    this.sorteduC = {
      active: "name",
      direction: "asc",
    };
    this.sortcollector()
    this.limitcollector();

    this.pageIndexE = 0;
    this.topPaginatorE.firstPage();
    this.bottomPaginatorE.firstPage();
    this.offsetE = this.pageSizeE * this.pageIndexE;
    this.sortedeventlist = this.eventlist.slice();
    this.searchValueE = "";
    this.searchboxE = "";
    this.sorteduE = {
      active: "date",
      direction: "desc",
    };
    this.sortevent()
    this.limitevent();

    this.pageIndexJ = 0;
    this.topPaginatorJ.firstPage();
    this.bottomPaginatorJ.firstPage();
    this.offsetJ = this.pageSizeJ * this.pageIndexJ;
    this.sortedjaduallist = this.jaduallist.slice();
    this.searchValueJ = "";
    this.searchboxJ = "";
    this.sorteduJ = {
      active: "",
      direction: "",
    };
    
    this.limitJ();
  }

  private dateFormat = new DateFormatService();

  async addLog(detail, type, message) {
    this.angularFireAuth.authState.subscribe(async (authState) => {
      if (authState) {
        const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(
          new Date()
        );
        const info = {
          message: message,
          date: new Date(),
          data: detail,
          updatedBy: authState.email,
          dateFormat: dateFormat,
          type: type,
        };
        await this.firestore.collection("AdminLog").add(info);
      }
    });
  }

  searchLog() {
    this.getULog();
    this.getCLog();
    this.getJLog();
  }
  userlogs = [];
  collogs = [];
  jaduallogs = [];
  userlength = 10;
  collength = 10;
  jaduallength = 10;

  GetHActivity(value) {
    this.userlength = value;
    this.getULog();
  }

  GetHActivity2(value) {
    this.collength = value;
    this.getCLog();
  }

  GetHActivity3(value) {
    this.jaduallength = value;
    this.getJLog();
  }

  getULog() {
    this.userlogs = [];
    const from = new Date(this.FromPicker);
    from.setHours(0);
    from.setMinutes(0);
    from.setSeconds(0);
    const to = new Date(this.datePicker);
    to.setHours(23);
    to.setMinutes(59);
    to.setSeconds(59);
    const fromdate = firestore.Timestamp.fromDate(from);
    const toDate = firestore.Timestamp.fromDate(to);

    this.firestore
      .collection("AdminLog", (ref) =>
        ref
          .where("type", "==", "user")
          .where("date", ">=", fromdate)
          .where("date", "<=", toDate)
          .orderBy("date", "desc")
          .limit(this.userlength)
      )
      .get()
      .forEach((data) => {
        data.forEach((e) => {
          const info = {
            ...e.data(),
          };
          this.userlogs.push(info);
        });
      });
  }

  getCLog() {
    this.collogs = [];
    const from = new Date(this.FromPicker);
    from.setHours(0);
    from.setMinutes(0);
    from.setSeconds(0);
    const to = new Date(this.datePicker);
    to.setHours(23);
    to.setMinutes(59);
    to.setSeconds(59);
    const fromdate = firestore.Timestamp.fromDate(from);
    const toDate = firestore.Timestamp.fromDate(to);

    this.firestore
      .collection("AdminLog", (ref) =>
        ref
          .where("type", "==", "collector")
          .where("date", ">=", fromdate)
          .where("date", "<=", toDate)
          .orderBy("date", "desc")
          .limit(this.collength)
      )
      .get()
      .forEach((data) => {
        data.forEach((e) => {
          const info = {
            ...e.data(),
          };
          this.collogs.push(info);
        });
      });
  }

  getJLog() {
    this.jaduallogs = [];
    const from = new Date(this.FromPicker);
    from.setHours(0);
    from.setMinutes(0);
    from.setSeconds(0);
    const to = new Date(this.datePicker);
    to.setHours(23);
    to.setMinutes(59);
    to.setSeconds(59);
    const fromdate = firestore.Timestamp.fromDate(from);
    const toDate = firestore.Timestamp.fromDate(to);

    this.firestore
      .collection("AdminLog", (ref) =>
        ref
          .where("type", "==", "collector")
          .where("date", ">=", fromdate)
          .where("date", "<=", toDate)
          .orderBy("date", "desc")
          .limit(this.jaduallength)
      )
      .get()
      .forEach((data) => {
        data.forEach((e) => {
          const info = {
            ...e.data(),
          };
          this.jaduallogs.push(info);
        });
      });
  }

  approveClient(client: any) {
    // Push into users.bin
    this.firestore
      .collection("users")
      .doc(client.ic_num)
      .get()
      .toPromise()
      .then((userDocSnapshot) => {
        if (userDocSnapshot.exists) {
          const userData = userDocSnapshot.data();
          const currentBinArray = userData.bin == undefined ? [] : userData.bin;

          // Calculate the new bin ID based on the length of the bin array
          const newBinId = currentBinArray.length;

          // Create the new bin entry
          const newBinEntry = {
            id: newBinId.toString(), // Use the calculated ID

            license_num: client.license_num,
            address: client.address,
            bin_size: client.bin_size,
            last_scan: "",
            location: client.location,
          };

          // Push the new entry into the bin array using arrayUnion
          return this.firestore
            .collection("users")
            .doc(client.ic_num)
            .update({
              bin: firestore.FieldValue.arrayUnion(newBinEntry),
            });
        } else {
          throw new Error("User document does not exist");
        }
      })
      .then(() => {
        this.toaster.success("New Bin Entry Added Successfully");
        this.firestore
          .collection("requests")
          .doc(client.requestId)
          .delete()
          .then(() => {
            // Refresh the approval list
            this.getApprovalList();
          })
          .catch((error) => {
            console.error("Error processing denial:", error);
          });
      })
      .catch((error) => {
        console.error("Error adding new bin entry:", error);
      });
  }

  denyClient(client: any) {
    // Delete from request table
    this.firestore
      .collection("requests")
      .doc(client.requestId)
      .delete()
      .then(() => {
        // Refresh the approval list
        this.toaster.success("Request Rejected Successfully");
        this.getApprovalList();
      })
      .catch((error) => {
        console.error("Error processing denial:", error);
      });
  }

  getApprovalList() {
    this.approvalList = [];


    // Fetch the request documents
    this.firestore
      .collection("requests")
      .get()
      .toPromise()
      .then((data) => {
        const requestPromises = data.docs.map(async (requestDoc) => {
          const requestData = requestDoc.data();
          // Fetch the user data based on ic_num from the request data
          const userDocSnapshot = await this.firestore
            .collection("users")
            .doc(requestData.ic_num)
            .get()
            .toPromise();
          const userData = userDocSnapshot.data();
          if (userData) {
            this.approvalList.push({
              ...requestData,
              business_type: userData.business_type,
              shop_name: userData.shop_name,
              name: userData.name,
              depo: userData.depo,
              requestId: requestDoc.id,
              userId: userDocSnapshot.id,
            });
          }
        });
        // Wait for all the promises to complete
        Promise.all(requestPromises).then(() => {
        });
      });
  }

  sortDataA(sort: Sort) {
    this.approvalList = this.approvalList.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "business_name":
          return this.compare(a.business_type, b.business_type, isAsc);
        case "shop_name":
          return this.compare(a.shop_name, b.shop_name, isAsc);
        case "name":
          return this.compare(a.name, b.name, isAsc);
        case "license_number":
          return this.compare(a.license_num, b.license_num, isAsc);
        case "bin_size":
          return this.compare(a.bin_size || "", b.bin_size || "", isAsc);
        case "address":
          return this.compare(a.address || "", b.address || "", isAsc);
        default:
          return 0;
      }
    });
    this.limitclient();
  }
}
