import { DateService } from './../../Services/date.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// 3rd Party Libraries
import { MatDialogConfig, MatDialog, DialogPosition } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../../Services/auth.service';


@Component({
  selector: 'app-top-nav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.css']
})
export class TopnavComponent implements OnInit {
  position: DialogPosition;
  dailyDate= new Date();
  minDate: Date;
  maxDate: Date;
  currentUser: any;
  elem;
  authser: any;
  flag= true;
  numberofuser: 1;
  role: any;
  constructor(
    private router: Router,
    private toast: ToastrService,
    private dialog: MatDialog,
    private auth: AngularFireAuth,
    private angularFireAuth: AngularFireAuth,
    private authSrv: AuthService,
    private dateSrv: DateService,

  ) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 20, 0, 1);
    this.maxDate = new Date();
    // this.authser = this.angularFireAuth.authState.subscribe(authState =>{
    //   if(authState === null){
    //     router.navigate(['error401'])
    //     this.authser.unsubscribe();
    //   } else{
    //     // this.fireSrv.getRole(authState.uid).forEach( val=> {
    //     //   if(val.data().role !== 'keselamatan'){
    //     //     this.flag = true;
    //     //     this.authser.unsubscribe();
    //     //   } else{
    //     //     this.flag = false;
    //     //     this.authser.unsubscribe();
    //     //   }
    //     // })
    //   }
    // })

  }

  ngOnInit() {
    this.role = localStorage.getItem('Role');

  }

  logout(){
    this.router.navigate(['/Login']);
    setTimeout(() => {
    this.auth.auth.signOut();

    }, 1000);
  }

  settings(){
    this.router.navigate(['Home/Settings']);
  }
  PPS(){
    localStorage.setItem('Role', 'PPS');
    this.role = 'PPS';
    location.reload();
  }

  KPA(){
    localStorage.setItem('Role', 'KPA');
    this.role = 'KPA';
    location.reload();
  }

  datePicked(){
    localStorage.setItem('Time', this.dailyDate.toDateString());
    this.dateSrv.SetDate(this.dailyDate);
  }
}
