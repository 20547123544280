import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  data:any;

  constructor(
      private dialogRef: MatDialogRef<DetailsComponent>,
      @Inject(MAT_DIALOG_DATA) data,
      private toast: ToastrService,
      private dialog: MatDialog,
      private firestore: AngularFirestore,
      private angularFireAuth: AngularFireAuth,
      private spinner: NgxSpinnerService
    ) {
      this.data = data;
    }

  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close(false);
  }



}
