import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import {v4 as uuidv4} from 'uuid';
@Component({
  selector: 'app-create-depoh',
  templateUrl: './create-depoh.component.html',
  styleUrls: ['./create-depoh.component.css']
})
export class CreateDepohComponent implements OnInit {

  Depoh = {
    ID:"",
    Name: "",
    UpdatedBy: "",
    UpdatedAt: new Date(),
  };
  email: string;
  depohlist = [];

  constructor(
    private dialogRef: MatDialogRef<CreateDepohComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private toast: ToastrService,
    private angularFireAuth: AngularFireAuth,

  ) {
    this.depohlist = data;
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    })
  }

  ngOnInit(): void {

  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {

    if (!this.Depoh.Name) {
      this.toast.warning('Information not completed', 'Please check')
      return;
    }

    if(this.depohlist){
      const nameExist = this.depohlist.find(d=>d.Name === this.Depoh.Name);
      if(nameExist){
        this.toast.warning('Same name alreayd existed', 'Please check')
        return;
      }
    }

    this.Depoh.ID = uuidv4();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '400px';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      message: 'Do you want to create this depoh? ' + this.Depoh.Name,
    }

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        this.Depoh.UpdatedAt = new Date();
        this.Depoh.UpdatedBy = this.email;
        this.firestore.collection('Depoh').doc(this.Depoh.ID).set(this.Depoh).then(r => {
          this.spinner.hide();
          this.toast.success('Added successful');

          this.dialogRef.close(true);
        });
      }
    });





  }


}
