import { RouteSelectComponent } from './../../../shared/route-select/route-select.component';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatCheckboxChange } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { VehicleRouteSelectComponent } from './route-select/route-select.component';
const cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'app-set-schedule',
  templateUrl: './set-schedule.component.html',
  styleUrls: ['./set-schedule.component.css']
})
export class SetScheduleComponent implements OnInit {

  fakeArray = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'];

  device = {
    ID: "",
    LorryType: "",
    PlateNumber: "",
    RouteCovered: "",
    Scheduling: [],
    UpdatedBy: "",
    UpdatedAt: new Date(),
  };

  day = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],

  }

  page = 0;
  routeList = [];
  email: string;
  constructor(
    private dialogRef: MatDialogRef<SetScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private toast: ToastrService,
    private angularFireAuth: AngularFireAuth,

  ) {
    this.device.ID = data.ID;
    this.device.LorryType = data.LorryType;
    this.device.PlateNumber = data.PlateNumber;
    this.device.RouteCovered = data.RouteCovered;
    if (data.Day)
      this.day = cloneDeep(data.Day);
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    })
  }

  ngOnInit(): void {

  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '400px';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      message: 'Do you want to update this vehicle ID? ' + this.device.ID,
    }

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();

        this.firestore.collection('Devices').doc(this.device.ID).update({ Day: this.day, UpdatedAt: new Date(), UpdatedBy: this.email }).then(r => {
          this.spinner.hide();
          this.toast.success('Updated successful');
          this.dialogRef.close(true);
        });
      }
    });
  }




  switchPage(value) {
    this.page = value;
  }

  tick(event: MatCheckboxChange, time, day) {
    if (event.checked) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.height = 'auto';
      dialogConfig.width = '600px';
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      this.dialog.open(VehicleRouteSelectComponent, dialogConfig).afterClosed().subscribe(result => {
        if (result) {
          const selectedday = this.page === 0 ? day : this.page === 1 ? day + 3 : 7
          let tempdate = new Date();
          tempdate.setHours(time);
          tempdate.setMinutes(0);
          tempdate.setSeconds(0);

          let finaldate = new Date(tempdate);
          finaldate.setMinutes(finaldate.getMinutes() + result.Duration);

          const info = {
            routeName: result.Name,
            routeID: result.id,
            startAt: tempdate.getHours(),
            endAt: finaldate.getHours() + ":" + finaldate.getMinutes(),
          }


          switch (selectedday) {
            case 1: this.day.Monday.push(info); break;
            case 2: this.day.Tuesday.push(info); break;
            case 3: this.day.Wednesday.push(info); break;
            case 4: this.day.Thursday.push(info); break;
            case 5: this.day.Friday.push(info); break;
            case 6: this.day.Saturday.push(info); break;
            case 7: this.day.Sunday.push(info); break;
          }

        } else {
          event.source.checked = false;
        }
      });
    }
  }

  getSchedule(time, day) {
    const selectedday = this.page === 0 ? day : this.page === 1 ? day + 3 : 7
    switch (selectedday) {
      case 1: {
        var flag = false;
        for (let index = 0; index < this.day.Monday.length; index++) {
          const start = this.day.Monday[index].startAt
          const endHour = parseFloat(this.day.Monday[index].endAt.split(':')[0]);
          var skipFlag = false;
          if (start > endHour) {
            skipFlag = true;
            if (time > start) {
              flag = true;
            }
          }
          if (skipFlag && time < start)
            continue;
          if (time === start) {
            flag = true;
          }
          if (time >= start && time <= endHour) {
            flag = true;
          }
          if (flag)
            return flag;
        }
        for (let index = 0; index < this.day.Sunday.length; index++) {

          const start = this.day.Sunday[index].startAt
          const endHour = parseFloat(this.day.Sunday[index].endAt.split(':')[0]);
          const minutes = parseFloat(this.day.Sunday[index].endAt.split(':')[1]);
          var overDay = false;
          if (start > endHour) {
            overDay = true;
          }
          if (overDay) {
            if (time <= endHour) {
              flag = true;
            }
            if (time === endHour - 1 && minutes != 0) {
              flag = true;
            }
          }

          if (flag)
            return flag;
        }
        return false;
      }

      case 2: {
        for (let index = 0; index < this.day.Tuesday.length; index++) {
          const start = this.day.Tuesday[index].startAt
          const endHour = parseFloat(this.day.Tuesday[index].endAt.split(':')[0]);
          const minutes = parseFloat(this.day.Tuesday[index].endAt.split(':')[1]);
          var skipFlag = false;
          var flag = false;
          if (start > endHour) {
            skipFlag = true;
            if (time > start) {
              flag = true;
            }
          }
          if (skipFlag && time < start)
            continue;
          if (time === start) {
            flag = true;
          }
          if (time >= start && time <= endHour) {
            flag = true;
          }

          if (flag)
            return flag;
        }
        for (let index = 0; index < this.day.Monday.length; index++) {
          const start = this.day.Monday[index].startAt
          const endHour = parseFloat(this.day.Monday[index].endAt.split(':')[0]);
          const minutes = parseFloat(this.day.Monday[index].endAt.split(':')[1]);
          var overDay = false;
          var flag = false;
          if (start > endHour) {
            overDay = true;
          }
          if (overDay) {
            if (time <= endHour) {
              flag = true;
            }
            if (time === endHour - 1 && minutes != 0) {
              flag = true;
            }
          }

          if (flag)
            return flag;
        }
        return false;
      }

      case 3: {
        for (let index = 0; index < this.day.Wednesday.length; index++) {
          const start = this.day.Wednesday[index].startAt
          const endHour = parseFloat(this.day.Wednesday[index].endAt.split(':')[0]);
          const minutes = parseFloat(this.day.Wednesday[index].endAt.split(':')[1]);
          var skipFlag = false;
          var flag = false;
          if (start > endHour) {
            skipFlag = true;
            if (time > start) {
              flag = true;
            }
          }
          if (skipFlag && time < start)
            continue;

          if (time === start) {
            flag = true;
          }
          if (time >= start && time <= endHour) {
            flag = true;
          }

          if (flag)
            return flag;
        }
        for (let index = 0; index < this.day.Tuesday.length; index++) {
          const start = this.day.Tuesday[index].startAt
          const endHour = parseFloat(this.day.Tuesday[index].endAt.split(':')[0]);
          const minutes = parseFloat(this.day.Tuesday[index].endAt.split(':')[1]);
          var overDay = false;
          var flag = false;
          if (start > endHour) {
            overDay = true;
          }
          if (overDay) {
            if (time <= endHour) {
              flag = true;
            }
            if (time === endHour - 1 && minutes != 0) {
              flag = true;
            }
          }

          if (flag)
            return flag;
        }
        return false;
      }

      case 4: {
        for (let index = 0; index < this.day.Thursday.length; index++) {
          const start = this.day.Thursday[index].startAt
          const endHour = parseFloat(this.day.Thursday[index].endAt.split(':')[0]);
          const minutes = parseFloat(this.day.Thursday[index].endAt.split(':')[1]);
          var skipFlag = false;
          var flag = false;
          if (start > endHour) {
            skipFlag = true;
            if (time > start) {
              flag = true;
            }
          }
          if (skipFlag && time < start)
            continue;

          if (time === start) {
            flag = true;
          }
          if (time >= start && time <= endHour) {
            flag = true;
          }

          if (flag)
            return flag;
        }
        for (let index = 0; index < this.day.Wednesday.length; index++) {
          const start = this.day.Wednesday[index].startAt
          const endHour = parseFloat(this.day.Wednesday[index].endAt.split(':')[0]);
          const minutes = parseFloat(this.day.Wednesday[index].endAt.split(':')[1]);
          var overDay = false;
          var flag = false;
          if (start > endHour) {
            overDay = true;
          }
          if (overDay) {
            if (time <= endHour) {
              flag = true;
            }
            if (time === endHour - 1 && minutes != 0) {
              flag = true;
            }
          }

          if (flag)
            return flag;
        }
        return false;
      }

      case 5: {
        for (let index = 0; index < this.day.Friday.length; index++) {
          const start = this.day.Friday[index].startAt
          const endHour = parseFloat(this.day.Friday[index].endAt.split(':')[0]);
          const minutes = parseFloat(this.day.Friday[index].endAt.split(':')[1]);
          var skipFlag = false;
          var flag = false;
          if (start > endHour) {
            skipFlag = true;
            if (time > start) {
              flag = true;
            }
          }
          if (skipFlag && time < start)
            continue;

          if (time === start) {
            flag = true;
          }
          if (time >= start && time <= endHour) {
            flag = true;
          }

          if (flag)
            return flag;
        }
        for (let index = 0; index < this.day.Thursday.length; index++) {
          const start = this.day.Thursday[index].startAt
          const endHour = parseFloat(this.day.Thursday[index].endAt.split(':')[0]);
          const minutes = parseFloat(this.day.Thursday[index].endAt.split(':')[1]);
          var overDay = false;
          var flag = false;
          if (start > endHour) {
            overDay = true;
          }
          if (overDay) {
            if (time <= endHour) {
              flag = true;
            }
            if (time === endHour - 1 && minutes != 0) {
              flag = true;
            }
          }

          if (flag)
            return flag;
        }
        return false;
      }

      case 6: {
        for (let index = 0; index < this.day.Saturday.length; index++) {
          const start = this.day.Saturday[index].startAt
          const endHour = parseFloat(this.day.Saturday[index].endAt.split(':')[0]);
          const minutes = parseFloat(this.day.Saturday[index].endAt.split(':')[1]);
          var skipFlag = false;
          var flag = false;
          if (start > endHour) {
            skipFlag = true;
            if (time > start) {
              flag = true;
            }
          }
          if (skipFlag && time < start)
            continue;

          if (time === start) {
            flag = true;
          }
          if (time >= start && time <= endHour) {
            flag = true;
          }

          if (flag)
            return flag;
        }
        for (let index = 0; index < this.day.Friday.length; index++) {
          const start = this.day.Friday[index].startAt
          const endHour = parseFloat(this.day.Friday[index].endAt.split(':')[0]);
          const minutes = parseFloat(this.day.Friday[index].endAt.split(':')[1]);
          var overDay = false;
          var flag = false;
          if (start > endHour) {
            overDay = true;
          }
          if (overDay) {
            if (time <= endHour) {
              flag = true;
            }
            if (time === endHour - 1 && minutes != 0) {
              flag = true;
            }
          }

          if (flag)
            return flag;
        }
        return false;
      }

      case 7: {
        for (let index = 0; index < this.day.Sunday.length; index++) {
          const start = this.day.Sunday[index].startAt
          const endHour = parseFloat(this.day.Sunday[index].endAt.split(':')[0]);
          const minutes = parseFloat(this.day.Sunday[index].endAt.split(':')[1]);
          var skipFlag = false;
          var flag = false;
          if (start > endHour) {
            skipFlag = true;
            if (time > start) {
              flag = true;
            }
          }
          if (skipFlag && time < start)
            continue;

          if (time === start) {
            flag = true;
          }
          if (time >= start && time <= endHour) {
            flag = true;
          }

          if (flag)
            return flag;
        }
        for (let index = 0; index < this.day.Saturday.length; index++) {
          const start = this.day.Saturday[index].startAt
          const endHour = parseFloat(this.day.Saturday[index].endAt.split(':')[0]);
          const minutes = parseFloat(this.day.Saturday[index].endAt.split(':')[1]);
          var overDay = false;
          var flag = false;
          if (start > endHour) {
            overDay = true;
          }
          if (overDay) {
            if (time <= endHour) {
              flag = true;
            }
            if (time === endHour - 1 && minutes != 0) {
              flag = true;
            }
          }

          if (flag)
            return flag;
        }
        return false;
      }
      default: return false;
    }

  }

  schedule(i){
    switch (i) {
      case 0: return this.day.Monday;
      case 1: return this.day.Tuesday
      case 2: return this.day.Wednesday
      case 3: return this.day.Thursday
      case 4: return this.day.Friday
      case 5: return this.day.Saturday
      case 6: return this.day.Sunday
    }
  }
  remove(scheduleIndex, day, routeInfo){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.height = 'auto';
      dialogConfig.width = '400px';
      const position = {
        top: '5%',
      };
      dialogConfig.position = position;
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        message: 'Remove this schedule? ' + routeInfo.routeName + '\n' + "Start At: " + routeInfo.startAt + " - " + routeInfo.endAt,
      }

      this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
        if (result) {
          switch (day) {
            case 0:
              this.day.Monday.splice(scheduleIndex,1);
              break;
            case 1:
              this.day.Tuesday.splice(scheduleIndex,1);
              break;
            case 2:
              this.day.Wednesday.splice(scheduleIndex,1);
              break;
            case 3:
              this.day.Thursday.splice(scheduleIndex,1);
              break;
            case 4:
              this.day.Friday.splice(scheduleIndex,1);
              break;
            case 5:
              this.day.Saturday.splice(scheduleIndex,1);
              break;
            case 6:
              this.day.Sunday.splice(scheduleIndex,1);
              break;
          }

        }
      });

  }

  addSchedule(day){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '600px';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.open(VehicleRouteSelectComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {


        const info = {
          routeName: result.route.Name,
          routeID: result.route.id,
          startAt: result.timestart,
          endAt: result.timeend,
        }

        // this.checkCrushSchedule(info,day);
        switch (day) {
          case 0: this.day.Monday.push(info); break;
          case 1: this.day.Tuesday.push(info); break;
          case 2: this.day.Wednesday.push(info); break;
          case 3: this.day.Thursday.push(info); break;
          case 4: this.day.Friday.push(info); break;
          case 5: this.day.Saturday.push(info); break;
          case 6: this.day.Sunday.push(info); break;
        }

      }
    });
  }

  // checkCrushSchedule(info,day){
  //   const hrs = parseInt(info.startAt.split(':')[0]);
  //   const min = parseInt(info.startAt.split(':')[1]);

  //   const hrs1 = parseInt(info.endAt.split(':')[0]);
  //   const min1 = parseInt(info.endAt.split(':')[1]);

  //   switch (day) {
  //     case 0:

  //       for (let index = 0; index < this.day.Monday.length; index++) {
  //         const ehrs = parseInt(this.day.Monday[0].startAt.split(':')[0]);
  //         const emin = parseInt(this.day.Monday[0].startAt.split(':')[1]);

  //         const ehrs1 = parseInt(this.day.Monday[0].endAt.split(':')[0]);
  //         const emin1 = parseInt(this.day.Monday[0].endAt.split(':')[1]);

  //         if(hrs == ehrs && min >= emin){
  //           return false;
  //         }
  //         else if ( (hrs >= ehrs && hrs <=ehrs1 ) && min >= emin && )

  //       }




  //     case 1: this.day.Tuesday.push(info); break;
  //     case 2: this.day.Wednesday.push(info); break;
  //     case 3: this.day.Thursday.push(info); break;
  //     case 4: this.day.Friday.push(info); break;
  //     case 5: this.day.Saturday.push(info); break;
  //     case 6: this.day.Sunday.push(info); break;
  //   }
  // }
}
