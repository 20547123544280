import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './../Services/auth.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  hidePassword = true;
  hidePasswordR = true;
  hideConfirmPasswordR = true;


  loginModel: any = {};
  registerModel: any = {};
  flag = true;
  validationsMessages = ' ';
  today = Date.now();
  breakpoint: boolean;

  constructor(
    private router: Router,
    private toast: ToastrService,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private authService: AuthService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {

    this.breakpoint = (window.innerWidth <= 800) ? true : false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    this.breakpoint = (window.innerWidth <= 800) ? true : false;
  }

  changeForm() {
    this.flag = !this.flag;
  }

  login() {
    this.spinner.show();
    const email = this.loginModel.email;
    const password = this.loginModel.password;
    this.angularFireAuth.auth.signInWithEmailAndPassword(email, password)
      .then(async res => {
        const snapshot = await this.firestore.collection('WebUsers').doc(res.user.uid).get();
        snapshot.forEach(e => {

          if (e.exists) {
            this.authService.SetRole(e.data().Role);
            this.authService.SetEmail(e.data().Email);
            if (e.data().Role === 'User'){
              if(e.data().Depoh){
                const depo = [];
                e.data().Depoh.forEach(de=>{

                  this.firestore.collection('depohs').doc(de).get().forEach(q=>{
                    if(q.exists){
                      depo.push(q.data().Name);
                      this.authService.SetDepo(depo);
                    }
                  });
                })
              }
            }

            this.toast.success('Welcome to I-Collect System', 'Login Successfully !', { timeOut: 3000 });
            this.router.navigate(['Home/Client']);
          } else {
            this.validationsMessages = 'Invalid email or password.';

          }
        })
      })
      .catch(err => {
        this.validationsMessages = 'Invalid email or password.';
      }).finally(() => {
        this.spinner.hide();

      });
  }

}
