import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements AfterViewInit {

  title: string = '';
  charttitle: string;
  total: number = 0;
  lineChart: Chart;
  data = []
  t: string = '';

  stringMONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  type: string;
  constructor(
    private dialogRef: MatDialogRef<ChartComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.data = data.data;
    this.charttitle = data.charttitle
    this.title = data.month
    this.type = data.type;

  }

  daysInMonth = (year, month) => new Date(year, month+1, 0).getDate();

  setupChart() {
    let substring = this.title.substring(0, 3)
    let month = 0;
    let year = this.title.substring(3, 7);
    this.t = substring + ' ' + year;

    let days = 0;
    let dayslabel = [];
    let dataList = []

    switch (substring) {
      case 'Jan':
        month = 0;
        days = this.daysInMonth(year, month)

        break;
      case 'Feb':
        month = 1;
        days = this.daysInMonth(year, month)
        break;
      case 'Mar':
        month = 2;
        days = this.daysInMonth(year, month)
        break;
      case 'Apr':
        month = 3;
        days = this.daysInMonth(year, month)
        break;
      case 'May':
        month = 4;
        days = this.daysInMonth(year, month)
        break;
      case 'Jun':
        month = 5;
        days = this.daysInMonth(year, month)
        break;
      case 'Jul':
        month = 6;
        days = this.daysInMonth(year, month)
        break;
      case 'Aug':
        month = 7;
        days = this.daysInMonth(year, month)
        break;
      case 'Sep':
        month = 8;
        days = this.daysInMonth(year, month)
        break;
      case 'Oct':
        month = 9;
        days = this.daysInMonth(year, month)
        break;
      case 'Nov':
        month = 10;
        days = this.daysInMonth(year, month)
        break;
      case 'Dec':
        month = 11;
        days = this.daysInMonth(year, month)
        break;
    }

    for (let index = 1; index <= days; index++) {
      dayslabel.push(index + '/' + substring + '/' + year)
      dataList.push(0)
    }

    let ind = 0;
    let total = 0;
    for (const info of this.data) {
      switch (info.name.toLowerCase()) {
        case 'top up':
          if(this.type === 'top up'){
            ind = dayslabel.findIndex(e => e === info.date.getDate() + '/' + this.stringMONTHS[info.date.getMonth()] + '/' + info.date.getFullYear());

            if (ind !== -1) {
              dataList[ind] += (parseFloat(info.transaction_amount));
              total += (parseFloat(info.transaction_amount));
            }
          }
          break;
        case 'pengutip sampah':
          if(this.type === 'anggaran'){
            ind = dayslabel.findIndex(e => e === info.date.getDate() + '/' + this.stringMONTHS[info.date.getMonth()] + '/' + info.date.getFullYear());

            if (ind !== -1) {
              dataList[ind] += (parseFloat(info.anggaran));
              total += (parseFloat(info.anggaran));
            }
          }else if(this.type === 'pay'){
            ind = dayslabel.findIndex(e => e === info.date.getDate() + '/' + this.stringMONTHS[info.date.getMonth()] + '/' + info.date.getFullYear());
            console.log(ind)
            if (ind !== -1) {
              dataList[ind] += (parseFloat(info.transaction_amount) * -1);
              total += (parseFloat(info.transaction_amount) * -1);
            }
          }
          break;
      }
    }
    this.total = total;
    this.lineChart.data.labels = dayslabel;
    this.lineChart.data.datasets[0].data = dataList;
    this.lineChart.data.datasets[0].label = this.charttitle;
    this.lineChart.update()
  }

  ngAfterViewInit() {
    this.lineChart = new Chart('dialog', {
      type: 'bar',
      data: {
        labels: [],
        datasets: [{
          label: '',
          data: [],
          fill: false,
          backgroundColor: [
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)',
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)',
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)',
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)',
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)',
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)',
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)',
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 205, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(201, 203, 207, 0.8)'
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)',
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)',
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)',
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)',
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)',
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)',
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)',
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)'
          ],
          tension: 0.1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }

    })

    this.setupChart()
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
