import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-assign-schedule',
  templateUrl: './assign-schedule.component.html',
  styleUrls: ['./assign-schedule.component.css']
})
export class AssignScheduleComponent implements OnInit {

  schedulelist= [];
  key: any;
  selectedJadual: any;
  constructor(  private dialogRef: MatDialogRef<AssignScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,
    private tosterservice: ToastrService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private  dialog : MatDialog,
    ) {
      this.schedulelist=data.list;
      this.key = data.key
    }

  ngOnInit() {
  }


  cancel(){
    this.dialogRef.close();
  }

  confirm(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: "Adakah anda ingin menetapkan jadual ini?"
    };
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result){
        this.spinner.show();
        this.firestore.collection("users").doc(this.key).update({schedule: this.selectedJadual.name, scheduleid: this.selectedJadual.key})
          .then(r=>{
          this.spinner.hide();
          this.toaster.success('Kemaskini berjaya');
          this.dialogRef.close(this.selectedJadual);
        })

      }
    })
  }
  
}
