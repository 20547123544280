import { AuthService } from './../../Services/auth.service';
import { Component, ChangeDetectorRef, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router, NavigationEnd } from '@angular/router';

// 3rd Party Libraries
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AngularFireAuth } from '@angular/fire/auth';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnDestroy {

  expand = false;
  mobileQuery: MediaQueryList;
  today = Date.now();
  navLinks: any[];
  activeLinkIndex = 0;
  myObserver: any;
  showScrollButton: boolean;
  PosToStartShowing = 1;
  noAskCloseTabAgain = false;
  authstate: any;
  depo: any;
  panelOpenState = false;
  accessright = "";
  role: any;
  authser: any;
  icollect = false;
  private mobileQueryListener: () => void;
  done = false;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private toast: ToastrService,
    private auth: AngularFireAuth,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
  ) {
    this.role = localStorage.getItem('Role');
    this.mobileQuery = media.matchMedia('(max-width: 800px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
    this.spinner.show();
    let ser = this.angularFireAuth.authState.subscribe(async authState => {
      if (authState === null) {
        this.toast.warning('Session expired please login again')
        this.router.navigate(['/login']);
      } else {
        const snapshot = await this.firestore.collection('WebUsers').doc(authState.uid).get();
        snapshot.forEach(e => {
          if (e.exists) {
            this.authService.SetRole(e.data().Role);
            this.authService.SetEmail(e.data().Email);
            if (e.data().Role === 'User'){
              if(e.data().Depoh){
                const depo = [];
                e.data().Depoh.forEach(de=>{

                  this.firestore.collection('depohs').doc(de).get().forEach(q=>{
                    if(q.exists){
                      depo.push(q.data().Name);
                      this.authService.SetDepo(depo);
                    }
                  });
                })
              }
            }
          } else {
            this.toast.warning('Invalid user')
            this.router.navigate(['/login']);
          }
        })
        this.done = true;
      }
      this.spinner.hide();
      ser.unsubscribe();

    })


    this.authService.currentRole.subscribe(role => this.role = role);
    this.authService.currentDepo.subscribe(depo => this.depo = depo);

  }


  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }

  sidebar() {
    this.expand = !this.expand;
  }


  logout() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = { message: 'Log out? ' };

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/Login']);
        setTimeout(() => {
          this.angularFireAuth.auth.signOut();
        }, 1000);
      }
    })
  }

  settings() {
    this.router.navigate(['Home/Settings']);
  }

  getActive(value) {
    if (value === this.router.url)
      return true
    return false
  }


}
