import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material';
import { RouterOutlet } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-run-scheduler',
  templateUrl: './run-scheduler.component.html',
  styleUrls: ['./run-scheduler.component.css']
})
export class RunSchedulerComponent implements OnInit {

  scheduler:Date;
  constructor(
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private cd: ChangeDetectorRef) {
      this.scheduler = new Date();
    }

  ngOnInit(): void {
    this.firestore.collection('Devices').get().forEach(async (e)=>{
      await e.forEach(async (table) => {
          this.devicelist.push(table.data());
      });
      this.getRouteInfo();

    })
  }

  devicelist = [];
  run(){
    this.devicelist.forEach(d=>{
      this.scheduler.setHours(0);
      this.scheduler.setMinutes(0);
      this.scheduler.setSeconds(0);

      const today = this.scheduler.getDay();
      const dateto = new Date(this.scheduler)
      const datefrom = new Date(this.scheduler);
      dateto.setHours(23);
      dateto.setMinutes(59);
      dateto.setSeconds(59);


      const hr = datefrom.getHours();
      const min = datefrom.getMinutes();

      var hr1; var min1;
      if(dateto)
       hr1 = datefrom.getHours();
       min1 = datefrom.getMinutes();


      var routeSchedule = [];
      if (d.Day) {
        switch (today) {
          case 0: routeSchedule = d.Day.Sunday; break;
          case 1: routeSchedule = d.Day.Monday; break;
          case 2: routeSchedule = d.Day.Tuesday; break;
          case 3: routeSchedule = d.Day.Wednesday; break;
          case 4: routeSchedule = d.Day.Thursday; break;
          case 5: routeSchedule = d.Day.Friday; break;
          case 6: routeSchedule = d.Day.Saturday; break;
        }
      }

      routeSchedule.forEach(e=>{
        const d1 = new Date(this.scheduler);
        const startHrs = parseInt(e.startAt.split(':')[0]);
        const startMin = parseInt(e.startAt.split(':')[1]);
        d1.setHours(startHrs);
        d1.setMinutes(startMin);
        d1.setSeconds(0);
        const d2 = new Date(d1);
        const endHrs = parseInt(e.endAt.split(':')[0]);
        const endMin = parseInt(e.endAt.split(':')[1]);
        var h = 0;
        var m = 0;
        if(endHrs - startHrs >= 0){
          if(endMin - startMin >= 0){
            h = endHrs - startHrs;
            m = endMin - startMin;
          }else{
            h = endHrs - startHrs;
            if(h > 0)
              h = h - 1;
            m = endMin + 60 - startMin;
          }
        }else{
          h = endHrs+24 - startHrs;
          if(endMin - startMin >=0){
            m = endMin - startMin;
          }else{
            m = endMin + 60 - startMin;
            h = h -1;
          }
        }
        d2.setHours(d1.getHours() + h )
        d2.setMinutes(d1.getMinutes() + m)

        var timestampFrom = d1.getTime() / 1000;
        var timestampTo = d2.getTime() / 1000;
        var index = 1;
        console.log(d.ID)
        console.log(d1)
        console.log(d2)

        this.firestore.collection('Devices').doc(d.ID).collection('Monitor', ref => ref.where('Timestamp', '>=', timestampFrom).where('Timestamp', '<=', timestampTo).orderBy('Timestamp', 'asc')).get().forEach(l => {
          const dateformat = d1.getFullYear() + ("0" + (d1.getMonth()+1).toString()).slice(-2) +  ("0" + (d1.getDate()).toString()).slice(-2)
          this.firestore.collection('Devices').doc(d.ID).collection('History').doc("Trip" +index+"-"+ dateformat).set({
            LastUpdate:new Date().getTime(),
            date:dateformat,
            routeName: e.routeName,
            routeID: e.routeID,
            startAt: e.startAt,
            endAt: e.endAt,
          }).then(add=>{
            l.forEach((location) => {
              this.firestore.collection('Devices').doc(d.ID).collection('History').doc("Trip" +index+"-"+ dateformat).collection('location').add(location.data()).then(e=>{
              });
            });
        index++;

          })



        });

      })


    })
  }

  getRouteInfo() {
    this.devicelist.forEach(device => {
      if (device.Day) {
        device.Day.Monday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Monday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Monday[index].data.regions[i] = value.data();
              })
            });
          })
        });
        device.Day.Tuesday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Tuesday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Tuesday[index].data.regions[i] = value.data();
              })
            });
          })
        });
        device.Day.Wednesday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Wednesday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Wednesday[index].data.regions[i] = value.data();
              })
            });
          })
        });
        device.Day.Thursday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Thursday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Thursday[index].data.regions[i] = value.data();
              })
            });
          })
        });
        device.Day.Friday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Friday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Friday[index].data.regions[i] = value.data();
              })
            });
          })
        });
        device.Day.Saturday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Saturday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Saturday[index].data.regions[i] = value.data();
              })
            });
          })
        });
        device.Day.Sunday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Sunday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Sunday[index].data.regions[i] = value.data();
              })
            });
          })
        });
      }
    })

  }
}
