import { RouteDetailComponent } from './route-detail/route-detail.component';
import { DetailComponent } from './detail/detail.component';
import { CreateRouteComponent } from './create-route/create-route.component';
import { AssignDeviceComponent } from './assign-device/assign-device.component';
import { ConfirmationDialogComponent } from './../../shared/confirmation-dialog/confirmation-dialog.component';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, NgZone } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, PageEvent, Sort } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgxSpinnerService } from 'ngx-spinner';
import * as firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.css']
})
export class AreaComponent implements OnInit, AfterViewInit {

  selectedIndex = 0;
  onFlag = false;
  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  @ViewChild('mapContainer1', { static: false }) gmap1: ElementRef;
  @ViewChild('search', { static: false }) searchElementRef: ElementRef;
  map: google.maps.Map;
  map1: google.maps.Map;
  marker: google.maps.Marker;
  mapOptions: google.maps.MapOptions;
  coordinates: any;
  lat = 4.2105;
  lng = 101.9758;
  listener: any;
  arealist = [];
  pathlist = [];
  savinglist = [];
  markerlist = [];
  pathSavedlist = [];
  role: any;
  path: any;
  word: any;

  tableroutetlist = [];
  sortedtableroutetlist: any;
  lengthroute = 0;
  pageSizeroute = 25;
  pageSizeOptionsroute: number[] = [10, 25, 50, 100];
  pageIndexroute = 0;
  offsetroute = this.pageSizeroute * this.pageIndexroute;
  searchboxroute = '';
  searchValueroute = '';
  sorteduroute = {
    active: '',
    direction: ''
  };

  @ViewChild('topPaginator3', { read: MatPaginator, static: false }) topPaginator3: MatPaginator;
  @ViewChild('bottomPaginator3', { read: MatPaginator, static: false }) bottomPaginator3: MatPaginator;


  constructor(private dialog: MatDialog,
    private firestore: AngularFirestore,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private ngZone: NgZone) {
    this.role = localStorage.getItem('Role');
    this.word = 'Region';
  }

  ngAfterViewInit(): void {
    this.coordinates = new google.maps.LatLng(this.lat, this.lng);
    this.mapOptions = {
      center: this.coordinates,
      zoom: 15
    };
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.map1 = new google.maps.Map(this.gmap1.nativeElement, this.mapOptions);

    const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        // get the place result
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();

        // verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        this.map.panTo(place.geometry.location);
        this.map.setZoom(18);

      });
    });

  }

  ngOnInit() {
    this.spinner.show();
    this.setup();
    this.initRoute();
  }

  routelist = [];
  cloneRoute = [];
  initRoute(){
    this.spinner.show();
    this.routelist = [];
    this.firestore.collection('Route').get().forEach(route=>{
      route.forEach(e=>{
        this.routelist.push(e.data());
      })
      this.routelist.sort((a,b)=>{
        return this.compareDay(new Date(a.UpdatedDate), new Date(b.UpdatedDate), false)
      })
      this.cloneRoute = this.routelist.slice();
      this.spinner.hide();
    })
  }
  async setup() {
    this.markerlist = [];
    this.pathSavedlist = [];
    this.firestore.collection('Region').get().forEach(routes => {
      let counter = 1;
      if (routes.size === 0) {
        this.spinner.hide();
      }
      routes.forEach(streetdoc => {
        const m = { ...streetdoc.data() }
        this.tableroutetlist.push(m);
        this.sortedtableroutetlist = this.tableroutetlist.slice(this.offsetroute, (this.offsetroute + this.pageSizeroute));
        this.lengthroute = this.tableroutetlist.length;
        const marker = new google.maps.Marker({
          ...m,
          position: new google.maps.LatLng(streetdoc.data().centerCoordinate.latitude,
            streetdoc.data().centerCoordinate.longitude),
          draggable: true,

        });
        marker.setMap(this.map);
        const infoWindow = new google.maps.InfoWindow({
          content: '<div class="text-center"><span> ' + streetdoc.data().RouteName + '</span><br>' +
            '<button class="btn btn-danger p-1 w-100" style="font-size:12px" id=window' + streetdoc.data().id + '>Remove </button></div>'
        });
        google.maps.event.addListener(infoWindow, 'domready', () => {
          document.getElementById('window' + streetdoc.id).addEventListener('click', () => {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.height = 'auto';
            dialogConfig.width = 'auto';
            const position = {
              top: '5%',
            };
            dialogConfig.position = position;
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;

            dialogConfig.data = {
              message: 'Do you want to remove: ' + streetdoc.data().RouteName
            };
            this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
              if (result) {
                    const ee = this.routelist.find(a=>a.regions.find(d=>d===streetdoc.data().id));
                    if(ee.mark === streetdoc.data().id)
                    {
                      this.toaster.warning('Cant delete due it is selected as disposal area in one of the route');
                      return;
                    }


                this.firestore
                  .collection('Region')
                  .doc(streetdoc.id)
                  .delete().then(e=>{

                    const r = this.markerlist.findIndex(m => m.id === streetdoc.data().id);
                    this.markerlist[r].setMap(null);
                    this.pathSavedlist[r].setMap(null);
                    const rou = this.routelist.find(a=>a.regions.find(d=>d===streetdoc.data().id));

                    if(rou){
                      rou.regions = rou.regions.filter(a=>a.regions === streetdoc.data().id);
                      this.firestore.collection('Route').doc(rou.id).update(rou);
                    }
                    this.tableroutetlist = this.tableroutetlist.filter(r => r.id !== streetdoc.data().id);
                    this.sortedtableroutetlist = this.tableroutetlist.slice(this.offsetroute, (this.offsetroute + this.pageSizeroute));

                    this.lengthroute = this.tableroutetlist.length;
                  });

              }

            });

          });
        })
        marker.addListener('click', () => {
          this.map.setZoom(18);
          this.map.setCenter(marker.getPosition());
          infoWindow.open(marker.getMap(), marker);
        });
        // google.maps.event.trigger(marker,'click');

        marker.addListener('dragend', (e) => {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.height = 'auto';
          dialogConfig.width = 'auto';
          const position = {
            top: '5%',
          };
          dialogConfig.position = position;
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;

          dialogConfig.data = {
            message: 'Move here? ' + streetdoc.data().RouteName
          };
          this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
            if (result) {
              this.firestore.collection('Region').doc(streetdoc.id)
                .update({ centerCoordinate: new firebase.firestore.GeoPoint(e.latLng.lat(), e.latLng.lng()) })
            }
            else {
              const r = this.markerlist.findIndex(m => m.id === streetdoc.id);
              this.markerlist[r].setPosition(new google.maps.LatLng(streetdoc.data().centerCoordinate.latitude,
                streetdoc.data().centerCoordinate.longitude));
            }
          })


        });
        this.map.panTo(marker.getPosition());
        this.markerlist.push(marker);
        const paths = [];
        streetdoc.data().coordinates.forEach(coor => {
          paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
        });
        const path = new google.maps.Polyline({
          path: paths,
          strokeColor: 'blue',
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });
        path.setMap(this.map);
        this.pathSavedlist.push(path);
        if (counter === routes.size) {
          this.spinner.hide();
        }
        counter++;

      });
    });


  }

  on() {
    this.onFlag = true;
    this.listener = google.maps.event.addListener(this.map, 'click', event => {
      this.pathlist.push(event.latLng);
      this.savinglist.push(new firebase.firestore.GeoPoint(event.latLng.lat(), event.latLng.lng()));
      if (this.path) {
        this.path.setMap(null);
      }
      this.path = new google.maps.Polyline({
        path: this.pathlist,
        strokeColor: 'blue',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      this.path.setMap(this.map);
    });

  }

  off() {
    this.onFlag = false;
    google.maps.event.removeListener(this.listener);
  }

  undo() {
    this.pathlist.pop();
    this.savinglist.pop();
    if (this.path) {
      this.path.setMap(null);
    }
    this.path = new google.maps.Polyline({
      path: this.pathlist,
      strokeColor: 'blue',
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    this.path.setMap(this.map);
  }

  clear() {
    this.pathlist = [];
    this.savinglist = [];
    if (this.path) {
      this.path.setMap(null);
    }
    this.path = new google.maps.Polyline({
      path: this.pathlist,
      strokeColor: 'blue',
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    this.path.setMap(this.map);
  }

  saveStreet() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    let message = '';
    if (this.role === 'PPS') {
      message = 'Add new region?';
      dialogConfig.data = {
        route: true,
        message
      };
    }
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();

        let totallat = 0;
        let totallng = 0;
        this.pathlist.forEach((path, index) => {
          totallat += path.lat();
          totallng += path.lng();
          if (index === this.pathlist.length - 1) {
            totallat = totallat / this.pathlist.length;
            totallng = totallng / this.pathlist.length;
            const data = {
              coordinates: this.savinglist,
              RouteName: result.route,
              timeSetting: result.time,
              centerCoordinate: new firebase.firestore.GeoPoint(totallat, totallng),
              id: uuidv4()
            };

            this.tableroutetlist.push(data);
            this.sortedtableroutetlist = this.tableroutetlist.slice(this.offsetroute, (this.offsetroute + this.pageSizeroute));
            this.lengthroute = this.tableroutetlist.length;
            const marker = new google.maps.Marker({
              ...data,
              position: new google.maps.LatLng(totallat, totallng),
              draggable: true

            });
            marker.setMap(this.map);

            const infoWindow = new google.maps.InfoWindow({
              content: '<div class="text-center"><span>' + result.route + '</span><br>' +
                '<button class="btn btn-danger p-1 w-100" style="font-size:12px" id=window' + data.id + '>Remove </button></div>'

            });

            google.maps.event.addListener(infoWindow, 'domready', () => {
              document.getElementById('window' + data.id).addEventListener('click', () => {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.height = 'auto';
                dialogConfig.width = 'auto';
                const position = {
                  top: '5%',
                };
                dialogConfig.position = position;
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;

                dialogConfig.data = {
                  message: 'Do you want to remove: ' + data.RouteName
                };
                this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
                  if (result) {    const ee = this.routelist.find(a=>a.regions.find(d=>d===data.id));
                    if(ee.mark === data.id)
                    {
                      this.toaster.warning('Cant delete due it is selected as disposal area in one of the route');
                      return;
                    }


                    this.firestore
                      .collection('Region')
                      .doc(data.id)
                      .delete().then(e=>{
                        const r = this.markerlist.findIndex(m => m.id === data.id);
                        this.markerlist[r].setMap(null);
                        this.pathSavedlist[r].setMap(null);
                        this.tableroutetlist = this.tableroutetlist.filter(t => t.id !== data.id);
                        this.sortedtableroutetlist = this.tableroutetlist.slice(this.offsetroute, (this.offsetroute + this.pageSizeroute));
                        this.lengthroute = this.tableroutetlist.length;
                        const rou = this.routelist.find(a=>a.regions.find(d=>d===data.id));
                        console.log(rou);
                        if(rou){
                          rou.regions = rou.regions.filter(a=>a.regions ===data.id);
                          this.firestore.collection('Route').doc(rou.id).update(rou);
                        }
                      });

                  }
                });

              });
            })
            marker.addListener('click', () => {
              this.map.setZoom(19);
              this.map.setCenter(marker.getPosition());
              infoWindow.open(marker.getMap(), marker);
            });

            // google.maps.event.trigger(marker,'click');

            marker.addListener('dragend', (e) => {
              const dialogConfig = new MatDialogConfig();
              dialogConfig.height = 'auto';
              dialogConfig.width = 'auto';
              const position = {
                top: '5%',
              };
              dialogConfig.position = position;
              dialogConfig.disableClose = true;
              dialogConfig.autoFocus = true;

              dialogConfig.data = {
                message: 'Move here? ' + data.RouteName
              };
              this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
                if (result) {
                  this.firestore.collection('Region').doc(data.id)
                    .update({ centerCoordinate: new firebase.firestore.GeoPoint(e.latLng.lat(), e.latLng.lng()) })
                }
                else {
                  const r = this.markerlist.findIndex(m => m.id === data.id);
                  this.markerlist[r].setPosition(new google.maps.LatLng(data.centerCoordinate.latitude,
                    data.centerCoordinate.longitude));
                }
              })


            });

            this.markerlist.push(marker);


            this.firestore.collection('Region').doc(data.id).set(data).then(result => {
              this.pathSavedlist.push(this.path);
              this.clear();
              this.redraw();
              this.spinner.hide();
            });
          }
        });


      }
    });
  }

  redraw() {
    this.pathSavedlist[this.pathSavedlist.length - 1].setMap(this.map);
  }




  compare(a: number | string, b: number | string, isAsc: boolean) {

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareDay(a, b, isAsc) {
    return ((a === b) ? 0 : a ? -1 : 1) * (isAsc ? 1 : -1);
  }


  getCheck(street, day) {
    if (street.dayAvailables.find(i => i === day)) {
      return true;
    } else {
      return false;
    }
  }

  SaveSchedule(street) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: 'Save this schedule for this street: ' + street.streetName
    };
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        const dayavailable = [];
        if (street.mon) {
          dayavailable.push(1);
        }
        if (street.tue) {
          dayavailable.push(2);
        }
        if (street.wed) {
          dayavailable.push(3);
        }
        if (street.thu) {
          dayavailable.push(4);
        }
        if (street.fri) {
          dayavailable.push(5);
        }
        if (street.sat) {
          dayavailable.push(6);
        }
        if (street.sun) {
          dayavailable.push(7);
        }
        street.save = '';
        this.firestore.collection('streets').doc(street.id)
          .update({ dayAvailables: dayavailable }).then(s => {
            street.dayAvailables = dayavailable;
            this.toaster.success('Save sucessfully', street.streetName);
          });
      }
    });
  }

  changeEvent(event, street) {
    street.save = "<-Detect change";
  }

  paginator3(pageEvent: PageEvent) {
    this.pageSizeroute = pageEvent.pageSize;
    this.pageIndexroute = pageEvent.pageIndex;
    this.offsetroute = this.pageSizeroute * this.pageIndexroute;
    if (this.topPaginator3.pageIndex < this.pageIndexroute) {
      this.topPaginator3.nextPage();
    } else if (this.topPaginator3.pageIndex > this.pageIndexroute) {
      this.topPaginator3.previousPage();
    }
    if (this.bottomPaginator3.pageIndex < this.pageIndexroute) {
      this.bottomPaginator3.nextPage();
    } else if (this.bottomPaginator3.pageIndex > this.pageIndexroute) {
      this.bottomPaginator3.previousPage();
    }
    this.sortedtableroutetlist = this.tableroutetlist.slice();
    if (this.searchValueroute) {
      this.DS3();
    }
    this.sortRoute();
    this.limitRoute();
  }

  limitRoute() {
    this.sortedtableroutetlist = this.sortedtableroutetlist.slice(this.offsetroute, (this.offsetroute + this.pageSizeroute));
  }

  sortData3(sort: Sort) {
    this.sorteduroute = sort;
    this.sortedtableroutetlist = this.tableroutetlist.slice();
    if (this.searchValueroute) {
      this.DS3();
    }
    if (!sort.active || sort.direction === '' && !this.searchValueroute) {
      this.sortedtableroutetlist = this.tableroutetlist.slice();
      this.limitRoute();
      return;
    }

    this.sortedtableroutetlist = this.sortedtableroutetlist.sort((a, b) => {
      const isAsc = this.sorteduroute.direction === 'asc';
      switch (this.sorteduroute.active) {
        case 'name': return this.compare(a.RouteName, b.RouteName, isAsc);
        case 'device': return this.compare(a.belongDevice, b.belongDevice, isAsc);
        default: return 0;
      }
    });
    this.limitRoute();
  }

  sortRoute() {
    if (!this.sorteduroute.active || this.sorteduroute.direction === '') {
      return;
    }
    this.sortedtableroutetlist = this.sortedtableroutetlist.sort((a, b) => {
      const isAsc = this.sorteduroute.direction === 'asc';
      switch (this.sorteduroute.active) {
        case 'name': return this.compare(a.RouteName, b.RouteName, isAsc);
        case 'device': return this.compare(a.belongDevice, b.belongDevice, isAsc);
        default: return 0;
      }
    });
  }
  dynamicSearch3() {
    this.searchValueroute = this.searchboxroute;
    this.sortedtableroutetlist = this.tableroutetlist.filter(u =>
      String(u.RouteName).toLowerCase().includes(this.searchValueroute.toLowerCase()));
    this.lengthroute = this.sortedtableroutetlist.length;
    this.sortRoute();
    this.limitRoute();
  }

  DS3() {
    this.sortedtableroutetlist = this.tableroutetlist.filter(u =>
      String(u.RouteName).toLowerCase().includes(this.searchValueroute.toLowerCase()));
    this.lengthroute = this.sortedtableroutetlist.length;
    this.sortRoute();
  }


  AssignRoute(route) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '70%';

    dialogConfig.disableClose = true;

    dialogConfig.data = route;
    this.dialog.open(AssignDeviceComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        route.belongRoute = result;
      }
    })
  }

  viewRoute(id, tab) {
    tab.selectedIndex = 0;
    const result = this.markerlist.find(m => m.id === id);
    this.map.panTo(result.position);
    this.map.setZoom(18);
  }

  viewStreet(id, tab) {
    tab.selectedIndex = 0;
    const result = this.markerlist.find(m => m.id === id);
    this.map.panTo(result.position);
    this.map.setZoom(18);
  }


  removeRoute(route){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: 'Do you want to remove: ' + route.RouteName
    };
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.firestore
          .collection('Region')
          .doc(route.id)
          .delete();
        const r = this.markerlist.findIndex(m => m.id === route.id);
        this.markerlist[r].setMap(null);
        this.pathSavedlist[r].setMap(null);
        this.tableroutetlist = this.tableroutetlist.filter(r => r.id !== route.id);
        this.sortedtableroutetlist = this.tableroutetlist.slice(this.offsetroute, (this.offsetroute + this.pageSizeroute));

        this.lengthroute = this.tableroutetlist.length;
      }

    });
  }
  viewDetail(region){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data =region
    this.dialog.open(DetailComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {

        this.tableroutetlist = [];
        this.remove(this.pathSavedlist);
        this.remove(this.markerlist);
        this.setup()

      }
    });
  }

  remove(path) {
    path.forEach(element => {
      element.setMap(null);
    });
  }

  searchfilter:string;
  applyFilter(value){
    this.searchfilter = value;
    this.routelist = this.cloneRoute.slice();
    this.routelist = this.filterItems(this.cloneRoute, value);

  }
  filterItems = (arr, query) => {
    return arr.filter(el => el.Name.toLowerCase().indexOf(query.toLowerCase()) > -1);
  }

  ViewRouteDetail(route){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = '90%';
    dialogConfig.width = '95vw';
    dialogConfig.maxWidth = '95vw';

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      region: this.tableroutetlist,
      route: route,

    }
    this.dialog.open(RouteDetailComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.initRoute();
      }
    })
  }

  createRoute(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = '90%';
    dialogConfig.width = '95vw';
    dialogConfig.maxWidth = '95vw';

    dialogConfig.disableClose = true;
    dialogConfig.data = this.tableroutetlist;

    this.dialog.open(CreateRouteComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.initRoute();
      }
    })
  }

  sortData(sort: Sort) {
    this.routelist = this.cloneRoute.slice();
    if (this.searchfilter) {
      this.applyFilter(this.searchfilter)
    }
    if (!sort.active || sort.direction === '' && !this.searchfilter) {
      this.routelist = this.cloneRoute.slice();
      return;
    }

    this.routelist = this.routelist.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a.Name, b.Name, isAsc);
        default: return 0;
      }
    });
    this.limitRoute();
  }

  markerlistRoute = [];
  pathlistRoute = [];
  selectedRoute : string;
  viewRouteInMap(route){
    this.selectedRoute = route.Name;
    this.remove(this.markerlistRoute);
    this.remove(this.pathlistRoute);

    route.regions.forEach(region => {
      const regionData = this.tableroutetlist.find(a=>a.id === region);
      if(!regionData)
        this.toaster.error('Cant find the region');
      else{
        const marker = new google.maps.Marker({
          ...regionData,
          position: new google.maps.LatLng(regionData.centerCoordinate.latitude,
            regionData.centerCoordinate.longitude),
        });
        marker.setMap(this.map1);
        const infoWindow = new google.maps.InfoWindow({
          content: '<div class="text-center"><span> ' + regionData.RouteName + ' ('+regionData.timeSetting+') </span>'
        });
        marker.addListener('click', () => {
          this.map1.setZoom(19);
          this.map1.setCenter(marker.getPosition());
          infoWindow.open(marker.getMap(), marker);

        });
        // google.maps.event.trigger(marker,'click');
        this.map1.panTo(marker.getPosition());
        this.markerlistRoute.push(marker);
        const paths = [];
        regionData.coordinates.forEach(coor => {
          paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
        });
        const path = new google.maps.Polyline({
          path: paths,
          strokeColor: 'blue',
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });
        path.setMap(this.map1);
        this.pathlistRoute.push(path);

      }

    });
  }

  deleteRoute(route){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: 'Do you want to remove: ' + route.Name
    };
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.firestore
          .collection('Route')
          .doc(route.id)
          .update({'Deactived':true}).then(e=>{
            this.toaster.success('Deleted Successful')
            if(this.selectedRoute === route.Name){
              this.remove(this.pathlistRoute);
              this.remove(this.markerlistRoute);
              this.selectedRoute = '';
            }

            this.firestore.collection('Devices').get().forEach(d=>{
              d.forEach(device=>{
               const devicesData = device.data();
               if(devicesData.Day){
                devicesData.Day.Monday.forEach((element,index,object) => {
                  if(element.routeID === route.id){
                    object.splice(index,1);
                  }
                });
                devicesData.Day.Tuesday.forEach((element,index,object) => {
                  if(element.routeID === route.id){
                    object.splice(index,1);
                  }
                });

                devicesData.Day.Wednesday.forEach((element,index,object) => {
                  if(element.routeID === route.id){
                    object.splice(index,1);
                  }
                });

                devicesData.Day.Thursday.forEach((element,index,object) => {
                  if(element.routeID === route.id){
                    object.splice(index,1);
                  }
                });

                devicesData.Day.Friday.forEach((element,index,object) => {
                  if(element.routeID === route.id){
                    object.splice(index,1);
                  }
                });

                devicesData.Day.Saturday.forEach((element,index,object) => {
                  if(element.routeID === route.id){
                    object.splice(index,1);
                  }
                });

                devicesData.Day.Sunday.forEach((element,index,object) => {
                  if(element.routeID === route.id){
                    object.splice(index,1);
                  }
                });
               this.firestore.collection('Devices').doc(device.id).update(devicesData);
               }

              })
            })

            this.initRoute();
          });



      }

    });
  }



}
