import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MatPaginator, MAT_DIALOG_DATA, PageEvent, Sort, MatDialogConfig, MatDialog } from '@angular/material';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import jsPDF from 'jspdf';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { ExcelHelperService } from '../../Services/excel-helper.service';
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  qr: string = "";
  qr660: string = "";
  binlist: any = [];
  sortedbinlist: any = []
  data: any;
  url = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  title: string;  fromDateRekod: Date;
  toDateRekod: Date;
  type;
  constructor(private dialogRef: MatDialogRef<HistoryComponent>,
    private  dialog : MatDialog,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private firestore: AngularFirestore,
    private excelHelper: ExcelHelperService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.type = data.type;
    this.title = data.name
    this.data = data;
    this.binlist = data.eventDtls
    if(this.type)
      this.binlist = this.binlist.filter(e=>e.name === this.type);
    this.sortedbinlist = this.binlist.slice();

    this.fromDateRekod = new Date(new Date().setMonth(new Date().getMonth() - 3));
    this.toDateRekod = new Date();
    this.regrab();
  }

  detectChanges3(event) {
    this.fromDateRekod = event;
  }

  detectChanges4(event) {
    this.toDateRekod = event;
  }


  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  sortedu = {
    active: '',
    direction: ''
  };

  bin = {
    address: '',
    bin_size: '',
    id: '',
    last_scan: '',
    license_num: '',
    location: '',
  }
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    this.regrab();
  }

  limitbin() {
    this.sortedbinlist = this.sortedbinlist.slice(this.offset, (this.offset + this.pageSize));
  }

  
  regrab() {
    const from = new Date(this.fromDateRekod.setHours(0, 0, 0, 0))
    const to = new Date(this.toDateRekod.setHours(23, 59, 59, 999))
    this.sortedbinlist = this.binlist.filter(u =>
      u.timestamp.getTime() >= from.getTime() && u.timestamp.getTime() <= to.getTime()
    );

    this.length = this.sortedbinlist.length;
    this.sortbin();
    this.limitbin();
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.sortedbinlist = this.binlist.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedbinlist = this.binlist.slice();
      this.limitbin();
      return;
    }

    this.sortedbinlist = this.sortedbinlist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'name': return this.compare(a.name, b.name, isAsc);
        case 'type': return this.compare(a.type, b.type, isAsc);
        case 'transaction_amount': return this.compare(a.transaction_amount, b.transaction_amount, isAsc);
        case 'address': return this.compare(a.address, b.address, isAsc);
        case 'anggaran': return this.compare(a.anggaran, b.anggaran, isAsc);
        case 'timestamp': return this.compareDate(a.timestamp, b.timestamp, isAsc);
        case 'collector_name': return this.compare(a.collector_name, b.collector_name, isAsc);
        default: return 0;
      }
    });
    this.limitbin();
  }


  sortbin() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.sortedbinlist = this.sortedbinlist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'name': return this.compare(a.name, b.name, isAsc);
        case 'type': return this.compare(a.type, b.type, isAsc);
        case 'transaction_amount': return this.compare(a.transaction_amount, b.transaction_amount, isAsc);
        case 'address': return this.compare(a.address, b.address, isAsc);
        case 'anggaran': return this.compare(a.anggaran, b.anggaran, isAsc);
        case 'timestamp': return this.compareDate(a.timestamp, b.timestamp, isAsc);
        case 'collector_name': return this.compare(a.collector_name, b.collector_name, isAsc);
        default: return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compareDate(a, b, isAsc: boolean) {

    a = new Date(a).getTime();
    b = new Date(b).getTime();
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }



  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close();
  }

  exportToExcel() {
    const exportInformation = [];
    this.spinner.show();
    const binlist = this.binlist.slice().sort((a, b) => {
      return (a.name < b.name ? -1 : 1) * (true ? 1 : -1);
    }
    );
    for (const raw of binlist) {
      const info = {
        "Jenis": raw.name === 'Top Up'? 'Tambah Nilai': (raw.name || "-"),
        "Saiz Tong": raw.type == 'null'? '-': (raw.type || "-"),
        "Lokasi": raw.address || "-",
        "Kadar Bayaran(RM)": raw.name === 'Top Up'?raw.transaction_amount*-1:raw.transaction_amoun  || 0,
        "Berat Kutipan(kg)": raw.anggaran || '-',
        "Staf Kutipan": raw.collector_name || '-',
        "Tarikh": raw.timestamp || '-',

      }
      exportInformation.push(info)
    }

    this.excelHelper.exportAsExcelFile(exportInformation, 'Rekod' + new Date().getTime());
    this.spinner.hide();
  }

  
}
