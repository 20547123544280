import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, mixinColor } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-add-route',
  templateUrl: './add-route.component.html',
  styleUrls: ['./add-route.component.css']
})
export class AddRouteComponent implements AfterViewInit {
  map: google.maps.Map;
  marker: google.maps.Marker;
  mapOptions: google.maps.MapOptions;
  @ViewChild('mapContainer1', { static: false }) gmap: ElementRef;
  coordinates: any;
  lat = 5.4141;
  lng = 100.3288;

  constructor(
    private dialogRef: MatDialogRef<AddRouteComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,

  ) { }

  cancel() {
    this.dialogRef.close(false);
  }

  ngAfterViewInit(): void {
    this.coordinates = new google.maps.LatLng(this.lat, this.lng);

    this.mapOptions = {
      center: this.coordinates,
      zoom: 15
    };
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.firestore.collection('Streets').get().forEach(element => {
      element.forEach(e => {
        const m = new google.maps.Marker({
          position: new google.maps.LatLng(e.data().path[0].latitude, e.data().path[0].longitude),
          map: this.map,
        });
        m.setMap(this.map);

        const infoWindow = new google.maps.InfoWindow({
          content: '<span>Street Name: ' + e.data().name + '</span>'
            });
        m.addListener('click', () => {
          this.map.setZoom(18);
          this.map.setCenter(m.getPosition());
          infoWindow.open(m.getMap(), m);
        });
        for (var i = 0; i < e.data().path.length - 1; i++) {
          var PathStyle = new google.maps.Polyline({
            path: [new google.maps.LatLng(e.data().path[i].latitude, e.data().path[i].longitude),
            new google.maps.LatLng(e.data().path[i + 1].latitude, e.data().path[i + 1].longitude)],
            strokeColor: "Blue",
            strokeOpacity: 1.0,
            strokeWeight: 2,
          });

          PathStyle.setMap(this.map);
        }
      })
    })

  }


}
