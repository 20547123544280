import { RouteInfoComponent } from './route-info/route-info.component';
import { RouteSelectComponent } from './../../shared/route-select/route-select.component';
import { element } from 'protractor';
import { TripSelectComponent } from './../../shared/trip-select/trip-select.component';
import { ToastrService } from 'ngx-toastr';
import { DateService } from './../Services/date.service';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent, Sort, MatDialogConfig, MatDialog } from '@angular/material';
import { FirestoreServiceService } from '../Services/firestore-service.service';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { AddRouteComponent } from './add-route/add-route.component';
import { DeviceInfoModalComponent } from 'src/app/shared/device-info-modal/device-info-modal.component';
import { AngularFireStorageReference, AngularFireStorage } from '@angular/fire/storage';
import Chart from 'chart.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { MonitorDatePickerComponent } from 'src/app/shared/monitor-date-picker/monitor-date-picker.component';
import 'chartjs-plugin-zoom';
import 'hammerjs';
@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.css']
})
export class MonitorComponent implements AfterViewInit, OnInit {

  dateFrom: Date;
  maxDate: Date;
  dateTo: Date;
  scheduler: Date;
  depoh = "All";
  depohlist = [];
  routeSelected: any;
  currentRegionLog = [];
  disposalArea: any;
  c: any;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private cd: ChangeDetectorRef
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 800px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this.mobileQueryListener);
    this.role = localStorage.getItem('Role');
    if (this.role === 'KPA') {
      this.minimizeFlag = true;
    }
    this.dateFrom = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    this.maxDate = new Date();
    this.dateTo = new Date();
    this.scheduler = new Date();
    this.firestore.collection('Depoh').get().forEach(depoh => {
      depoh.forEach(d => {
        const info = {
          key: d.id,
          ...d.data()
        }
        this.depohlist.push(info)
      })
    })
  }

  changeDepoh(value) {

    this.depoh = value;
    if (this.depoh === 'All')
      this.sorteddevicelist = this.devicelist.slice();
    else {
      this.sorteddevicelist = this.devicelist.filter(d => d.Depoh === this.depoh);
    }
  }

  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  selectedIndex = 1;
  speedGraph: any;
  map: google.maps.Map;
  marker: google.maps.Marker;
  mapOptions: google.maps.MapOptions;
  coordinates: any;
  lat = 4.2105;
  lng = 101.9758;
  isTracking = false;
  mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;
  devicelist = [];
  sorteddevicelist: any;
  sortedzonelist: any;
  zonelist = []
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  searchbox = '';
  searchValue = '';
  sortedu = {
    active: '',
    direction: ''
  };
  markerlist = [];
  linelist = [];
  positionlist = [];
  lorrylist = [];
  imagelist = [];
  counter = 0;
  speed: any;
  role: any;
  devices: any;
  trips = [];
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;
  identifyCounterTrip = 1;
  minimizeFlag = false;
  minimizeGraphFlag = false;
  maplist = [];
  deletecounter = 0;
  receiptlist = [];
  locatelist = [];
  savinglist = [];
  trackingPath: any;
  mode = 'single';
  pathSavedlist = [];

  ngOnInit(): void {
    const geocoder = new google.maps.Geocoder();
    this.SetupTable(geocoder);
    if (this.role === 'KPA') {
      this.GetKPAImage(this.kpaDate);
    }

    this.spinner.show();
  }




  removePath(path) {
    path.forEach(element => {
      element.setMap(null);
    });
  }

  removeMarkers(marker) {
    marker.forEach(element => {
      element.setMap(null);
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }

  ngAfterViewInit(): void {
    const d = new Date();
    d.setHours(0);

    this.coordinates = new google.maps.LatLng(this.lat, this.lng);

    this.mapOptions = {
      center: this.coordinates,
      zoom: 15
    };
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);

  }

  markerLorryList = [];

  async SetupTable(geocoder: google.maps.Geocoder) {
    if (this.role === 'PPS') {
      await this.firestore.collection('Devices', ref => ref.orderBy('ID', 'asc')).get().forEach(async (e) => {
        await e.forEach(async (table) => {
          const r = this.devicelist.find(d => d.ID === table.id);
          if (!r) {
            var marker;
            if (table.data().LorryType === 'Garbage Truck') {
              marker = new google.maps.Marker({
                position: new google.maps.LatLng(table.data().Latitude, table.data().Longitude),
                map: this.map,
                icon: this.garbageUrl,
              })
            } else if (table.data().LorryType === 'Roro Truck') {
              marker = new google.maps.Marker({
                position: new google.maps.LatLng(table.data().Latitude, table.data().Longitude),
                map: this.map,
                icon: this.roroUrl,
              })
            } else {
              marker = new google.maps.Marker({
                position: new google.maps.LatLng(table.data().Latitude, table.data().Longitude),
                map: this.map,
              })
            }
            this.markerLorryList.push(marker);
            marker.setMap(this.map);
            const infoWindow = new google.maps.InfoWindow({
              content:
                '<p>Plate Number: ' + table.data().PlateNumber + '</p>' +
                '<p>Status: ' + table.data().Status + '</p>' +
                '<p>Speed: ' + table.data().Speed.toFixed(2) + 'km/h</p>'
            });
            marker.addListener('click', () => {
              this.map.setZoom(18);
              this.map.setCenter(marker.getPosition());
              infoWindow.open(marker.getMap(), marker);
            });

            this.map.setCenter(marker.getPosition());

            this.devicelist.push(table.data());

            // await geocoder.geocode(
            //   { location: new google.maps.LatLng(table.data().Latitude, table.data().Longitude) },
            //   (
            //     results: google.maps.GeocoderResult[],
            //     status: google.maps.GeocoderStatus
            //   ) => {
            //     if (status === 'OK') {
            //       var d;
            //       if (results[0]) {
            //          d = {
            //           ...table.data(),
            //           location: results[0].formatted_address
            //         };
            //         this.devicelist.push(d);
            //         this.sorteddevicelist = this.devicelist.slice();
            //       } else {
            //          d = {
            //           ...table.data(),
            //           location: ''
            //         };
            //         this.devicelist.push(d);
            //         this.sorteddevicelist = this.devicelist.slice();
            //       }
            //     } else {
            //        d = {
            //         ...table.data(),
            //         location: ''
            //       };
            //       this.devicelist.push(d);
            //       this.sorteddevicelist = this.devicelist.slice();
            //     }
            //   }

            // );
          }

        });
        this.sorteddevicelist = this.devicelist.slice();
        this.getRouteInfo();

      });
    }
  }

  getRouteInfo() {
    this.devicelist.forEach(device => {
      if (device.Day) {
        device.Day.Monday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Monday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Monday[index].data.regions[i] = value.data();
              })
            });
          })
        });
        device.Day.Tuesday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Tuesday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Tuesday[index].data.regions[i] = value.data();
              })
            });
          })
        });
        device.Day.Wednesday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Wednesday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Wednesday[index].data.regions[i] = value.data();
              })
            });
          })
        });
        device.Day.Thursday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Thursday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Thursday[index].data.regions[i] = value.data();
              })
            });
          })
        });
        device.Day.Friday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Friday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Friday[index].data.regions[i] = value.data();
              })
            });
          })
        });
        device.Day.Saturday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Saturday[index].data = data.data();
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Saturday[index].data.regions[i] = value.data();
              })
            });
          })
        });
        device.Day.Sunday.forEach((element, index) => {
          this.firestore.collection('Route').doc(element.routeID).get().forEach(data => {
            device.Day.Sunday[index].data = data.data();
            console.log(device);
            data.data().regions.forEach((e, i) => {
              this.firestore.collection('Region').doc(e).get().forEach(value => {
                device.Day.Sunday[index].data.regions[i] = value.data();
              })
            });
          })
        });
      }
    })
    setTimeout(() => {
      this.spinner.hide();
    }, 3000);
    this.getMarkerInfo();
  }

  subsribeService;

  getMarkerInfo() {
    this.subsribeService = this.firestore.collection('Devices', ref => ref.orderBy('ID', 'asc')).snapshotChanges().subscribe(async (e) => {
      await e.forEach(async (table: any, index) => {
        this.markerLorryList[index].setPosition(new google.maps.LatLng(table.payload.doc.data().Latitude, table.payload.doc.data().Longitude))
        this.markerLorryList[index].setMap(this.map);
      });
    });
  }

  removeLorryMarker() {
    this.markerLorryList.forEach(e => {
      e.setMap(null);
    })
  }

  MonitorInMap(date: Date, device) {
    this.spinner.show();
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.subsribeService.unsubscribe();
    this.removeLorryMarker();
    const today = date.getDay();

    var routeSchedule = [];
    if (device.Day) {
      switch (today) {
        case 0: routeSchedule = device.Day.Sunday; break;
        case 1: routeSchedule = device.Day.Monday; break;
        case 2: routeSchedule = device.Day.Tuesday; break;
        case 3: routeSchedule = device.Day.Wednesday; break;
        case 4: routeSchedule = device.Day.Thursday; break;
        case 5: routeSchedule = device.Day.Friday; break;
        case 6: routeSchedule = device.Day.Saturday; break;
      }
    }


    if (routeSchedule.length === 0) {
      this.toaster.info('No schedule of the selected date');
    }

  }

  routeMarker = [];
  route = [];
  roroUrl = "https://img.icons8.com/dusk/50/000000/truck.png";
  garbageUrl = "https://img.icons8.com/dusk/64/000000/garbage-truck.png";


  job = "";
  GetImage(deviceID, date, trip) {
    const year = date.getFullYear();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const dateString = year + month + day;
    console.log(deviceID);
    console.log(dateString);
    console.log(trip);

    this.firestore.collection('ppsReports').doc(dateString).
      collection('reports', ref => ref.where('deviceId', '==', deviceID).
        where('trip', '==', trip.toString()).where('mode', '==', this.role.toLowerCase())).snapshotChanges().subscribe(result => {
          console.log(result);
          result.forEach(e => {
            const info = {
              position: new google.maps.LatLng(e.payload.doc.data().Latitude,
                e.payload.doc.data().Longitude),
              latitude: e.payload.doc.data().Latitude,
              longitude: e.payload.doc.data().Longitude,
              map: this.map,
              id: e.payload.doc.data().deviceId,
              date: e.payload.doc.data().date,
              imageurl: e.payload.doc.data().image,
              imagetype: e.payload.doc.data().type,
            };
            this.imagelist.push(info);
          });
          this.imagelist.forEach(e => {
            let marker;
            if (e.type === 'operasi') {
              marker = new google.maps.Marker({
                ...e,
                icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'

              });
            } else {
              marker = new google.maps.Marker({
                ...e,
                icon: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'

              });
            }
            marker.setMap(this.map);
            this.map.setCenter(marker.getPosition());
            let infoWindow;
            const d = new Date(e.date.toDate());
            const hrs = d.getHours();
            const minute = d.getMinutes();
            const sec = d.getSeconds();
            let display;
            if (hrs > 12) {
              display = hrs + ':' + minute + ':' + sec + 'pm';
            } else {
              display = hrs + ':' + minute + ':' + sec + 'am';
            }
            if (e.type === 'operasi') {
              infoWindow = new google.maps.InfoWindow({
                content:
                  '<span>Waktu Operasi:' + display + ' </span><br><br>' +
                  '<div class="operasiFrame" style="width: 250px;height: 250px;border: 3px solid red;background: #eee;margin: auto; padding: 15px 10px;"><img src=' + e.imageurl + ' width="100%" height="100%"></img></div>'
              });
            } else {
              infoWindow = new google.maps.InfoWindow({
                content:
                  '<span>Waktu Aduan Halangan:' + display + ' </span><br><br>' +
                  '<div class="aduanFrame" style="width: 250px;height: 250px;border: 3px solid red;background: #eee;margin: auto; padding: 15px 10px;"><img src=' + e.imageurl + ' width="100%" height="100%"></img></div>'
              });
            }

            marker.addListener('click', () => {
              this.map.setZoom(18);
              this.map.setCenter(marker.getPosition());
              infoWindow.open(marker.getMap(), marker);
            });
          });
        });
  }
  streets = [];
  savedMarkerlist = [];

  removeSavedPath() {
    this.pathSavedlist.forEach(e => {
      e.setMap(null);
    })
  }

  removeMarkerlist() {
    this.savedMarkerlist.forEach(e => {
      e.setMap(null);
    })
  }
  aduanlist = [];
  async GetKPAImage(date) {
    this.spinner.show();
    if (this.pathSavedlist.length > 0) {
      this.removeSavedPath();
    }
    if (this.savedMarkerlist.length > 0) {
      this.removeMarkerlist();
    }
    this.pathSavedlist = [];
    this.streets = [];
    this.savedMarkerlist = [];
    this.imagelist = [];
    this.aduanlist = [];
    const check = date.getDay();
    await this.firestore.collection('streets').get().forEach(async streets => {
      let counter = 1;
      console.log(streets.size);
      if (streets.size === 0) {
        this.spinner.hide();
      }
      await streets.forEach(streetdoc => {
        if (this.filterzone) {
          if (streetdoc.data().belongZone) {
            if (this.filterzone === streetdoc.data().belongZone) {
              if (this.getCheck(streetdoc.data(), check)) {
                this.streets.push(streetdoc.data().streetName);
                const paths = [];
                streetdoc.data().coordinates.forEach(coor => {
                  paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
                });
                const path = new google.maps.Polygon({
                  paths,
                  strokeColor: 'black',
                  fillColor: 'red',
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                });
                path.setMap(this.map);
                this.pathSavedlist.push(path);
                this.map.panTo(new google.maps.LatLng(streetdoc.data().centerCoordinate.latitude,
                  streetdoc.data().centerCoordinate.longitude));

              }
            }
          }
        } else {
          if (this.getCheck(streetdoc.data(), check)) {
            this.streets.push(streetdoc.data().streetName);
            const paths = [];
            streetdoc.data().coordinates.forEach(coor => {
              paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
            });
            const path = new google.maps.Polygon({
              paths,
              strokeColor: 'black',
              fillColor: 'red',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            });
            path.setMap(this.map);
            this.pathSavedlist.push(path);
            this.map.panTo(new google.maps.LatLng(streetdoc.data().centerCoordinate.latitude, streetdoc.data().centerCoordinate.longitude));
          }
        }


        if (counter === streets.size) {
        }
        counter++;

      });
    });


    const year = date.getFullYear();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const dateString = year + month + day;
    this.firestore.collection('reports').doc(dateString).
      collection('reports', ref => ref.where('mode', '==', this.role.toLowerCase())).snapshotChanges().subscribe(result => {
        this.removeMarkerlist();
        console.log(result.length);

        result.forEach(e => {
          let info = {};
          const r = this.imagelist.find(i => i.choosedWork === e.payload.doc.data().choosedWork &&
            i.choosedStreet === e.payload.doc.data().choosedStreet);
          if (r) {
            if (e.payload.doc.data().type === 'sebelum') {
              r.imagesebelum = e.payload.doc.data().image;
            } else if (e.payload.doc.data().type === 'semasa') {
              r.imagesemasa = e.payload.doc.data().image;
            } else if (e.payload.doc.data().type === 'selepas') {
              r.imageselepas = e.payload.doc.data().image;
            }
          } else {
            if (e.payload.doc.data().type === 'sebelum') {
              info = {
                map: this.map,
                id: e.payload.doc.data().deviceId,
                date: e.payload.doc.data().date,
                imagesebelum: e.payload.doc.data().image,
                imageselepas: '../../../assets/notyet.png',
                imagesemasa: '../../../assets/notyet.png',
                choosedStreet: e.payload.doc.data().choosedStreet,
                flag: false,
                choosedWork: e.payload.doc.data().choosedWork,
              };
              this.imagelist.push(info);

            } else if (e.payload.doc.data().type === 'semasa') {
              info = {
                map: this.map,
                id: e.payload.doc.data().deviceId,
                date: e.payload.doc.data().date,
                imagesebelum: '../../../assets/notyet.png',
                imagesemasa: e.payload.doc.data().image,
                imageselepas: '../../../assets/notyet.png',
                choosedStreet: e.payload.doc.data().choosedStreet,
                flag: false,
                choosedWork: e.payload.doc.data().choosedWork,
              };
              this.imagelist.push(info);

            } else if (e.payload.doc.data().type === 'selepas') {
              info = {
                map: this.map,
                id: e.payload.doc.data().deviceId,
                date: e.payload.doc.data().date,
                imagesebelum: '../../../assets/notyet.png',
                imagesemasa: '../../../assets/notyet.png',
                imageselepas: e.payload.doc.data().image,
                choosedStreet: e.payload.doc.data().choosedStreet,
                flag: false,
                choosedWork: e.payload.doc.data().choosedWork,
              };
              this.imagelist.push(info);

            }
          }

        });

        this.imagelist = this.imagelist.sort((a, b) => {
          return this.compare(a.choosedStreet, b.choosedStreet, true);
        })
        this.spinner.hide();

        this.imagelist.forEach(async e => {

          await this.firestore.collection('streets', ref => ref.where('streetName', '==', e.choosedStreet)).get().forEach(s => {
            s.forEach(document => {
              var ind = this.streets.findIndex(a => a === document.data().streetName);
              if (ind > -1) {

                e.position = new google.maps.LatLng(document.data().centerCoordinate.latitude,
                  document.data().centerCoordinate.longitude);
                const marker = new google.maps.Marker({
                  ...e,
                  icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
                });
                marker.setMap(this.map);
                this.savedMarkerlist.push(marker);
                this.map.setCenter(marker.getPosition());
                const findsameStreet = this.imagelist.filter(i => (i.choosedStreet === e.choosedStreet) && (i.choosedWork !== e.choosedWork) && !i.flag);
                let infoWindow;
                let d = new Date();
                try {
                  d = new Date(e.date.toDate());

                } catch (error) {
                  d = new Date(e.date);

                }
                let htmlfull = '';
                let hrs = d.getHours();
                let minute = d.getMinutes();
                let sec = d.getSeconds();
                let display;
                if (hrs > 12) {
                  display = hrs + ':' + minute + ':' + sec + 'pm';
                } else {
                  display = hrs + ':' + minute + ':' + sec + 'am';
                }
                let html = '<br><span>Waktu Operasi:' + display + ' </span><br>' +
                  '<span>Nama Jalan:' + e.choosedStreet + ' </span><br>' +
                  '<span>Aktiviti:' + e.choosedWork + ' </span><br>' +
                  '<div class="row">' +
                  '<div class="col" >Sebelum:</div>' +
                  '<div class="col" >Semasa:</div>' +
                  '<div class="col" >Selepas:</div>' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col" style="width: 150px;height: 150px;"><img src=' + e.imagesebelum + ' width="100%" height="100%"></img></div>' +
                  '<div class="col" style="width: 150px;height: 150px;"><img src=' + e.imagesemasa + ' width="100%" height="100%"></img></div>' +
                  '<div class="col"  style="width: 150px;height: 150px;"><img src=' + e.imageselepas + ' width="100%" height="100%"></img></div>' +
                  '</div>';
                htmlfull += html;
                if (findsameStreet.length > 0) {

                  findsameStreet.forEach(element => {
                    element.flag = true;
                    try {
                      d = new Date(element.date.toDate());

                    } catch (error) {
                      d = new Date(element.date);

                    }
                    hrs = d.getHours();
                    minute = d.getMinutes();
                    sec = d.getSeconds();
                    if (hrs > 12) {
                      display = hrs + ':' + minute + ':' + sec + 'pm';
                    } else {
                      display = hrs + ':' + minute + ':' + sec + 'am';
                    }
                    html = '<br><span>Waktu Operasi:' + display + ' </span><br>' +
                      '<span>Nama Jalan:' + element.choosedStreet + ' </span><br>' +
                      '<span>Aktiviti:' + element.choosedWork + ' </span><br>' +
                      '<div class="row">' +
                      '<div class="col" >Sebelum:</div>' +
                      '<div class="col" >Semasa:</div>' +
                      '<div class="col" >Selepas:</div>' +
                      '</div>' +
                      '<div class="row">' +
                      '<div class="col"  style="width: 150px;height: 150px;"><img src=' + element.imagesebelum + ' width="100%" height="100%"></img></div>' +
                      '<div class="col"  style="width: 150px;height: 150px;"><img src=' + element.imagesemasa + ' width="100%" height="100%"></img></div>' +
                      '<div class="col"  style="width: 150px;height: 150px;"><img src=' + element.imageselepas + ' width="100%" height="100%"></img></div>' +
                      '</div>';
                    htmlfull += html;

                  });
                }
                infoWindow = new google.maps.InfoWindow({
                  content: htmlfull
                });


                marker.addListener('click', () => {
                  this.map.setZoom(20);
                  this.map.setCenter(marker.getPosition());
                  infoWindow.open(marker.getMap(), marker);
                });


                const paths = [];
                document.data().coordinates.forEach(coor => {
                  paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
                });
                this.pathSavedlist[ind].setMap(null);
                this.pathSavedlist[ind] = new google.maps.Polygon({
                  paths,
                  strokeColor: 'black',
                  fillColor: 'green',
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                });
                this.pathSavedlist[ind].setMap(this.map);


              }

            });
          });

        });
      });

    this.firestore.collection('complaints').doc(dateString).
      collection('reports', ref => ref.where('mode', '==', 'aduan')).snapshotChanges().subscribe(result => {
        result.forEach(e => {
          let info = {};
          const r = this.aduanlist.find(i => i.choosedWork === e.payload.doc.data().choosedWork &&
            i.choosedStreet === e.payload.doc.data().choosedStreet);
          if (r) {
            if (e.payload.doc.data().type === 'sebelum') {
              r.imagesebelum = e.payload.doc.data().image;
            } else if (e.payload.doc.data().type === 'semasa') {
              r.imagesemasa = e.payload.doc.data().image;
            } else if (e.payload.doc.data().type === 'selepas') {
              r.imageselepas = e.payload.doc.data().image;
            }
          } else {
            if (e.payload.doc.data().type === 'sebelum') {
              info = {
                position: new google.maps.LatLng(e.payload.doc.data().Latitude,
                  e.payload.doc.data().Longitude),
                latitude: e.payload.doc.data().Latitude,
                longitude: e.payload.doc.data().Longitude,
                map: this.map,
                id: e.payload.doc.data().deviceId,
                date: e.payload.doc.data().date,
                imagesebelum: e.payload.doc.data().image,
                imageselepas: '../../../assets/notyet.png',
                imagesemasa: '../../../assets/notyet.png',
                choosedStreet: e.payload.doc.data().choosedStreet,
                choosedWork: e.payload.doc.data().choosedWork,
                flag: false,
              };
              this.aduanlist.push(info);

            } else if (e.payload.doc.data().type === 'semasa') {
              info = {
                position: new google.maps.LatLng(e.payload.doc.data().Latitude,
                  e.payload.doc.data().Longitude),
                latitude: e.payload.doc.data().Latitude,
                longitude: e.payload.doc.data().Longitude,
                map: this.map,
                id: e.payload.doc.data().deviceId,
                date: e.payload.doc.data().date,
                imagesebelum: '../../../assets/notyet.png',
                imagesemasa: e.payload.doc.data().image,
                imageselepas: '../../../assets/notyet.png',
                choosedStreet: e.payload.doc.data().choosedStreet,
                choosedWork: e.payload.doc.data().choosedWork,
                flag: false,

              };
              this.aduanlist.push(info);

            } else if (e.payload.doc.data().type === 'selepas') {
              info = {
                position: new google.maps.LatLng(e.payload.doc.data().Latitude,
                  e.payload.doc.data().Longitude),
                latitude: e.payload.doc.data().Latitude,
                longitude: e.payload.doc.data().Longitude,
                map: this.map,
                id: e.payload.doc.data().deviceId,
                date: e.payload.doc.data().date,
                imagesebelum: '../../../assets/notyet.png',
                imagesemasa: '../../../assets/notyet.png',
                imageselepas: e.payload.doc.data().image,
                choosedStreet: e.payload.doc.data().choosedStreet,
                choosedWork: e.payload.doc.data().choosedWork,
                flag: false,
              };
              this.aduanlist.push(info);

            }
          }
        });

        this.aduanlist = this.aduanlist.sort((a, b) => {
          return this.compare(a.choosedStreet, b.choosedStreet, true);
        })
        this.spinner.hide();

        this.aduanlist.forEach(async e => {

          await this.firestore.collection('streets', ref => ref.where('streetName', '==', e.choosedStreet)).get().forEach(s => {
            s.forEach(document => {
              var ind = this.streets.findIndex(a => a === document.data().streetName);
              if (ind > -1) {

                e.position = new google.maps.LatLng(document.data().centerCoordinate.latitude,
                  document.data().centerCoordinate.longitude);
                const marker = new google.maps.Marker({
                  ...e,
                  icon: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
                });
                marker.setMap(this.map);
                this.savedMarkerlist.push(marker);
                this.map.setCenter(marker.getPosition());
                const findsameStreet = this.aduanlist.filter(i => (i.choosedStreet === e.choosedStreet) && (i.choosedWork !== e.choosedWork) && !i.flag);
                let infoWindow;
                let d = new Date();
                try {
                  d = new Date(e.date.toDate());

                } catch (error) {
                  d = new Date(e.date);

                }
                let htmlfull = '';
                let hrs = d.getHours();
                let minute = d.getMinutes();
                let sec = d.getSeconds();
                let display;
                if (hrs > 12) {
                  display = hrs + ':' + minute + ':' + sec;
                } else {
                  display = hrs + ':' + minute + ':' + sec;
                }
                let html = '<br><span>Waktu Operasi:' + display + ' </span><br>' +
                  '<span>Nama Jalan:' + e.choosedStreet + ' </span><br>' +
                  '<span>Aktiviti:' + e.choosedWork + ' </span><br>' +
                  '<div class="row">' +
                  '<div class="col" >Sebelum:</div>' +
                  '<div class="col" >Semasa:</div>' +
                  '<div class="col" >Selepas:</div>' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col" style="width: 150px;height: 150px;"><img src=' + e.imagesebelum + ' width="100%" height="100%"></img></div>' +
                  '<div class="col" style="width: 150px;height: 150px;"><img src=' + e.imagesemasa + ' width="100%" height="100%"></img></div>' +
                  '<div class="col"  style="width: 150px;height: 150px;"><img src=' + e.imageselepas + ' width="100%" height="100%"></img></div>' +
                  '</div>';
                htmlfull += html;
                if (findsameStreet.length > 0) {

                  findsameStreet.forEach(element => {
                    element.flag = true;
                    try {
                      d = new Date(element.date.toDate());

                    } catch (error) {
                      d = new Date(element.date);

                    }
                    hrs = d.getHours();
                    minute = d.getMinutes();
                    sec = d.getSeconds();
                    if (hrs > 12) {
                      display = hrs + ':' + minute + ':' + sec;
                    } else {
                      display = hrs + ':' + minute + ':' + sec;
                    }
                    html = '<br><span>Waktu Operasi:' + display + ' </span><br>' +
                      '<span>Nama Jalan:' + element.choosedStreet + ' </span><br>' +
                      '<span>Aktiviti:' + element.choosedWork + ' </span><br>' +
                      '<div class="row">' +
                      '<div class="col" >Sebelum:</div>' +
                      '<div class="col" >Semasa:</div>' +
                      '<div class="col" >Selepas:</div>' +
                      '</div>' +
                      '<div class="row">' +
                      '<div class="col"  style="width: 150px;height: 150px;"><img src=' + element.imagesebelum + ' width="100%" height="100%"></img></div>' +
                      '<div class="col"  style="width: 150px;height: 150px;"><img src=' + element.imagesemasa + ' width="100%" height="100%"></img></div>' +
                      '<div class="col"  style="width: 150px;height: 150px;"><img src=' + element.imageselepas + ' width="100%" height="100%"></img></div>' +
                      '</div>';
                    htmlfull += html;

                  });
                }
                infoWindow = new google.maps.InfoWindow({
                  content: htmlfull
                });


                marker.addListener('click', () => {
                  this.map.setZoom(20);
                  this.map.setCenter(marker.getPosition());
                  infoWindow.open(marker.getMap(), marker);
                });

                const paths = [];
                document.data().coordinates.forEach(coor => {
                  paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
                });
                this.pathSavedlist[ind].setMap(null);
                this.pathSavedlist[ind] = new google.maps.Polygon({
                  paths,
                  strokeColor: 'black',
                  fillColor: 'yellow',
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                });
                this.pathSavedlist[ind].setMap(this.map);




              }
            });
          });

        });
      });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  viewDetail(id) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = '50%';
    dialogConfig.width = '50%';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id,
    };

    this.dialog.open(DeviceInfoModalComponent, dialogConfig);
  }

  minimize() {
    this.minimizeFlag = true;
  }
  minimizeGraph() {
    this.minimizeGraphFlag = true;
  }

  maximize() {
    this.minimizeFlag = false;
  }


  kpaDate = new Date();
  selectDate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.open(MonitorDatePickerComponent, dialogConfig).afterClosed().subscribe((date: any) => {
      if (date) {
        this.kpaDate = date;
        this.GetKPAImage(this.kpaDate);
      }
    });

  }

  addMonitorMap(deviceId, device) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.open(MonitorDatePickerComponent, dialogConfig).afterClosed().subscribe((date: any) => {
      if (date) {
        const d = new Date(date);
        this.deletecounter++;
        const trackingdevice = {
          id: deviceId,
          deleteID: deviceId + this.deletecounter,
          device: device,
          date: d,
        };
        const result = this.maplist.find(map => map.id === trackingdevice.id &&
          map.date.getFullYear() === trackingdevice.date.getFullYear() &&
          map.date.getMonth() === trackingdevice.date.getMonth() &&
          map.date.getDate() === trackingdevice.date.getDate());
        if (!result) {
          if (this.mode === 'single') {
            this.maplist = [];
            this.maplist.push(trackingdevice);
            this.devices = device.PlateNumber;
            this.removeMarkers(this.markerlist);
            this.removePath(this.linelist);
            this.markerlist = [];
            this.linelist = [];
            this.lorrylist = [];
            this.positionlist = [];
            this.savinglist = [];
            this.imagelist = [];
            this.MonitorInMap(date, device);
          } else {
            this.maplist.push(trackingdevice);
            if (this.maplist.length === 1) {
              this.devices = device.PlateNumber;
              this.removeMarkers(this.markerlist);
              this.removePath(this.linelist);
              this.markerlist = [];
              this.linelist = [];
              this.lorrylist = [];
              this.positionlist = [];
              this.savinglist = [];
              this.imagelist = [];
              this.MonitorInMap(date, device);
            }

          }
        } else {
          this.toaster.error('This device and the chosen date already been tracking', 'Error');
        }
      }
    });
  }



  fitlerDevice = (arr, query) => {
    return arr.filter(el => (el.id !== query.id || el.deleteID !== query.deleteID));

  }

  stopMonitor() {


    this.removeMarkers(this.markerlist);
    this.removePath(this.linelist);
    this.getMarkerInfo();
    this.removeSavedPath();
    this.removeMarkers(this.routeMarker);

    this.devices = '';
    this.speed = '';
    this.markerlist = [];
    this.linelist = [];
    this.lorrylist = [];
    this.positionlist = [];
    this.savinglist = [];
    this.imagelist = [];
    this.trips = [];

    this.maplist = [];
  }
  single() {
    this.mode = 'single';
  }

  multiple() {
    this.mode = 'multiple';
  }
  applyFilter(filterValue: string) {
    this.sorteddevicelist = this.devicelist.slice(0);
    this.sorteddevicelist = this.filterItems(this.sorteddevicelist, filterValue);
  }

  filterItems = (arr, query) => {
    return arr.filter(el => el.id.toLowerCase().indexOf(query.toLowerCase()) > -1);
  }

  applyFilter2(filterValue: string) {
    this.sortedzonelist = this.zonelist.slice(0);
    this.sortedzonelist = this.filterItems2(this.sortedzonelist, filterValue);
  }

  filterItems2 = (arr, query) => {
    return arr.filter(el => el.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
  }
  getCheck(street, day) {
    if (day === 0) {
      day = 7;
    }
    if (street.dayAvailables.find(i => i === day)) {
      return true;
    } else {
      return false;
    }
  }

  filterzone = '';
  showZone(zone) {
    this.filterzone = zone.name;
    this.GetKPAImage(this.kpaDate);
  }
  clear() {
    this.filterzone = '';
    this.GetKPAImage(this.kpaDate);

  }

  // tripSelect(t){
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.height = 'auto';
  //   dialogConfig.width = '50%';
  //   const position = {
  //     top: '5%',
  //   };
  //   dialogConfig.position = position;
  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = true;

  //   dialogConfig.data = {
  //     trips: this.trips.slice(),
  //     devices: this.devices,
  //     date:this.maplist[0].date
  //   };

  //   const deviceRoute = [];
  //   this.firestore.collection('DrawingRoutes',ref=>ref.where('belongDevice','==',this.devices)).get().forEach(routes=>{
  //     if(routes.size === 0){
  //       this.StartTracking(t,null);
  //       this.GetImage(this.devices, this.maplist[0].date, t.trip);
  //     }
  //     routes.forEach(r=>{
  //       const info={
  //         ...r.data()
  //       }
  //       deviceRoute.push(info);
  //     })
  //     dialogConfig.data = {
  //       routes: deviceRoute.slice(),
  //       devices: this.devices,
  //       date:this.maplist[0].date
  //     };

  //     this.dialog.open(RouteSelectComponent, dialogConfig).afterClosed().subscribe(route => {
  //       if(route){
  //         this.StartTracking(t,route);
  //         this.GetImage(this.devices, this.maplist[0].date, t.trip);

  //       }
  //     });
  //   })
  // }

  onehrs = false;
  twohrs = false;
  fourhrs = false;
  eighthrs = false;
  twehrs = false;
  twofourhrs = false;

  jobTime: any;
  schedulerTime: Date;
  monitoring(hrs, device) {
    this.selectedArea = null;
    this.RegionLog = [];
    this.timelist = [];
    this.speedlist = [];

    this.spinner.show();
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.subsribeService.unsubscribe();
    this.removeLorryMarker();
    const currenttime = new Date();
    if (hrs)
      this.activebutton(hrs);

    currenttime.setHours(currenttime.getHours() - hrs);

    this.job = "Pemantauan daripada - ";
    this.jobTime = currenttime;
    this.historyFrom = null;
    this.historyTo = null;
    const today = currenttime.getDay();
    this.schedulerTime = currenttime;

    var routeSchedule = [];

    if (!device)
      device = this.deviceInfo;
    if (device.Day) {
      switch (today) {
        case 0: routeSchedule = device.Day.Sunday; break;
        case 1: routeSchedule = device.Day.Monday; break;
        case 2: routeSchedule = device.Day.Tuesday; break;
        case 3: routeSchedule = device.Day.Wednesday; break;
        case 4: routeSchedule = device.Day.Thursday; break;
        case 5: routeSchedule = device.Day.Friday; break;
        case 6: routeSchedule = device.Day.Saturday; break;
      }
    }
    if (routeSchedule.length === 0) {
      this.toaster.info('No schedule of the selected date');
    }
    this.route = routeSchedule;
    this.spinner.hide();
    this.tracking(device, currenttime, null)
  }

  historyFrom: any;
  historyTo: any;

  timeSearch() {
    this.spinner.show();
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.subsribeService.unsubscribe();
    this.removeLorryMarker();
    this.activebutton(false,true);

    this.job = "History - ";
    this.jobTime = null;

    this.schedulerTime = this.dateFrom;
    this.historyFrom = this.dateFrom;
    this.historyTo = this.dateTo;



    var routeSchedule = [];
    const today = this.dateFrom.getDay();

    var device = this.deviceInfo;
    if (device.Day) {
      switch (today) {
        case 0: routeSchedule = device.Day.Sunday; break;
        case 1: routeSchedule = device.Day.Monday; break;
        case 2: routeSchedule = device.Day.Tuesday; break;
        case 3: routeSchedule = device.Day.Wednesday; break;
        case 4: routeSchedule = device.Day.Thursday; break;
        case 5: routeSchedule = device.Day.Friday; break;
        case 6: routeSchedule = device.Day.Saturday; break;
      }
    }
    if (routeSchedule.length === 0) {
      this.toaster.info('No schedule of the selected date');
    }
    this.route = routeSchedule;
    this.spinner.hide();
    this.tracking(device, this.dateFrom, this.dateTo)
  }

  trackingService: any;
  tracking(device, datefrom: Date, dateto) {
    var timestampFrom = datefrom.getTime() / 1000;
    this.locatelist = [];
    this.removeMarkers(this.markerlist);
    this.removePath(this.linelist);
    this.markerlist = [];
    this.linelist = [];
    this.lorrylist = [];
    this.positionlist = [];
    this.savinglist = [];
    this.imagelist = [];
    this.routeMarker = [];
    this.speedlist = [];
    this.spinner.show();

    const hr = datefrom.getHours();
    const min = datefrom.getMinutes();

    var hr1; var min1;
    if (dateto)
      hr1 = datefrom.getHours();
    min1 = datefrom.getMinutes();

    var tempDuration = 0;
    var tempEndDuration = 0;
    var temp = 0;
    var temp1 = -999;
    if (this.route.length > 0) {
      this.removeSavedPath();
      this.pathSavedlist = [];
      this.route.forEach(e => {

        tempDuration = hr - parseInt(e.startAt.split(':')[0]);
        if (tempDuration < 0)
          tempDuration = tempDuration * -1

        tempEndDuration = hr - e.endAt.split(':')[0];
        if (tempEndDuration < 0)
          tempEndDuration = tempEndDuration * -1

        if (tempDuration > tempEndDuration)
          temp = tempEndDuration
        else
          temp = tempDuration

        if (temp1 === -999) {
          this.routeSelected = e;
          temp1 = temp;
        } else if (temp1 > temp) {
          this.routeSelected = e;
          temp1 = temp;
        }
      });

      this.minimizeGraphFlag = false;

      this.disposalArea = this.routeSelected.data.regions.find(e => e.id === this.routeSelected.data.mark);
      this.c = this.routeSelected.data.regions.findIndex(e => e.id === this.routeSelected.data.mark);


      this.routeSelected.data.regions.forEach(element => {
        let empty = [];
        this.RegionLog.push(empty);
        const paths = [];
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(element.centerCoordinate.latitude, element.centerCoordinate.longitude),
          icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
        })
        const infoWindow = new google.maps.InfoWindow({
          content: '<span>Region : ' + element.RouteName + '</span><br>'
        });
        marker.addListener('click', () => {
          this.map.setZoom(18);
          this.map.setCenter(marker.getPosition());
          infoWindow.open(marker.getMap(), marker);
        });
        marker.setMap(this.map);
        this.routeMarker.push(marker);
        element.coordinates.forEach(coor => {
          paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
        });
        const path = new google.maps.Polygon({
          paths: paths,
          strokeColor: 'black',
          fillColor: 'red',
          strokeOpacity: 1.0,
          strokeWeight: 1,
        });

        path.setMap(this.map);
        this.pathSavedlist.push(path);

      });



    }
    else {
      this.spinner.hide();
    }
    if (this.trackingService)
      this.trackingService.unsubscribe();
    if (dateto === null) {
      this.trackingService = this.firestore.collection('Devices').doc(device.ID).collection('Monitor', ref => ref.where('Timestamp', '>=', timestampFrom).orderBy('Timestamp', 'asc')).snapshotChanges().subscribe(l => {
        l.forEach((location, index) => {
          const same = this.locatelist.find(l => l.payload.doc.id === location.payload.doc.id);
          if (!same) {
            if (location.payload.doc.data().Speed) {
              this.speed = (location.payload.doc.data().Speed).toFixed(2) + 'km/h';
            } else {
              this.speed = '0 km/h';
            }
            this.speedlist.push(this.speed);

            this.locatelist.push(location);
            if (index === 0) {
              const mark = {
                position: new google.maps.LatLng(location.payload.doc.data().Latitude,
                  location.payload.doc.data().Longitude),
                map: this.map,
                icon: device.LorryType === 'Garbage Truck' ? this.garbageUrl : this.roroUrl,
                trip: device.trip,
                date: new Date(location.payload.doc.data().Timestamp),
                id: device.PlateNumber
              };
              const marker = new google.maps.Marker({
                ...mark
              });
              this.lorrylist.push(marker);
              marker.setMap(this.map);
              this.markerlist.push(marker);
              this.map.setCenter(new google.maps.LatLng(location.payload.doc.data().Latitude,
                location.payload.doc.data().Longitude))
              const infoWindow = new google.maps.InfoWindow({
                content: '<span>Vehicle : ' + mark.id + '</span><br>'
              });
              marker.addListener('click', () => {
                this.map.setZoom(18);
                this.map.setCenter(marker.getPosition());
                infoWindow.open(marker.getMap(), marker);
              });
              this.timelist.push(new Date(location.payload.doc.data().Timestamp * 1000));

              this.positionlist.push(new google.maps.LatLng(location.payload.doc.data().Latitude,
                location.payload.doc.data().Longitude));
              this.savinglist.push(new firebase.firestore.GeoPoint(location.payload.doc.data().Latitude,
                location.payload.doc.data().Longitude));
            } else {
              const r = this.lorrylist.find(m => m.id === device.PlateNumber);
              if (r) {
                r.date = new Date(location.payload.doc.data().Timestamp);
                r.setPosition(
                  new google.maps.LatLng(location.payload.doc.data().Latitude,
                    location.payload.doc.data().Longitude)
                );
                const SameThreshold = 0.1;
                this.speedlist.push(this.speed);
                this.timelist.push(new Date(location.payload.doc.data().Timestamp * 1000));

                this.map.setCenter(new google.maps.LatLng(location.payload.doc.data().Latitude,
                  location.payload.doc.data().Longitude));
                this.positionlist.push(new google.maps.LatLng(location.payload.doc.data().Latitude,
                  location.payload.doc.data().Longitude));
                this.savinglist.push(new firebase.firestore.GeoPoint(location.payload.doc.data().Latitude,
                  location.payload.doc.data().Longitude));
                // if (google.maps.geometry.spherical.computeDistanceBetween(this.positionlist[this.positionlist.length - 1],
                //   new google.maps.LatLng(location.payload.doc.data().Latitude,
                //     location.payload.doc.data().Longitude)) > SameThreshold) {
                //       this.map.panTo(new google.maps.LatLng(location.payload.doc.data().Latitude,
                //       location.payload.doc.data().Longitude));
                //       this.positionlist.push(new google.maps.LatLng(location.payload.doc.data().Latitude,
                //       location.payload.doc.data().Longitude));
                //     this.savinglist.push(new firebase.firestore.GeoPoint(location.payload.doc.data().Latitude,
                //       location.payload.doc.data().Longitude));
                // }

                if (index === l.length - 1) {
                  if (this.positionlist.length > 1) {
                    const list = [];
                    if (this.trackingPath) {
                      this.trackingPath.setMap(null);
                    }
                    for (let i = 0; i < this.positionlist.length; i++) {
                      list.push(this.positionlist[i]);

                      this.pathSavedlist.forEach((ab, index) => {
                        const resultPath = google.maps.geometry.poly.containsLocation(
                          this.positionlist[i],
                          ab
                        )
                        if (resultPath) {
                          const info = {
                            message: "IN",
                            time: this.timelist[i],
                            position: this.positionlist[i],
                          }
                          if (this.RegionLog[index].length === 0) {
                            this.RegionLog[index].push(info)
                          }
                          else if (this.RegionLog[index][this.RegionLog[index].length - 1]) {
                            let m = this.RegionLog[index][this.RegionLog[index].length - 1].message;
                            if (m !== 'IN') {
                              this.RegionLog[index].push(info)
                            }
                          }

                          this.pathSavedlist[index].setMap(null);
                          this.pathSavedlist[index] = new google.maps.Polygon({
                            paths: this.pathSavedlist[index].getPaths(),
                            strokeColor: 'black',
                            fillColor: 'green',
                            strokeOpacity: 1.0,
                            strokeWeight: 2,
                          })
                          this.pathSavedlist[index].setMap(this.map);
                        }
                        else {
                          const info = {
                            message: "OUT",
                            time: this.timelist[i],
                            position: this.positionlist[i],
                          }
                          if (this.RegionLog[index][this.RegionLog[index].length - 1]) {
                            let m = this.RegionLog[index][this.RegionLog[index].length - 1].message;
                            if (m === 'IN') {
                              this.RegionLog[index].push(info)
                            }
                          }
                        }
                      })

                    }
                    this.trackingPath = new google.maps.Polyline({
                      path: list,
                      strokeColor: 'blue',
                      strokeOpacity: 1.0,
                      strokeWeight: 2,
                    });
                    if (this.trackingLineMode)
                      this.trackingPath.setMap(this.map);
                    this.linelist.push(this.trackingPath);
                    this.spinner.hide();
                  } else {
                    this.spinner.hide();
                  }
                }
              }
            }
            index++;
          }
        });
        if (l.length === 0) {
          this.trackingService.unsubscribe();
          this.trackingService = this.firestore.collection('Devices').doc(device.ID).snapshotChanges().subscribe((location: any) => {

            const r = this.lorrylist.find(m => m.id === device.PlateNumber);
            if (r) {
              r.setPosition(
                new google.maps.LatLng(location.payload.data().Latitude,
                  location.payload.data().Longitude)
              )
              this.map.setCenter(new google.maps.LatLng(location.payload.data().Latitude,
                location.payload.data().Longitude))

            } else {
              const mark = {
                position: new google.maps.LatLng(location.payload.data().Latitude,
                  location.payload.data().Longitude),
                map: this.map,
                icon: device.LorryType === 'Garbage Truck' ? this.garbageUrl : this.roroUrl,
                trip: device.trip,
                id: device.PlateNumber
              };
              const marker = new google.maps.Marker({
                ...mark
              });
              this.lorrylist.push(marker);
              marker.setMap(this.map);
              this.markerlist.push(marker);
              this.map.setCenter(new google.maps.LatLng(location.payload.data().Latitude,
                location.payload.data().Longitude))
              const infoWindow = new google.maps.InfoWindow({
                content: '<span>Vehicle : ' + mark.id + '</span><br>'
              });
              marker.addListener('click', () => {
                this.map.setZoom(18);
                this.map.setCenter(marker.getPosition());
                infoWindow.open(marker.getMap(), marker);
              });
            }

            this.timelist.push(new Date(location.payload.data().Timestamp * 1000));


            this.positionlist.push(new google.maps.LatLng(location.payload.data().Latitude,
              location.payload.data().Longitude));
            this.savinglist.push(new firebase.firestore.GeoPoint(location.payload.data().Latitude,
              location.payload.data().Longitude));

            const list = [];
            if (this.trackingPath) {
              this.trackingPath.setMap(null);
            }
            for (let i = 0; i < this.positionlist.length; i++) {
              list.push(this.positionlist[i]);
              this.pathSavedlist.forEach((ab, index) => {
                const resultPath = google.maps.geometry.poly.containsLocation(
                  this.positionlist[i],
                  ab
                )
                if (resultPath) {
                  const info = {
                    message: "IN",
                    time: this.timelist[i],
                    position: this.positionlist[i],
                  }
                  if (this.RegionLog[index].length === 0) {
                    this.RegionLog[index].push(info)
                  }
                  else if (this.RegionLog[index][this.RegionLog[index].length - 1]) {
                    let m = this.RegionLog[index][this.RegionLog[index].length - 1].message;
                    if (m !== 'IN') {
                      this.RegionLog[index].push(info)
                    }
                  }

                  this.pathSavedlist[index].setMap(null);
                  this.pathSavedlist[index] = new google.maps.Polygon({
                    paths: this.pathSavedlist[index].getPaths(),
                    strokeColor: 'black',
                    fillColor: 'green',
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                  })
                  this.pathSavedlist[index].setMap(this.map);
                } else {
                  const info = {
                    message: "OUT",
                    time: this.timelist[i],
                    position: this.positionlist[i],
                  }
                  if (this.RegionLog[index][this.RegionLog[index].length - 1]) {
                    let m = this.RegionLog[index][this.RegionLog[index].length - 1].message;
                    if (m === 'IN') {
                      this.RegionLog[index].push(info)
                    }
                  }
                }
              })

            }
            this.trackingPath = new google.maps.Polyline({
              path: list,
              strokeColor: 'blue',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            });
            if (this.trackingLineMode)
              this.trackingPath.setMap(this.map);
            this.linelist.push(this.trackingPath);
            this.spinner.hide();
          })
        }

      });
    } else {
      var timestampTo = dateto.getTime() / 1000;

      this.firestore.collection('Devices').doc(device.ID).collection('Monitor', ref => ref.where('Timestamp', '>=', timestampFrom).where('Timestamp', '<=', timestampTo).orderBy('Timestamp', 'asc')).get().forEach(l => {
        console.log(l.size)
        l.forEach((location) => {
          const same = this.locatelist.find(l => l === location.id);
          if (!same) {
            if (location.data().Speed) {
              this.speed = (location.data().Speed).toFixed(2) + 'km/h';
            } else {
              this.speed = '0 km/h';
            }
            this.speedlist.push(this.speed);

            const r = this.lorrylist.find(m => m.id === device.PlateNumber);
            if (r) {
              r.setPosition(
                new google.maps.LatLng(location.data().Latitude,
                  location.data().Longitude)
              )
              this.map.setCenter(new google.maps.LatLng(location.data().Latitude,
                location.data().Longitude))
            } else {
              const mark = {
                position: new google.maps.LatLng(location.data().Latitude,
                  location.data().Longitude),
                map: this.map,
                icon: device.LorryType === 'Garbage Truck' ? this.garbageUrl : this.roroUrl,
                trip: device.trip,
                date: new Date(location.data().Timestamp),
                id: device.PlateNumber
              };
              const marker = new google.maps.Marker({
                ...mark
              });
              this.lorrylist.push(marker);
              marker.setMap(this.map);
              this.markerlist.push(marker);
              this.map.setCenter(new google.maps.LatLng(location.data().Latitude,
                location.data().Longitude))
              const infoWindow = new google.maps.InfoWindow({
                content: '<span>Vehicle : ' + mark.id + '</span><br>'
              });
              marker.addListener('click', () => {
                this.map.setZoom(18);
                this.map.setCenter(marker.getPosition());
                infoWindow.open(marker.getMap(), marker);
              });
            }

            this.timelist.push(new Date(location.data().Timestamp * 1000));

            this.locatelist.push(location.id);
            this.positionlist.push(new google.maps.LatLng(location.data().Latitude,
              location.data().Longitude));
            this.savinglist.push(new firebase.firestore.GeoPoint(location.data().Latitude,
              location.data().Longitude));

            this.map.setCenter(new google.maps.LatLng(location.data().Latitude,
              location.data().Longitude));


          }

        });

        if (this.positionlist.length > 1) {
          const list = [];
          if (this.trackingPath) {
            this.trackingPath.setMap(null);
          }
          for (let i = 0; i < this.positionlist.length; i++) {
            list.push(this.positionlist[i]);

            this.pathSavedlist.forEach((ab, index) => {
              const resultPath = google.maps.geometry.poly.containsLocation(
                this.positionlist[i],
                ab
              )
              if (resultPath) {
                const info = {
                  message: "IN",
                  time: this.timelist[i],
                  position: this.positionlist[i],
                }
                if (this.RegionLog[index].length === 0) {
                  this.RegionLog[index].push(info)
                }
                else if (this.RegionLog[index][this.RegionLog[index].length - 1]) {
                  let m = this.RegionLog[index][this.RegionLog[index].length - 1].message;
                  if (m !== 'IN') {
                    this.RegionLog[index].push(info)
                  }
                }
                this.pathSavedlist[index].setMap(null);
                this.pathSavedlist[index] = new google.maps.Polygon({
                  paths: this.pathSavedlist[index].getPaths(),
                  strokeColor: 'black',
                  fillColor: 'green',
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                })
                this.pathSavedlist[index].setMap(this.map);
              } else {
                const info = {
                  message: "OUT",
                  time: this.timelist[i],
                  position: this.positionlist[i],
                }
                if (this.RegionLog[index][this.RegionLog[index].length - 1]) {
                  let m = this.RegionLog[index][this.RegionLog[index].length - 1].message;
                  if (m === 'IN') {
                    this.RegionLog[index].push(info)
                  }
                }
              }
            })

          }
          this.trackingPath = new google.maps.Polyline({
            path: list,
            strokeColor: 'blue',
            strokeOpacity: 1.0,
            strokeWeight: 2,
          });
          if (this.trackingLineMode)
            this.trackingPath.setMap(this.map);
          this.linelist.push(this.trackingPath);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        if (l.size === 0) {
          this.toaster.info('No records')
        }
      });
    }
  }


  deviceInfo: any;
  addMonitor(deviceId, device) {
    this.deletecounter++;
    const trackingdevice = {
      id: deviceId,
      deleteID: deviceId + this.deletecounter,
      device: device,
    };
    const result = this.maplist.find(map => map.id === trackingdevice.id);
    if (!result) {
      if (this.mode === 'single') {
        this.maplist = [];
        this.maplist.push(trackingdevice);
        this.devices = device.PlateNumber;
        this.removeMarkers(this.markerlist);
        this.removePath(this.linelist);
        this.markerlist = [];
        this.linelist = [];
        this.lorrylist = [];
        this.positionlist = [];
        this.savinglist = [];
        this.imagelist = [];
        this.deviceInfo = device;
        this.monitoring(1, device);
      } else {
        this.maplist.push(trackingdevice);
        if (this.maplist.length === 1) {
          this.devices = device.PlateNumber;
          this.removeMarkers(this.markerlist);
          this.removePath(this.linelist);
          this.markerlist = [];
          this.linelist = [];
          this.lorrylist = [];
          this.positionlist = [];
          this.savinglist = [];
          this.imagelist = [];
          this.monitoring(1, device);
        }
      }
    }
    else {
      this.toaster.warning('This device and the chosen date already been tracking');
    }
  }

  realTime = true;
  activebutton(hrs, flag = false) {
    if(flag){
      this.onehrs = false;
      this.twohrs = false;
      this.fourhrs = false;
      this.eighthrs = false;
      this.twehrs = false;
      this.twofourhrs = false;
      this.realTime = true;
    }else{
      this.realTime = true;
      if (!hrs) {
        this.onehrs = false;
        this.twohrs = false;
        this.fourhrs = false;
        this.eighthrs = false;
        this.twehrs = false;
        this.twofourhrs = false;
        this.realTime = false;
      }
    }


    switch (hrs) {
      case 1:
        this.onehrs = true;
        this.twohrs = false;
        this.fourhrs = false;
        this.eighthrs = false;
        this.twehrs = false;
        this.twofourhrs = false;
        break;
      case 2:
        this.onehrs = false;
        this.twohrs = true;
        this.fourhrs = false;
        this.eighthrs = false;
        this.twehrs = false;
        this.twofourhrs = false;
        break;
      case 4:
        this.onehrs = false;
        this.twohrs = false;
        this.fourhrs = true;
        this.eighthrs = false;
        this.twehrs = false;
        this.twofourhrs = false;
        break;
      case 8:
        this.onehrs = false;
        this.twohrs = false;
        this.fourhrs = false;
        this.eighthrs = true;
        this.twehrs = false;
        this.twofourhrs = false;
        break; case 12:
        this.onehrs = false;
        this.twohrs = false;
        this.fourhrs = false;
        this.eighthrs = false;
        this.twehrs = true;
        this.twofourhrs = false;
        break;
      case 24:
        this.onehrs = false;
        this.twohrs = false;
        this.fourhrs = false;
        this.eighthrs = false;
        this.twehrs = false;
        this.twofourhrs = true;
        break;

    }

  }

  changeDate(){
    const today = this.scheduler.getDay();
    var routeSchedule = [];
    this.schedulerTime = this.scheduler;

    var device = this.deviceInfo;
    if (device.Day) {
      switch (today) {
        case 0: routeSchedule = device.Day.Sunday; break;
        case 1: routeSchedule = device.Day.Monday; break;
        case 2: routeSchedule = device.Day.Tuesday; break;
        case 3: routeSchedule = device.Day.Wednesday; break;
        case 4: routeSchedule = device.Day.Thursday; break;
        case 5: routeSchedule = device.Day.Friday; break;
        case 6: routeSchedule = device.Day.Saturday; break;
      }
    }
    this.route = routeSchedule;
    this.viewMap(this.routeSelected)
  }

  updateSchedule() {
    const today = this.scheduler.getDay();
    var routeSchedule = [];
    this.schedulerTime = this.scheduler;

    var device = this.deviceInfo;
    if (device.Day) {
      switch (today) {
        case 0: routeSchedule = device.Day.Sunday; break;
        case 1: routeSchedule = device.Day.Monday; break;
        case 2: routeSchedule = device.Day.Tuesday; break;
        case 3: routeSchedule = device.Day.Wednesday; break;
        case 4: routeSchedule = device.Day.Thursday; break;
        case 5: routeSchedule = device.Day.Friday; break;
        case 6: routeSchedule = device.Day.Saturday; break;
      }
    }
    this.route = routeSchedule;

  }

  speedlist = [];
  RegionLog = [];
  timelist = [];


  viewMap(route) {
    this.minimizeRightFlag = false;
    this.RegionLog = [];
    this.timelist = [];
    this.speedlist = [];
    this.locatelist = [];
    this.removeMarkers(this.markerlist);
    this.removePath(this.linelist);
    this.markerlist = [];
    this.linelist = [];
    this.lorrylist = [];
    this.positionlist = [];
    this.savinglist = [];
    this.imagelist = [];
    this.routeMarker = [];
    this.spinner.show();
    this.removeSavedPath();
    this.pathSavedlist = [];

    this.routeSelected = route;
    this.disposalArea = this.routeSelected.data.regions.find(e => e.id === this.routeSelected.data.mark);
    this.c = this.routeSelected.data.regions.findIndex(e => e.id === this.routeSelected.data.mark);


    const dateFrom = new Date(this.schedulerTime);

    this.activebutton(false);
    const startHrs = parseInt(route.startAt.split(':')[0]);
    const startMin = parseInt(route.startAt.split(':')[1]);

    dateFrom.setHours(startHrs);
    dateFrom.setMinutes(startMin);
    dateFrom.setSeconds(0);
    const dateto = new Date(dateFrom);

    const endHrs = parseInt(route.endAt.split(':')[0]);
    const endMin = parseInt(route.endAt.split(':')[1]);

    var h = 0;
    var m = 0;

    if (endHrs - startHrs >= 0) {
      if (endMin - startMin >= 0) {
        h = endHrs - startHrs;
        m = endMin - startMin;
      } else {
        h = endHrs - startHrs;
        if (h > 0)
          h = h - 1;
        m = endMin + 60 - startMin;
      }
    } else {
      h = endHrs + 24 - startHrs;
      if (endMin - startMin >= 0) {
        m = endMin - startMin;
      } else {
        m = endMin + 60 - startMin;
        h = h - 1;
      }
    }

    dateto.setHours(dateto.getHours() + h)
    dateto.setMinutes(dateFrom.getMinutes() + m)

    console.log(dateFrom);
    console.log(dateto);

    this.job = "Schedule - " + route.routeName + ' - ' + route.startAt + " -" + route.endAt;
    this.jobTime = null;
    this.historyFrom = null;
    this.historyTo = null;

    if (this.trackingService)
      this.trackingService.unsubscribe();
    route.data.regions.forEach(element => {
      let empty = [];
      this.RegionLog.push(empty);
      const paths = [];
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(element.centerCoordinate.latitude, element.centerCoordinate.longitude),
        icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
      })
      const infoWindow = new google.maps.InfoWindow({
        content: '<span>Region : ' + element.RouteName + '</span><br>'
      });
      marker.addListener('click', () => {
        this.map.setZoom(18);
        this.map.setCenter(marker.getPosition());
        infoWindow.open(marker.getMap(), marker);
      });
      marker.setMap(this.map);
      this.routeMarker.push(marker);
      element.coordinates.forEach(coor => {
        paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
      });
      const path = new google.maps.Polygon({
        paths: paths,
        strokeColor: 'black',
        fillColor: 'red',
        strokeOpacity: 1.0,
        strokeWeight: 1,
      });

      path.setMap(this.map);
      this.pathSavedlist.push(path);

    });

    var timestampFrom = dateFrom.getTime() / 1000;
    var timestampTo = dateto.getTime() / 1000;

    this.firestore.collection('Devices').doc(this.deviceInfo.ID).collection('Monitor', ref => ref.where('Timestamp', '>=', timestampFrom).where('Timestamp', '<=', timestampTo).orderBy('Timestamp', 'asc')).get().forEach(l => {
      this.createGraph();
      l.forEach((location) => {
        const time = new Date(location.data().Timestamp * 1000);

        if (time.getTime() >= (dateFrom.getTime())) {
          this.speedGraph.data.labels.push(time);
          if (location.data().Speed) {
            this.speedGraph.data.datasets[0].data.push((location.data().Speed).toFixed(2));
          } else {
            this.speedGraph.data.datasets[0].data.push(0);
          }
          dateFrom.setMinutes(dateFrom.getMinutes() + 1);
        }

        const same = this.locatelist.find(l => l === location.id);
        if (!same) {
          if (location.data().Speed) {
            this.speed = (location.data().Speed).toFixed(2) + 'km/h';
          } else {
            this.speed = '0 km/h';
          }
          this.speedlist.push(this.speed);


          const r = this.lorrylist.find(m => m.id === this.deviceInfo.PlateNumber);
          if (r) {
            r.setPosition(
              new google.maps.LatLng(location.data().Latitude,
                location.data().Longitude)
            )
            this.map.setCenter(new google.maps.LatLng(location.data().Latitude,
              location.data().Longitude))
          } else {
            const mark = {
              position: new google.maps.LatLng(location.data().Latitude,
                location.data().Longitude),
              map: this.map,
              icon: this.deviceInfo.LorryType === 'Garbage Truck' ? this.garbageUrl : this.roroUrl,
              date: new Date(location.data().Timestamp),
              id: this.deviceInfo.PlateNumber
            };
            const marker = new google.maps.Marker({
              ...mark
            });
            this.lorrylist.push(marker);
            marker.setMap(this.map);
            this.markerlist.push(marker);
            this.map.setCenter(new google.maps.LatLng(location.data().Latitude,
              location.data().Longitude))
            const infoWindow = new google.maps.InfoWindow({
              content: '<span>Vehicle : ' + mark.id + '</span><br>'
            });
            marker.addListener('click', () => {
              this.map.setZoom(18);
              this.map.setCenter(marker.getPosition());
              infoWindow.open(marker.getMap(), marker);
            });
          }

          this.locatelist.push(location.id);
          this.timelist.push(new Date(location.data().Timestamp * 1000));

          this.positionlist.push(new google.maps.LatLng(location.data().Latitude,
            location.data().Longitude));
          this.savinglist.push(new firebase.firestore.GeoPoint(location.data().Latitude,
            location.data().Longitude));

          this.map.setCenter(new google.maps.LatLng(location.data().Latitude,
            location.data().Longitude));


        }

      });
      this.speedGraph.update();

      if (this.positionlist.length > 1) {
        const list = [];
        if (this.trackingPath) {
          this.trackingPath.setMap(null);
        }
        for (let i = 0; i < this.positionlist.length; i++) {
          list.push(this.positionlist[i]);

          this.pathSavedlist.forEach((ab, index) => {
            const resultPath = google.maps.geometry.poly.containsLocation(
              this.positionlist[i],
              ab
            )
            if (resultPath) {
              const info = {
                message: "IN",
                time: this.timelist[i],
                position: this.positionlist[i],
              }
              if (this.RegionLog[index].length === 0) {
                this.RegionLog[index].push(info)
              }
              else if (this.RegionLog[index][this.RegionLog[index].length - 1]) {
                let m = this.RegionLog[index][this.RegionLog[index].length - 1].message;
                if (m !== 'IN') {
                  this.RegionLog[index].push(info)
                }
              }

              this.pathSavedlist[index].setMap(null);
              this.pathSavedlist[index] = new google.maps.Polygon({
                paths: this.pathSavedlist[index].getPaths(),
                strokeColor: 'black',
                fillColor: 'green',
                strokeOpacity: 1.0,
                strokeWeight: 2,
              })
              this.pathSavedlist[index].setMap(this.map);
            }
            else {
              const info = {
                message: "OUT",
                time: this.timelist[i],
                position: this.positionlist[i],
              }
              if (this.RegionLog[index][this.RegionLog[index].length - 1]) {
                let m = this.RegionLog[index][this.RegionLog[index].length - 1].message;
                if (m === 'IN') {
                  this.RegionLog[index].push(info)
                }
              }
            }
          })

        }
        this.trackingPath = new google.maps.Polyline({
          path: list,
          strokeColor: 'blue',
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });
        if (this.trackingLineMode)
          this.trackingPath.setMap(this.map);
        this.linelist.push(this.trackingPath);
        this.calculateCompletion();
        this.speedGraph.update();

        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.speedGraph.update();

        this.calculateCompletion();

      }
      if (l.size === 0) {
        this.toaster.info('No records')
      }
    });
  }

  trackingLineMode = true;

  enable() {
    this.trackingLineMode = true;
    if (this.trackingPath) {
      this.trackingPath.setMap(this.map);
    }
  }

  disable() {
    this.trackingLineMode = false;
    if (this.trackingPath) {
      this.trackingPath.setMap(null);
    }
  }

  viewInfo(route) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = '90%';
    dialogConfig.maxHeight = '90vw'
    dialogConfig.width = '95%';
    dialogConfig.maxWidth = '90vw';

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const data = {
      route: route,
      device: this.deviceInfo,
      date: this.schedulerTime,
    }
    dialogConfig.data = data;
    this.dialog.open(RouteInfoComponent, dialogConfig);

  }
  formatLabel(value: number) {
    return value;
  }
  onInputChange(event) {
    const position = this.positionlist[event.value]
    this.map.setCenter(position);
    this.lorrylist[0].setPosition(position);
    this.speed = this.speedlist[event.value];
  }

  currentselect = '';
  selectedArea = null;
  view(i) {
    this.currentRegionLog = this.RegionLog[i]
    this.selectedArea = i;
    this.map.setCenter(this.routeMarker[i].getPosition())
    this.resetIcon();
    this.routeMarker[i].setIcon('http://maps.google.com/mapfiles/ms/icons/red-dot.png');
    this.map.setZoom(18);
    this.currentselect = this.routeSelected.data.regions[i].RouteName
  }
  resetIcon() {
    this.routeMarker.forEach(e => {
      e.setIcon('http://maps.google.com/mapfiles/ms/icons/blue-dot.png')
    })
  }

  regionMarkerTimelist = [];

  viewInMap(item) {
    const marker = new google.maps.Marker({
      position: item.position
    });
    marker.setMap(this.map);
    this.regionMarkerTimelist.push(marker);
    const infoWindow = new google.maps.InfoWindow({
      content: '<span>Time : ' + item.time.getHours() + ':' + ("0" + item.time.getMinutes().toString()).slice(-2) + ':' + ("0" + item.time.getSeconds().toString()).slice(-2) + '</span><br>'
    });
    marker.addListener('click', () => {
      this.map.setZoom(18);
      this.map.setCenter(marker.getPosition());
      infoWindow.open(marker.getMap(), marker);
    });
    google.maps.event.trigger(marker, 'click');

  }
  clearMarker() {
    this.regionMarkerTimelist.forEach(e => {
      e.setMap(null)
    })
    this.regionMarkerTimelist = [];
  }
  completion = 0;
  text = '';
  calculateCompletion() {

    this.completion = 0;
    this.RegionLog.forEach(e => {
      if (e.length > 1)
        this.completion = this.completion + 1
    })

    this.text = ((this.completion / this.routeSelected.data.regions.length) * 100).toFixed(2) + '%';
  }

  cakculateTotalTimeSpent(i) {
    if (this.RegionLog[i].length === 0)
      return "0:00"
    else {
      var timeSpent = 0;
      var temptime = null;
      this.RegionLog[i].forEach(e => {
        if (!temptime) {
          temptime = e.time
          return;
        } else {
          timeSpent += e.time.getTime() - temptime.getTime();
          temptime = null;
        }
      })
      return this.msToTime(timeSpent);
    }
  }

  calculateCount(length) {
    return Math.ceil(length / 2);
  }

  msToTime(duration) {
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);

    let m = (minutes < 10) ? "0" + minutes : minutes;
    let s = (seconds < 10) ? "0" + seconds : seconds;

    return + m + ":" + s;
  }
  minimizeRightFlag = true;
  minimizeRight() {
    this.minimizeRightFlag = !this.minimizeRightFlag;
  }
  getColor() {
    const value = this.completion / this.routeSelected.data.regions.length * 100;
    if (value < 33) {
      return "#f53c56";
    } else if (value < 66) {
      return "#ffed06"
    } else {
      return "#5fe2a0"
    }
  }

  getWidth(value) {
    return value.toFixed(2) + '%';
  }
  createGraph() {
    if (this.speedGraph) {
      this.speedGraph.destroy();
    }
    this.speedGraph = new Chart('speedgraph', {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            borderColor: '#01b2f7',
            label: 'Kelajuan Kendaraan (km/h)',
            fill: false
          },
        ]
      },
      options: {
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              return "";
            },
            color: '#fff',
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'x'
            },
            zoom: {
              enabled: true,
              mode: 'x'
            }
          }
        },
        legend: {
          display: false
        },

        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
            },
            type: 'time',
            time: {
              unit: 'hour'
            },
            ticks: {
              stepSize: 1,
              autoSkip: false
            }

          }],
          yAxes: [{
            display: true
          }],
        },
        maintainAspectRatio: false,

      },
    });
  }

}
