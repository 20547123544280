import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-vehicle-detail',
  templateUrl: './vehicle-detail.component.html',
  styleUrls: ['./vehicle-detail.component.css']
})
export class VehicleDetailComponent implements OnInit {
  device = {
    ID: "",
    Depoh: "",
    LorryType: "",
    PlateNumber: "",
    RouteCovered: "",
    Scheduling: [],
    UpdatedBy: "",
    UpdatedAt: new Date(),
  };
  routeList = [];
  email: string;
  depoh = [];
  constructor(
    private dialogRef: MatDialogRef<VehicleDetailComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private toast: ToastrService,
    private angularFireAuth: AngularFireAuth,

  ) {
    this.depoh = data.depoh;
    console.log(this.depoh);
    this.device.ID= data.ID;
    this.device.LorryType= data.LorryType;
    this.device.PlateNumber= data.PlateNumber;
    this.device.RouteCovered= data.RouteCovered;

    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    })
  }

  ngOnInit(): void {

  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {

    if (!this.device.ID || !this.device.PlateNumber || !this.device.LorryType) {
      this.toast.warning('Information not completed', 'Please check')
      return;
    }



    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '400px';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      message: 'Do you want to update this vehicle ID? ' + this.device.ID,
    }

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        this.device.UpdatedAt = new Date();
        this.device.UpdatedBy = this.email;
        this.firestore.collection('Devices').doc(this.device.ID).update(this.device).then(r => {
          this.spinner.hide();
          this.toast.success('Updated successful');
          this.dialogRef.close(true);
        });
      }
    });
  }






}
