import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, AfterViewInit, ElementRef, Inject, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import Chart from 'chart.js';

@Component({
  selector: 'app-route-info',
  templateUrl: './route-info.component.html',
  styleUrls: ['./route-info.component.css']
})
export class RouteInfoComponent implements AfterViewInit {

  map: google.maps.Map;
  marker: google.maps.Marker;
  mapOptions: google.maps.MapOptions;
  @ViewChild('mapContainer1', { static: false }) gmap: ElementRef;
  coordinates: any;
  lat = 5.4141;
  lng = 100.3288;
  route:any;
  trackingLineMode = true;
  linelist = [];
  speedGraph: any;
  currentRegionLog=[];
  disposalArea : any;
  counter:any;

  constructor(
    private dialogRef: MatDialogRef<RouteInfoComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
  ) {
    this.route = data;
    this.disposalArea = this.route.route.data.regions.find(e=>e.id === this.route.route.data.mark);
    this.counter = this.route.route.data.regions.findIndex(e=>e.id === this.route.route.data.mark);

    }

  cancel() {
    this.dialogRef.close(false);
  }

  ngAfterViewInit(): void {
    this.coordinates = new google.maps.LatLng(this.lat, this.lng);
    if (this.speedGraph) {
      this.speedGraph.destroy();
    }
    this.speedGraph = new Chart('speedgraph', {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            borderColor: 'Blue',
            label: 'Kelajuan Kendaraan (km/h)',
            fill: false
          },
        ]
      },
      options: {
        plugins: {
          zoom: {
            pan: {
              enabled: true,
              mode: 'x'
            },
            zoom: {
              enabled: true,
              mode: 'x'
            }
          }
        },
        legend: {
          display: true
        },

        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Period'
            },
            type: 'time',
            time: {
              unit: 'hour'
            },
            ticks: {
              stepSize: 1,
              autoSkip: false
          }

          }],
          yAxes: [{
            display: true
          }],
        },
        responsive: true,
        maintainAspectRatio: false
      },
    });
    this.mapOptions = {
      center: this.coordinates,
      zoom: 15
    };
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.showMap();



  }
  pathSavedlist = [];
  locatelist = [];
  lorrylist = [];
  roroUrl = "https://img.icons8.com/dusk/50/000000/truck.png";
  garbageUrl = "https://img.icons8.com/dusk/64/000000/garbage-truck.png";
  markerlist = [];
  positionlist = [];
  savinglist = [];
  trackingPath:any;
  idle=[];
  RegionLog = [];
  timelist = [];
  regionMarker = [];
  showMap(){
    this.spinner.show();
    const dateFrom = new Date(this.route.date);

    const startHrs = parseInt(this.route.route.startAt.split(':')[0]);
    const startMin = parseInt(this.route.route.startAt.split(':')[1]);

    dateFrom.setHours(startHrs);
    dateFrom.setMinutes(startMin);
    dateFrom.setSeconds(0);
    const dateto = new Date(dateFrom);

    const endHrs = parseInt(this.route.route.endAt.split(':')[0]);
    const endMin = parseInt(this.route.route.endAt.split(':')[1]);

    var h = 0;
    var m = 0;

    if(endHrs - startHrs >= 0){
      if(endMin - startMin >= 0){
        h = endHrs - startHrs;
        m = endMin - startMin;
      }else{
        h = endHrs - startHrs;
        if(h > 0)
          h = h - 1;
        m = endMin + 60 - startMin;
      }
    }else{
      h = endHrs+24 - startHrs;
      if(endMin - startMin >=0){
        m = endMin - startMin;
      }else{
        m = endMin + 60 - startMin;
        h = h -1;
      }
    }

    dateto.setHours(dateto.getHours() + h )
    dateto.setMinutes(dateFrom.getMinutes() + m)

    this.route.route.data.regions.forEach(element => {
      let empty = [];
      this.RegionLog.push(empty);
      const paths = [];
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(element.centerCoordinate.latitude, element.centerCoordinate.longitude),
        icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
      })
      const infoWindow = new google.maps.InfoWindow({
        content: '<span>Region : ' + element.RouteName + '</span><br>'
      });
      marker.addListener('click', () => {
        this.map.setZoom(18);
        this.map.setCenter(marker.getPosition());
        infoWindow.open(marker.getMap(), marker);
      });
      marker.setMap(this.map);
      this.regionMarker.push(marker);
      element.coordinates.forEach(coor => {
        paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
      });
      const path = new google.maps.Polygon({
        paths: paths,
        strokeColor: 'black',
        fillColor: 'red',
        strokeOpacity: 1.0,
        strokeWeight: 1,
      });
      path.setMap(this.map);
      this.pathSavedlist.push(path);
    });

    var timestampFrom = dateFrom.getTime() / 1000;
    var timestampTo = dateto.getTime() / 1000;

    this.firestore.collection('Devices').doc(this.route.device.ID).collection('Monitor', ref => ref.where('Timestamp', '>=', timestampFrom).where('Timestamp', '<=', timestampTo).orderBy('Timestamp', 'asc')).get().forEach(l => {
      var count = 0;
      var currenttime;
      var endtime;
      var flag = true;
      var nexttime = new Date();

      l.forEach((location) => {

        if((!location.data().Speed || location.data().Speed === 0 ) && flag){
          currenttime = new Date(location.data().Timestamp);
          nexttime = new Date(currenttime);
          nexttime.setMinutes(currenttime.getMinutes() + 15);
          flag = false;
        } else if (location.data().Speed && !flag){
          if(location.data().Speed > 0 ){
            if(l.docs[count].data().Speed > location.data().Speed){

            } else{

              if(nexttime.getTime() < new Date(location.data().Timestamp).getTime()){
                endtime = new Date(location.data().Timestamp);

              }
            }
          }
        }
        if(currenttime && endtime){
          flag = true;
          const idletime = {
            starttime: currenttime,
            endtime
          }
          nexttime = null;
          currenttime = null;
          endtime = null;
          this.idle.push(idletime);
        }


        const time = new Date(location.data().Timestamp * 1000);

        if(time.getTime() >= (dateFrom.getTime())){
          this.speedGraph.data.labels.push(time);
          if (location.data().Speed) {
            this.speedGraph.data.datasets[0].data.push((location.data().Speed).toFixed(2));
          } else {
            this.speedGraph.data.datasets[0].data.push(0);
          }
          dateFrom.setMinutes(dateFrom.getMinutes()+1);
        }

        count++;

        const same = this.locatelist.find(l => l === location.id);
        if (!same) {
          const r = this.lorrylist.find(m => m.id === this.route.device.PlateNumber);
          if (r) {
            r.setPosition(
              new google.maps.LatLng(location.data().Latitude,
                location.data().Longitude)
            )
            this.map.setCenter(new google.maps.LatLng(location.data().Latitude,
              location.data().Longitude))
          } else {
            const mark = {
              position: new google.maps.LatLng(location.data().Latitude,
                location.data().Longitude),
              map: this.map,
              icon: this.route.device.LorryType === 'Garbage Truck' ? this.garbageUrl : this.roroUrl,
              id: this.route.device.PlateNumber
            };
            const marker = new google.maps.Marker({
              ...mark
            });
            this.lorrylist.push(marker);
            marker.setMap(this.map);
            this.markerlist.push(marker);
            this.map.setCenter(new google.maps.LatLng(location.data().Latitude,
              location.data().Longitude))
            const infoWindow = new google.maps.InfoWindow({
              content: '<span>Vehicle : ' + mark.id + '</span><br>'
            });
            marker.addListener('click', () => {
              this.map.setZoom(18);
              this.map.setCenter(marker.getPosition());
              infoWindow.open(marker.getMap(), marker);
            });
          }
          this.locatelist.push(location.id);
          this.timelist.push(new Date(location.data().Timestamp * 1000));
          this.positionlist.push(new google.maps.LatLng(location.data().Latitude,
            location.data().Longitude));
          this.savinglist.push(new firebase.firestore.GeoPoint(location.data().Latitude,
            location.data().Longitude));

          this.map.setCenter(new google.maps.LatLng(location.data().Latitude,
            location.data().Longitude));
        }
      });
      this.speedGraph.update();

      if (this.positionlist.length > 1) {
        const list = [];
        if (this.trackingPath) {
          this.trackingPath.setMap(null);
        }
        for (let i = 0; i < this.positionlist.length; i++) {
          list.push(this.positionlist[i]);

          this.pathSavedlist.forEach((ab, index) => {
            const resultPath = google.maps.geometry.poly.containsLocation(
              this.positionlist[i],
              ab
            )
            if (resultPath) {
              const info={
                message: "IN",
                time: this.timelist[i],
                position: this.positionlist[i],
              }
              if(this.RegionLog[index].length === 0){
                this.RegionLog[index].push(info)
              }
              else if(this.RegionLog[index][this.RegionLog[index].length-1]){
                let m = this.RegionLog[index][this.RegionLog[index].length-1].message;
                if(m !== 'IN'){
                  this.RegionLog[index].push(info)
                }
              }
              this.pathSavedlist[index].setMap(null);
              this.pathSavedlist[index] = new google.maps.Polygon({
                paths: this.pathSavedlist[index].getPaths(),
                strokeColor: 'black',
                fillColor: 'green',
                strokeOpacity: 1.0,
                strokeWeight: 2,
              })

              this.pathSavedlist[index].setMap(this.map);
            }else{
              const info={
                message: "OUT",
                time: this.timelist[i],
                position: this.positionlist[i],
              }
              if(this.RegionLog[index][this.RegionLog[index].length-1]){
                let m = this.RegionLog[index][this.RegionLog[index].length-1].message;
                if(m === 'IN'){
                  this.RegionLog[index].push(info)
                }
              }
            }
          })

        }
        this.trackingPath = new google.maps.Polyline({
          path: list,
          strokeColor: 'blue',
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });
        if(this.trackingLineMode)
          this.trackingPath.setMap(this.map);
        this.linelist.push(this.trackingPath);
        this.spinner.hide();
        this.speedGraph.update();
        this.calculateCompletion();

      } else {
        this.spinner.hide();
        this.speedGraph.update();
        this.calculateCompletion();

      }
      if (l.size === 0) {
        this.toaster.info('No records')
      }
    });
  }

  enable(){
    this.trackingLineMode = true;
    this.trackingPath.setMap(this.map);
  }

  disable(){
    this.trackingLineMode = false;
    this.trackingPath.setMap(null);
  }

  formatLabel(value: number) {
    return value;
  }
  onInputChange(event){
    const position = this.positionlist[event.value]
    this.map.setCenter(position);
    this.lorrylist[0].setPosition(position);
  }

  currentselect='';
  view(i){
    this.currentRegionLog = this.RegionLog[i]
    this.map.setCenter(this.regionMarker[i].getPosition())
    this.resetIcon();
    this.regionMarker[i].setIcon('http://maps.google.com/mapfiles/ms/icons/red-dot.png');
    this.map.setZoom(18);
    this.currentselect = this.route.route.data.regions[i].RouteName
  }

  resetIcon(){
    this.regionMarker.forEach(e=>{
      e.setIcon('http://maps.google.com/mapfiles/ms/icons/blue-dot.png')
    })
  }

  regionMarkerTimelist = [];

  viewInMap(item){
    const marker = new google.maps.Marker({
       position:item.position
    });
    marker.setMap(this.map);
    this.regionMarkerTimelist.push(marker);
    const infoWindow = new google.maps.InfoWindow({
      content: '<span>Time : ' + item.time.getHours() + ':' +("0" + item.time.getMinutes().toString()).slice(-2)  +':' + ("0" + item.time.getSeconds().toString()).slice(-2)  + '</span><br>'
    });
    marker.addListener('click', () => {
      this.map.setZoom(18);
      this.map.setCenter(marker.getPosition());
      infoWindow.open(marker.getMap(), marker);
    });
   google.maps.event.trigger(marker,'click');

  }

  clearMarker(){
    this.regionMarkerTimelist.forEach(e=>{
      e.setMap(null)
    })
    this.regionMarkerTimelist = [];
  }
  completion = 0;
  text ='';
  calculateCompletion(){

    this.completion = 0;
    this.RegionLog.forEach(e=>{
      if(e.length > 1)
        this.completion = this.completion+1
    })

     this.text = ((this.completion/ this.route.route.data.regions.length) * 100 ).toFixed(2) + '%';
  }

  cakculateTotalTimeSpent(i){
    if(this.RegionLog[i].length === 0)
       return "0:00"
    else{
      var timeSpent = 0;
      var temptime = null;
      this.RegionLog[i].forEach(e=>{
        if(!temptime){
          temptime = e.time
          return;
        }else{
          timeSpent += e.time.getTime() - temptime.getTime();
          temptime = null;
        }
      })
      return this.msToTime(timeSpent);
    }
  }

  calculateCount(length){
    return Math.ceil(length/2);
  }

  msToTime(duration) {
    let  seconds = Math.floor((duration / 1000) % 60);
    let  minutes = Math.floor((duration / (1000 * 60)) % 60);

    let m = (minutes < 10) ? "0" + minutes : minutes;
    let s = (seconds < 10) ? "0" + seconds : seconds;

    return + m + ":" + s ;
  }


}
